import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { AuthApi } from "../apis/auth.api";
import { adminRolesSelector } from "../states/admin";

let isFetchRolesLoading = false;
function useFetchRoles() {
  const [roles, setRoles] = useRecoilState(adminRolesSelector);

  useEffect(() => {
    if (!roles || !roles.length) {
      fetchData();
    }
  }, []);

  async function fetchData() {
    if (isFetchRolesLoading) {
      return;
    }
    isFetchRolesLoading = true;
    try {
      const { data } = await AuthApi.getRoles();
      setRoles(data);
    } catch {}

    isFetchRolesLoading = false;
  }

  return roles;
}

export default useFetchRoles;
