import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import {AppConfig} from "./configs/app.config"

const firebaseConfig = AppConfig.firebaseConfig;

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
export const getMessagingToken = async () => {
  let currentToken = "";
  if (!messaging) return;
  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
  return currentToken;
};
