import { atom } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";


const socialDashboardDateRangeState = atom({
  key: APP_STATE_KEYS.SOCIAL_DASHBOARD_DATE_RANGE_STATE,
  default: {
    startDate: "",
    endDate: "",
    totalDays: 0,
    value: "",
  },
});

export {
  socialDashboardDateRangeState
};
