import { Collapse, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthApi } from "../../../../apis/auth.api";
import { IntegrationPanelKey } from "../../../../constants/app.enums";
import { GFIntegrationModel } from "../../../../models/gf.model";
import { connectionState, integrationState } from "../../../../states/clientSites";
import SVGIcons from "../../../icons/svgs";
import Birdeye from "../../../views/client-sites/intergration-form/birdeye";
import CallRail from "../../../views/client-sites/intergration-form/call-rail";
import GoogleAnalytics from "../../../views/client-sites/intergration-form/google-analytics";
import GoogleMyBusiness from "../../../views/client-sites/intergration-form/google-my-business";
import GravityForm from "../../../views/client-sites/intergration-form/gravity-form";
import UniversalAnalytics from "../../../views/client-sites/intergration-form/universal-analytic";
import "./index.scss";

const { Panel } = Collapse;

interface IntegrationCollapseProps {
  clientId: number;
}

const InsightHubIntegrationCollapse = (props: IntegrationCollapseProps) => {
  const { clientId } = props;
  const { t } = useTranslation();
  const [integrationLoading, setIntegrationLoading] = useState<boolean>(false)

  const { loading } = useRecoilValue(connectionState);
  const [integrationValues, setIntegrationValues] =
    useRecoilState(integrationState);

  const initListIntegration = async () => {
    if (!clientId) return;
    setIntegrationLoading(true)
    try {
      const { data } = await AuthApi.getIntegrationClient(clientId);
      setIntegrationValues(data);
    } finally {
      setIntegrationLoading(false)
    }
   
  };

  const getDescriptionGA = () => {
    if (!integrationValues?.gaIntegrations[0]?.gaProperty?.googleConnection)
      return "";
    const { gaProperty } = integrationValues?.gaIntegrations[0];
    const { googleConnection } = gaProperty;
    return `${googleConnection.givenName} ${googleConnection.familyName} - ${gaProperty?.displayName}`;
  };

  const getDescriptionGMB = () => {
    if (!integrationValues?.gmbIntegrations[0]?.gmbLocation?.googleConnection)
      return "";
    const { gmbLocation } = integrationValues?.gmbIntegrations[0];
    return `${gmbLocation.address}`;
  };

  const isInvalidGA = useMemo(() => {
    return (
      integrationValues?.gaIntegrations[0]?.gaProperty?.googleConnection
        ?.isInvalid ||
      integrationValues?.gaIntegrations[0]?.gaProperty?.isInvalid
    );
  }, [integrationValues]);

  const isInvalidGMB = useMemo(() => {
    return (
      integrationValues?.gmbIntegrations[0]?.gmbLocation?.googleConnection
        ?.isInvalid ||
      integrationValues?.gmbIntegrations[0]?.gmbLocation?.isInvalid
    );
  }, [integrationValues]);

  const isInvalidBirdeye = useMemo(() => {
    return integrationValues?.beIntegrations[0]?.isInvalid;
  }, [integrationValues]);

  const isInvalidCallrail = useMemo(() => {
    return integrationValues?.crIntegrations[0]?.isInvalid;
  }, [integrationValues]);

  const isInvalidGF = useCallback(
    (integration: GFIntegrationModel) => {
      return integration?.isInvalid;
    },
    [integrationValues]
  );

  useEffect(() => {
    initListIntegration();
  }, [clientId]);
  return (
    <Spin spinning={loading || integrationLoading}>
      <Collapse
        className="site-collapse-custom-collapse-integration"
        bordered={false}
        expandIconPosition={"end"}
      >
        {integrationValues?.gaIntegrations[0] &&
          (integrationValues?.gaIntegrations[0].gaProperty ? (
            <Panel
              header={
                <>
                  <div className="title">
                    {t("clientSites.googleAnalytics")}{" "}
                    {isInvalidGA ? <SVGIcons.AlertTriangleIcon /> : ""}
                  </div>
                  <div className="description">{getDescriptionGA()}</div>
                </>
              }
              key={IntegrationPanelKey.GoogleAnalytics}
              className={isInvalidGA ? "invalid" : ""}
            >
              <Spin spinning={loading}>
                <GoogleAnalytics />
              </Spin>
            </Panel>
          ) : (
            <Panel
              header={
                <>
                  <div className="title">
                    {t("clientSites.googleAnalytics")} (Legacy)
                  </div>
                  <div className="description">
                    {integrationValues?.gaIntegrations[0].viewName}
                  </div>
                </>
              }
              key={IntegrationPanelKey.GoogleAnalytics}
              className="UA-panel"
            >
              <Spin spinning={loading}>
                <UniversalAnalytics />
              </Spin>
            </Panel>
          ))}
        {integrationValues?.gmbIntegrations[0] && (
          <Panel
            header={
              <>
                <div className="title">
                  {t("clientSites.googleMyBusiness")}{" "}
                  {isInvalidGMB ? <SVGIcons.AlertTriangleIcon /> : ""}
                </div>
                <div className="description">{getDescriptionGMB()}</div>
              </>
            }
            key={IntegrationPanelKey.GoogleMyBusiness}
            className={isInvalidGMB ? "invalid" : ""}
          >
            <Spin spinning={loading}>
              <GoogleMyBusiness />
            </Spin>
          </Panel>
        )}
        {integrationValues?.beIntegrations[0] && (
          <Panel
            header={
              <>
                <div className="title">
                  {t("clientSites.birdeye")}{" "}
                  {isInvalidBirdeye ? <SVGIcons.AlertTriangleIcon /> : ""}
                </div>
                <div className="description">
                  {integrationValues?.beIntegrations[0].businessId}
                </div>
              </>
            }
            key={IntegrationPanelKey.Birdeye}
            className={isInvalidBirdeye ? "invalid" : ""}
          >
            <Birdeye />
          </Panel>
        )}
        {integrationValues?.crIntegrations[0] && (
          <Panel
            header={
              <>
                <div className="title">
                  {t("clientSites.callRail")}{" "}
                  {isInvalidCallrail ? <SVGIcons.AlertTriangleIcon /> : ""}
                </div>
                <div className="description">
                  {integrationValues?.crIntegrations[0].companyId}
                </div>
              </>
            }
            key={IntegrationPanelKey.CallRail}
            className={isInvalidCallrail ? "invalid" : ""}
          >
            <CallRail />
          </Panel>
        )}
        {integrationValues?.gfIntegrations.map(
          (integration: GFIntegrationModel, index) => {
            return (
              <Panel
                header={
                  <>
                    <div className="title">
                      {t("clientSites.gravityForms")}{" "}
                      {isInvalidGF(integration) ? (
                        <SVGIcons.AlertTriangleIcon />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="description">{integration.description}</div>
                  </>
                }
                key={`${IntegrationPanelKey.GravityForm}${index}`}
                className={isInvalidGF(integration) ? "invalid" : ""}
              >
                <GravityForm integrationInfo={integration} />
              </Panel>
            );
          }
        )}
      </Collapse>
    </Spin>
  );
};

export default InsightHubIntegrationCollapse;
