import { Input, Select } from 'antd';
import { useState } from 'react';
import useFetchTimezones from '../../../hooks/useFetchTimezones';
import { TimezonesModel } from '../../../models/timezones.model';
import DateUtils from '../../../utils/date.utils';
import SVGIcons from '../../icons/svgs';
import "./index.scss";

interface AppSelectTimezoneProps {
    onChange: (values: string) => void;
    value?: string
}

const AppSelectTimezone = (props: AppSelectTimezoneProps) => {
    const { onChange, value } = props
    const [searchTimeZone, setSearchTimeZone] = useState<string>("");
    const timezones = useFetchTimezones();

    function displayTimezone(option: TimezonesModel) {
        const { displayName } = option;
        const indexSearch = displayName
            .toLocaleLowerCase()
            .indexOf(searchTimeZone.toLocaleLowerCase());
        return (
            <div className="timezone__display">
                <div className="timezone__display-name">
                    {displayName.substring(0, indexSearch)}
                    <span className="timezone__display-highlight">
                        {displayName.substring(indexSearch, indexSearch + searchTimeZone.length)}
                    </span>
                    {displayName.substring(indexSearch + searchTimeZone.length)}
                </div>
            </div>
        );
    }

    const onSearchTimeZone = (e: any) => {
        setSearchTimeZone(e.target.value);
    };


    function sortTimeZones() {
        const sortedTimeZones = [];
        if(timezones && timezones.records?.length > 0) {
            for (const timeZone of timezones.records) {
                if (value) {
                    if (timeZone.id === value) {
                        sortedTimeZones.unshift(timeZone);
                    }
                    else {
                        sortedTimeZones.push(timeZone);
                    }
                }
                else {
                    sortedTimeZones.push(timeZone);
                }
            }
        }
        return sortedTimeZones;
       
    }

    const getSelectedValue = () => {
        if(value) {
            return timezones.records?.find(x => x.id === value)?.id
        }
        else {
            DateUtils.getDefaultTimeZone(timezones.records)
        }
    }

    return (
        <div className='timezone'>
            <Select
                loading={timezones.loading}
                value={getSelectedValue()}
                className='timezone__select'
                onChange={(value) => onChange(value)}
                dropdownRender={(menu) => (
                    <>
                        <div className="timezone__search">
                            <Input
                                className='timezone__input'
                                value={searchTimeZone}
                                size="small"
                                suffix={
                                    <span className="timezone__icon">
                                        <SVGIcons.SearchIcon />
                                    </span>
                                }
                                onChange={onSearchTimeZone}
                                onBlur={() => setSearchTimeZone("")}
                            />
                        </div>
                        {menu}
                    </>
                )}
            >
                {sortTimeZones()
                    ?.filter((item) =>
                        item.displayName
                            .toLocaleLowerCase()
                            .includes(searchTimeZone.toLocaleLowerCase())
                    )
                    .map((option, index) => {
                        return (
                            <Select.Option
                                value={option.id}
                                key={index}
                            >
                                {displayTimezone(option)}
                            </Select.Option>
                        );
                    })}
            </Select>
        </div>
    )
}

export default AppSelectTimezone