import { useLocation } from "react-router-dom";
import { AdminPrefixRouter } from "../constants/router.constants";

function useAdminSite(): boolean {
  const { pathname } = useLocation();

  return pathname.startsWith(AdminPrefixRouter);
}

export default useAdminSite;
