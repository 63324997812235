import SocialProfilesTable from "../../social/setting/social-profiles-table";

function GeneralSettingSocialProfiles() {
  return (
    <div className="general-setting-social-profile">
      <SocialProfilesTable />
    </div>
  );
}

export default GeneralSettingSocialProfiles;
