import { Button, Drawer, Spin } from "antd";
import { useEffect, useState } from "react";
import { SocialApi } from "../../../../../apis/social.api";
import { ContenApprovalModel } from "../../../../../models/approval.model";
import { ApprovalAcvitityModel } from "../../../../../models/content.model";
import ActivityApprovalItem from "../../social-content/components/activity-approval-item";
import "./index.scss";

interface Props {
  approvalInfo?: ContenApprovalModel | undefined;
  open: boolean;
  close: () => void;
}

const ApprovalActivityDrawer = (props: Props) => {
  const { approvalInfo, open, close } = props;
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState<ApprovalAcvitityModel[]>([]);

  const initListActivities = async (approvalId: number) => {
    setLoading(true);
    try {
      const { data } = await SocialApi.getListApprovalActivities(
        approvalId,
        1,
        999
      );
      setActivities(data.records);
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    if (approvalInfo?.id) initListActivities(approvalInfo?.id);
  }, [approvalInfo?.id]);
  return (
    <Drawer
      className="app-edit-drawer"
      title={"Activity"}
      open={open}
      width={480}
      onClose={() => close()}
      maskClosable={false}
      footer={
        <div className="drawer-footer-wrapper">
          <Button type="text" onClick={() => close()}>
            Close
          </Button>
        </div>
      }
    >
      <Spin spinning={loading}>
        <div className="activity-tab">
          {activities.map((activity, index) => {
            return (
              <ActivityApprovalItem
                activityInfo={activity}
                approval={approvalInfo}
                key={index}
              />
            );
          })}
        </div>
      </Spin>
    </Drawer>
  );
};

export default ApprovalActivityDrawer;
