import { Button } from "antd";
import { useTranslation } from "react-i18next";
import GraphicSocialImg from "../../../../assets/images/graphic_social.png";
import useSocial from "../../../../hooks/useSocial";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import useContent from "../../../../hooks/useContent";
import CreateContent from "../social-content/create-content";
import useUpdateContent from "../social-content/hook/useUpdateContent";
import { useSearchParams } from "react-router-dom";
import { PermissionSocial } from "../../../../constants/app.enums";
import useClient from "../../../../hooks/useClient";
interface AddConnectProps {
  connectProfile: () => Promise<void>;
  loadingConnect: boolean;
}

const AddConnect = (props: AddConnectProps) => {
  const { t } = useTranslation();
  const { connectProfile, loadingConnect } = props;
  const { createContent, isValidContent } = useUpdateContent();
  const [searchParams] = useSearchParams();
  const { hasPermissionSocial } = useClient();
 
  const {
    setOpenCreateModal,
    openCreateModal,
    initListContent
  } = useContent();

  const handleCreateContent = async () => {
    if (!(await isValidContent())) return;
    const isSuccess = await createContent();
    if(isSuccess) {
      setOpenCreateModal(false);
      if (searchParams.has("page")) {
        const pageIndex = Number(searchParams.get("page"));
        await initListContent({ pageIndex });
      } else {
        await initListContent();
      }
    }
  };

  return (
    <div className="add-connect-wrapper">
      <div className="images">
        <img src={GraphicSocialImg} alt="" />
      </div>
      <div className="title">{t("social.addConnectTitle")}</div>
      <div className="description">{t("social.addConnectDescription")}</div>
      {hasPermissionSocial(PermissionSocial.CanManage) && (
      <div className="btn-connect-wrapper" >
        <div>
          <Button
            type="primary"
            onClick={() => setOpenCreateModal(true)}
          >
            <SVGIcons.PlusIcon /> {t("social.createDraft")}
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            className="secondary-btn btn-connect"
            onClick={connectProfile}
            loading={loadingConnect}
          >
            <SVGIcons.PlusIcon /> {t("social.connectProfile")}
          </Button>
        </div>
      </div>
      )}

      <CreateContent
        open={openCreateModal}
        close={() => setOpenCreateModal(false)}
        onCreateContent={handleCreateContent}
      />
    </div>
  );
};

export default AddConnect;
