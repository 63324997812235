import { Button, Form, Input } from "antd";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FailedAnimation from "../../../../assets/animations/failed.gif";
import { SUPPORT_EMAIL, VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import showNotification from "../../../common/notification";
import "./index.scss";

function AccountVerificationError() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  function resendEmail(value: any) {
    setLoading(true);
    Auth.resendSignUp(value.email)
      .then(() => {
        showNotification("success", t("common.sentEmail"));
      })
      .catch((error) => {
        showNotification("error", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className="account-verification-error auth__form">
        <div className="account-verification-success__animation">
          <img src={FailedAnimation} />
        </div>
        <div className="header">{t("accountVerification.errorTitle")}</div>
        <p className="description">
          {t("accountVerification.errorDescription")}
        </p>

        <Form
          name="activationForm"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          onFinish={resendEmail}
        >
          <Form.Item
            label={t("signUp.businessEmail")}
            name="email"
            rules={[{ required: true }, { type: "email" }]}
          >
            <Input placeholder={t("signUp.businessEmailPlahoder")} />
          </Form.Item>

          <Button htmlType="submit" type="primary" block loading={loading}>
            {t("accountVerification.resentBtn")}
          </Button>
        </Form>
      </div>

      <a
        href={`mailto:${SUPPORT_EMAIL}`}
        className="auth__backLink"
        dangerouslySetInnerHTML={{
          __html: t("accountVerification.contactLink"),
        }}
      ></a>
    </>
  );
}

export default AccountVerificationError;
