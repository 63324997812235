import { TagModel } from "../../../../../../models/content.model";
import SVGIcons from "../../../../../icons/svgs";
import { TagSocialStyled } from "./index.style";

interface TagSocialProps {
  text: string;
  color: string;
  canDelete?: boolean;
  size?: "small" | "medium" | "custom";
  onClick?: () => void;
  onRemove?: (tag: TagModel) => void;
}

const TagSocial = (props: TagSocialProps) => {
  const { text, color, canDelete, size = "small", onClick, onRemove } = props;

  const properties = () => {
    switch (size) {
      case "small":
        return {
          heightDiv: "16px",
          heightSpan: "12px",
          "font-size": "10px",
          "line-height": 1.6,
          "letter-spacing": "0.02px",
        };
      case "medium":
        return {
          height: "21px",
          heightSpan: "17px",
          "font-size": "14px",
          "line-height": 1.43,
          "letter-spacing": "0.03px",
        };
      case "custom": 
      return {
        height: "24px",
        "font-size": "12px",
        "font-weight": 500,
        "line-height": 16,
        "letter-spacing": "0.02px",
        "border-radius": "12px"
      };
    }
  };

  return (
    <TagSocialStyled
      color={color}
      properties={properties()}
      className="tag-social"
      onClick={canDelete ? onRemove : onClick}
    >
      <span>{text}</span>
      {canDelete && <SVGIcons.CloseIcon />}
    </TagSocialStyled>
  );
};

export default TagSocial;
