import { AxiosResponse } from "axios";
import qs from "qs";
import {
  CreateIdeaModel,
  GetIdeasModel,
  IdeaModel,
  ResultGenerateAIModel,
  SuggestIdeasAIModel,
} from "../models/ai.model";
import {
  ApproveContentApprovalRequest,
  ContenApprovalModel,
  CreateContentApprovalRequest,
  GetContentApprovalDetailRequest,
  GetContentApprovaslRequest,
  UpdateContentApprovalRequest,
} from "../models/approval.model";
import { ListDataResponse } from "../models/common.model";
import {
  AcvitityModel,
  ApprovalAcvitityModel,
  CommentModel,
  ContentForShareModel,
  ContentInteractionModel,
  ContentModel,
  CreateCommentRequest,
  CreateContentRequest,
  CreateGuestCommentRequest,
  CreateHashtagRequest,
  CreateTagRequest,
  DeleteContentRequest,
  EditHashtagRequest,
  EditTagRequest,
  GBPCategoryModel,
  GetListCommentRequest,
  GetListContentForApprovalRequestModel,
  GetListContentRequestModel,
  GetListMediaParams,
  ListCommentModel,
  ListContentModel,
  MediaModel,
  SocialContentModel,
  UploadMediaModel,
  YoutubeCategoryModel,
} from "../models/content.model";
import { PlanUsageModel } from "../models/plan.model";
import {
  AgesReportRowModel,
  CountriesReportModel,
  CreateShortLinkRequest,
  DashboardCategoryModel,
  DashboardHashtagModel,
  FollowerReportModel,
  GetAgesAudienceParams,
  GetCountriesAudienceParams,
  GetDashboardCategoryParams,
  GetDashboardHashTagParams,
  GetFollowersParams,
  GetPostTypeParams,
  GetTimePostParams,
  GetTopPlatformContentsParams,
  GetTotalEngagementParams,
  GetWeekPostParams,
  PostTypeModel,
  SocialDateReportModel,
  SocialProfileModel,
  TimePostModel,
  WeekPostModel,
} from "../models/social.model";
import {
  deleteAsync,
  getAsync,
  postAsync,
  postFormDataAsync,
  putAsync,
} from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/social/api/v1`;

/* Profile */
function connectProfile(clientId?: number) {
  const url = `${baseUrl}/profile/connect-profile`;
  return postAsync(url, { clientId });
}

function getSsoUrl(params: {
  clientId?: number;
  redirectPath?: string;
}): Promise<AxiosResponse<string>> {
  const url = `${baseUrl}/profile/get-sso-url`;
  return getAsync(url, params);
}

function retrieveConnectProfile(params: {
  clientId?: number;
  isResync?: boolean;
}): Promise<AxiosResponse<SocialProfileModel, any>> {
  const url = `${baseUrl}/profile/retrieve-connect-profile`;
  return getAsync(url, params);
}

function syncConnectedProfiles(
  clientId: number
): Promise<AxiosResponse<SocialProfileModel, any>> {
  const url = `${baseUrl}/profile/sync-connected-profiles/${clientId}`;
  return getAsync(url, undefined, undefined, false, true);
}

function deletePlatform(
  id: number,
  clientId?: number
): Promise<AxiosResponse<any, any>> {
  const url = `${baseUrl}/profile/delete-platform/${id}?clientId=${clientId}`;
  return deleteAsync(url);
}

/* Content */

function getContent(
  request: GetListContentRequestModel
): Promise<AxiosResponse<ListContentModel, any>> {
  const url = `${baseUrl}/content`;
  return getAsync(url, request, true, true, false, "repeat");
}

function getContentDetail(
  contentId: number
): Promise<AxiosResponse<ContentModel, any>> {
  const url = `${baseUrl}/content/${contentId}`;
  return getAsync(url);
}

function getContentDetailForShare(
  contentId: number
): Promise<AxiosResponse<ContentForShareModel, any>> {
  const url = `${baseUrl}/content/${contentId}/for-share`;
  return getAsync(url);
}

function getContentForApproval(
  request: GetListContentForApprovalRequestModel
): Promise<AxiosResponse<ListDataResponse<ContentModel>, any>> {
  const url = `${baseUrl}/content/for-approval`;
  return getAsync(url, request, true, true, false, "repeat");
}

function getContentCalendar(
  request: GetListContentRequestModel
): Promise<AxiosResponse<ListContentModel, any>> {
  const url = `${baseUrl}/content/calendar`;
  return getAsync(url, request);
}

function createContent(
  request: CreateContentRequest
): Promise<AxiosResponse<ContentModel, any>> {
  const url = `${baseUrl}/content`;
  return postAsync(url, request);
}

function editContent(
  request: CreateContentRequest
): Promise<AxiosResponse<ContentModel, any>> {
  const url = `${baseUrl}/content`;
  return putAsync(url, request);
}

function deleteContent(request: DeleteContentRequest) {
  const { contentId, platformIds } = request;
  const query = qs.stringify({ platformIds }, { arrayFormat: "repeat" });
  const url = `${baseUrl}/content/${contentId}/delete-platforms?${query}`;
  return deleteAsync(url);
}

function approveContent(contentId: number) {
  const url = `${baseUrl}/content/${contentId}/approve`;
  return putAsync(url);
}

function rejectContent(contentId: number, reason: string) {
  const url = `${baseUrl}/content/${contentId}/reject`;
  return putAsync(url, { reason });
}

function toggleRequireApproval(contentId: number, requireApproval: boolean) {
  const url = `${baseUrl}/content/${contentId}/toggle-require-approval`;
  return putAsync(url, { requireApproval });
}

function publishContent(
  contentId: number
): Promise<AxiosResponse<ContentModel, any>> {
  const url = `${baseUrl}/content/${contentId}/publish`;
  return putAsync(url);
}

function scheduleContent(contentId: number, scheduleOn: string) {
  const url = `${baseUrl}/content/${contentId}/schedule`;
  return putAsync(url, { scheduleOn }, false, true, false);
}

function getContentInteraction(
  platformContentIds: number[]
): Promise<AxiosResponse<ContentInteractionModel[]>> {
  const url = `${baseUrl}/content/interaction`;
  return getAsync(
    url,
    { platformContentIds: platformContentIds },
    false,
    false,
    false
  );
}
function getPostTypeContent(params: { clientId: number }) {
  const url = `${baseUrl}/content/used-post-types`;
  return getAsync(url, params);
}
function getYoutubeCategories(
  region: string
): Promise<AxiosResponse<YoutubeCategoryModel[]>> {
  const url = `${baseUrl}/content/youtube-categories`;
  return getAsync(url, { region });
}
function getGBPCategories(): Promise<AxiosResponse<GBPCategoryModel[]>> {
  const url = `${baseUrl}/content/gbp-categories`;
  return getAsync(url);
}

/* Content Approval */
function getContentApprovals(
  request?: GetContentApprovaslRequest
): Promise<AxiosResponse<ListDataResponse<ContenApprovalModel>, any>> {
  const url = `${baseUrl}/approvals`;
  return getAsync(url, request, true, true, false, "repeat");
}

function createContentApproval(request: CreateContentApprovalRequest) {
  const url = `${baseUrl}/approvals`;
  return postAsync(url, request);
}

function updateContentApproval(request: UpdateContentApprovalRequest) {
  const url = `${baseUrl}/approvals`;
  return putAsync(url, request);
}

function getApprovalDetail(approvalId: number) {
  const url = `${baseUrl}/approvals/${approvalId}`;
  return getAsync(url);
}

function getContentApprovalDetail(
  request: GetContentApprovalDetailRequest
): Promise<AxiosResponse<ListDataResponse<ContentModel>, any>> {
  const url = `${baseUrl}/approvals/${request.id}/contents`;
  return getAsync(url, request);
}

function getProfileApprovalDetail(approvalId: number) {
  const url = `${baseUrl}/approvals/${approvalId}/client-profile`;
  return getAsync(url);
}

function deleteContentApproval(approvalId: number) {
  const url = `${baseUrl}/approvals/${approvalId}`;
  return deleteAsync(url);
}

function approveContentApproval(
  approvalId: number,
  request: ApproveContentApprovalRequest
) {
  const url = `${baseUrl}/approvals/${approvalId}/approve`;
  return postAsync(url, request);
}

function declineContentApproval(
  approvalId: number,
  request: ApproveContentApprovalRequest
) {
  const url = `${baseUrl}/approvals/${approvalId}/decline`;
  return postAsync(url, request);
}

function notifyApprover(approvalId: number) {
  const url = `${baseUrl}/approvals/${approvalId}/notify-approver`;
  return postAsync(url);
}

/* Media */
function getMedia(
  model: GetListMediaParams
): Promise<AxiosResponse<ListDataResponse<MediaModel>, any>> {
  const url = `${baseUrl}/media`;
  return getAsync(url, model);
}

function uploadMedia(
  model: UploadMediaModel,
  signal?: AbortSignal
): Promise<AxiosResponse<MediaModel, any>> {
  const url = `${baseUrl}/media`;
  return postFormDataAsync(url, model, false, false, false, { signal });
}

function deleteMedia(id: number) {
  const url = `${baseUrl}/media/${id}`;
  return deleteAsync(url);
}

function getPresignedUrl(fileName: string) {
  const url = `${baseUrl}/media/presigned-url/${fileName}`;
  return getAsync(url);
}

function renameMedia(
  id: number,
  newName: string
): Promise<AxiosResponse<MediaModel, any>> {
  const url = `${baseUrl}/media/rename/${id}?newName=${newName}`;
  return putAsync(url);
}

function duplicateMedia(id: number) {
  const url = `${baseUrl}/media/duplicate/${id}`;
  return putAsync(url);
}

/* Tag */

function getListTag(params: { clientId?: number }) {
  const url = `${baseUrl}/tag`;
  return getAsync(url, params);
}

function createTag(request: CreateTagRequest) {
  const url = `${baseUrl}/tag`;
  return postAsync(url, request);
}

function removeTag(id: number) {
  const url = `${baseUrl}/tag/${id}`;
  return deleteAsync(url);
}

function editTag(request: EditTagRequest) {
  const url = `${baseUrl}/tag`;
  return putAsync(url, request);
}

/* Activities */

function getListActivities(
  contentId: number
): Promise<AxiosResponse<AcvitityModel[], any>> {
  const url = `${baseUrl}/content/${contentId}/activities`;
  return getAsync(url);
}

function getListApprovalActivities(
  approvalId: number,
  pageIndex: number,
  pageSize: number
): Promise<AxiosResponse<ListDataResponse<ApprovalAcvitityModel>, any>> {
  const url = `${baseUrl}/approvals/activities?BulkApprovalId=${approvalId}&PageIndex=${pageIndex}&PageSize=${pageSize}`;
  return getAsync(url);
}

/* Comment */

function getListComment(
  request: GetListCommentRequest
): Promise<AxiosResponse<ListCommentModel, any>> {
  const url = `${baseUrl}/comment`;
  return getAsync(url, request);
}

function createComment(
  request: CreateCommentRequest
): Promise<AxiosResponse<CommentModel, any>> {
  const url = `${baseUrl}/comment`;
  return postAsync(url, {
    url: window.location.href,
    ...request,
  });
}

function createGuestComment(
  request: CreateGuestCommentRequest
): Promise<AxiosResponse<CommentModel, any>> {
  const url = `${baseUrl}/comment/guest`;
  return postAsync(
    url,
    {
      url: window.location.href,
      ...request,
    },
    false,
    false
  );
}

function deleteComment(id: number) {
  const url = `${baseUrl}/comment/${id}`;
  return deleteAsync(url);
}

function getConnectProfileCode(
  clientId?: number
): Promise<AxiosResponse<string>> {
  const url = `${baseUrl}/profile/connect-profile-code`;
  return getAsync(url, { clientId });
}

function getConnectProfileLink(
  code: string,
  redirectPath: string
): Promise<AxiosResponse<string>> {
  const url = `${baseUrl}/profile/connect-profile-link`;
  return getAsync(url, { code, redirectPath }, false, false, true);
}

/* Hash Tag */

function getListHashtag(params: { clientId?: number }) {
  const url = `${baseUrl}/hash-tag-groups`;
  return getAsync(url, params);
}

function createHashtag(request: CreateHashtagRequest) {
  const url = `${baseUrl}/hash-tag-groups`;
  return postAsync(url, request);
}

function removeHashtag(id: number) {
  const url = `${baseUrl}/hash-tag-groups/${id}`;
  return deleteAsync(url);
}

function editHashtag(request: EditHashtagRequest) {
  const url = `${baseUrl}/hash-tag-groups`;
  return putAsync(url, request);
}

function getShortLink(code: string) {
  const url = `${baseUrl}/shortlink/code?code=${code}`;
  return getAsync(url, {}, false, false, true);
}

function createShortLink(request: CreateShortLinkRequest) {
  const url = `${baseUrl}/shortlink`;
  return postAsync(url, request);
}

/* Social Dashboard */

// hashtag
function getMostUsedHashtag(
  params: GetDashboardHashTagParams
): Promise<AxiosResponse<DashboardHashtagModel[]>> {
  const url = `${baseUrl}/dashboard/hash-tags/most-used`;
  return getAsync(url, params);
}
function getMostViewedHashtag(
  params: GetDashboardHashTagParams
): Promise<AxiosResponse<DashboardHashtagModel[]>> {
  const url = `${baseUrl}/dashboard/hash-tags/most-viewed`;
  return getAsync(url, params);
}
function getMostEngagedHashtag(
  params: GetDashboardHashTagParams
): Promise<AxiosResponse<DashboardHashtagModel[]>> {
  const url = `${baseUrl}/dashboard/hash-tags/most-engaged`;
  return getAsync(url, params);
}

//Categories
function getMostUsedCategory(
  params: GetDashboardCategoryParams
): Promise<AxiosResponse<DashboardCategoryModel[]>> {
  const url = `${baseUrl}/dashboard/categories/most-used`;
  return getAsync(url, params);
}
function getMostViewedCategory(
  params: GetDashboardCategoryParams
): Promise<AxiosResponse<DashboardCategoryModel[]>> {
  const url = `${baseUrl}/dashboard/categories/most-viewed`;
  return getAsync(url, params);
}
function getMostEngagedCategory(
  params: GetDashboardCategoryParams
): Promise<AxiosResponse<DashboardCategoryModel[]>> {
  const url = `${baseUrl}/dashboard/categories/most-engaged`;
  return getAsync(url, params);
}

// content dashboard
function getPostTypeDashboard(
  params: GetPostTypeParams
): Promise<AxiosResponse<PostTypeModel[]>> {
  const url = `${baseUrl}/dashboard/post-type`;
  return getAsync(url, params);
}

function getTotalEngagementDashboard(
  params: GetTotalEngagementParams
): Promise<AxiosResponse<SocialDateReportModel>> {
  const url = `${baseUrl}/dashboard/date-post/engagement`;
  return getAsync(url, params);
}

function getTimePostDashboard(
  params: GetTimePostParams
): Promise<AxiosResponse<TimePostModel[]>> {
  const url = `${baseUrl}/dashboard/time-post`;
  return getAsync(url, params);
}

function getWeekPostDashboard(
  params: GetWeekPostParams
): Promise<AxiosResponse<WeekPostModel[]>> {
  const url = `${baseUrl}/dashboard/day-of-week-post`;
  return getAsync(url, params);
}

// overview dashboard
function getTopPlatformContents(
  params: GetTopPlatformContentsParams
): Promise<AxiosResponse<SocialContentModel[]>> {
  const url = `${baseUrl}/dashboard/top-platform-contents`;
  return getAsync(url, params);
}

function getTotalPostDashboard(
  params: GetTopPlatformContentsParams
): Promise<AxiosResponse<SocialDateReportModel>> {
  const url = `${baseUrl}/dashboard/date-post/count`;
  return getAsync(url, params);
}

function getTotalViewDashboard(
  params: GetTopPlatformContentsParams
): Promise<AxiosResponse<SocialDateReportModel>> {
  const url = `${baseUrl}/dashboard/date-post/view`;
  return getAsync(url, params);
}

function getTotalLikeDashboard(
  params: GetTopPlatformContentsParams
): Promise<AxiosResponse<SocialDateReportModel>> {
  const url = `${baseUrl}/dashboard/date-post/like`;
  return getAsync(url, params);
}

function getTotalShareDashboard(
  params: GetTopPlatformContentsParams
): Promise<AxiosResponse<SocialDateReportModel>> {
  const url = `${baseUrl}/dashboard/date-post/share`;
  return getAsync(url, params);
}

function getTotalCommentDashboard(
  params: GetTopPlatformContentsParams
): Promise<AxiosResponse<SocialDateReportModel>> {
  const url = `${baseUrl}/dashboard/date-post/comment`;
  return getAsync(url, params);
}

function getFollowersDashboard(
  params: GetFollowersParams
): Promise<AxiosResponse<FollowerReportModel>> {
  const url = `${baseUrl}/dashboard/followers`;
  return getAsync(url, params);
}

// audience dashboard
function getAgesAudienceDashboard(
  params: GetAgesAudienceParams
): Promise<AxiosResponse<AgesReportRowModel[]>> {
  const url = `${baseUrl}/dashboard/audience/ages`;
  return getAsync(url, params);
}

function getCountriesAudienceDashboard(
  params: GetCountriesAudienceParams
): Promise<AxiosResponse<CountriesReportModel[]>> {
  const url = `${baseUrl}/dashboard/audience/countries`;
  return getAsync(url, params);
}

// AI
function getSuggestIdeas(
  category: number
): Promise<AxiosResponse<SuggestIdeasAIModel[]>> {
  const url = `${baseUrl}/ai/suggests/${category}`;
  return getAsync(url);
}
function getSavedIdeas(
  clientId: number
): Promise<AxiosResponse<GetIdeasModel>> {
  const url = `${baseUrl}/ai/saved-ideas`;
  return getAsync(url, { clientId });
}
function createIdeas(
  model: CreateIdeaModel
): Promise<AxiosResponse<IdeaModel>> {
  const url = `${baseUrl}/ai/saved-ideas`;
  return postAsync(url, model);
}
function generateAIContent(
  model: CreateIdeaModel
): Promise<
  AxiosResponse<{ results: ResultGenerateAIModel[]; usage: PlanUsageModel }>
> {
  const url = `${baseUrl}/ai`;
  return postAsync(url, model);
}
function deleteIdea(ideaId: number): Promise<AxiosResponse<any>> {
  const url = `${baseUrl}/ai/saved-ideas?ideaId=${ideaId}`;
  return deleteAsync(url);
}

export const SocialApi = {
  connectProfile,
  getSsoUrl,
  retrieveConnectProfile,
  deletePlatform,
  getContent,
  getContentForApproval,
  getContentDetail,
  getContentDetailForShare,
  getContentCalendar,
  createContent,
  editContent,
  publishContent,
  scheduleContent,
  getContentInteraction,
  uploadMedia,
  getListTag,
  createTag,
  removeTag,
  editTag,
  getListActivities,
  getListApprovalActivities,
  getListComment,
  createComment,
  createGuestComment,
  deleteComment,
  getMedia,
  deleteMedia,
  deleteContent,
  getConnectProfileCode,
  getConnectProfileLink,
  syncConnectedProfiles,
  approveContent,
  rejectContent,
  toggleRequireApproval,
  getContentApprovals,
  createContentApproval,
  updateContentApproval,
  getApprovalDetail,
  getProfileApprovalDetail,
  getContentApprovalDetail,
  deleteContentApproval,
  getListHashtag,
  createHashtag,
  editHashtag,
  removeHashtag,
  approveContentApproval,
  declineContentApproval,
  notifyApprover,
  getPresignedUrl,
  getShortLink,
  createShortLink,
  getPostTypeContent,
  renameMedia,
  duplicateMedia,

  Content: {
    getYoutubeCategories,
    getGBPCategories,
  },

  SocialDashboard: {
    getMostUsedHashtag,
    getMostViewedHashtag,
    getMostEngagedHashtag,
    getPostTypeDashboard,
    getTopPlatformContents,
    getTimePostDashboard,
    getWeekPostDashboard,
    getTotalEngagementDashboard,
    getTotalPostDashboard,
    getTotalLikeDashboard,
    getTotalShareDashboard,
    getTotalViewDashboard,
    getTotalCommentDashboard,
    getFollowersDashboard,
    getAgesAudienceDashboard,
    getCountriesAudienceDashboard,
    getMostUsedCategory,
    getMostViewedCategory,
    getMostEngagedCategory,
  },

  AI: {
    getSuggestIdeas,
    getSavedIdeas,
    createIdeas,
    generateAIContent,
    deleteIdea,
  },
};
