import "./index.scss";

import logo from "../../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import SVGIcons from "../../../components/icons/svgs";
import { useNavigate } from "react-router-dom";
import useDashboardPath from "../../../hooks/useDashboardPath";
import { Link } from "react-router-dom";
import { SUPPORT_EMAIL } from "../../../constants/app-constants";

function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dashboardPath = useDashboardPath();

  function backToHome() {
    navigate(dashboardPath);
  }

  return (
    <div className="not-found-page">
      <div className="not-found-page__header">
        <Link to={dashboardPath}>
          <img src={logo} />
        </Link>
      </div>

      <div className="not-found-page__body">
        <h1 dangerouslySetInnerHTML={{ __html: t("404.title") }}></h1>
        <p>{t("404.description")}</p>
        <Button
          icon={<SVGIcons.HomeIcon />}
          type="primary"
          onClick={backToHome}
        >
          {t("404.backToHome")}
        </Button>
        <a
          href={`mailto:${SUPPORT_EMAIL}`}
          className="text-success">
          {t("404.contactSupport")}
        </a>
      </div>

      <div className="not-found-page__foolter">
        <small>{t("common.copyRight")}</small>
      </div>
    </div>
  );
}

export default NotFoundPage;
