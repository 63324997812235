import { Button, Drawer, Form, Input, Radio, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../../apis/auth.api";
import {
  REPORT_DELIVERY_FREQUENCY,
  REPORT_FORMATS,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../../constants/app-constants";
import { EmailReportSettingsModel } from "../../../../../models/client-settings.model";
// import "./index.scss";

interface Props {
  open: boolean;
  close: () => void;
  item?: EmailReportSettingsModel;
  submit?: (value: EmailReportSettingsModel) => Promise<void>;
}

function EditReportSettings(props: Props) {
  const { open, close, item, submit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && item) {
      form.resetFields();
      form.setFieldsValue(item);
    }
  }, [open]);

  async function formSubmit() {
    if (submit) {
      setLoading(true);
      try {
        const value = form.getFieldsValue();
        await submit({ ...value, clientId: item?.clientId, id: item?.id });
      } catch {}
      setLoading(false);
    }
  }

  function okClick() {
    form.submit();
  }

  function cancel() {
    form.resetFields();
    close();
  }

  return (
    <Drawer
      className="app-edit-drawer"
      title={t("generalSettings.autoReportingSettings")}
      open={open}
      width={540}
      onClose={() => cancel()}
      maskClosable={false}
      footer={
        <div className="drawer-footer-wrapper">
          <Button type="text" onClick={() => cancel()}>
            {t("common.close")}
          </Button>
          <Button type="primary" onClick={okClick} loading={loading}>
            {t("common.saveChanges")}
          </Button>
        </div>
      }
    >
      <Form
        name="editReportSettings"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={formSubmit}
        form={form}
      >
        <span style={{fontWeight: 500}} >{t("generalSettings.reportName")}</span>
        <Form.Item >
          <label>{item?.reportName}</label>
        </Form.Item>
        <Form.Item
          label={t("generalSettings.deliveryFrequency")}
          name="deliveryFrequency"
        >
          <Radio.Group>
            <Space direction="vertical">
              {REPORT_DELIVERY_FREQUENCY.map((x) => (
                <Radio key={x.key} value={x.key}>
                  {x.name}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item
          label={t("generalSettings.reportFormat")}
          name="reportFormat"
        >
          <Select>
            {REPORT_FORMATS.map((x) => (
              <Select.Option value={x.key} key={x.key}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item
          name="customEmailSubject"
          label={
            <div className="form-item-has-extra">
              <p>{t("generalSettings.customEmailSubject")}</p>
              <span>{t("generalSettings.customEmailSubjectExtra")}</span>
            </div>
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="emailRecipient"
          label={
            <div className="form-item-has-extra">
              <p>{t("generalSettings.deliveryToEmail")}</p>
              <span>{t("generalSettings.deliveryToEmailExtra")}</span>
            </div>
          }
          rules={[{ type: "email" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default EditReportSettings;
