import { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ChartDataModel } from "../../../../../models/chart.model";
import { ApexOptions } from "apexcharts";
import useMockupData from "../../../../../hooks/useMockupData";
import { EMPTY_DATA_COLOR } from "../../../../../constants/app-constants";

interface Props {
  chartId: string;
  height: number;
  title: string;
  data: ChartDataModel[];
}

function ColumnChart(props: Props) {
  const { chartId, data, height, title } = props;

  const series: ApexOptions["series"] = [
    {
      name: title,
      data: data.map((x) => x.value),
    },
  ];

  const isEmpty = !props.data || !props.data.length;
  const color = isEmpty ? EMPTY_DATA_COLOR : "#0185de";
  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false
      },
      type: 'bar',

    },
    colors: [color],
    plotOptions: {
      bar: {
        columnWidth: '48px',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: data.map((x) => x.label),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: data.length > 0,
        style: {
          colors: '#4b546e',
          fontSize: '10px',
        },
      },
    },
    grid: {
      strokeDashArray: 4,
      borderColor: '#e3e4e6',
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  };


  return (
    <div
      className={`${isEmpty ? "chart-disabled " : ""}`}
      style={{ marginBottom: -13, marginLeft: -16 }}
    >
      <Chart options={options} series={series} type="bar" height={height} />
    </div>
  );
}

export default memo(ColumnChart);
