import { Button, Input, Space, Tabs, Tooltip } from "antd";
import moment from "moment";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import FacebookPNG from "../../../../../../assets/images/app-icon-facebook.png";
import InstagramPNG from "../../../../../../assets/images/app-icon-instag.png";
import TiktokPNG from "../../../../../../assets/images/app-icon-tiktok.png";
import YoutubePNG from "../../../../../../assets/images/app-icon-youtube.png";
import GooglePNG from "../../../../../../assets/images/app-icon-google.png";
import AvatarDefault from "../../../../../../assets/images/avatar-default.svg";
import {
  ApprovalStatusEnum,
  PostStatusType,
  SocialExtendReelType,
  SocialType,
} from "../../../../../../constants/app.enums";
import { ContentModel } from "../../../../../../models/content.model";
import { NetworkModel } from "../../../../../../models/social.model";
import { isMobileSelector } from "../../../../../../states/common";
import {
  SocialContentState,
  triggerFetchBulkApprovalState,
} from "../../../../../../states/social";
import AppDateTimePicker from "../../../../../controls/app-date-time-picker";
import { ApprovalEditStatus } from "../../../social-content/components/approval-status";
import AvatarSocial from "../../../social-content/components/avatar-social";
import ListMedia from "../../../social-content/components/list-media";
import TagSocial from "../../../social-content/components/tag-social";
import CommentTab from "../../../social-content/edit-content/comment-tab";
import { ROUTE_PATHS } from "../../../../../../constants/router.constants";
import { useLocation } from "react-router-dom";
import { contentTabRender } from "../../../social-content/create-content/content-editor";
import "./index.scss";
import AdvancedSettingYoutube from "../../../social-content/create-content/content-editor/advanced-setting-youtube";
import ContentCaption from "../../../social-content/create-content/content-editor/content-caption";
import ContentCaptionGBP from "../../../social-content/create-content/content-editor/content-caption-gbp";
import { getSocialImage } from "../../../social-content/edit-content";

interface ViewContentProps {
  contentInfo: ContentModel;
  listProfile: NetworkModel[];
  showViewAllButton?: boolean;
  isInModal?: boolean;
}

const ViewContent = (props: ViewContentProps) => {
  const { contentInfo, listProfile, showViewAllButton, isInModal } = props;
  const elementRef = useRef<HTMLDivElement>(null);

  const [currentSocial, setCurrentSocial] = useState<SocialExtendReelType>();
  const [currentContent, setCurrentContent] = useState<
    SocialContentState | undefined
  >();
  const [listPlatformContent, setListPlarformContent] = useState<
    SocialContentState[]
  >([]);
  const [currentApprovalStatus, setCurrentApprovalStatus] =
    useState<ApprovalStatusEnum>(ApprovalStatusEnum.None);

  const [triggerFetchBulkApproval, setTriggerFetchBulkApproval] =
    useRecoilState(triggerFetchBulkApprovalState);
  const location = useLocation();

  const {
    id,
    date: dateContent,
    status,
    platformContents,
    tags,
    isSyncContent,
  } = contentInfo;

  const captionEditorRef = useRef<any>();
  const commentTabRef = useRef<any>();
  const [elementHeight, setElementHeight] = useState<number | null>(null);
  const isMobile = useRecoilValue(isMobileSelector);

  const onFetchBulkApprovalStatus = () => {
    if (
      location &&
      location.pathname &&
      location.pathname.includes(ROUTE_PATHS.Social.ApprovalRequest)
    ) {
      setTriggerFetchBulkApproval(!triggerFetchBulkApproval);
    }
  };

  const onChangeApproval = (status: ApprovalStatusEnum) => {
    setCurrentApprovalStatus(status);
    if (status === ApprovalStatusEnum.Declined) {
      commentTabRef.current.reloadComment();
    }
    contentInfo.approvalStatus = status;

    onFetchBulkApprovalStatus();
  };

  const onChangeStatus = (content: SocialContentState) => {
    if (content) {
      setCurrentSocial(content.platform);
      converterToMask(content.post || "");
    }
  };

  const renderDatetimeTooltip = () => {
    const timeFormat = moment
      .utc(dateContent)
      .local()
      .format("ddd, MMM DD, h:mma");
    switch (contentInfo?.status) {
      case PostStatusType.Drafted:
      case PostStatusType.Error:
        return `Content was created on ${timeFormat}`;
      case PostStatusType.Scheduled:
        return `Content will be published on ${timeFormat}`;
      case PostStatusType.Posted:
        return `Content was published on ${timeFormat}`;
      default:
        return "";
    }
  };

  function converterToMask(value: string) {
    let str = value;
    str = str.replace(/(<)/gi, "&lt;");
    str = str.replace(/(<)/gi, "&lg;");
    str = str.replace(/(?:\r\n|\n\r|\r|\n)/g, " <br />");
    str = str.replace(/#(\S+)(?=[\s.,:]|$)/g, "<span>#$1</span>");
    // str = str.replace(/@(.+?)(?=[\s.,:,]|$)/g, "<span>@$1</span>");
    str = str.replace(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g,
      "<span>$1</span>"
    );
    if (captionEditorRef.current) {
      captionEditorRef.current.innerHTML = str;
    }
  }

  const renderListMedia = useCallback(() => {
    if (currentContent?.medias?.length === 0) return "";
    return (
      <div className="media">
        <ListMedia.ListMediaContentCard
          listMedia={currentContent?.medias || []}
          hasLightbox={true}
        />
      </div>
    );
  }, [currentContent?.medias]);

  useEffect(() => {
    const currentContent = listPlatformContent.find(
      (content) => content?.platformExtendReel === currentSocial
    );
    setCurrentContent(currentContent);
    converterToMask(currentContent?.post || "");
  }, [currentSocial]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0] && entries[0].target) {
        const newElementHeight = (entries[0].target as HTMLDivElement)
          .offsetHeight;
        setElementHeight(newElementHeight);
      }
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, []);

  // useEffect(() => {
  //   onSetApprovalStatus(currentApprovalStatus);
  // }, [contentInfo, currentApprovalStatus]);

  const onSetApprovalStatus = (status: ApprovalStatusEnum) => {
    setCurrentApprovalStatus(status);
    contentInfo.approvalStatus = status;
  };

  const renderTab = () => {
    if (currentContent?.platform === undefined) return "";
    if (isSyncContent) return "";

    if (
      ![SocialType.Facebook, SocialType.Instagram, SocialType.Youtube].includes(
        currentContent.platform
      )
    )
      return "";

    const reelContent = listPlatformContent.find(
      (c) => c.isReels && c.platform === currentContent.platform
    );
    if (!reelContent?.post && !reelContent?.medias?.length) return "";

    if (isMobile) {
      return (
        <div className="content-editor-tab-mobile">
          <Button
            type="default"
            className={`tab-post ${!currentContent.isReels ? "active" : ""}`}
            onClick={() => setCurrentSocial(currentContent.platform)}
          >
            Post
          </Button>
          <Button
            type="default"
            className={`tab-reel ${currentContent.isReels ? "active" : ""}`}
            onClick={() => setCurrentSocial(currentContent.platform + 10)}
          >
            {currentContent.platform === SocialType.Youtube ? "Short" : "Reel"}
          </Button>
        </div>
      );
    }

    return (
      <div className="content-editor-tab">
        <Tabs
          defaultActiveKey="0"
          onChange={(value) => {
            setCurrentSocial(Number(value));
            converterToMask(
              listPlatformContent.find(
                (c) => c.platformExtendReel === Number(value)
              )?.post || ""
            );
          }}
          activeKey={currentSocial?.toString()}
          items={listPlatformContent
            .filter((content) => {
              return !content?.disabled;
            })
            .map((content: SocialContentState) => {
              // fix underline RLV2-1442
              const isHidden = content.platform !== currentContent?.platform;
              const fbContent = listPlatformContent.find(
                (c) => c.platform === SocialType.Facebook
              );
              const isIG =
                currentContent?.platform === SocialType.Instagram &&
                fbContent &&
                !fbContent.disabled;
              return {
                label: (
                  <div
                    className={`content-editor-tab-label ${
                      isHidden ? "hidden" : ""
                    } ${isIG ? "isIG" : ""}`}
                  >
                    {contentTabRender(content?.platform, content?.isReels)}
                  </div>
                ),
                key: content?.platformExtendReel?.toString() || "0",
              };
            })}
        />
      </div>
    );
  };

  const renderContentCaptionByPlatform = () => {
    const contentValue = {
      isSyncContent,
      currentSocial,
      platformContents: listPlatformContent,
      currentContent: currentContent,
    };
    return (
      <>
        <div
          style={{
            display:
              currentSocial === SocialType.Google && !isSyncContent
                ? "unset"
                : "none",
          }}
        >
          <ContentCaptionGBP
            isReviewing={true}
            contentValue={contentValue}
          ></ContentCaptionGBP>
        </div>
        <div
          style={{
            display:
              isSyncContent || currentSocial !== SocialType.Google
                ? "unset"
                : "none",
          }}
        >
          <ContentCaption isReviewing={true} contentValue={contentValue} />
        </div>
      </>
    );
  };

  useEffect(() => {
    setCurrentApprovalStatus(contentInfo.approvalStatus);
    setListPlarformContent(
      platformContents.map((content) => {
        const profile = listProfile?.find(
          (profile) => profile.platform === content.platform
        );
        return {
          ...content,
          disabled: false,
          profile,
          platform: content.platform,
          platformExtendReel: content.isReels
            ? content.platform + 10
            : content.platform,
        };
      })
    );
  }, [contentInfo]);

  useEffect(() => {
    if (listPlatformContent[0]?.platform !== undefined) {
      setCurrentSocial(listPlatformContent[0]?.platform);
      converterToMask(listPlatformContent[0]?.post || "");
    }
  }, [listPlatformContent]);
  return (
    <div className="edit-content-container edit-content-approval-container">
      <div className="edit-content-container-header">
        <div className="edit-content-container-header__left">
          <Space
            style={{ rowGap: isMobile ? 16 : 8, columnGap: isMobile ? 16 : 8 }}
          >
            {listPlatformContent
              .filter((content) => !content.isReels)
              .map((content, index) => {
                return (
                  <Fragment key={index}>
                    <AvatarSocial
                      avatar={getSocialImage(content?.platform)}
                      onChangeStatus={() => content && onChangeStatus(content)}
                      size={isMobile ? 40 : 32}
                      sizeIcon={14}
                      key={content?.platform}
                      tooltip={content?.profile?.name}
                      status={content?.status}
                      isReviewing={true}
                      disabled={
                        !isSyncContent &&
                        currentSocial !== undefined &&
                        currentSocial % 10 !== content?.platform
                      }
                    />
                  </Fragment>
                );
              })}
          </Space>
        </div>
      </div>
      <div className="edit-content-container-body">
        <div className="edit-content-container-body__left">
          {
            <ApprovalEditStatus
              approvalStatus={currentApprovalStatus}
              onChangeStatus={onChangeApproval}
              contentId={id}
              exceptStatus={[ApprovalStatusEnum.None]}
            />
          }
        </div>
        <div className="edit-content-container-body__middle">
          <div className="content-editor" ref={elementRef}>
            <div className="content-editor-tag">
              <div className="list-tag">
                {tags.map((tag) => (
                  <TagSocial text={tag.name} color={tag.color} key={tag.id} />
                ))}
              </div>
            </div>

            {renderTab()}

            {contentInfo && (
              <div
                className={`content-editor-avatar ${
                  !currentContent?.profile ? "no-profile" : ""
                }`}
              >
                <div className="avatar">
                  <AvatarSocial
                    avatar={currentContent?.profile?.userImage || AvatarDefault}
                    size={32}
                  />
                </div>
                <div className="name-and-date">
                  <div className="name">
                    {currentContent?.profile?.name || "Profile Name"}
                  </div>
                  <Tooltip title={renderDatetimeTooltip()}>
                    <div className="date">
                      {moment
                        .utc(dateContent)
                        .local()
                        .format("ddd, MMM DD, h:mma")}
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}

            {renderContentCaptionByPlatform()}
            {/* <div className="content-editor-caption">
              <div
                ref={captionEditorRef}
                className="content-editor-caption__mask"
              ></div>
              {currentContent?.post && (
                <Input.TextArea
                  placeholder="Write your caption here..."
                  autoSize={true}
                  value={currentContent?.post}
                />
              )}
            </div> */}
            {currentSocial !== undefined &&
              currentSocial % 10 === SocialType.Youtube &&
              !isSyncContent && (
                <div className="advanced-setting-youtube">
                  <AdvancedSettingYoutube disabled={true} />
                </div>
              )}

            {renderListMedia()}
          </div>
          <div className="create-content-modal-footer">
            {status === PostStatusType.Scheduled ? (
              <AppDateTimePicker
                onChange={() => {}}
                defaulValue={moment.utc(dateContent)}
                readonly={true}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="edit-content-container-body__right">
          <CommentTab
            approvalStatus={currentApprovalStatus}
            onChangeStatus={onChangeApproval}
            onSetApprovalStatus={onSetApprovalStatus}
            showViewAllButton={showViewAllButton}
            contentInfo={contentInfo}
            isReviewing={true}
            contentId={contentInfo.id}
            ref={commentTabRef}
            contentHeight={elementHeight}
            isInModal={isInModal}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewContent;
