import { Button } from "antd";
import "./index.scss";
import CheckedAnimation from "../../../assets/animations/checked.gif";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useAdminSite from "../../../hooks/useAdminSite";

function UpdatePasswordSuccessPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useAdminSite();

  return (
    <div className="account-verification-success auth__form">
      <div className="account-verification-success__animation">
        <img src={CheckedAnimation} />
      </div>
      <div className="header">{t("updatePassword.successTitle")}</div>
      <p className="description">{t("updatePassword.successDescription")}</p>
      <Button type="primary" block onClick={() => navigate(isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn)}>
        {t("updatePassword.btnSubmit")}
      </Button>
    </div>
  );
}

export default UpdatePasswordSuccessPage;
