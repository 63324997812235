import { Table } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  AdminViewSitePrefixRouter,
  ROUTE_PATHS,
} from "../../../../../constants/router.constants";
import { UserCompanyModel } from "../../../../../models/client.model";

interface Props {
  listCompanyUser: UserCompanyModel[];
}
const SiteTabTable = (props: Props) => {
  const { listCompanyUser } = props;
  const { t } = useTranslation();

  const onClickClient = (clientId: number) => {
    window.open(
      `${window.location.origin}${AdminViewSitePrefixRouter}${ROUTE_PATHS.Home}${clientId}`
    );
  };

  const columns = [
    {
      title: t("users.clientSite"),
      dataIndex: "companyName",
      key: "companyName",
      width: "70%",
      render: (name: string, record: UserCompanyModel) => {
        return (
          <a
            className="company-name-site-tab-table"
            onClick={() => onClickClient(record.id)}
            key={record.id}
          >
            {name}
          </a>
        );
      },
    },
    {
      title: t("common.createdOn"),
      dataIndex: "createOn",
      key: "createOn",
      render: (createOn: string) =>
        moment(createOn).utc().local().format("MMM DD, YYYY"),
    },
  ];
  return (
    <Table
      className="main-table"
      columns={columns}
      dataSource={listCompanyUser}
      rowKey="id"
      pagination={false}
    />
  );
};

export default SiteTabTable;
