import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { ContentMediaModel } from "../../../../../../models/content.model";
import MediaLibrary from "../../../../../../pages/media-library";
import { ListMediaSelectedState } from "../../../../../../states/media-library";
import "./index.scss";

interface AddMediaModalProps {
  open: boolean;
  close: () => void;
  addMedia?: (listUrl: ContentMediaModel[]) => Promise<boolean | void>;
  addMediaRef?: any;
  addMediaFooterRef?: any;
}

const AddMediaModal = (props: AddMediaModalProps) => {
  const { open, close, addMedia, addMediaRef, addMediaFooterRef } = props;
  const { t } = useTranslation();

  const [addMediaLoading, setAddMediaLoading] = useState(false);
  const [listMediaSelected, setListMediaSelected] = useRecoilState(
    ListMediaSelectedState
  );

  const onAddMedia = async () => {
    if (addMedia) {
      setAddMediaLoading(true);
      const isOk = await addMedia(listMediaSelected);
      if (isOk) {
        setListMediaSelected([]);
      }
      setAddMediaLoading(false);
    }
  };

  const onClose = () => {
    setListMediaSelected([]);
    close();
  };
  return (
    <Modal
      title="Add Images/Videos"
      open={open}
      onCancel={() => onClose()}
      className="custom-create-modal custom-media-modal"
      closeIcon={
        <span className="close-modal-icon">
          <CloseOutlined />
        </span>
      }
      footer={
        <div ref={addMediaFooterRef} className="media-footer-wrapper">
          <div className="media-footer-wrapper__right">
            <Button
              loading={addMediaLoading}
              type="primary"
              onClick={onAddMedia}
            >
              {t("social.content.btnAddMedia")}
            </Button>
          </div>
        </div>
      }
    >
      <div ref={addMediaRef}>
        <MediaLibrary />
      </div>
    </Modal>
  );
};

export default AddMediaModal;
