import { useTranslation } from "react-i18next";
import GeneralSettingReports from "../../components/views/general-settings/reports";
import { Card } from "antd";

const Reports = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="page-header">
                <h4 className="page-header__title">{t("generalSettings.reports")}</h4>
            </div>
            <Card >
                <GeneralSettingReports />
            </Card>
        </div>
    );
};

export default Reports;
