import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SocialApi } from "../../apis/social.api";
import { ROUTE_PATHS } from "../../constants/router.constants";
import "./index.scss";

function ShortLinkPage() {
    const { code } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const getShortLink = async () => {
        setLoading(true);
        try {
            if (code) {
                const { data } = await SocialApi.getShortLink(code)
                window.location.replace(data)
            }
        } catch (error) {
            window.location.replace(ROUTE_PATHS.ClientNotFound)
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getShortLink()
    }, [])

    return (
        <div className="general-settings-page page-content">
            {loading && (
                <div className="short-link">
                    <Spin
                        className="spin"
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    ></Spin>
                </div>
            )}
        </div>
    );
}

export default ShortLinkPage;
