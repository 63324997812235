import { useTranslation } from "react-i18next";
import AddConnection from "./add-connection";
import "./index.scss";
import { useRecoilValue } from "recoil";
import { clientSitesState } from "../../../../states/clientSites";
import useFetchConnection from "../../../../hooks/useFetchConnection";
import { useEffect } from "react";
import InsightHubIntegrationCollapse from "../../../common/integration-collapse/insight-hub-integration";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import SaleAnalyticIntegrationCollapse from "../../../common/integration-collapse/sale-analytic-integration";
import AddZohoConnection from "./add-zoho-connection";

function IntergrationForm() {
  const { fetchConnection } = useFetchConnection();
  const { t } = useTranslation();
  const { clientSelected } = useRecoilValue(clientSitesState);

  useEffect(() => {
    if (clientSelected) {
      fetchConnection();
    }
  }, [clientSelected]);

  return (
    <div className="integration-form">
      <div>
        <div className="connection-head">
          <div className="title">
            <span>{t("common.insightsHub")}</span>
            <div className="connect-apps">
              <div className="label">
                {t("generalSettings.adminConnectInsightApps")}{" "}
                <a
                  href={ROUTE_PATHS.Docs.SetUpDashboard}
                  target="_blank"
                  className="learn-more"
                >
                  {t("generalSettings.learnMore")}
                </a>
              </div>
            </div>
          </div>
        </div>
        {clientSelected?.id && (
          <InsightHubIntegrationCollapse clientId={clientSelected?.id} />
        )}

        <AddConnection />
      </div>
      <div>
        <div className="connection-head">
          <div className="title">
            <span>{t("common.salesAnalytics")}</span>
            <div className="connect-apps">
              <div className="label">
                {t("generalSettings.adminConnectSalesApps")}{" "}
                <a
                  href={ROUTE_PATHS.Docs.ConnectZohoCrm}
                  target="_blank"
                  className="learn-more"
                  rel="noreferrer"
                >
                  {t("generalSettings.learnMore")}
                </a>
              </div>
            </div>
          </div>
        </div>
        {clientSelected?.id && (
          <SaleAnalyticIntegrationCollapse clientId={clientSelected?.id} />
        )}

        <AddZohoConnection />
      </div>
    </div>
  );
}

export default IntergrationForm;
