import "./index.scss";
// import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts";
import { memo, useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import { generalChartSelector } from "../../../../../states/dashboard";
import { CommonUtils } from "../../../../../utils/common";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import { SideTrafficChartType, SideTrafficModel } from "../../site-traffic";
import { DATE_TIME_FORMAT } from "../../../../../constants/app-constants";

interface Props {
  chartId: string;
  chartData: SideTrafficModel;
}

function SiteTrafficChart(props: Props) {
  const { chartId, chartData } = props;
  const generalChartData = useRecoilValue(generalChartSelector);
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([]);

  useEffect(() => {
    getSeriesData();
  }, [chartData, generalChartData]);

  const valueFormater = useCallback(
    (value: number) => {
      switch (chartData.key) {
        // case SideTrafficChartType.NewVisitors:
        //   return NUMBER_UTIL.converNumberToPercentage(value);
        case SideTrafficChartType.AvgVisitDuration:
          return CommonUtils.secondsToHms(value);
        case SideTrafficChartType.BounceRate:
          return NUMBER_UTIL.converNumberToPercentage(value);
        default:
          return NUMBER_UTIL.convertNumericToFormattedString(value);
      }
    },
    [chartData.key]
  );

  function getSeriesData() {
    let data: number[] | undefined = [];

    if (generalChartData) {
      switch (chartData.key) {
        case SideTrafficChartType.Visitors:
          data = generalChartData.map((x) => x.users);
          break;
        case SideTrafficChartType.NewVisitors:
          data = generalChartData.map((x) => x.newUsers);
          break;
        case SideTrafficChartType.AvgVisitDuration:
          data = generalChartData.map((x) => x.avgSessionDuration);
          break;
        case SideTrafficChartType.BounceRate:
          data = generalChartData.map((x) => x.bounceRate);
          break;
        case SideTrafficChartType.PageViews:
          data = generalChartData.map((x) => x.pageviews);
          break;
        default:
          break;
      }
    }

    setSeries([
      {
        name: chartData.label,
        data: data || [],
      },
    ]);
  }

  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: generalChartData.map((x) => x.date),
      type: "datetime",
      labels: {
        format: DATE_TIME_FORMAT.chartDateFormat,
        datetimeUTC: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return valueFormater(value);
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return valueFormater(val);
        },
      },
      marker: {
        show: true,
        fillColors: [chartData.color]
      }
    },
    markers: {
      colors: [chartData.color]
    },
    stroke: {
      width: 2,
      curve: "smooth",
      colors: [chartData.color],
    },
    fill: {
      colors: ["#ffffff"],
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {},
    },
  };

  return (
    <div style={{ height: 258 }} className="site-traffic-chart">
      <Chart
        options={options}
        series={series}
        type="area"
        width={"100%"}
        height={258}
      />
    </div>
  );
}

export default memo(SiteTrafficChart);
