import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FailedAnimation from "../../../../assets/animations/failed.gif";
import { SUPPORT_EMAIL } from "../../../../constants/app-constants";
import "./index.scss";

interface Props {
  email: string;
  resend: ()=> Promise<void>;
}

function EmailVerificationError({email, resend}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  async function resendEmail() {
    setLoading(true);
    try {
      await resend();
    } catch {
    }
    setLoading(false);
  }

  return (
    <>
      <div className="account-verification-error auth__form">
        <div className="account-verification-success__animation">
          <img src={FailedAnimation} />
        </div>
        <div className="header">{t("emailVerification.errorTitle")}</div>
        <p className="description"   dangerouslySetInnerHTML={{
          __html: t("emailVerification.errorDescription", {param1: email}),
        }}>
        </p>

        <Button htmlType="submit" type="primary" block loading={loading} onClick={resendEmail}>
          {t("emailVerification.resentBtn")}
        </Button>
      </div>

      <a
        href={`mailto:${SUPPORT_EMAIL}`}
        className="auth__backLink"
        dangerouslySetInnerHTML={{
          __html: t("emailVerification.contactLink"),
        }}
      ></a>
    </>
  );
}

export default EmailVerificationError;
