import { Button, Col, Form, Input, Row } from "antd";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SVGIcons from "../../components/icons/svgs";
import showNotification from "../../components/common/notification";
import {
  PASSWORD_POLYCIES,
  VALIDATION_MESSAGE_CONFIG,
} from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import "./index.scss";
import useAdminSite from "../../hooks/useAdminSite";
import { CommonUtils } from "../../utils/common";

function ResetPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = CommonUtils.queryParamReplaceSpace(searchParams.get("email") || "");
  const isAdmin = useAdminSite();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [passwordPolicies, setPasswordPolicies] = useState(
    PASSWORD_POLYCIES.filter((x) => !x.disabled)
  );

  function passwordOnchange(value: string) {
    const newArray = [...passwordPolicies];
    newArray.forEach((item) => {
      var reg = new RegExp(item.regex);
      item.valid = reg.test(value);
    });

    setPasswordPolicies(newArray);
  }

  function handleSubmit() {
    setLoading(true);
    const { code, password } = form.getFieldsValue();
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        navigate(isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn, { state: { resetPassword: true } });
      })
      .catch((error) => {
        showNotification("error", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function resendEmail() {
    Auth.forgotPassword(email)
      .then(() => {
        showNotification("success", t("common.sentEmail"));
      })
      .catch((error) => {
        showNotification("error", error.message);
      });
  }

  return (
    <>
      <div className="reset-password-form">
        <div className="reset-password-form__header">
          {t("resetPassword.title")}
        </div>

        <div className="reset-password-form__description">
          <span
            dangerouslySetInnerHTML={{
              __html: t("resetPassword.description", {
                param1: email,
              }),
            }}
          ></span>
        </div>

        <Form
          name="signin"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          onFinish={handleSubmit}
          form={form}
          className="hiden-required-mark"
        >

          <Form.Item
            label={t("resetPassword.passwordResetCode")}
            name="code"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("resetPassword.passwordResetCode")} autoComplete="new-password" />
          </Form.Item>


          <Form.Item
            label={t("resetPassword.newPassword")}
            name="password"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (passwordPolicies.some((x) => !x.valid)) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className="mr-bottom-0"
          >
            <Input.Password
              placeholder={t("resetPassword.password")}
              onChange={(e) => passwordOnchange(e.target.value)}
              autoComplete="new-password"
            />
          </Form.Item>
          <Row className="password-hint-wrapper">
            {passwordPolicies.map((item) => (
              <Col
                span={12}
                key={item.key}
                className={`password-hint-item${item.valid ? " active" : ""}`}
              >
                <SVGIcons.KeyIcon />
                <span>{item.label}</span>
              </Col>
            ))}
          </Row>
          <Form.Item
            label={t("resetPassword.confirmNewPassword")}
            name="confirmPassword"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (value !== form.getFieldValue("password")) {
                    return Promise.reject(t("resetPassword.passwordsNotMatch"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password placeholder={t("resetPassword.password")} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" block loading={loading}>
              {t("resetPassword.btnReset")}
            </Button>
          </Form.Item>
        </Form>

        <div className="text-center">
          <p className="mr-bottom-0">{t("signUp.checkSpam")}</p>
          <a
            className="reset-password-form__resendMail"
            href="#"
            onClick={resendEmail}
          >
            {t("signUp.sendMailAgain")}
          </a>
        </div>
      </div>

      <Link
        className="auth__backLink"
        to={isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn}
        dangerouslySetInnerHTML={{ __html: t("forgotPassword.sendMeBack") }}
      ></Link>
    </>
  );
}

export default ResetPasswordPage;
