import { Alert, Button, Divider, Form, Input, Modal, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { AuthApi } from "../../../../apis/auth.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import useClient from "../../../../hooks/useClient";
import useUnsaveChange from "../../../../hooks/useUnsaveChange";
import { authUserSelector } from "../../../../states/auth";
import AppAvatar from "../../../common/app-avatar/app-avatar";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import ResetPasswordDrawer from "./reset-password-drawer";
import { CommonUtils } from "../../../../utils/common";

function GeneralSettingAccount() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isAdminViewSite, client, setClient, clientId } = useClient();
  const [userState, setUserState] = useRecoilState(authUserSelector);
  const [showDrawer, setShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [imgSource, setImgSource] = useState("");
  const [imgBinary, setImgBinary] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isEditting, setIsEditting] = useState(false);

  useUnsaveChange(isEditting);

  const profilePictureUrl = isAdminViewSite
    ? client?.user?.profilePictureUrl
    : userState?.profilePictureUrl;

  const isSiteNotHaveUser = isAdminViewSite && !client?.user;

  const submit = async (values: any) => {
    const currentEmail = isAdminViewSite
      ? client?.user?.email
      : userState?.email;
    const hasChangeEmail =
      values && values.email !== currentEmail && values.email !== newEmail;
    if (hasChangeEmail) {
      setOpenConfirmModal(true);
    } else {
      updateData(values);
    }
  };

  async function updateData(values: any, hasChangeEmail = false) {
    setLoading(true);
    try {
      const requestModel = {
        ...values,
        profilePicture: imgBinary,
        userId: form.getFieldValue("userId"),
        clientId: clientId
      };

      const { data } = await AuthApi.updateClientProfile(requestModel);
      showNotification("success", t("generalSettings.updateAccountSuccess"));
      setIsEditting(false);
      const { firstName, lastName, email } = values;

      const formUpdate: any = {
        firstName,
        lastName,
        profilePictureUrl: data.profilePictureUrl,
      };

      const currentEmail = isAdminViewSite
        ? client?.user?.email
        : userState?.email;

      if (hasChangeEmail) {
        formUpdate.newEmail = email;
        setOpenConfirmModal(false);
      } else if (newEmail && currentEmail === email) {
        formUpdate.newEmail = "";
      }

      if (client?.user) {
        setClient({ ...client, user: { ...client.user, ...formUpdate } });
      } else {
        let newUserState = CommonUtils.deepCopy(userState);

        setUserState({ ...newUserState, ...formUpdate });
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  const onChangePicture = async (fileInfo: any) => {
    setLoadingAvatar(true);
    try {
      const { file } = fileInfo;
      setImgSource(URL.createObjectURL(file.originFileObj));
      setImgBinary(await file.originFileObj);
    } catch (error) {}
    setLoadingAvatar(false);
  };

  const onValuesChange = (values: any) => {
    setIsEditting(true);
  };

  useEffect(() => {
    if (isSiteNotHaveUser) return;

    let newEmail = "";

    if (client && client.user) {
      newEmail = client.user.newEmail;
      form.setFieldsValue({
        ...client.user,
        userId: client.user.id,
        email: newEmail || client.user.email,
      });
    } else if (userState) {
      form.setFieldsValue({ ...userState, email: newEmail || userState.email });
    }

    setNewEmail(newEmail);
  }, [userState, client]);

  // useEffect(()=> {
  //   form.validateFields(["email"])
  // }, [newEmail])

  return (
    <div className="general-setting-account">
      {newEmail && (
        <Alert
          className="mr-bottom-20"
          message={t("generalSettings.verifyEmailAlert")}
          type="error"
          showIcon
          icon={<SVGIcons.ErrorIcon />}
        />
      )}

      <Form
        name="general-setting-account"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 10 }}
        labelAlign="left"
        form={form}
        onFinish={submit}
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        disabled={isSiteNotHaveUser}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label={
            <>
              <div>{t("users.userProfilePicture")}</div>
              <div className="format-picture">Format: JPG, JPEG, PNG</div>
              <div className="format-picture">Maximum 250 x 250px</div>
            </>
          }
          colon={false}
          className="label-avatar"
        >
          <div className="avatar">
            <Spin spinning={loadingAvatar}>
              <AppAvatar
                size={100}
                shape="square"
                src={imgSource || profilePictureUrl}
              ></AppAvatar>
            </Spin>
            <div className="btn-edit-avatar">
              <Upload
                onChange={onChangePicture}
                fileList={[]}
                customRequest={() => {}}
                accept=".jpg, .jpeg, .png, .svg"
              >
                <SVGIcons.EditIcon />
              </Upload>
            </div>
          </div>
        </Form.Item>
        <Form.Item
          label={t("signUp.firstName")}
          colon={false}
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("signUp.lastName")}
          colon={false}
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("users.businessEmail")}
          colon={false}
          name="email"
          rules={[
            { required: true },
            { type: "email" },
            // () => ({
            //   validator(_, value) {
            //     if (newEmail && newEmail === value) {
            //       return Promise.reject(t("generalSettings.emailVerificationRequired"));
            //     }
            //     return Promise.resolve();
            //   },
            // })
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item label={t("users.password")} colon={false}>
          <Button
            onClick={() => setShowDrawer(true)}
            className="mid-night-border"
            disabled={isAdminViewSite && !client?.user}
          >
            <SVGIcons.KeyIcon />
            {t("resetPassword.title")}
          </Button>
        </Form.Item>
        <Divider />

        <div className="group-btn-account">
          <Button
            htmlType="submit"
            type="primary"
            loading={!openConfirmModal && loading}
          >
            {t("common.saveChanges")}
          </Button>
        </div>
      </Form>

      <ResetPasswordDrawer
        showDrawer={showDrawer}
        onSetShowDrawer={setShowDrawer}
      />

      <Modal
        title={t("generalSettings.confirmChangeEmailTitle")}
        cancelText={t("common.cancel")}
        okText={t("generalSettings.confirmChangeEmailSubmitButton")}
        open={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onOk={() => updateData(form.getFieldsValue(), true)}
        confirmLoading={loading}
      >
        <p>{t("generalSettings.confirmChangeEmailDescription")}</p>
      </Modal>
    </div>
  );
}

export default GeneralSettingAccount;
