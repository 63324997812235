export const APP_STATE_KEYS = {
  AUTH_STATE: "AUTH_STATE",
  PROFILE_UPDATED_STATE: "PROFILE_UPDATED_STATE",
  ADMIN_STATE: "ADMIN_STATE",
  USER_STATE: "USER_STATE",
  DASHBOARD_GA_GENERAL_STATE: "DASHBOARD_GA_GENERAL_STATE",
  DASHBOARD_DATE_RANGE_STATE: "DASHBOARD_DATE_RANGE_STATE",
  DASHBOARD_DATA_LOADING_STATE: "DASHBOARD_DATA_LOADING_STATE",
  DASHBOARD_STATE: "DASHBOARD_STATE",
  DASHBOARD_GA_TRAFFIC_STATE: "DASHBOARD_GA_TRAFFIC_STATE",
  DASHBOARD_CALLS_STATE: "DASHBOARD_CALLS_STATE",
  DASHBOARD_GL_STATE: "DASHBOARD_GL_STATE",
  DASHBOARD_FORMS_STATE: "DASHBOARD_FORMS_STATE",
  DASHBOARD_BIRDEYE: "DASHBOARD_BIRDEYE",
  SOCIAL_DASHBOARD_DATE_RANGE_STATE: "SOCIAL_DASHBOARD_DATE_RANGE_STATE",
  CLIENT_SITES_STATE: "CLIENT_SITES_STATE",
  DASHBOARD_TOTAL_LEADS_STATE: "DASHBOARD_TOTAL_LEADS_STATE",
  PAGE_TITLE_STATE: "PAGE_TITLE_STATE",
  CONNECTION_STATE: "CONNECTION_STATE",
  LIST_CLIENT_STATE: "LIST_CLIENT_STATE",
  CREATE_CONTENT_STATE: "CREATE_CONTENT_STATE",
  NAVBAR_STATE: "NAVBAR_STATE",
  WINDOWS_WIDTH_STATE: "WINDOWS_WIDTH_STATE",
  LIST_PROFILE: "LIST_PROFILE",
  TRIGGER_FETCH_PROFILES: "TRIGGER_FETCH_PROFILES",
  LIST_ENABLE_CONTENT: "LIST_ENABLE_CONTENT",
  LIST_CONTENT: "LIST_CONTENT",
  LIST_CONTENT_CALENDAR: "LIST_CONTENT_CALENDAR",
  NOTIFICATION_STATE: "NOTIFICATION_STATE",
  LIST_TAG: "LIST_TAG",
  LIST_POST_TYPE: "LIST_POST_TYPE",
  LIST_ERROR: "LIST_ERROR",
  ACCESS_SERVICES: "ACCESS_SERVICES",
  LIST_HASH_TAG: "LIST_HASH_TAG",
  LIST_INTERACTION: "LIST_INTERACTION",
  LIST_TIMEZONES: "LIST_TIMEZONES",
  SOCIAL_PERMISSION: "SOCIAL_PERMISSION",
  SOCIAL_PERMISSION_ONLY_MANAGE_CONTENT: "SOCIAL_PERMISSION_ONLY_MANAGE_CONTENT",
  SOCIAL_FETCH_BULK_APPROVAL: "SOCIAL_FETCH_BULK_APPROVAL",
  CLIENT_PLAN: "CLIENT_PLAN",
  SALES_ANALYTICS_CALL_COUNT: "SALES_ANALYTICS_CALL_COUNT",
  SALES_ANALYTICS_TOTAL_LEAD_WORKED: "SALES_ANALYTICS_TOTAL_LEAD_WORKED",
  SALES_ANALYTICS_TOTAL_CALLS: "SALES_ANALYTICS_TOTAL_CALLS",
  SALES_ANALYTICS_CONNECTION_RATE: "SALES_ANALYTICS_CONNECTION_RATE",
  SALES_ANALYTICS_TOTAL_DEMO_BOOKED: "SALES_ANALYTICS_TOTAL_DEMO_BOOKED",
  SALES_ANALYTICS_BOOKING_COUNT: "SALES_ANALYTICS_BOOKING_COUNT",
  SALES_ANALYTICS_CONNECTED_BOOKING_DATE: "SALES_ANALYTICS_CONNECTED_BOOKING_DATE",
  SALES_ANALYTICS_CALL_STATUS: "SALES_ANALYTICS_CALL_STATUS",
  SALES_ANALYTICS_DATE_RANGE_STATE: "SALES_ANALYTICS_DATE_RANGE_STATE",
  ZOHO_USER_INFO: "ZOHO_USER_INFO",
  ZOHO_REPORT_CARD: "ZOHO_REPORT_CARD",
  ZOHO_CONNECTED: "ZOHO_CONNECTED",
  ZOHO: "ZOHO",
  ZOHO_CLIENTS_STATE: "ZOHO_CLIENTS_STATE",
};
