import { Col, Form, Input, Row, Select, Space, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useRecoilState } from "recoil";
import {
  CLIENT_CETEGORIES,
  COUNTRIES_STATES,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { getStatusClient, getUserPlan } from "../../../../pages/admin/client-sites";
import { clientSitesState } from "../../../../states/clientSites";
import AppSelectCountry from "../../../controls/app-select-country";
import AppSelectTimezone from "../../../controls/app-select-timezone";
import "./index.scss";
import { StateModel } from "../../../../models/state.model";
import { UserPlanStatus } from "../../../../constants/app.enums";

interface OverviewFormProps {
  form: any;
}

function OverviewForm(props: OverviewFormProps) {
  const { form } = props;
  const { t } = useTranslation();
  const [state, setState] = useRecoilState(clientSitesState);
  const { overViewFormValues, clientSelected } = state;

  const [countryWithStates, setCountryWithStates] = useState<StateModel[]>([])

  const enableValue = Form.useWatch("enabled", form);
  const hasUser = !!clientSelected?.user;

  useEffect(() => {
    if (overViewFormValues) {
      form.setFieldsValue(overViewFormValues);
    }
  }, [overViewFormValues]);

  function formChange(changedValues: any) {
  
    setState({
      ...state,
      overViewFormValues: { ...overViewFormValues, ...changedValues },
    });
  }
  const isCanceledPlan = clientSelected?.userPlan?.status === UserPlanStatus.Canceled || clientSelected?.userPlan?.status === UserPlanStatus.PendingCancel;
  useEffect(() => {
    if (!overViewFormValues?.country) {
      setCountryWithStates([]);
      form.setFieldValue("state", '');
      setState({ ...state, overViewFormValues: { ...overViewFormValues, state: '' } });
      return;
    }
  
    const selectedCountry = COUNTRIES_STATES.find(x => x.code === overViewFormValues.country) || { states: [] };
    const { states } = selectedCountry;
  
    setCountryWithStates(states);
    const countryState = states.find(x => x.value === overViewFormValues.state);
    const newStateValue = countryState ? countryState.value : '';
  
    form.setFieldValue("state", newStateValue);
    setState({ ...state, overViewFormValues: { ...overViewFormValues, state: newStateValue } });
  }, [overViewFormValues?.country, overViewFormValues?.state, form]);
  
  return (
    <Form
      name="overview"
      layout="vertical"
      validateMessages={VALIDATION_MESSAGE_CONFIG}
      onValuesChange={formChange}
      form={form}
      className="overview-form"
    >
      <Form.Item>
        <Row gutter={16} align="middle">
          <Col flex="1">
            {t("clientSites.siteId")}: {state?.clientSelected?.id}
          </Col>
          <Col>
            <div className="d-flex align-center">
              <Space>
                Enable
                <Form.Item
                  valuePropName="checked"
                  name="enabled"
                  className="no-margin"
                >
                  <Switch />
                </Form.Item>
              </Space>
            </div>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item>
        <Row gutter={16}>
          <Col flex="1">
            {t("clientSites.status")}:{" "}
            {getStatusClient(clientSelected?.clientStatus, !enableValue, clientSelected?.user?.isPendingDeletion, isCanceledPlan )}
          </Col>
          <Col>
            <div style={{gap: 4}} className="d-flex align-center">
              {t("clientSites.plan")}:
              {
                 getUserPlan(clientSelected?.userPlan)
              }
            </div>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label={t("clientSites.companyName")}
        name="companyName"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("clientSites.firstName")}
            name="firstName"
            rules={[{ required: hasUser }]}
          >
            <Input disabled={!hasUser} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t("clientSites.lastName")}
            name="lastName"
            rules={[{ required: hasUser }]}
          >
            <Input disabled={!hasUser} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={t("clientSites.industry")}
        name="category"
        rules={[{ required: true }]}
      >
        <Select showSearch  optionFilterProp="children">
          {CLIENT_CETEGORIES.map((x) => (
            <Select.Option key={x.value} value={x.value}>
              {x.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("clientSites.businessEmail")}
        name="email"
        rules={[{ required: hasUser }, { type: "email" }]}
      >
        <Input disabled={!hasUser} />
      </Form.Item>

      <Form.Item
        label={t("clientSites.businessPhone")}
        name="businessPhone"
        rules={[{ required: hasUser }]}
      >
        <PhoneInput country={"us"} enableSearch={true} disabled={!hasUser} />
      </Form.Item>

      <Form.Item
        label={t("clientSites.websiteURL")}
        name="website"
        rules={[
          { required: true },
          {
            type: "url",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("generalSettings.timeZone")}
        name="timeZone"
        rules={[{ required: true }]}
      >
        <AppSelectTimezone
          value={overViewFormValues?.timeZone}
          onChange={(value: string) => form.setFieldValue("timeZone", value)}
        />
      </Form.Item>

      <Form.Item
        label={t("generalSettings.country")}
        colon={false}
        name="country"
      >
        <AppSelectCountry
          value={overViewFormValues?.country}
          onChange={(value: string) => form.setFieldValue("country", value)}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("clientSites.streetAddress")}
            name="streetAddress"
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={t("clientSites.city")} name="city">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("clientSites.state")} name="state">
            <Select disabled={countryWithStates.length === 0}>
              {countryWithStates.map((x) => (
                  <Select.Option key={x.value} value={x.value}>
                    {x.value} - {x.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={t("clientSites.zipCode")} name="zipCode">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default OverviewForm;
