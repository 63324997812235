import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import showNotification from "../../../../common/notification";
import IntegrationAlert from "../alert";
import "./index.scss";
import useZoho from "../../../../../hooks/useZoho";
import { ZohoClientInfo } from "../../../../../models/zoho.model";
import DateUtils from "../../../../../utils/date.utils";
import useClient from "../../../../../hooks/useClient";
import { IntegrationStatus, ZClientConnectStatus, ZohoSyncStatus } from "../../../../../constants/app.enums";
import { ZohoApi } from "../../../../../apis/zoho.api";
import { useRecoilState, useRecoilValue } from "recoil";
import { saleAnalyticZohoState } from "../../../../../states/zoho";
import { useEffect } from "react";
import useAdminSite from "../../../../../hooks/useAdminSite";
import { clientSitesState } from "../../../../../states/clientSites";

interface Props {
  accountInfo: ZohoClientInfo;
}

function ZohoCRMPanel(props: Props) {
  const { accountInfo } = props;
  const { t } = useTranslation();
  const { timezoneId, client, isAdminViewSite } = useClient();
  const {
    checkConnectedZoho,
    checkConnectedLoading,
    connectZoho,
    connectZohoLoading,
    status,
    setStatus,
    getZohoClient,
  } = useZoho();
  const { clientSelected } = useRecoilValue(clientSitesState);

  const [zohoState, setZohoState] = useRecoilState(saleAnalyticZohoState);
  const isAdmin = useAdminSite();

  async function testConnection() {
    await checkConnectedZoho(accountInfo.zohoClientId);
    await getZohoClient(accountInfo.clientId);
  }

  const removeAccount = () => {
    Modal.confirm({
      title: "You are about to remove Zoho CRM account",
      content: (
        <div className="remove-decs">
          <div>
            You are about to remove <b>{accountInfo.email}</b> from RocketLevel.
          </div>
          <div>What will happen:</div>
          <ul>
            <li>
              Dashboard cards associated with this account will be emptied
            </li>
            <li>All historical data from this account will be lost</li>
          </ul>
          <div>
            The action cannot be undone. Are you sure you want to proceed?{" "}
          </div>
        </div>
      ),
      okText: t("common.removeConnectionBtn"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal remove-account-modal",
      centered: true,
      onOk: async () => {
        try {
          await ZohoApi.deleteZohoClient(accountInfo.zohoClientId, accountInfo.clientId);
        } catch (error) { }
        showNotification(
          "delete",
          t("generalSettings.removedAccountZoho", { email: accountInfo.email })
        );
        getZohoClient(accountInfo.clientId);
      },
    });
  };

  const renderStatus = () => {
    switch (accountInfo.status) {
      case ZClientConnectStatus.Unused:
        return <span>Unused</span>;
      case ZClientConnectStatus.Active:
        return <span className="color-secondary">Active</span>;
      case ZClientConnectStatus.Error:
        return <span className="color-sunset">Error</span>;
      default:
        break;
    }
  };


  const onSyncData = async () => {
    const cId = isAdmin && !isAdminViewSite ? clientSelected?.id : client?.id;
    if (!cId) return;
    try {
      setStatus(ZohoSyncStatus.ZohoSyncing);
      await ZohoApi.syncLatest(cId, accountInfo.orgId);
      await checkPendingSyncJobs(cId);
    } catch (error) {
      setStatus(ZohoSyncStatus.ZohoSynced);
    }
  };

  const checkPendingSyncJobs = (clientId: number) => {
    let attempts = 0;
    let intervalId: NodeJS.Timeout;
    const MAX_ATTEMPTS = 10;
    const DELAY = 15000;
    if (!client?.id) return;

    const request = async () => {
      attempts++;
      try {

        const response = await ZohoApi.getPendingSyncJobs(clientId, accountInfo.orgId);
        const allJobsSuccess = response.data.every(x => x.status === ZohoSyncStatus.SUCCESS);

        if (allJobsSuccess) {
          clearInterval(intervalId);

          setZohoState({
            ...zohoState,
            triggerReloadReports: zohoState.triggerReloadReports + 1,
          });
          setStatus(ZohoSyncStatus.ZohoSynced);
          return response;
        }

        if (
          response.data.filter(x => x.status === ZohoSyncStatus.NEW).length > 0 ||
          response.data.filter(x => x.status === ZohoSyncStatus.RUNNING).length > 0
        ) {
          setStatus(ZohoSyncStatus.ZohoSyncing);
          if (attempts >= MAX_ATTEMPTS) {
            clearInterval(intervalId);
            setStatus(IntegrationStatus.None);
            return response;
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        setStatus(IntegrationStatus.None);
        throw error;
      }
    };

    const promise = request();

    intervalId = setInterval(request, DELAY);

    return promise;
  };

  useEffect(() => {
    setZohoState({
      ...zohoState,
      triggerReloadReports: 0,
    });
  }, [zohoState.triggerReloadReports]);

  return (
    <div className="integration-form__section">
      <IntegrationAlert
        status={status || accountInfo.statusMessage}
        onReAuthenticate={() => connectZoho(true)}
        onSyncDataZoho={onSyncData}
        reAuthenticateLoading={connectZohoLoading}
      />
      <div className="account-info">
        <div className="account-info-item">
          <div className="label">{t("generalSettings.account")}</div>
          <div className="value">{accountInfo?.email || "-"}</div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.connectionStatus")}</div>
          <div className="value">{renderStatus()}</div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.connectedBy")}</div>
          <div className="value">{accountInfo?.createdBy || "-"}</div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.connectedOn")}</div>
          <div className="value">
            {accountInfo?.createdOn ? DateUtils.getDateWithTimezone(
              accountInfo?.createdOn,
              timezoneId
            ).format("MMMM DD,YYYY hh:mm a") : "-"}
          </div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.lastSynced")}</div>
          <div className="value">
            {accountInfo?.latestSyncOn ?
              DateUtils.getDateWithTimezone(
                accountInfo?.latestSyncOn,
                timezoneId
              ).format("MMMM DD,YYYY hh:mm a") : "-"}
          </div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.lastSyncedBy")}</div>
          <div className="value">{accountInfo?.latestSyncBy || "-"}</div>
        </div>
      </div>

      <div className="group-btn">
        <Button type="text" className="remove-btn" onClick={removeAccount}>
          {t("common.remove")}
        </Button>
        <Button
          className="secondary-btn"
          type="primary"
          loading={checkConnectedLoading}
          onClick={testConnection}
        >
          {t("clientSites.testConnection")}
        </Button>
      </div>
    </div>
  );
}

export default ZohoCRMPanel;
