import { Button, Col, Form, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AuthApi } from "../../../apis/auth.api";
import SVGIcons from "../../../components/icons/svgs";
import {
  PASSWORD_POLYCIES,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.scss";

function TeamInvitationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const [passwordPolicies, setPasswordPolicies] = useState(
    PASSWORD_POLYCIES.filter((x) => !x.disabled)
  );

  const passwordOnchange = (value: string) => {
    const newArray = [...passwordPolicies];
    newArray.forEach((item) => {
      var reg = new RegExp(item.regex);
      item.valid = reg.test(value);
    });

    setPasswordPolicies(newArray);
  };

  const getMemberInfo = async (memberId: number) => {
    setLoadingForm(true);
    const { data } = await AuthApi.getAcceptInvitation(memberId);
    if (data.userId)
      navigate(ROUTE_PATHS.SignIn, { state: { email: data.email } });
    form.setFieldsValue(data);
    setLoadingForm(false);
  };

  const submit = async () => {
    if (!params.memberId) return;
    setLoading(true);
    try {
      const { firstName, lastName, password, email } = form.getFieldsValue();
      await AuthApi.createAccountForMember({
        firstName,
        lastName,
        password,
        id: parseInt(params.memberId),
      });

      navigate(ROUTE_PATHS.CreateMemberSuccess, { state: { email } });
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (params.memberId) getMemberInfo(parseInt(params.memberId));
  }, []);

  return (
    <>
      {loadingForm ? (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        ></Spin>
      ) : (
        <div className="invite-team-form">
          <div className="header">{t("signUp.inviteTeamTitle")}</div>
          <p className="description">{t("signUp.inviteTeamDescription")}</p>
          <Form
            name="signin"
            layout="vertical"
            validateMessages={VALIDATION_MESSAGE_CONFIG}
            onFinish={submit}
            form={form}
          >
            <Row gutter={17}>
              <Col span={12}>
                <Form.Item
                  label={t("signUp.firstName")}
                  name="firstName"
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t("signUp.firstNamePlahoder")} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("signUp.lastName")}
                  name="lastName"
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t("signUp.lastNamePlahoder")} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label={t("signUp.email")}
              name="email"
              rules={[{ required: true }, { type: "email" }]}
            >
              <Input placeholder={t("signUp.businessEmailPlahoder")} disabled />
            </Form.Item>

            <Form.Item
              label={t("signUp.password")}
              name="password"
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (passwordPolicies.some((x) => !x.valid)) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className="mr-bottom-0 padding-bottom-0"
            >
              <Input.Password
                placeholder={t("signUp.password")}
                onChange={(e) => passwordOnchange(e.target.value)}
              />
            </Form.Item>

            <Row className="password-hint-wrapper">
              {passwordPolicies.map((item) => (
                <Col
                  span={12}
                  key={item.key}
                  className={`password-hint-item${item.valid ? " active" : ""}`}
                >
                  <SVGIcons.KeyIcon />
                  <span>{item.label}</span>
                </Col>
              ))}
            </Row>

            <Button htmlType="submit" type="primary" block loading={loading}>
              {t("signUp.continue")}
            </Button>
          </Form>
        </div>
      )}
    </>
  );
}

export default TeamInvitationPage;
