import { atom } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import {
  BookingCountModel,
  CallCountModel,
  CallStatusModel,
  ConnectedBookingDateStateModel,
  ConnectionRateStateModel,
  TotalCallsModel,
  TotalDemoBookedModel,
  TotalLeadWorkedModel,
  ZohoClientInfo,
  ZohoReportCard,
  ZohoUserInfo,
} from "../models/zoho.model";


interface TotalLeadWorkedState {
  loading: boolean;
  data: TotalLeadWorkedModel | undefined;
}
interface CallCountState {
  loading: boolean;
  data: CallCountModel[] | undefined;
}

interface BookingCountState {
  loading: boolean;
  data: BookingCountModel[] | undefined;
}

interface ConnectedBookingDateState {
  loading: boolean;
  data: ConnectedBookingDateStateModel[] | undefined;
}

interface CallStatusState {
  loading: boolean;
  data: CallStatusModel[] | undefined;
}
interface ReportCardState {
  loading: boolean;
  data: ZohoReportCard[] | undefined;
}
interface TotalCallState {
  loading: boolean;
  data: TotalCallsModel | undefined;
}

interface ConnectionRateState {
  loading: boolean;
  data: ConnectionRateStateModel[] | undefined;
}
interface TotalDemoBookedState {
  loading: boolean;
  data: TotalDemoBookedModel | undefined;
}

interface UsersInfoState {
  loading: boolean;
  data: ZohoUserInfo | undefined;
}

interface ConnectedState {
  isConnected: boolean;
}

interface ZohoStateModel {
  triggerReloadReports: number; 
}

const saleAnalyticZohoState = atom<ZohoStateModel>({
  key: APP_STATE_KEYS.ZOHO,
  default: {
    triggerReloadReports: 0,
  },
});


const saleAnalyticsTotalLeadWorkedState = atom<TotalLeadWorkedState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_TOTAL_LEAD_WORKED,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsCallCountState = atom<CallCountState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_CALL_COUNT,
  default: {
    data: undefined,
    loading: false,
  },
});
const saleAnalyticsBookingCountState = atom<BookingCountState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_BOOKING_COUNT,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsConnectedBookingDateState = atom<ConnectedBookingDateState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_CONNECTED_BOOKING_DATE,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsCallStatusState = atom<CallStatusState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_CALL_STATUS,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsTotalCallsState = atom<TotalCallState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_TOTAL_CALLS,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsTotalDemoBookedState = atom<TotalDemoBookedState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_TOTAL_DEMO_BOOKED,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsConnectionRateState = atom<ConnectionRateState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_CONNECTION_RATE,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsDateRangeState = atom({
  key: APP_STATE_KEYS.SALES_ANALYTICS_DATE_RANGE_STATE,
  default: {
    startDate: "",
    endDate: "",
    totalDays: 0,
    value: "",
  },
});

const usersInfoState = atom<UsersInfoState>({
  key: APP_STATE_KEYS.ZOHO_USER_INFO,
  default: {
    data: undefined,
    loading: false,
  },
});

const zohoClientsState = atom<ZohoClientInfo[]>({
  key: APP_STATE_KEYS.ZOHO_CLIENTS_STATE,
  default: [],
});

const reportCardState = atom<ReportCardState>({
  key: APP_STATE_KEYS.ZOHO_REPORT_CARD,
  default: {
    data: undefined,
    loading: false,
  },
});

const connectedState = atom<ConnectedState>({
  key: APP_STATE_KEYS.ZOHO_CONNECTED,
  default: {
    isConnected: false,
  },
});

export {
  saleAnalyticsCallCountState,
  saleAnalyticsDateRangeState,
  usersInfoState,
  saleAnalyticsCallStatusState,
  saleAnalyticsTotalLeadWorkedState,
  saleAnalyticsTotalCallsState,
  saleAnalyticsTotalDemoBookedState,
  saleAnalyticsConnectionRateState,
  saleAnalyticsBookingCountState,
  saleAnalyticsConnectedBookingDateState,
  connectedState,
  reportCardState,
  saleAnalyticZohoState,
  zohoClientsState
};
