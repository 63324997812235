import { useRecoilState } from "recoil";
import { AuthApi } from "../apis/auth.api";
import { listClientState } from "../states/clientSites";
import { useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../constants/router.constants";

interface GetClientParams {
  keyword?: string;
  pageIndex?: number;
  pageSize?: number;
  orderBy?: string;
  orderDirection?: string;
  statuses?: string[];
  userGroups?: string[];
  categories?: string[];
  plans?: number[];
}

function useGetClients() {
  const [state, setState] = useRecoilState(listClientState);
  const location = useLocation();

  const getClients = async (query?: GetClientParams) => {
    setState({ ...state, loading: true });

    try {
      const params = { ...state.query, ...query };
      const { data } = await AuthApi.getClients(params);
      
      setState({
        ...state,
        loading: false,
        data,
        query: params,
      });
    } catch (error) {
      console.log(error);
      setState({ ...state, loading: false });
    }
  };

  const refreshClientList = ()=> {
    if (
      location &&
      location.pathname &&
      location.pathname.includes(ROUTE_PATHS.ClientSite)
    ) {
      getClients();
    }
  }

  return {
    getClients,
    refreshClientList
  };
}

export default useGetClients;
