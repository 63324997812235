import styled from "styled-components";

export const AvatarSocialStyled: any = styled.div`
  .avatar-social-wrap {
    width: ${(props: any) => (props.size ? `${props.size}px` : "51px")};
    height: ${(props: any) => (props.size ? `${props.size}px` : "51px")};
    position: relative;
    cursor: pointer;
    opacity: ${(props: any) => (props.disabled ? "0.4" : "1")};
    transition: 0.4s;
    & > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .social-icon {
      width: ${(props: any) => (props.sizeIcon ? `${props.sizeIcon}px` : "20px")};
      height: ${(props: any) => (props.sizeIcon ? `${props.sizeIcon}px` : "20px")};
      position: absolute;
      bottom: 0;
      right: 0;
      > img {
        width: 100%;
      }
    }
    .remove-icon {
        width: ${(props: any) => (props.sizeIcon ? `${props.sizeIcon}px` : "20px")};
        height: ${(props: any) => (props.sizeIcon ? `${props.sizeIcon}px` : "20px")};
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #f35848;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        line-height: 1.67;
        letter-spacing: 0.02px;
    }
  }
`;
