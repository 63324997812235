import { useRecoilValue } from "recoil";
import { ROUTE_PATHS } from "../constants/router.constants";
import { authIsSupperAdminSelector } from "../states/auth";
import useAdminSite from "./useAdminSite";

function useDashboardPath() {
  const isAdminSite = useAdminSite();
  const isSupperAdmin = useRecoilValue(authIsSupperAdminSelector);

  if (isAdminSite) {
    return isSupperAdmin ? ROUTE_PATHS.UserList : ROUTE_PATHS.ClientSite;
  }

  return ROUTE_PATHS.Home;
}

export default useDashboardPath;
