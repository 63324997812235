import { Button, Col, Input, Pagination, Popover, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import ContentEmptyImage from "../../../assets/images/icon-content-empty.png";
import NoResultImage from "../../../assets/images/icon-no-result.png";
import "./index.scss";
import { useEffect, useState } from "react";
import SVGIcons from "../../../components/icons/svgs";
import {
  ApprovalStatusEnum,
  LimitedTrialPlanType,
  PermissionSocial,
  PlanUsageType,
  PostStatusType,
} from "../../../constants/app.enums";
import ContentCard from "../../../components/views/social/social-content/components/content-card";
import CreateContent from "../../../components/views/social/social-content/create-content";
import AddConnect from "../../../components/views/social/add-connect";
import SpinDiv from "../../../components/common/spin-div";
import useSocial from "../../../hooks/useSocial";
import {
  ContentModel,
  GetListContentRequestModel,
} from "../../../models/content.model";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  contentValueState,
  listContentState,
  listPostTypeState,
  listTagState,
} from "../../../states/social";
import useClient from "../../../hooks/useClient";
import AppDateTimePicker from "../../../components/controls/app-date-time-picker";
import moment from "moment";
import DeleteContentModal from "../../../components/views/social/social-content/components/delete-content-modal";
import useService from "../../../hooks/useService";
import searchDebounce from "../../../utils/searchDebounce";
import { CONTENT_FILTER_DATA } from "../../../constants/app-constants";
import { useSearchParams } from "react-router-dom";
import { ShareLinkModal } from "../../../components/views/social/share-link-modal";
import EditContentModal from "../../../components/views/social/social-content/edit-content/edit-content-modal";
import FilterList from "../../../components/common/filter";
import useContent from "../../../hooks/useContent";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useUpdateContent from "../../../components/views/social/social-content/hook/useUpdateContent";
import DateUtils from "../../../utils/date.utils";
import SocialUtils from "../../../utils/social";
import { planState } from "../../../states/plan";
import useClientPlan from "../../../hooks/useClientPlan";

const SocialContent = () => {
  const { t } = useTranslation();
  const { clientId, hasPermissionSocial } = useClient();
  const { SocialService } = useService();
  const {
    isProfileConnected,
    loading: loadingConnectSocial,
    loadingConnect,
    connectProfile,
  } = useSocial();
  const {
    onActionDropdown,
    scheduleNow,
    initContentDetail,
    initListContent,
    contentSelected,
    setContentSelected,
    openCreateModal,
    setOpenCreateModal,
    openDeleteModal,
    openEditModal,
    openScheduleModal,
    scheduleTime,
    contentShareLink,
    setScheduleTime,
    setOpenScheduleModal,
    setOpenEditModal,
    setContentShareLink,
    setOpenDeleteModal,
    loadingSchedule,
    closeCreateModal,
    onOpenCreateWithAIModal
  } = useContent();
  const [searchParams, setSearchParams] = useSearchParams();
  const contentId = Number(searchParams.get("contentId"));
  const { createContent, editContent, isValidContent } = useUpdateContent();
  const [keyword, setKeyword] = useState("");
  const [dateTimePickerDefaultValue, setDateTimePickerDefaultValue] =
    useState(scheduleTime);
  const { checkPremiumPlan } = useClientPlan();
  const [contentState, setContentState] = useRecoilState(listContentState);
  const resetContentValue = useResetRecoilState(contentValueState);
  const resetContentState = useResetRecoilState(listContentState);
  const listTag = useRecoilValue(listTagState);
  const listPostType = useRecoilValue(listPostTypeState);
  const url = `${window.location.origin}${ROUTE_PATHS.Social.ReviewContent}?contentId=${contentShareLink?.id}&clientId=${clientId}`;
  const { client } = useClient();

  const {
    records: listContent,
    error,
    drafted,
    posted,
    scheduled,
    totalRecords,
    totalPosts,
    loading,
    query: { pageIndex, pageSize },
  } = contentState;

  useEffect(() => {

    if (openEditModal) {
      if (!contentSelected) {
        return;
      }
      searchParams.set("contentId", contentSelected.id.toString());
      setSearchParams(searchParams);
    } else {
      if (searchParams.has("contentId")) {
        searchParams.delete("contentId");
        setSearchParams(searchParams);
      }
    }
  }, [openEditModal]);

  const onEditApprovalContent = async (
    contentId: number,
    approvalStatus: ApprovalStatusEnum
  ) => {
    const newListContent = listContent.map((content) => {
      return content.id !== contentId
        ? content
        : { ...content, approvalStatus };
    });
    setContentState({ ...contentState, records: newListContent });
  };

  const onChangePagination = async (page: number, pageSize: number) => {
    setSearchParams({ page: page.toString() });
    await SocialService.getListContent({
      clientId,
      pageIndex: page,
      pageSize: pageSize,
    });
  };

  const onSearchContent = async (e: any) => {
    const value = e.target.value;
    searchDebounce(value, () => {
      setSearchParams({ page: "1" });
      SocialService.getListContent({
        clientId,
        pageIndex: 1,
        keyword: value,
      });
    });
    setKeyword(value);
  };

  const handleEditContent = async (contentId: number) => {
    if (!(await isValidContent())) return;
    const isSuccess = await editContent(contentId);
    if(isSuccess) {
      setOpenEditModal(false);
      if (searchParams.has("page")) {
        const pageIndex = Number(searchParams.get("page"));
        await initListContent({ pageIndex });
      } else {
        await initListContent();
      }
    }
  };

  const handleCreateContent = async () => {
    if (!(await isValidContent())) return;
    const isSuccess = await createContent();
    if(isSuccess) {
      setOpenCreateModal(false);
      if (searchParams.has("page")) {
        const pageIndex = Number(searchParams.get("page"));
        await initListContent({ pageIndex });
      } else {
        await initListContent();
      }
    }
  };

  const filterChange = async (values: any) => {
    setSearchParams({ page: "1" });
    await SocialService.getListContent({
      platforms: undefined,
      approvalStatus: undefined,
      statuses: undefined,
      tagIds: undefined,
      postTypes: undefined,
      pageIndex: 1,
      ...values,
    });
  };

  const dataFilter = {
    platforms: CONTENT_FILTER_DATA["platforms"],
    statuses: CONTENT_FILTER_DATA["statuses"],
    postTypes: listPostType.map((postType) => {
      return { name: SocialUtils.createKeyPostType(postType), value: postType };
    }),
    approvalStatus: CONTENT_FILTER_DATA["approvalStatus"],
    tagIds: listTag.map((tag) => {
      return {
        name: tag.name,
        value: tag.id,
        color: tag.color,
      };
    }),
  };

  const initListTag = async () => {
    clientId && (await SocialService.getListTag(clientId));
  };

  const initListPostType = async () => {
    clientId && (await SocialService.getListPostType(clientId));
  };

  const onOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  useEffect(() => {
    initListTag();
    initListPostType();
    return () => {
      resetContentState();
    };
  }, []);

  useEffect(() => {
    let params: GetListContentRequestModel = {};
    if (searchParams.has("page")) {
      params.pageIndex = Number(searchParams.get("page"));
    }
    if (searchParams.has("postType")) {
      params.postTypes = [Number(searchParams.get("postType"))];
    }
    initListContent(params);
  }, [clientId]);

  useEffect(() => {
    if (contentId && !contentSelected) {
      initContentDetail(contentId);
    }
  }, [contentId]);

  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return (
        <>
          <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
        </>
      );
    }
    if (type === "next") {
      return (
        <>
          {t("clientSites.next")} <SVGIcons.ChevronRight />
        </>
      );
    }
    return originalElement;
  };

  const isQuerying =
    keyword ||
    contentState.query.approvalStatus ||
    contentState.query.statuses ||
    contentState.query.platforms ||
    contentState.query.tagIds;

  const getDateTimePickerDefaultValue = (
    contentStatus: number,
    date: string
  ) => {
    if (contentStatus === PostStatusType.Drafted) {
      const clientTime = DateUtils.convertTimeToTimezone(
        client?.timeZoneInfo?.id
      );
      setDateTimePickerDefaultValue(clientTime);
    } else {
      const timeWithTimezone = DateUtils.getDateWithTimezone(
        date,
        client?.timeZoneInfo?.id
      );
      setDateTimePickerDefaultValue(timeWithTimezone);
    }
  };

  return (
    <div className="social-content">
      <div className="page-header social-content-header">
        <Row>
          <Col>
            <h4 className="page-header__title">{t("social.content.title")}</h4>
          </Col>
          {isProfileConnected && (
            <Col flex="1">
              <Row gutter={16} justify="end">
                <Col>
                  <Input
                    placeholder={t("common.searchPlahoder")}
                    suffix={<SVGIcons.SearchIcon />}
                    className="search-input"
                    onChange={onSearchContent}
                    value={keyword}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
      <SpinDiv
        loading={loadingConnectSocial}
        style={{ height: "calc(100vh - 300px)" }}
      >
        <div className="social-content-body">
          {!isProfileConnected && listContent.length === 0 && !loading ? (
            <div className="no-connect">
              <div className="background"></div>
              <div className="add-connect">
                <AddConnect
                  loadingConnect={loadingConnect}
                  connectProfile={connectProfile}
                />
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="container__header">
                <Row>
                  <Col>
                    <div className="total-post">
                      {totalPosts} {t("social.content.totalPosts")}
                    </div>
                    <div className="total-post-detail">
                      {error} {t("social.content.error")}, {drafted}{" "}
                      {t("social.content.drafted")}, {scheduled}{" "}
                      {t("social.content.scheduled")}, {posted}{" "}
                      {t("social.content.published")}
                    </div>
                  </Col>
                  <Col flex="1">
                    <Row gutter={16} justify="end">
                      <Col>
                        <FilterList data={dataFilter} onChange={filterChange} />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className="ant-btn-primary ant-btn-purple"
                          onClick={onOpenCreateWithAIModal}
                        >
                          <SVGIcons.SparklesIcon />
                          {t("social.aiFeature.writeWithAI")}
                        </Button>
                      </Col>
                      {hasPermissionSocial(PermissionSocial.CanManage) && (
                        <Col>
                          <Button
                            type="primary"
                            onClick={() => {
                              checkPremiumPlan(() => {
                                onOpenCreateModal();
                              }, PlanUsageType.PostPerCom);
                            }}
                          >
                            <SVGIcons.PlusIcon />
                            {t("social.content.createContent")}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>

              <Spin spinning={loading}>
                <div className="container__body">
                  {!listContent.length && !loading ? (
                    !isQuerying ? (
                      <div className="create-content">
                        <img src={ContentEmptyImage} alt="" />
                        <div className="create-content-title">
                          {t("social.content.createContentTitle")}
                        </div>
                        <div className="create-content-description">
                          {t("social.content.createContentDescription")}
                        </div>
                        <div className="group-btn">
                          <Button
                            type="primary"
                            className="ant-btn-primary ant-btn-purple"
                            onClick={onOpenCreateWithAIModal}
                          >
                            <SVGIcons.SparklesIcon />
                            {t("social.aiFeature.writeWithAI")}
                          </Button>
                          <Button type="primary" onClick={onOpenCreateModal}>
                            <SVGIcons.PlusIcon />
                            {t("social.content.createContent")}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="create-content no-result">
                        <img src={NoResultImage} alt="" />
                        <div className="create-content-title">
                          {t("social.content.noContentTitle")}
                        </div>
                        <div className="create-content-description">
                          {t("social.content.noContentDescription")}
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="list-content">
                      <Row gutter={[16, 16]}>
                        {listContent.map((contentItem: ContentModel) => {
                          return (
                            <Col key={contentItem.id} lg={6} md={8} sm={12}>
                              <Popover
                                placement="rightTop"
                                title={null}
                                content={
                                  <div className="content-card-popover-schedule__content">
                                    <p>
                                      {t(
                                        "social.content.scheduleNowDescription"
                                      )}
                                    </p>
                                    <AppDateTimePicker
                                      onChange={(values) => {
                                        setDateTimePickerDefaultValue(
                                          DateUtils.convertTimeToTimezone(
                                            client?.timeZoneInfo?.id,
                                            values
                                          )
                                        );
                                        setScheduleTime(moment(values));
                                      }}
                                      defaulValue={dateTimePickerDefaultValue}
                                    />
                                    <div className="group-btn">
                                      <Button
                                        type="text"
                                        onClick={() =>
                                          setOpenScheduleModal(false)
                                        }
                                      >
                                        {t("common.cancel")}
                                      </Button>
                                      <Button
                                        type="primary"
                                        onClick={() => scheduleNow(contentItem)}
                                        loading={loadingSchedule}
                                      >
                                        {t("common.schedule")}
                                      </Button>
                                    </div>
                                  </div>
                                }
                                overlayClassName="content-card-popover-schedule"
                                open={
                                  openScheduleModal &&
                                  contentSelected?.id === contentItem.id
                                }
                              >
                                <ContentCard
                                  contentInfo={contentItem}
                                  onAction={(action) => {
                                    getDateTimePickerDefaultValue(
                                      contentItem.status,
                                      contentItem.date
                                    );
                                    onActionDropdown(action, contentItem);
                                  }}
                                />
                              </Popover>
                            </Col>
                          );
                        })}
                      </Row>

                      <Pagination
                        current={pageIndex}
                        total={totalRecords}
                        pageSize={pageSize}
                        className="content-pagination"
                        itemRender={itemRender}
                        onChange={onChangePagination}
                      />
                    </div>
                  )}
                </div>
              </Spin>
            </div>
          )}
        </div>
      </SpinDiv>

      <CreateContent
        open={openCreateModal}
        close={closeCreateModal}
        onCreateContent={handleCreateContent}
      />

      {contentSelected && (
        <EditContentModal
          open={openEditModal}
          close={() => {
            setOpenEditModal(false);
            resetContentValue();
            setTimeout(()=> {
              setContentSelected(undefined);
            },500) // set timeout to keep close modal effect
          }}
          contentInfo={contentSelected}
          onEditContent={handleEditContent}
          onEditApprovalContent={onEditApprovalContent}
        />
      )}

      {contentSelected && (
        <DeleteContentModal
          open={openDeleteModal}
          close={() => setOpenDeleteModal(false)}
          contentInfo={contentSelected}
        />
      )}

      <ShareLinkModal
        content={contentShareLink}
        close={() => {
          setContentShareLink(undefined);
        }}
        url={url}
      />
    </div>
  );
};

export default SocialContent;
