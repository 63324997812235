import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  GMB_ACTION_BUTTONS,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../../../../../constants/app-constants";
import {
  GmbActionType,
  GmbPostType,
} from "../../../../../../../../constants/app.enums";
import {
  contentValueState,
  currentContentSelector,
  SocialContentState,
} from "../../../../../../../../states/social";
import showNotification from "../../../../../../../common/notification";
import AppDatePicker from "../../../../../../../controls/app-date-picker";
import AppTimePicker from "../../../../../../../controls/app-time-picker";
import useUpdateContent from "../../../../hook/useUpdateContent";
import "./index.scss";

interface Props {
  isReviewing: boolean | undefined;
  currentContent?: SocialContentState;
}

const EventTab = (props: Props) => {
  const { onValuesPlatformChange } = useUpdateContent();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const gmbActionTypeForm = Form.useWatch("gmbActionType", form);
  const startTime = Form.useWatch("startTime", form);
  const endTime = Form.useWatch("endTime", form);

  const [open, setOpen] = useState(false);
  const currentContent = useRecoilValue(currentContentSelector);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);

  const [eventTitle, setEventTitle] = useState("");
  const [eventDetail, setEventDetail] = useState("");

  const { eventPostModalStatus } = contentValue;

  const {
    gmbPostType,
    gmbTitle,
    post,
    gmbStartDate,
    gmbEndDate,
    gmbActionType,
    gmbActionUrl,
  } = props.currentContent || currentContent || {};
  const disabledUrl =
    !gmbActionTypeForm ||
    [GmbActionType.None, GmbActionType.CallNow].includes(gmbActionTypeForm);

  const onOK = async () => {
    await form.validateFields(
      disabledUrl ? ["gmbTitle"] : ["gmbTitle", "gmbActionUrl"]
    );
    const values = form.getFieldsValue();

    onValuesPlatformChange({
      gmbActionType: values.gmbActionType,
      gmbActionUrl: values.gmbActionUrl,
      gmbTitle: values.gmbTitle,
      gmbStartDate: !values.startTime
        ? moment().format()
        : moment(values.startTime).format(),
      gmbEndDate: !values.endTime
        ? moment().add(7, "day").format()
        : moment(values.endTime).format(),
      post: values.eventDetail,
    });

    if (eventPostModalStatus === "edit") {
      showNotification("success", t("common.changeSaveSuccess"));
    }
    cancel();
  };
  const cancel = () => {
    form.resetFields();
    setEventTitle("");
    setEventDetail("");
    setOpen(false);
  };
  const onChangeStartTime = (value: any) => {
    form.setFields([{ name: "startTime", value: moment(value) }]);
  };

  const onChangeEndTime = (value: any) => {
    form.setFields([{ name: "endTime", value: moment(value) }]);
  };

  const onOpenModal = () => {
    setContentValue({ ...contentValue, eventPostModalStatus: "add" });
  };

  const renderPost = () => {
    return post?.split(/\n/).map((line: any, index) => {
      let str = line;
      return (
        <React.Fragment key={index}>
          <span dangerouslySetInnerHTML={{ __html: str }}></span>
          <br />
        </React.Fragment>
      );
    });
  };

  const renderCTAButton = () => {
    if (gmbActionType === GmbActionType.None) return "";
    const cta = GMB_ACTION_BUTTONS.find((e) => e.value === gmbActionType);
    if (cta) return <Button type="primary">{cta.name}</Button>;
    return "";
  };

  useEffect(() => {
    if (!open)
      setContentValue({ ...contentValue, eventPostModalStatus: undefined });
  }, [open]);
  useEffect(() => {
    if (eventPostModalStatus) setOpen(true);
    if (eventPostModalStatus === "edit") {
      form.setFields([
        { name: "gmbTitle", value: gmbTitle },
        { name: "eventDetail", value: post },
        { name: "startTime", value: moment(gmbStartDate) },
        { name: "endTime", value: moment(gmbEndDate) },
        { name: "gmbActionType", value: gmbActionType },
        { name: "gmbActionUrl", value: gmbActionUrl },
      ]);
      setEventTitle(gmbTitle || "");
      setEventDetail(post || "");
    }
    if (eventPostModalStatus === "add") {
      form.setFields([
        { name: "gmbActionType", value: GmbActionType.None },
        { name: "startTime", value: moment() },
        { name: "endTime", value: moment().add(7, "day") },
      ]);
    }
  }, [eventPostModalStatus]);
  return (
    <div className="event-tab">
      {!gmbTitle && (
        <div className="add-post" onClick={onOpenModal}>
          Click to add post
        </div>
      )}

      {gmbTitle && (
        <div className="event-content">
          <p className="title">{gmbTitle}</p>
          <p className="date">
            {moment(gmbStartDate).format("MMM DD, hh:mm a")} -{" "}
            {moment(gmbEndDate).format("MMM DD, hh:mm a")}
          </p>
          <p className="caption">{renderPost()}</p>

          {renderCTAButton()}
        </div>
      )}

      <Modal
        open={!!eventPostModalStatus && gmbPostType === GmbPostType.EVENT}
        title={
          eventPostModalStatus === "add"
            ? t("social.content.addEventPost")
            : t("social.content.editEventPost")
        }
        okText={
          eventPostModalStatus === "add"
            ? t("social.content.addPost")
            : t("common.saveChanges")
        }
        onOk={onOK}
        className="custom-create-modal add-event-post-modal"
        onCancel={cancel}
      >
        <Form
          name="addEventPost"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label={
              <div>
                <div>
                  <span>{t("social.content.eventTitle")}</span>
                  <span style={{ color: "red" }}> *</span>
                </div>
                <span>{eventTitle?.length || 0}/60</span>
              </div>
            }
            name="gmbTitle"
            rules={[
              { required: true, message: "Event Title field is required." },
            ]}
            className="event-title"
          >
            <div>
              <Input
                placeholder={"Example: Big Sale this week!"}
                value={eventTitle}
                onChange={(e) => {
                  if (e.target.value.length > 60) return;
                  setEventTitle(e.target.value);
                }}
              />
            </div>
          </Form.Item>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t("social.content.startDate")}
                rules={[{ required: true }]}
                name="startDate"
              >
                <AppDatePicker
                  onChange={onChangeStartTime}
                  defaulValue={moment(startTime)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={t("social.content.startTime")} name="startTime">
                <AppTimePicker
                  onChange={onChangeStartTime}
                  defaulValue={moment(startTime)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t("social.content.endDate")}
                rules={[{ required: true }]}
                name="endDate"
              >
                <AppDatePicker
                  onChange={onChangeEndTime}
                  defaulValue={moment(endTime)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={t("social.content.endTime")} name="endTime">
                <AppTimePicker
                  onChange={onChangeEndTime}
                  defaulValue={moment(endTime)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={
              <div>
                <div>
                  <span>{t("social.content.eventDetail")}</span>
                </div>
                <span>{eventDetail?.length || 0}/1500</span>
              </div>
            }
            name="eventDetail"
            className="event-title"
          >
            <div>
              <Input.TextArea
                placeholder={"Enter your Event Details here..."}
                value={eventDetail}
                onChange={(e) => {
                  if (e.target.value.length > 1500) return;
                  setEventDetail(e.target.value);
                }}
                maxLength={1500}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </div>
          </Form.Item>

          <Form.Item label={t("social.content.addButton")} name="gmbActionType">
            <Select defaultValue={GmbActionType.None}>
              {GMB_ACTION_BUTTONS.map((action) => {
                return (
                  <Select.Option key={action.value} value={action.value}>
                    {action.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              gmbActionTypeForm === GmbActionType.CallNow
                ? "Phone Number"
                : t("social.content.linkForButton")
            }
            name="gmbActionUrl"
            rules={
              disabledUrl
                ? []
                : [
                    {
                      required: true,
                      message: "Link For Your Button field is required.",
                    },
                    {
                      message: "Invalid URL format.",
                      type: "url",
                    },
                  ]
            }
            help={disabledUrl ? "" : undefined}
          >
            <Input
              disabled={disabledUrl}
              placeholder={
                gmbActionTypeForm === GmbActionType.CallNow ? "" : "Enter URL"
              }
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EventTab;
