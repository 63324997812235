import { Button, Input, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AuthApi } from "../../../apis/auth.api";
import FilterList from "../../../components/common/filter";
import showNotification from "../../../components/common/notification";
import SVGIcons from "../../../components/icons/svgs";
import CreateGroupForm from "../../../components/views/user-groups/create-form";
import EditGroupForm from "../../../components/views/user-groups/edit-form";
import { ANT_LOCALE } from "../../../constants/app-constants";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../constants/permission.constants";
import useFetchRoles from "../../../hooks/useFetchRoles";
import useGetAdminStats from "../../../hooks/useGetAdminStats";
import useHasPermission from "../../../hooks/useHasPermission";
import { FilterModel } from "../../../models/common.model";
import {
  CreateUserGroupModel,
  EditUserGroupModel,
} from "../../../models/sign-in.model";
import { UserGroupModel } from "../../../models/user-group.model";
import { adminUseGroupsSelector } from "../../../states/admin";
import { pageTitleState } from "../../../states/common";
import { CommonUtils } from "../../../utils/common";
import DateUtils from "../../../utils/date.utils";
import searchDebounce from "../../../utils/searchDebounce";
import "./index.scss";

function UserGroupsPage() {
  const { t } = useTranslation();
  const roles = useFetchRoles();
  const { hasPermission } = useHasPermission();
  const [groups, setGroups] = useRecoilState(adminUseGroupsSelector);
  const [groupsFiltered, setGroupsFiltered] = useState<UserGroupModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const setPageTile = useSetRecoilState(pageTitleState);
  const [editingGroup, setEditingGroup] = useState<UserGroupModel>();
  const [keyword, setKeyword] = useState<string>();
  const [filter, setFilter] = useState<number[]>([]);
  const { getAdminStats } = useGetAdminStats();

  const dataFilter = {
    role: roles.map((role) => {
      return { name: role.roleName, value: role.roleId };
    }),
  };

  useEffect(() => {
    setPageTile(t("userGroups.title"));
    getUserGroups();
  }, []);

  useEffect(() => {
    if (groups) {
      setGroupsFiltered(groups);
    }
  }, [groups]);

  useEffect(() => {
    if (groups) {
      filterData();
    }
  }, [keyword, filter]);

  const getUserGroups = async () => {
    setLoading(true);
    try {
      const { data } = await AuthApi.getUserGroups();
      setGroups(data);
    } catch { }
    setLoading(false);
  };

  async function createUserGroups(model: CreateUserGroupModel) {
    await AuthApi.createUserGroups(model);
    getUserGroups();
    getAdminStats();
  }

  function editGroup(g: UserGroupModel) {
    setEditingGroup({ ...g });
    setOpenEditModal(true);
  }

  const onEditUserGroup = async (values: EditUserGroupModel) => {
    await AuthApi.editUserGroups(values);
    getUserGroups();
  };

  function filterData() {
    let newGroups = [...groups];
    if (keyword) {
      newGroups = newGroups.filter(
        (x) => x.name && x.name.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (filter && filter.length) {
      newGroups = newGroups.filter((x) =>
        x.roles.some((r) => filter.includes(r.id))
      );
    }

    setGroupsFiltered(newGroups);
  }

  async function deleteGroup(g: UserGroupModel) {
    await AuthApi.deleteUserGroups(g.id);

    showNotification(
      "delete",
      t("userGroups.deleteUserGroupSuccess", { name: g.name })
    );

    getUserGroups();
    getAdminStats();
  }

  function confirmDelete(g: UserGroupModel) {
    Modal.confirm({
      title: t("userGroups.deleteTitle"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("userGroups.deleteDescription", { param1: g.name }),
          }}
        ></span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => deleteGroup(g),
    });
  }

  const columns: ColumnsType<UserGroupModel> = [
    {
      title: t("userGroups.userGroupId"),
      dataIndex: "id",
      sorter: (a, b) => CommonUtils.numberSorting(a.id, b.id),
      render: (text) => text,
    },
    {
      title: t("userGroups.userGroup"),
      dataIndex: "name",
      sorter: (a, b) => CommonUtils.stringSorting(a.name, b.name),
    },
    {
      title: t("userGroups.description"),
      dataIndex: "description",
      sorter: (a, b) => CommonUtils.stringSorting(a.description, b.description),
      render: (text) => text,
      width: 350,
    },
    {
      title: t("userGroups.role"),
      dataIndex: "roles",
      sorter: (a, b) => {
        const roleA = a.roles && a.roles.length ? a.roles[0].name : "";
        const roleB = b.roles && b.roles.length ? b.roles[0].name : "";
        return CommonUtils.stringSorting(roleA, roleB);
      },
      render: (text, record) =>
        record.roles && record.roles.length ? record.roles[0].name : "",
    },
    {
      title: t("userGroups.userCount"),
      dataIndex: "userCount",
      sorter: (a, b) => CommonUtils.numberSorting(a.userCount, b.userCount),
    },
    {
      title: t("userGroups.lastModified"),
      dataIndex: "updatedDate",
      sorter: (a, b) => CommonUtils.dateSorting(a.updatedBy, b.updatedBy),
      render: (text, record) => (
        <div className="table-cell">
          <p>
            {DateUtils.convertServerToDateViewFormat(
              text || record.createdDate
            )}
          </p>
          <span>
            {DateUtils.convertServerToTimeViewFormat(
              text || record.createdDate
            )}
          </span>
        </div>
      ),
    },
    {
      title: t("userGroups.dateCreated"),
      dataIndex: "createdDate",
      sorter: (a, b) => CommonUtils.dateSorting(a.createdDate, b.createdDate),
      render: (text) => (
        <div className="table-cell">
          <p>{DateUtils.convertServerToDateViewFormat(text)}</p>
          <span>{DateUtils.convertServerToTimeViewFormat(text)}</span>
        </div>
      ),
    },
    {
      title: "",
      align: "right",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <Space size={"small"}>
            {hasPermission({
              function: FunctionEnum.UserGroups,
              permission: PermissionEnum.Delete,
            }) &&
              record.canDelete && (
                <Button
                  className="delete-ghost-button table-row-action"
                  type="text"
                  onClick={() => confirmDelete(record)}
                >
                  {t("users.btnDelete")}
                </Button>
              )}

            {hasPermission({
              function: FunctionEnum.UserGroups,
              permission: PermissionEnum.Edit,
            }) && (
                <Button
                  className="color-blue table-row-action"
                  type="text"
                  onClick={() => editGroup(record)}
                >
                  {t("users.btnEdit")}
                </Button>
              )}

            {hasPermission({
              function: FunctionEnum.UserGroups,
              permission: PermissionEnum.View
            }) && !hasPermission({
              function: FunctionEnum.UserGroups,
              permission: PermissionEnum.Edit
            }) && (
                <Button
                  className="color-blue table-row-action"
                  type="text"
                  onClick={() => editGroup(record)}
                >
                  {t("users.btnView")}
                </Button>
              )}
          </Space>
        </div>
      ),
    },
  ];

  function filterChange(values: FilterModel<number>) {
    setFilter(values.role || []);
  }

  function searchChange(value: string) {
    searchDebounce(value, () => setKeyword(value));
  }

  return (
    <div className="users-page">
      <div className="users-page__tableWrapper">
        <div className="users-page__tableHeader">
          <div className="table-action">
            <div className="table-action__left">
              <Input
                placeholder={t("common.searchPlahoder")}
                suffix={<SVGIcons.SearchIcon />}
                className="search-input"
                onChange={(e) => searchChange(e.target.value)}
              />
            </div>

            <div className="table-action__right">
              <FilterList data={dataFilter} onChange={filterChange} />

              {
                hasPermission({
                  function: FunctionEnum.UserGroups,
                  permission: PermissionEnum.Create
                }) &&
                <Button
                  type="primary"
                  icon={<SVGIcons.PlusIcon />}
                  onClick={() => setOpenCreateModal(true)}
                >
                  {t("userGroups.btnAddNew")}
                </Button>
              }
            </div>
          </div>
        </div>

        <div className="users-page__table">
          <Table
            className="main-table"
            columns={columns}
            dataSource={groupsFiltered}
            pagination={{
              hideOnSinglePage: true,
            }}
            rowKey="id"
            loading={loading}
            locale={ANT_LOCALE}
          />
        </div>
      </div>

      <CreateGroupForm
        open={openCreateModal}
        close={() => setOpenCreateModal(false)}
        onSubmit={(value) => createUserGroups(value)}
      />

      <EditGroupForm
        open={openEditModal}
        close={() => setOpenEditModal(false)}
        item={editingGroup}
        onEdit={onEditUserGroup}
      />
    </div>
  );
}

export default UserGroupsPage;
