import showNotification from "../components/common/notification";
import {
  SocialExtendReelType,
  SocialReelType,
  SocialType,
} from "../constants/app.enums";

const getExtension = (url: string): string => {
  return url?.split(".").pop() || "";
};

const isVideo = (url: string): boolean => {
  const extension = getExtension(url);
  return ["mp4", "mov", "avi", "webm"].includes(extension);
};

const isValidMedia = async (
  url: string,
  socialType: SocialExtendReelType
): Promise<boolean> => {
  const isVideoUrl = isVideo(url);
  const extension = getExtension(url);
  switch (socialType) {
    case SocialType.Facebook:
      if (isVideoUrl) {
        const { duration }: any = await getMetadataVideo(url);
        if (duration >= 4 * 60 * 60) {
          showNotification(
            "error",
            "Maximum video Facebook duration is 4 hours."
          );
          return false;
        }
        if (!["mp4", "mov", "avi"].includes(extension)) {
          showNotification(
            "error",
            "The supported file for video Facebook: MP4, MOV, and AVI"
          );
          return false;
        }
        return true;
      } else {
        if (!["jpg", "jpeg", "bmp", "png", "gif", "tiff"].includes(extension)) {
          showNotification(
            "error",
            "The supported file for image Facebook: JPG, JPEG, BMP, PNG, GIF and TIFF"
          );
          return false;
        }
        const metaImage: any = await getMetaImage(url);
        if (metaImage.height > 2048) {
          showNotification("error", "Maximum image Facebook height is 2048px");
          return false;
        }
        if (metaImage.width > 2048) {
          showNotification("error", "Maximum image Facebook width is 2048px");
          return false;
        }
        return true;
      }

    case SocialReelType.FacebookReel:
      if (isVideoUrl) {
        if (!["mp4", "mov", "avi"].includes(extension)) {
          showNotification(
            "error",
            "Supported video formats for Facebook Reels are MOV, MP4 and AVI."
          );
          return false;
        }
        const { duration, videoHeight, videoWidth }: any =
          await getMetadataVideo(url);
        if (duration > 90) {
          showNotification(
            "error",
            "Maximum duration for Facebook Reels is 90 seconds."
          );
          return false;
        }
        if (duration < 3) {
          showNotification(
            "error",
            "Minimum duration for Facebook Reels is 3 seconds."
          );
          return false;
        }
        if (videoWidth / videoHeight !== 9 / 16) {
          showNotification(
            "error",
            "Video aspect ratio for Facebook Reels is 9:16"
          );
          return false;
        }
        return true;
      }
      showNotification("error", "Images are not supported for Facebook Reels");
      return false;

    case SocialType.Instagram:
      if (isVideoUrl) {
        if (!["mp4", "mov"].includes(extension)) {
          showNotification(
            "error",
            "The supported file for video Instagram: MP4 and MOV"
          );
          return false;
        }
        const { duration, videoHeight, videoWidth }: any =
          await getMetadataVideo(url);
        if (duration > 15 * 60) {
          showNotification(
            "error",
            "Maximum video Instagram duration is 15 minutes."
          );
          return false;
        }
        if (duration < 3) {
          showNotification(
            "error",
            "Minimum video Instagram duration is 3 seconds."
          );
          return false;
        }
        return true;
      } else {
        if (!["jpg", "jpeg", "png", "gif"].includes(extension)) {
          showNotification(
            "error",
            "The supported file for image Instagram: JPG, JPEG, GIF, and PNG"
          );
          return false;
        }
        const metaImage: any = await getMetaImage(url);
        if (metaImage.height < 320) {
          showNotification("error", "Minimum image Instagram height is 320px");
          return false;
        }
        if (metaImage.width > 1440) {
          showNotification("error", "Maximum image Instagram width is 1440px");
          return false;
        }
        if (
          metaImage.width / metaImage.height < 4 / 5 ||
          metaImage.width / metaImage.height > 1.91 / 1
        ) {
          showNotification(
            "error",
            "Aspect ratio for image Instagram must be within a 4:5 to 1.91:1 range"
          );
          return false;
        }
        return true;
      }

    case SocialReelType.InstagramReel:
      if (isVideoUrl) {
        if (!["mp4", "mov"].includes(extension)) {
          showNotification(
            "error",
            "Supported video formats for Instagram Reels are MOV and MP4."
          );
          return false;
        }
        const { duration, videoHeight, videoWidth }: any =
          await getMetadataVideo(url);
        if (duration > 15 * 60) {
          showNotification(
            "error",
            "Maximum duration for Instagram Reels is 90 seconds."
          );
          return false;
        }
        if (duration < 3) {
          showNotification(
            "error",
            "Minimum duration for Instagram Reels is 3 seconds."
          );
          return false;
        }
        if (
          videoWidth / videoHeight < 0.01 / 1 ||
          videoWidth / videoHeight > 10 / 1
        ) {
          showNotification(
            "error",
            "Video aspect ratio for Instagram Reels is between 0.01:1 and 10:1 but 9:16 is recommended to avoid cropping or blank spaces"
          );
          return false;
        }
        return true;
      }
      showNotification("error", "Images are not supported for Instagram Reels");
      return false;

    case SocialType.Tiktok:
      if (isVideoUrl) {
        if (!["mp4", "mov", "webm"].includes(extension)) {
          showNotification(
            "error",
            "The supported file for video Tiktok: MP4, MOV and WebM"
          );
          return false;
        }
        const { duration }: any = await getMetadataVideo(url);
        if (duration > 60) {
          showNotification(
            "error",
            "Maximum video Tiktok duration is 60 seconds."
          );
          return false;
        }
        if (duration < 3) {
          showNotification(
            "error",
            "Minimum video Tiktok duration is 3 seconds."
          );
          return false;
        }
        return true;
      }
      showNotification("error", "Images are not supported for Tiktok");
      return false;

    case SocialType.Youtube:
      if (isVideoUrl) {
        if (!["mp4", "mov"].includes(extension)) {
          showNotification("error", "Youtube only supports MP4 and MOV");
          return false;
        }
        return true;
      }
      showNotification("error", "Images are not supported for Youtube");
      return false;

    case SocialReelType.YoutubeShort:
      if (isVideoUrl) {
        if (!["mp4", "mov"].includes(extension)) {
          showNotification("error", "Youtube Short only supports MP4 and MOV");
          return false;
        }
        return true;
      }
      showNotification("error", "Images are not supported for Youtube Short");
      return false;

    case SocialType.Google:
      if (!isVideoUrl) {
        if (!["jpg", "png"].includes(extension)) {
          showNotification(
            "error",
            "Google Business Profile only supports JPG and PNG"
          );
          return false;
        }
        return true;
      } else {
        if (!["mp4", "mov"].includes(extension)) {
          showNotification(
            "error",
            "Google Business Profile only supports MP4 and MOV"
          );
          return false;
        }
        const { duration }: any = await getMetadataVideo(url);
        if (duration > 30) {
          showNotification(
            "error",
            "Maximum video duration for Google Business Profile is 30 seconds"
          );
          return false;
        }
        return true;
      }
    default:
      return true;
  }
};
const getMetaImage = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });
const getMetadataVideo = (url: string) =>
  new Promise(async (resolve, reject) => {
    let video = document.createElement("video");
    video.setAttribute("src", url);
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      let duration = video.duration;
      let videoHeight = video.videoHeight;
      let videoWidth = video.videoWidth;
      resolve({ duration, videoHeight, videoWidth });
    };
    video.onerror = (err) => reject(err);
    video.src = url;
  });
const MediaUtils = {
  getExtension,
  isVideo,
  isValidMedia,
  getMetaImage,
};

export default MediaUtils;
