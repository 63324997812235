import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { SocialApi } from "../../../../../../apis/social.api";
import { AcvitityModel } from "../../../../../../models/content.model";
import { contentValueState } from "../../../../../../states/social";
import ActivityItem from "../../components/activity-item";
import "./index.scss";

const ActivityTab = () => {
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState<AcvitityModel[]>([]);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const { id: contentId } = contentValue;

  const initListActivities = async (contentId: number) => {
    setLoading(true);
    try {
      const { data } = await SocialApi.getListActivities(contentId);
      setActivities(data);
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    if (contentId) initListActivities(contentId);
  }, [contentId, contentValue.approvalStatus]);
  return (
    <Spin spinning={loading}>
      <div className="activity-tab">
        {activities.map((activity) => {
          return <ActivityItem activityInfo={activity} key={activity.id} />;
        })}
      </div>
    </Spin>
  );
};

export default ActivityTab;
