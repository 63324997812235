import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import {
  DATE_TIME_FORMAT,
  EMPTY_DATA_COLOR,
} from "../../../../../constants/app-constants";
import useMockupData from "../../../../../hooks/useMockupData";
import { callsFullDateSelector } from "../../../../../states/dashboard";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import "./index.scss";

function LineCallsChart() {
  const calls = useRecoilValue(callsFullDateSelector);
  const misedCalls = useMockupData();
  const answeredCalls = useMockupData();
  const isEmpty = !calls || !calls.length;
  const categories = isEmpty
    ? misedCalls.map((x) => x.label)
    : calls.map((x) => x.date);

  const series = [
    {
      name: "Missed Calls",
      data: isEmpty
        ? misedCalls.map((x) => x.value)
        : calls.map((x) => x.missed),
    },
    {
      name: "Answered Calls",
      data: isEmpty
        ? answeredCalls.map((x) => x.value)
        : calls.map((x) => x.answered),
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: "lineCallsChart",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "datetime",
      categories: categories,
      labels: {
        show: false,
        datetimeUTC: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return NUMBER_UTIL.convertNumericToFormattedString(val);
        },
      },
      marker: {
        show: true,
        fillColors: ["#0185de", "#9bc13c"]
      }
    },
    markers: {
      colors: ["#0185de", "#9bc13c"]
    },
    stroke: {
      width: [2, 1],
      curve: "smooth",
      colors: [
        isEmpty ? EMPTY_DATA_COLOR : "#0185de",
        isEmpty ? EMPTY_DATA_COLOR : "#9bc13c",
      ],
      dashArray: [0, 4],
    },
    fill: {
      colors: ["#ffffff"],
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  };

  return (
    <div className={`${isEmpty ? "chart-disabled " : ""}line-calls-chart`}>
      <Chart
        options={options}
        series={series}
        type="area"
        width={"100%"}
        height={170}
      />
    </div>
  );
}

export default memo(LineCallsChart);
