import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PASSWORD_POLYCIES,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { DefaultUserGroups } from "../../../../constants/permission.constants";
import useFetchUserGroups from "../../../../hooks/useFetchUserGroups";
import { CreateUserRequestModel } from "../../../../models/user";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";

interface CreateUserFormProps {
  open: boolean;
  close: () => void;
  onSubmit?: (model: CreateUserRequestModel) => Promise<void>;
}

function CreateUserForm(props: CreateUserFormProps) {
  const { open, close, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const groups = useFetchUserGroups();
  const [passwordPolicies, setPasswordPolicies] = useState(
    PASSWORD_POLYCIES.filter((x) => !x.disabled)
  );

  const passwordOnchange = (value: string) => {
    const newArray = [...passwordPolicies];
    newArray.forEach((item) => {
      var reg = new RegExp(item.regex);
      item.valid = reg.test(value);
    });

    setPasswordPolicies(newArray);
  };

  async function submit() {
    if (onSubmit) {
      setLoading(true);
      try {
        const values = form.getFieldsValue();
        const model: CreateUserRequestModel = {
          email: values.email,
          lastName: values.lastName,
          firstName: values.firstName,
          password: values.password,
          userGroupIds: [values.userGroup],
        };
        await onSubmit(model);

        showNotification(
          "success",
          t("users.addNewUserSuccessTitle", {
            name: `${values.firstName} ${values.lastName}`,
          }),
          t("users.addNewUserSuccessDescription")
        );

        cancel();
      } catch (error) {}
      setLoading(false);
    }
  }

  function okClick() {
    form.submit();
  }

  function cancel() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      open={open}
      title={t("users.createModalTitle")}
      okText={t("users.btnAddUser")}
      onOk={okClick}
      className="custom-create-modal add-user-modal"
      onCancel={cancel}
      confirmLoading={loading}
    >
      <Form
        name="createUserForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("users.firstName")}
              rules={[{ required: true }]}
              name="firstName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("users.lastName")}
              rules={[{ required: true }]}
              name="lastName"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("users.email")}
          name="email"
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input placeholder={t("signUp.businessEmailPlahoder")} />
        </Form.Item>

        <Form.Item
          label={t("signUp.password")}
          name="password"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (passwordPolicies.some((x) => !x.valid)) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }),
          ]}
          className="mr-bottom-0 padding-bottom-0 ant-form-item-new-pw"
        >
          <Input.Password
            placeholder={t("signUp.password")}
            onChange={(e) => passwordOnchange(e.target.value)}
            autoComplete="new-password"
          />
        </Form.Item>

        <Row className="password-hint-wrapper" style={{ marginBottom: 16 }}>
          {passwordPolicies.map((item) => (
            <Col
              span={12}
              key={item.key}
              className={`password-hint-item${item.valid ? " active" : ""}`}
            >
              <SVGIcons.KeyIcon />
              <span>{item.label}</span>
            </Col>
          ))}
        </Row>

        <Form.Item
          label={t("users.userGroup")}
          name="userGroup"
          rules={[{ required: true }]}
          style={{ marginBottom: 0 }}
        >
          <Select>
            {groups
              .filter(
                (g) =>
                  g.name !== DefaultUserGroups.Client &&
                  g.name !== DefaultUserGroups.SuperAdmin
              )
              .map((x) => (
                <Select.Option value={x.id} key={x.id}>
                  {x.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateUserForm;
