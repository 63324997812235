import { atom, selector } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";

const pageTitleState = atom<string>({
  key: APP_STATE_KEYS.PAGE_TITLE_STATE,
  default: "",
});

const navbarOpenState = atom<boolean>({
  key: APP_STATE_KEYS.NAVBAR_STATE,
  default: false,
});

const windowsWidthState = atom<number>({
  key: APP_STATE_KEYS.WINDOWS_WIDTH_STATE,
  default: window.innerWidth,
});


const isMobileSelector = selector<boolean>({
  key: "isMobileSelector",
  get: ({ get }) => {
    const width = get(windowsWidthState);
    return width <= 576;
  },
});

export { pageTitleState, navbarOpenState, windowsWidthState, isMobileSelector};
