import { Button, Form, Input, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { MediaLibraryApi } from "../../../../../apis/media-library.api";
import FileDetailEmpty from "../../../../../assets/images/file-details-empty.png";
import {
  DATE_TIME_FORMAT,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../../constants/app-constants";
import useClient from "../../../../../hooks/useClient";
import {
  MediaLibraryItems,
  renderLibraryName,
} from "../../../../../pages/media-library";
import DateUtils from "../../../../../utils/date.utils";
import SVGIcons from "../../../../icons/svgs";
import MediaSocial from "../../../social/social-content/components/media-social";
import "./index.scss";
import { MediaType } from "../media-library-header";
import showNotification from "../../../../common/notification";
import EditImageModal from "../edit-image-modal";
import { SocialApi } from "../../../../../apis/social.api";
import { useTranslation } from "react-i18next";
import { useForm } from "antd/es/form/Form";
import { useRecoilState } from "recoil";
import {
  FileSelectedState,
  MediaLibraryState,
} from "../../../../../states/media-library";
import { CommonUtils } from "../../../../../utils/common";
import MediaUtils from "../../../../../utils/media.utils";
import useClientPlan from "../../../../../hooks/useClientPlan";
import { PlanUsageType } from "../../../../../constants/app.enums";
import { useTriggerReloadUsageStorage } from "../../../../../hooks/useTriggerReloadUsageStorage";

interface Props {
  library?: MediaLibraryItems;
  libraryUrl?: string;
}
const FileDetail = (props: Props) => {
  const { library, libraryUrl } = props;
  const { t } = useTranslation();
  const { client } = useClient();
  const [form] = useForm();
  const [fileSize, setFileSize] = useState<string | null>();
  const [saveFileLoading, setSaveFileLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [cacheSavedFileIds, setCacheSavedFileIds] = useState<string[]>([]);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [openRenameImage, setOpenRenameImage] = useState(false);
  const { checkUserPlan, checkLimitedPlanLoading, checkPremiumPlan } = useClientPlan()
  const { triggerReloadStorage } = useTriggerReloadUsageStorage()

  const [fileSelected, setFileSelected] = useRecoilState(FileSelectedState);
  const [mediaLibraryState, setMediaLibraryState] =
    useRecoilState(MediaLibraryState);

  useEffect(() => {
    if (fileSelected && fileSelected.url) {
      setFileSize(null);
      if (fileSelected.fileSize > 0) {
        setFileSize(CommonUtils.formatFileSize(fileSelected.fileSize));
      } else {
        getImageSize(fileSelected.url);
      }
    } else {
      setFileSize(null);
    }
  }, [fileSelected]);

  async function getImageSize(url: string) {
    try {
      let response = await fetch(url, {
        method: "HEAD",
        headers: {
          "Cache-Control": "no-cache",
        },
      });

      if (!response.ok) {
        // If HEAD request fails, fall back to GET request
        response = await fetch(url, {
          method: "GET",
          headers: {
            "Cache-Control": "no-cache",
          },
        });
      }

      const fs = response.headers.get("content-length");
      setFileSize(CommonUtils.formatFileSize(Number(fs)));
    } catch (error) {
      console.error(error);
    }
  }

  async function saveFile() {
    setSaveFileLoading(true);
    try {
      if (fileSelected) {
        const payload: any = {
          clientId: client?.id,
          fileName: fileSelected?.name || "-",
        };

        let mediaType = MediaType.images;
        if (fileSelected.isVideo) {
          payload.videoId = fileSelected?.sourceId;
          mediaType = MediaType.videos;
        } else {
          payload.photoId = fileSelected?.sourceId;
        }

        await MediaLibraryApi.saveLibraryMedias(
          payload,
          library || "",
          mediaType
        );

        showNotification(
          "success",
          "File has been successfully saved to My Files!"
        );
        triggerReloadStorage();
        setCacheSavedFileIds((pre) => [
          ...pre,
          library + fileSelected.sourceId,
        ]);
      }
    } catch (error) { }
    setSaveFileLoading(false);
  }

  const renameFile = async () => {
    setRenameLoading(true);
    try {
      if (fileSelected) {
        const { data } = await SocialApi.renameMedia(
          fileSelected.id,
          form.getFieldValue("fileName")
        );
        showNotification("success", "File has been successfully renamed!");
        setOpenRenameImage(false);
        setFileSelected(data);
        setMediaLibraryState({
          ...mediaLibraryState,
          triggerReloadMediaList: mediaLibraryState.triggerReloadMediaList + 1,
        });
      }
    } catch (error) { }
    setRenameLoading(false);
  };

  const onRenameFile = () => {
    if (fileSelected) {
      form.setFieldValue("fileName", fileSelected.name);
      setOpenRenameImage(true);
    }
  };

  const duplicateFile = async () => {
    setDuplicateLoading(true);
    try {
      if (fileSelected) {
        await SocialApi.duplicateMedia(fileSelected.id);
        showNotification("success", "File has been successfully duplicated!");
        setMediaLibraryState({
          ...mediaLibraryState,
          triggerReloadMediaList: mediaLibraryState.triggerReloadMediaList + 1,
        });
      }
    } catch (error) { }
    setDuplicateLoading(false);
  };

  const saveImage = async (fileInfo?: any): Promise<boolean> => {
    if (!fileSelected?.id) return Promise.resolve(false);
    try {
      return fetch(fileInfo.imageBase64 || "")
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], fileInfo.fullName, {
            type: fileInfo.mimeType,
          });
          const { data } = await MediaLibraryApi.editMedia(fileSelected?.id, {
            file,
          });

          setFileSelected(data);

          setMediaLibraryState({
            ...mediaLibraryState,
            triggerReloadMediaList: mediaLibraryState.triggerReloadMediaList + 1,
          });
          showNotification("success", "Changes successfully saved!");
          setOpenEditImage(false);
          triggerReloadStorage()
          return true;
        });
    } catch (error) {
      return Promise.resolve(false);
    }
  };

  return (
    <div className="file-detail-container">
      <div className="title">File Details</div>
      <div className="image">
        <MediaSocial
          url={fileSelected?.urlSmall || fileSelected?.url || FileDetailEmpty}
        ></MediaSocial>
      </div>
      <div className="title file-name">
        <span>{fileSelected?.name || "-"}</span>
      </div>
      {library === MediaLibraryItems.myFile ? (
        <div className="file-action">
          <div
            onClick={onRenameFile}
            className={fileSelected ? "" : "disabled"}
          >
            <SVGIcons.PencilIcon />
            Rename
          </div>

          {
            (!fileSelected || (!fileSelected.isVideo &&
              !MediaUtils.isVideo(fileSelected.url))) &&
            <div
              onClick={() =>
                fileSelected?.isVideo ? null : setOpenEditImage(true)
              }
              className={fileSelected ? "" : "disabled"}
            >
              <SVGIcons.ShapeIcon />
              Edit Image
            </div>
          }


          <Spin spinning={duplicateLoading}>
            <div
              onClick={duplicateFile}
              className={fileSelected ? "" : "disabled"}
            >
              <SVGIcons.CopyIcon />
              Duplicate
            </div>
          </Spin>
        </div>
      ) : (
        fileSelected && (
          <div className="file-action">
            {fileSelected.isSaved ||
              cacheSavedFileIds.includes(library + fileSelected.sourceId) ? (
              <div className="file-saved">
                <SVGIcons.CheckedIcon />
                Saved
              </div>
            ) : (
              <Spin spinning={saveFileLoading}>
                <div
                  onClick={saveFile}>
                  <SVGIcons.DownloadIcon />
                  Save to My Files
                </div>
              </Spin>

              // <Button
              //   style={{
              //     padding: 0,
              //     boxShadow: "none",
              //     border: "none",
              //     height: "18px",
              //     marginBottom: 24,
              //   }}
              //   type="ghost"
              //   onClick={saveFile}
              //   loading={saveFileLoading}
              // >
              //   <SVGIcons.DownloadIcon />
              //   Save to My Files
              // </Button>
            )}
          </div>
        )
      )}
      <div className="title info">Information</div>
      <div className="info-item">
        <span className="info-item__label">File Size</span>
        <span className="info-item__value">{fileSize || " - "}</span>
      </div>
      <div className="info-item">
        <span className="info-item__label">Dimensions</span>
        <span className="info-item__value">
          {fileSelected
            ? fileSelected.width + " x " + fileSelected.height
            : "-"}
        </span>
      </div>
      <div className="info-item">
        <span className="info-item__label">Added by</span>
        <span className="info-item__value">
          {(library === MediaLibraryItems.myFile ? fileSelected?.userUploadName : fileSelected?.authorName) || "-"}
        </span>
      </div>
      <div className="info-item">
        <span className="info-item__label">
          {" "}
          {library === MediaLibraryItems.myFile ? "Added at" : "Uploaded on"}
        </span>
        <span className="info-item__value">
          {fileSelected?.createdDate
            ? DateUtils.convertUTCTimeToTimezone(
              client?.timeZoneInfo.id,
              fileSelected?.createdDate
            ).format(
              library === MediaLibraryItems.myFile
                ? DATE_TIME_FORMAT.dateTimeViewFormatPM
                : DATE_TIME_FORMAT.viewFormat
            )
            : "-"}
        </span>
      </div>
      {library === MediaLibraryItems.myFile && (
        <div className="info-item">
          <span className="info-item__label">Modified at</span>
          <span className="info-item__value">
            {fileSelected?.updatedDate
              ? DateUtils.convertUTCTimeToTimezone(
                client?.timeZoneInfo.id,
                fileSelected?.updatedDate
              ).format(DATE_TIME_FORMAT.dateTimeViewFormatPM)
              : "-"}
          </span>
        </div>
      )}

      {library && library !== MediaLibraryItems.myFile && (
        <div className="more-info">
          This image is provided by{" "}
          <a target="_blank" href={libraryUrl} rel="noreferrer">
            {renderLibraryName(library)}
          </a>
          .
        </div>
      )}

      <EditImageModal
        open={openEditImage}
        setOpen={setOpenEditImage}
        imageUrl={fileSelected?.url}
        onSaveImage={saveImage}
      />
      <Modal
        open={openRenameImage}
        title={t(`mediaLibrary.renameFile`)}
        className="custom-create-modal"
        onCancel={() => setOpenRenameImage(false)}
        okText={t("common.confirm")}
        cancelText={t("common.cancel")}
        onOk={renameFile}
        confirmLoading={renameLoading}
      >
        <Form
          name="rename-file"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          form={form}
        >
          <Form.Item
            label={"File name"}
            name="fileName"
            rules={[{ required: true }]}
          >
            <Input placeholder="File name" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FileDetail;
