import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SocialApi } from "../../../../../../../../../apis/social.api";
import useClient from "../../../../../../../../../hooks/useClient";
import {
  IdeaModel,
  SuggestIdeasAIModel,
} from "../../../../../../../../../models/ai.model";
import SVGIcons from "../../../../../../../../icons/svgs";

const { Panel } = Collapse;

enum IdeasCollapseItem {
  Suggested = " Suggested Ideas",
  Saved = " Saved Ideas",
}

interface IdeasCollapseProps {
  listSavedIdeas: IdeaModel[];
  ideaSelected: IdeaModel;
  onSelectIdea: (idea: IdeaModel) => void;
  onSelectSuggestIdea: (idea: SuggestIdeasAIModel) => void;
  deleteIdea: (ideaId: number) => void;
}
const IdeasCollapse = (props: IdeasCollapseProps) => {
  const { listSavedIdeas, ideaSelected, onSelectIdea, onSelectSuggestIdea, deleteIdea } =
    props;
  const { t } = useTranslation();
  const { client } = useClient();

  const [listSuggest, setListSuggest] = useState<SuggestIdeasAIModel[]>([]);

  const onChangeCollapse = () => {};

  const initListSuggest = async () => {
    if (client?.category !== undefined) {
      const { data } = await SocialApi.AI.getSuggestIdeas(client?.category);
      setListSuggest(data);
    }
  };

  useEffect(() => {
    initListSuggest();
  }, [client]);
  return (
    <Collapse
      onChange={onChangeCollapse}
      bordered={false}
      expandIconPosition={"end"}
      expandIcon={(panelProps) => {
        return panelProps.isActive ? (
          <SVGIcons.ChevronUp />
        ) : (
          <SVGIcons.ChevronDown />
        );
      }}
      defaultActiveKey={[IdeasCollapseItem.Suggested]}
    >
      <Panel
        header={
          <>
            <SVGIcons.BulbIcon />
            {t("social.aiFeature.suggestedIdeas")}
          </>
        }
        key={IdeasCollapseItem.Suggested}
      >
        {listSuggest.map((s) => {
          return (
            <div
              key={s.id}
              className={`idea-item ${
                s.id === ideaSelected.id ? "selected" : ""
              }`}
              onClick={() => onSelectSuggestIdea(s)}
            >
              {s.prompt}
            </div>
          );
        })}
      </Panel>
      <Panel
        header={
          <>
            <SVGIcons.BookmarksIcon />
            {`${t("social.aiFeature.savedIdeas")} (${
              listSavedIdeas.length
            }/10)`}
          </>
        }
        key={IdeasCollapseItem.Saved}
      >
        {listSavedIdeas.map((s) => {
          return (
            <div
              key={s.id}
              className={`idea-item ${
                s.id === ideaSelected.id ? "selected" : ""
              }`}
              onClick={() => onSelectIdea(s)}
            >
              <span>{s.prompt}</span>
              <span onClick={() => s.id && deleteIdea(s.id)}>
                <SVGIcons.TrashIcon />
              </span>
            </div>
          );
        })}
        {listSavedIdeas.length === 0 && (
          <div className="idea-item-empty">
            Saving your first idea by clicking the Save Your Idea button on the
            right.
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

export default IdeasCollapse;
