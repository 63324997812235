import { Col, Row } from "antd";

function ReviewsPage() {
  return (
    <div className="general-settings-page page-content">
      <div className="page-header">
        <Row>
          <Col span={24}>
            <h4 className="page-header__title">Reviews</h4>
          </Col>
        </Row>
      </div>
      <div className="page-body"></div>
    </div>
  );
}

export default ReviewsPage;
