import { Button } from "antd";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import HappyEmoj from "../../../../assets/icons/emoji-happy.svg";
import BlurBackground from "../../../../assets/images/blur_bg.png";
import { useTranslation } from "react-i18next";
import { SUPPORT_EMAIL } from "../../../../constants/app-constants";
import { Link } from "react-router-dom";
import { GeneralSettingItems } from "../../../../pages/general-settings";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import useClient from "../../../../hooks/useClient";

function SupportModal() {
  const { t } = useTranslation();
  const { getRouter } = useClient();

  const link = getRouter(ROUTE_PATHS.GeneralSettings);

  return (
    <div
      className="support-modal"
      style={{ backgroundImage: `url("${BlurBackground}")` }}
    >
      <div className="support-modal__body">
        <img src={HappyEmoj} />
        <p>{t("dashboard.welcomeTite")}</p>
        <small>{t("dashboard.welcomeDes")}</small>
        <div className="support-modal__body-buttons" >
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
          >
            <Button
              type="primary"
              className="secondary-btn"
              icon={<SVGIcons.MessageCircleIcon />}
            >
              {t("dashboard.welcomeBtn")}
            </Button>
          </a>

          <Link
              to={`${link}?tab=${GeneralSettingItems.advanced}`}
          >
            <Button
              type="primary"
              icon={<SVGIcons.AppsIcon />}
            >
              {t("clientSites.addConnection")}
            </Button>
          </Link>
        </div>
        
      </div>
      {/* <div className="support-modal__footer">{t("common.copyRight")}</div> */}
    </div>
  );
}

export default SupportModal;
