import { Button, Dropdown, Menu, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { useState } from "react";
import SVGIcons from "../../../icons/svgs";
import useHasPermission from "../../../../hooks/useHasPermission";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../../constants/permission.constants";
import useFetchUserGroups from "../../../../hooks/useFetchUserGroups";
import showNotification from "../../../common/notification";
import { UserGroupModel } from "../../../../models/user-group.model";
import { BulkActionType } from "../../../../constants/app.enums";

interface Props {
  typeBulkAction: string;
  bulkAction: BulkActionType | null;
  onSetBulkAction: any;
  amount: number;
  onDeleteAction: () => void;
  onMoveAction: (selectedGroup: UserGroupModel) => void;
}
function BulkAction(props: Props) {
  const {
    typeBulkAction,
    bulkAction,
    onSetBulkAction,
    amount,
    onDeleteAction,
    onMoveAction,
  } = props;
  const { t } = useTranslation();
  const groups = useFetchUserGroups();
  const { hasPermission } = useHasPermission();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>();

  const handleMenuClick = (menuItem: any) => {
    onSetBulkAction((menuItem.key));
  };

  const cancelAction = () => {
    onSetBulkAction(null);
  };

  const deleteAction = async () => {
    if (amount === 0) return;
    Modal.confirm({
      title: t(`${typeBulkAction}.deleteBulkActionTitle`, { amount: amount }),
      content: (
        <span>
          {t(`${typeBulkAction}.deleteBulkActionDescription`, {
            amount: amount,
          })}
        </span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => onDeleteAction(),
    });
  };

  const moveAction = async () => {
    const group = groups.find((g) => g.id === selectedGroup);
    if (!selectedGroup || !group) {
      showNotification("warning", "Please select group!");
      return;
    }
    onMoveAction(group);
    setIsOpenModal(false);
  };

  const onChangeGroup = (value: any) => {
    setSelectedGroup(value);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: t(`${typeBulkAction}.labelMoveAction`),
          key: BulkActionType.MOVE,
        },
        hasPermission({
          function: FunctionEnum.Everything,
          permission: PermissionEnum.Delete,
        })
          ? {
              label: t(`${typeBulkAction}.labelDeleteAction`),
              key: BulkActionType.DELETE,
              danger: true,
            }
          : null,
      ]}
    />
  );

  const renderActionBtn = () => {
    switch (bulkAction) {
      case BulkActionType.MOVE:
        return (
          <Button
            type="primary"
            className="secondary-btn mr-right-16"
            onClick={() => amount > 0 && setIsOpenModal(true)}
          >
            {t(`${typeBulkAction}.moveBulkActionBtn`, { amount: amount })}
          </Button>
        );
      case BulkActionType.DELETE:
        return (
          <Button
            type="primary"
            className="mr-right-16"
            danger
            onClick={deleteAction}
          >
            {t(`${typeBulkAction}.deleteBulkActionBtn`, { amount: amount })}
          </Button>
        );

      default:
        break;
    }
  };

  return (
    <>
      {!bulkAction ? (
        <Dropdown overlay={menu}>
          <Button>
            <span className="mr-right-4">{t("clientSites.bulkActions")}</span>
            <SVGIcons.ChevronDown />
          </Button>
        </Dropdown>
      ) : (
        <>
          {renderActionBtn()}
          <Button onClick={cancelAction}>{t("common.cancel")}</Button>
        </>
      )}
      <Modal
        open={isOpenModal}
        title={t(`${typeBulkAction}.moveBulkActionTitle`)}
        okText={t("common.move")}
        onOk={moveAction}
        className="custom-move-action-modal custom-create-modal"
        onCancel={() => setIsOpenModal(false)}
      >
        <div className="modal-content">
          <div className="description">
            {t(`${typeBulkAction}.moveBulkActionDescription`, {
              amount: amount,
            })}
          </div>
          <div className="select-label">
            {t(`${typeBulkAction}.listGroupName`)}
          </div>
          <Select
            onChange={onChangeGroup}
            suffixIcon={<SVGIcons.ChevronDown />}
          >
            {groups.map((x) => (
              <Select.Option value={x.id} key={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
}

export default BulkAction;
