import { Form, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { GaApi } from "../../../../../apis/ga.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import { IntegrationStatus } from "../../../../../constants/app.enums";
import useGetClients from "../../../../../hooks/useGetClients";
import {
  connectionState,
  integrationState,
} from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import IntegrationAlert from "../alert";
import GroupBtnIntegration from "../group-btn-integration";

interface Props {
  isAddConnection?: boolean;
}

function UniversalAnalytics(props: Props) {
  const { isAddConnection } = props;
  const [form] = Form.useForm();
  const isGA4 = Form.useWatch("isGA4", form);
  const { t } = useTranslation();
  const { refreshClientList } = useGetClients();
  const [status, setStatus] = useState(IntegrationStatus.None);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [disableTestBtn, setDisableTestBtn] = useState(false);
  const [{ currentConnection }, setConnection] =
    useRecoilState(connectionState);

  const [integrationValues, setIntegrationValues] =
    useRecoilState(integrationState);

  useEffect(() => {
    integrationValues
      ? form.setFieldsValue(integrationValues?.gaIntegrations[0])
      : form.resetFields();
    setStatus(IntegrationStatus.None);
  }, [integrationValues, form]);

  async function testConnection() {}

  async function removeConnection() {
    setRemoveLoading(true);
    try {
      const integrationId = form.getFieldValue("id");
      if (!integrationId) throw new Error("");

      await GaApi.removeIntegration(integrationId);

      setIntegrationValues({
        ...integrationValues,
        gaIntegrations: [],
      });

      showNotification(
        "delete",
        t("clientSites.connectionRemoved", { name: "Google Analytics" })
      );

      refreshClientList();
    } catch {}
    setRemoveLoading(false);
  }

  async function submit(values: any) {}

  function onChangeGA4(value: boolean) {
    form.setFieldValue("isGA4", value);
    updateTestBtn(value);
  }

  function updateTestBtn(isGA4: boolean) {
    setDisableTestBtn(
      currentConnection
        ? (!isGA4 &&
            form.getFieldValue("viewName") &&
            form.getFieldValue("gaViewId")) ||
            (isGA4 &&
              form.getFieldValue("ga4PropertyName") &&
              form.getFieldValue("ga4PropertyId"))
        : true
    );
  }

  function onFormChanges() {
    updateTestBtn(isGA4);
  }

  useEffect(() => {
    updateTestBtn(isGA4);
  }, [form.getFieldsValue()]);

  useEffect(() => {
    if (status !== IntegrationStatus.None) {
      setTimeout(function () {
        setStatus(IntegrationStatus.None);
      }, 5000);
    }
  }, [status]);
  return (
    <div className="integration-form__section">
      <IntegrationAlert status={status} />

      <Form
        name="googleAnalyticsIntegration"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        className={`custom-connection-form ${
          !currentConnection ? "hiden-required-mark" : ""
        }`}
        onValuesChange={onFormChanges}
      >
        <Form.Item name="isGA4" valuePropName="checked">
          UA{" "}
          <Switch
            onChange={onChangeGA4}
            checked={form.getFieldValue("isGA4")}
            disabled={true}
          />{" "}
          GA4
        </Form.Item>

        {isGA4 ? (
          <Form.Item
            name="ga4PropertyName"
            label={t("clientSites.propertyName")}
            rules={[{ required: true }]}
          >
            <Input disabled={true} />
          </Form.Item>
        ) : (
          <Form.Item
            name="viewName"
            label={t("clientSites.viewName")}
            rules={[{ required: true }]}
          >
            <Input disabled={true} />
          </Form.Item>
        )}

        {isGA4 ? (
          <Form.Item
            name="ga4PropertyId"
            label={t("clientSites.propertyId")}
            rules={[{ required: true }]}
          >
            <Input disabled={true} />
          </Form.Item>
        ) : (
          <Form.Item
            name="gaViewId"
            label={t("clientSites.viewID")}
            rules={[{ required: true }]}
          >
            <Input disabled={true} />
          </Form.Item>
        )}

        <GroupBtnIntegration
          removeLoading={removeLoading}
          testLoading={testLoading}
          saveLoading={saveLoading}
          onTestConnection={testConnection}
          connectionName="Google Analytics"
          onRemove={removeConnection}
          disableTestBtn={false}
          disableSaveBtn={true}
          isAddConnection={isAddConnection}
        />
      </Form>
    </div>
  );
}

export default UniversalAnalytics;
