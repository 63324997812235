import { Form, Input, Modal } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DATE_TIME_FORMAT,
    VALIDATION_MESSAGE_CONFIG
} from "../../../../../constants/app-constants";
import useClient from "../../../../../hooks/useClient";
import { GenerateReportRequestModel, SendEmailReportsRequest } from "../../../../../models/report.model";
import "./index.scss";
import { ReportApi } from "../../../../../apis/report.api";
import showNotification from "../../../../common/notification";

interface SendEmailFormProps {
    open: boolean;
    close: () => void;
    currentReport?: GenerateReportRequestModel;
    initListReport: () => void
}

function SendEmailForm(props: SendEmailFormProps) {
    const { open, close, currentReport, initListReport } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onClose = () => {
        form.resetFields();
        close();
    };
    function okClick() {
        form.submit();
    }

    async function submit(values: SendEmailReportsRequest) {
        setLoading(true);
        try {
            const requestModel = {
                id: currentReport?.id,
                emails: values.emails
            };
            await ReportApi.sendEmail(requestModel)
            showNotification(
                "success",
                t("generalSettings.sendEmailReportSuccess", { title: currentReport?.title })
            );
            onClose();
            initListReport()

        } finally {
            setLoading(false);
        }
    }
    function cancel() {
        form.resetFields();
        close();
    }

    const validateEmailList = (_: any, value: string) => {
        let valid = true;
        const emails = value.split(',').map((email) => email.trim());
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const invalidEmail = [];

        for (let i = 0; i < emails.length; i++) {
            if (emails[i] === "" || !regex.test(emails[i])) {
                valid = false;
                invalidEmail.push(" " + emails[i]);
            }
        }
        if (!valid) {
            return Promise.reject(`${invalidEmail.toString()} : ${t("common.emailReportValidation")}`);
        }

        return Promise.resolve();
    };

    const handleChange = (value: string) => {
        const emails = value.split(',').map((email: string) => email.trim());
        console.log(emails);
        if (emails.length > 5) {
            form.setFieldValue("emails", emails.slice(0, 5).join(", "));
        }
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                open={open}
                title={t("generalSettings.emailReport")}
                okText={t("common.send")}
                onOk={okClick}
                className="custom-create-modal send-email-report-modal"
                onCancel={cancel}
                confirmLoading={loading}
                centered
            >
                <Form
                    name="generateReportForm"
                    layout="vertical"
                    validateMessages={VALIDATION_MESSAGE_CONFIG}
                    onFinish={submit}
                    form={form}
                    autoComplete="off"
                >
                    <div className="label top" >{t("generalSettings.emailReportTile")}</div>
                    <div className="title mb-16" >{currentReport?.title}</div>

                    <div className="label" >{t("generalSettings.emailDateRange")}</div>
                    <div className="title mb-16" >
                        {moment(currentReport?.startDate).format(DATE_TIME_FORMAT.viewFormat)} - {moment(currentReport?.endDate).format(DATE_TIME_FORMAT.viewFormat)}
                    </div>
                    <Form.Item
                        name="emails"
                        label={t("generalSettings.sendReportTo")}
                        rules={[
                            { required: true },
                            { validator: validateEmailList },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input.TextArea rows={2} onChange={(e) => handleChange(e.target.value)} />
                    </Form.Item>
                    <p className="helper-text">
                        {t("generalSettings.emailReportHelper")}
                    </p>

                </Form>
            </Modal>

        </>
    );
}

export default SendEmailForm;
