import { ApexOptions } from "apexcharts";
import { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { DATE_TIME_FORMAT, EMPTY_DATA_COLOR } from "../../../../../../constants/app-constants";
import useMockupData from "../../../../../../hooks/useMockupData";
import { ChartDataModel } from "../../../../../../models/chart.model";
import { NUMBER_UTIL } from "../../../../../../utils/number.utils";

interface Props {
  chartId: string;
  height: number;
  title: string;
  data?: ChartDataModel[];
}

function BarChartSocial(props: Props) {
  const { chartId, height, title } = props;
  const [data, setData] = useState<ChartDataModel[]>([]);
  const mockupData = useMockupData();
  const isEmpty = !props.data || !props.data.length;
  const color = isEmpty ? EMPTY_DATA_COLOR : "#0185de";

  useEffect(() => {
    if (props.data && props.data.length) {
      setData(props.data);
    } else {
      setData(mockupData);
    }
  }, [props.data]);

  useEffect(() => {
    if (!props.data || (!props.data.length && mockupData)) {
      setData(mockupData);
    }
  }, [mockupData]);

  const series: ApexOptions["series"] = [
    {
      name: title,
      data: data.map((x) => x.value),
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter(val, opts?) {
          return NUMBER_UTIL.convertNumericToFormattedString(val);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map((x) => x.label),
      type: "datetime",
      labels: {
        format: DATE_TIME_FORMAT.chartDateFormat,
        datetimeUTC: false,
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    colors: [color],
    fill: {
      colors: [color],
      opacity: 1,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  };

  return (
    <div
      className={`${isEmpty ? "chart-disabled " : ""}`}
      style={{ height: height, marginBottom: -15 }}
    >
      <Chart
        options={options}
        series={series}
        type="bar"
        width={"100%"}
        height={height}
      />
    </div>
  );
}

export default memo(BarChartSocial);
