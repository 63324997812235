import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import HeaderImage from "../../../assets/images/header-bg-admin.png";
import MainHeader from "../main-header";
import PageTitle from "../page-title";
import AdminSummarySection from "../summary-section";
import "./index.scss";

function AdminLayout() {

  const {t} = useTranslation();

  return (
    <div
      className="admin-layout">
      <div className={`header-wrapper header-overlay`}
        style={{
          backgroundImage: `url(${HeaderImage})`,
        }} >
        <div className="page-container-width">
          <MainHeader />
        </div>
        <div className="line"></div>
      </div>
      <div className="page-container-width app-body">
        <div className="page-content">
          <div className="page-header">
            <PageTitle />
          </div>
          <AdminSummarySection />
          <Outlet />
        </div>
      </div>

      <div className="app-footer">
        <small className="copy-right">{t("common.copyRight")}</small>
      </div>
    </div>
  );
}

export default AdminLayout;
