import { useRecoilValue } from "recoil";
import { pageTitleState } from "../../../states/common";

function PageTitle() {
  const pageTitle = useRecoilValue(pageTitleState);

  return <h4 className="page-header__title admin-page-title">{pageTitle}</h4>;
}

export default PageTitle;
