import { Button, Form, Modal, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { AuthApi } from "../../apis/auth.api";
import AppAvatar from "../../components/common/app-avatar/app-avatar";
import showNotification from "../../components/common/notification";
import SVGIcons from "../../components/icons/svgs";
import { PlanUsageType } from "../../constants/app.enums";
import { ROUTE_PATHS } from "../../constants/router.constants";
import useAdminSite from "../../hooks/useAdminSite";
import useClientPlan from "../../hooks/useClientPlan";
import { CreateCompanyRequestModel } from "../../models/client.model";
import { ClientModel } from "../../models/user";
import { authUserSelector } from "../../states/auth";
import LocalUtils from "../../utils/local.utils";
import CreateCompanyForm from "./create-company";
import "./index.scss";

function SelectCompanyPage() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isAdmin = useAdminSite();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useRecoilState(authUserSelector);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const clients = user?.clients;
  const { checkUserPlan, checkLimitedPlanLoading, checkPremiumPlan } = useClientPlan()
  const isExternalUser = !clients?.some((c) => c.email === user?.email);

  const selectCompanyTeam = async (client: ClientModel) => {
    if (!client.id) return;
    try {
      const newClient =
        clients?.map((c) => {
          if (c.id === client.id)
            return { ...c, lastLoggedIn: moment().format() };
          return c;
        }) || [];
      user && setUser({ ...user, clientSelected: client, clients: newClient });
      navigate(state?.redirectUrl || ROUTE_PATHS.Home);
    } catch (error: any) { }
    AuthApi.logAccessClient(client.id, isAdmin);
  };

  const createCompany = async (value: CreateCompanyRequestModel) => {
    try {
      await AuthApi.createNewSite(value);
      setOpenCreateModal(false);
      showNotification(
        "success",
        t("clientSites.addNewCompanySuccess", { name: value.companyName }));

      await getUserProfile();
      return true;
    } catch (error: any) {
      if (error?.response?.data === t("signUp.companyNameExist")) {
        form.setFieldValue("existName", true);
      }
      return false;
    }
  };

  const getUserProfile = async () => {
    const { data: userProfile } = await AuthApi.getUserProfile();
    LocalUtils.setUserInfo(userProfile);
    setUser(userProfile);
  };

  const modalDeleteCompany = (companyInfo: ClientModel) => {
    Modal.confirm({
      title: t("signUp.deleteCompanyTitle", { name: companyInfo.companyName }),
      content: (
        <div className="alert-desc">
          <span>
            {t("signUp.actionCannotBeUndone")}
          </span>
          <ul>
            <li>
              {t("signUp.deleteAllThePosts")}
            </li>

            <li>
              {t("signUp.deleteAllTheConnections")}
            </li>

            <li>
              {t("signUp.deleteAllTheGeneratedReports")}
            </li>

            <li>
              {t("signUp.deleteAllTheUploadedMediaFiles")}
            </li>
            <li>
              {t("signUp.removeAccessForAllMembers")}
            </li>

          </ul>
          <span>
            {t("signUp.areYouSureToContinue")}
          </span>
        </div>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal confirm-delete-company-modal",
      centered: true,
      onOk: async () => {
        await AuthApi.selfDeleteClient(companyInfo.id);
        await getUserProfile();
        showNotification(
          "delete",
          t("signUp.deleteCompanySuccess", { name: companyInfo.companyName })
        );
      },
    });
  };

  const onClickRemove = (e: any, companyInfo: ClientModel) => {
    e.stopPropagation();
    modalDeleteCompany(companyInfo);
  };

  useEffect(() => {
    const asyncFunc = () => {
      const isAddCompany = searchParams.get("isAddCompany");
      if (isAddCompany === "true") {
        checkPremiumPlan(async () => {
          await checkUserPlan(() => {
            setOpenCreateModal(true);
            searchParams.delete("isAddCompany");
            setSearchParams(searchParams);
          }, PlanUsageType.Company);
        }, PlanUsageType.Company)
      }
    }
    asyncFunc()
  }, []);
  return (
    <>
      <div className="select-company-page">
        <div className="header">{t("signUp.selectCompanyTitle")}</div>
        <div className="list-client">
          {clients?.map((client) => {
            return (
              <div
                className="client-item"
                onClick={() => selectCompanyTeam(client)}
                key={client.id}
              >
                <div className="avatar">
                  <AppAvatar
                    src={client.profilePictureUrl}
                    shape="square"
                    size={60}
                  />
                </div>
                <div className="info">
                  <div className="name">{client.companyName}</div>
                  <div className="email">{client.email}</div>
                  {client.lastLoggedIn && (
                    <div className="last-login">
                      Last logged in {moment.utc(client.lastLoggedIn).fromNow()}
                    </div>
                  )}
                </div>
                {client.email === user?.email && (clients.length > 1) && (
                  <Tooltip title="Delete the company">
                    <div
                      className="remove"
                      onClick={(e) => onClickRemove(e, client)}
                    >
                      <SVGIcons.TrashIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
            );
          })}
          {!isExternalUser && (
            <Button
              loading={checkLimitedPlanLoading}
              className="client-item add-new-company"
              onClick={() => {
                checkPremiumPlan(async () => {
                  await checkUserPlan(() => {
                    setOpenCreateModal(true)
                  }, PlanUsageType.Company);
                }, PlanUsageType.Company)
              }}
            >
              <SVGIcons.PlusIcon /> {t("signUp.addNewCompany")}
            </Button>
          )}
        </div>
      </div>
      <Link
        className="auth__backLink"
        to={ROUTE_PATHS.SignIn}
        dangerouslySetInnerHTML={{ __html: t("forgotPassword.sendMeBack") }}
      ></Link>

      <CreateCompanyForm
        open={openCreateModal}
        close={() => setOpenCreateModal(false)}
        onSubmit={(value) => createCompany(value)}
        form={form}
      />
    </>
  );
}

export default SelectCompanyPage;
