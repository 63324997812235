import { Button, Dropdown, Modal, Space, Switch } from "antd";
import { useTranslation } from "react-i18next";
import "./index.scss";
import ContentEditor from "./content-editor";
import AvatarSocial from "../components/avatar-social";
import {
  SocialReelType,
  SocialType,
  SubmitType,
} from "../../../../../constants/app.enums";
import { useRecoilState, useRecoilValue } from "recoil";
import SVGIcons from "../../../../icons/svgs";
import AppDateTimePicker from "../../../../controls/app-date-time-picker";
import { useEffect, useState } from "react";
import {
  contentValueState,
  listPlatformContentSelector,
  listProfileSelector,
  SocialContentState,
} from "../../../../../states/social";
import useUpdateContent from "../hook/useUpdateContent";
import moment from "moment";
import useClient from "../../../../../hooks/useClient";
import DateUtils from "../../../../../utils/date.utils";
import momentTimezone from "moment-timezone";
import { SocialTypes } from "../../../../../constants/social.constants";
import SocialUtils from "../../../../../utils/social";
import ContentTab from "../components/content-tab";

interface CreateContentProps {
  open: boolean;
  close: () => void;
  scheduleTime?: moment.Moment;
  onCreateContent: () => Promise<void>;
}

const CreateContent = (props: CreateContentProps) => {
  const { open, close, scheduleTime, onCreateContent } = props;
  const { t } = useTranslation();
  const {
    onChangeMenu,
    onChangeDate,
    renderModeSubmit,
    onChangeSync,
    onChangeStatusAvatar,
  } = useUpdateContent();
  const { client } = useClient();
  const [dateTimePickerDefaultValue, setDateTimePickerDefaultValue] =
    useState(scheduleTime);

  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const { isSyncContent, status, loadingSubmit } = contentValue;

  const listProfile = useRecoilValue(listProfileSelector);
  const listPlatformContent = useRecoilValue(listPlatformContentSelector);

  const isProfileConnected = !!listProfile.length;

  useEffect(() => {
    if (open) {
      const listContentDefault = SocialTypes?.reduce(
        (result: SocialContentState[], socialType: SocialType) => {
          const profile = listProfile.find((p) => p.platform === socialType);

          // add reelContent if fb or insta
          let reelContent: SocialContentState[] = [];
          if (
            [
              SocialType.Facebook,
              SocialType.Instagram,
              SocialType.Youtube,
            ].includes(socialType)
          ) {
            reelContent = [
              {
                disabled: isProfileConnected && !profile ? true : false,
                profile,
                platform: socialType,
                isReels: true,
                platformExtendReel: socialType + 10,
                // socialType === SocialType.Facebook
                //   ? SocialReelType.FacebookReel
                //   : SocialReelType.InstagramReel,
                shareReelsFeed:
                  socialType === SocialType.Instagram ? true : undefined,
              },
            ];
          }
          return [
            ...result,
            {
              disabled: isProfileConnected && !profile ? true : false,
              profile,
              platform: socialType,
              platformExtendReel: socialType,
            },
            ...reelContent,
          ];
        },
        []
      );
      setContentValue({
        ...contentValue,
        platformContents: listContentDefault,
        currentSocial:
          listContentDefault.find((c) => !c.disabled)?.platformExtendReel ||
          listContentDefault[0].platformExtendReel,
        scheduleOn: scheduleTime
          ? momentTimezone(scheduleTime).format()
          : moment().format(),
        status: scheduleTime ? SubmitType.Schedule : SubmitType.Save,
        tags: [],
      });
    }
  }, [open, scheduleTime]);
  useEffect(() => {
    if (scheduleTime) {
      client?.timeZoneInfo?.id && setDateTimePickerDefaultValue(scheduleTime);
    } else if (client?.timeZoneInfo?.id) {
      setDateTimePickerDefaultValue(
        DateUtils.convertTimeToTimezone(client?.timeZoneInfo?.id)
      );
    } else {
      setDateTimePickerDefaultValue(scheduleTime);
    }
  }, [client?.timeZoneInfo?.id, scheduleTime]);

  return (
    <div className="create-content">
      <Modal
        open={open}
        title={t("social.content.createTitle")}
        className="custom-create-modal create-content-modal content-modal"
        onCancel={close}
        maskClosable={false}
        footer={null}
      >
        <div className="container">
          <div className="container-profiles">
            <div className="container-profiles__left">
              <Space>
                {listPlatformContent
                  ?.filter((content) => !content.isReels)
                  .map((content: SocialContentState, index) => {
                    return (
                      <AvatarSocial
                        avatar={content?.profile?.userImage || ""}
                        socialType={content?.platform}
                        onChangeStatus={() =>
                          content && onChangeStatusAvatar(content)
                        }
                        disabled={content?.disabled}
                        tooltip={
                          content?.profile?.name ||
                          `Your ${SocialUtils.getSocialName(
                            content?.platform
                          )} profile`
                        }
                        key={index}
                      />
                    );
                  })}
              </Space>
            </div>
            <div className="container-profiles__right">
              <div className="sync-content">
                <span>{t("social.content.syncContent")}</span>
                <Switch
                  checked={isSyncContent}
                  onChange={onChangeSync}
                ></Switch>
              </div>
            </div>
          </div>
          <div className="container-content">
            <div className="container-content__tab">
              <ContentTab />
            </div>
            <div className="container-content__editor">
              <ContentEditor
                canEditContent={true}
                canEditCurrentContent={true}
              />

              <div className="create-content-modal-footer">
                {status === SubmitType.Schedule ? (
                  <AppDateTimePicker
                    onChange={onChangeDate}
                    defaulValue={dateTimePickerDefaultValue}
                  />
                ) : (
                  <div></div>
                )}
                <div className="btn-group-save">
                  <Button
                    type="primary"
                    className={`btn-save${
                      !isProfileConnected ? " no-dropdown" : ""
                    }`}
                    onClick={onCreateContent}
                    loading={loadingSubmit}
                  >
                    {renderModeSubmit()}
                  </Button>
                  {isProfileConnected && (
                    <Dropdown
                      menu={{
                        items: [
                          { key: SubmitType.Save, label: "Save" },
                          {
                            key: SubmitType.Schedule,
                            label: "Schedule",
                          },
                          {
                            key: SubmitType.Publish,
                            label: "Publish Now",
                          },
                        ].filter((item) => item.key !== status),
                        onClick: (item) => onChangeMenu(item.key),
                      }}
                      trigger={["click"]}
                      disabled={loadingSubmit}
                      placement="bottomRight"
                    >
                      <Button type="primary" className="btn-dropdown">
                        <SVGIcons.ChevronDown />
                      </Button>
                    </Dropdown>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateContent;
