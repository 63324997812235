import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios";
import qs from "qs";
import { getRecoil, setRecoil } from "recoil-nexus";
import showNotification from "../components/common/notification";
import {
  LOCAL_COOKIE_KEY,
  LOCAL_STORAGE_KEY,
  PLAN_CHECK_MESSAGES,
} from "../constants/app-constants";
import { LimitedTrialPlanType, PlanType } from "../constants/app.enums";
import { ROUTE_PATHS } from "../constants/router.constants";
import { RefreshTokenModel } from "../models/sign-in.model";
import { authState } from "../states/auth";
import { planState } from "../states/plan";
import i18n from "../utils/i18n";
import LocalUtils from "../utils/local.utils";
import { AuthApi } from "./auth.api";

const toggleLoading = (value: boolean) => { };

let IS_REFRESHING_TOKEN = false;

const getHeaders = (contentType: string) => {
  let headers: any = {
    "Content-Type": contentType
  };

  if (LocalUtils.getCookie(LOCAL_COOKIE_KEY.ID_TOKEN)) {
    headers = {
      ...headers,
      Authorization: `Bearer ${LocalUtils.getCookie(LOCAL_COOKIE_KEY.ID_TOKEN)}`
    }
  }

  if (LocalUtils.getGuestUserInfo().uuid) {
    headers = {
      ...headers,
      GuestUuid: LocalUtils.getGuestUserInfo().uuid
    }
  }

  return headers;
};

const axiosInstance = (
  contentType: string = "application/json",
  responseType: ResponseType = "json",
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): AxiosInstance => {
  if (isShowLoading) toggleLoading(true);

  const instance = axios.create({
    responseType: responseType,
  });

  instance.interceptors.request.use(async (config: any) => {
    if (allowAnonymous) {
      return config;
    }

    //can check ingore in here
    await checkRefreshTokenFinished(config);

    let idToken = LocalUtils.getCookie(LOCAL_COOKIE_KEY.ID_TOKEN);
    const refreshToken = LocalUtils.get(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
    const username = LocalUtils.getUserInfo().username;

    if (!idToken) {
      if (refreshToken && username && !IS_REFRESHING_TOKEN) {
        try {
          IS_REFRESHING_TOKEN = true;
          const refreshTokenRefresh: RefreshTokenModel = {
            refreshToken: refreshToken,
            username: username,
          };
          const { data } = await AuthApi.refreshToken(refreshTokenRefresh);
          const isRememberMe =
            LocalUtils.get(LOCAL_STORAGE_KEY.IS_REMEMBER_ME) == "true";
          LocalUtils.setAuthenticatedData(data, isRememberMe);
        } catch (error) {
          console.error(error);
          LocalUtils.remove(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
        }

        IS_REFRESHING_TOKEN = false;
      }
    }

    config.headers = getHeaders(contentType);
    return config;
  });



  instance.interceptors.response.use(
    (response) => {
      if (isShowLoading) toggleLoading(false);

      return response;
    },
    async (error) => {
      if (isShowLoading) toggleLoading(false);

      if (error.response.status === 401) {
        handleUnAuthorize();
      } else if (error.response.data === PLAN_CHECK_MESSAGES.CLIENT ||
        error.response.data === PLAN_CHECK_MESSAGES.USER) {
        showLimitedModal(error.config.url)
      } else if (error.response.data === PLAN_CHECK_MESSAGES.CANCELED) {
        const plan = getRecoil(planState);
        if (plan?.planType === PlanType.Trial) {
          setRecoil(planState, {
            ...plan,
            isShowUpgradeModal: true,
          });
        }
        else {
          showNotification("error",
            i18n.t("usageAndBilling.canceledErrorMsg", {
              url: ROUTE_PATHS.Stripe
            }))
        }
      } else {
        const data = error.response.data;
        if (isShowErrorMessage) {
          let message = i18n.t("common.serverError");

          if (data && data.message) {
            message = data.message;
          } else if (typeof data == "string" && data !== "") {
            message = data;
          }

          showNotification("error", message);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const getAsync = (
  url: string,
  params?: { [key: string]: any },
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false,
  arrayFormat?: "repeat" | "comma"
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: arrayFormat || "comma" });
    },
  });
};

export const getFileAsync = (
  url: string,
  params?: { [key: string]: any },
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "blob",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const postAsync = (
  url: string,
  json?: object,
  isShowLoading = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).post(url, json);
};

export const putAsync = (
  url: string,
  json?: object,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, json);
};

export const deleteAsync = (
  url: string,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    (allowAnonymous = false)
  ).delete(url);
};

export const postFormDataAsync = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).post(url, parseFormdata(json), config);
};

export const putFormDataAsync = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, parseFormdata(json));
};

export const downloadAsync = (
  url: string,
  params?: object
): Promise<AxiosResponse> => {
  return axiosInstance("application/json", "blob", true).get(url, { params });
};

const parseFormdata = (model: any) => {
  const formdata = new FormData();
  Object.keys(model || {}).forEach((p) => {
    if (model[p]) {
      if (Array.isArray(model[p])) {
        (model[p] as Array<any>).forEach((q) => {
          formdata.append(p + "[]", q);
        });
      } else {
        formdata.append(p, model[p]);
      }
    }
  });

  return formdata;
};

function handleUnAuthorize() {
  const auth = getRecoil(authState);
  setRecoil(authState, { ...auth, isLogined: false });
}

function getLimitedLabelByUrl(url: string) {
  if (url.includes('report')) return LimitedTrialPlanType.Reports;
  if (url.includes('client-new-site')) return LimitedTrialPlanType.Companies;
  if (url.includes('client-team-member')) return LimitedTrialPlanType.TeamMembers;
  if (url.includes('content')) return LimitedTrialPlanType.PostsPerCompany;
  if (url.includes('/ai')) return LimitedTrialPlanType.Ai;
  return '';
}

function showLimitedModal(url: string) {

  const plan = getRecoil(planState);
  if (url.includes("/media/handle-upload/")
    || url.includes("/library/")) {
    setRecoil(planState, {
      ...plan,
      isShowLimitedStorageModal: true,
    });
    return;
  }

  const label = getLimitedLabelByUrl(url)

  setRecoil(planState, {
    ...plan,
    isShowLimitedTrialPlanModal: true,
    limitedTrialPlanLabel: label
  });
}

function checkRefreshTokenFinished(config: any): Promise<boolean> {
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (!IS_REFRESHING_TOKEN) {
        clearInterval(timer);
        resolve(true);
      }
    }, 100);
  });
}
