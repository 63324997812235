import { useRecoilValue } from "recoil";
import {
  FunctionEnum,
  PermissionEnum,
} from "../constants/permission.constants";
import { authFunctionsSelector } from "../states/auth";

interface HasPermissionProps {
  function: FunctionEnum;
  permission: PermissionEnum;
}

const useHasPermission = () => {
  const functions = useRecoilValue(authFunctionsSelector);

  const hasPermission = (props: HasPermissionProps) => {
    return functions.some(
      (x) =>
        (x.functionCode === FunctionEnum.Everything ||
          x.functionCode === props.function) &&
        x.permissions &&
        (x.permissions.some(p => p === PermissionEnum.All || p === props.permission))
    );
  };

  return {
    hasPermission,
  };
};

export default useHasPermission;
