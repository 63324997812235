import { AxiosResponse } from "axios";
import { ReportCardModel } from "../models/report-card.model";
import { ZohoReportCard } from "../models/zoho.model";
import { getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/callrail/api/v1/ReportCard`;

function getReportCard(clientId: number): Promise<AxiosResponse<ZohoReportCard[]>> {
    const url = `${baseUrl}`;
    return getAsync(url, { clientId }, false, false);
}

function createReportCard(clientId: number, data: ReportCardModel): Promise<AxiosResponse<ZohoReportCard>> {
    const url = `${baseUrl}?clientId=${clientId}`;
    return postAsync(url, data);
}

function updateReportCard(cardId: number, data: ReportCardModel): Promise<AxiosResponse<ZohoReportCard>> {
    const url = `${baseUrl}?cardId=${cardId}`;
    return putAsync(url, data);
}

export const ReportCardApi = {
    getReportCard,
    createReportCard,
    updateReportCard
};
