import { useSetRecoilState } from "recoil";
import { AuthApi } from "../apis/auth.api";
import { socialPermissionState } from "../states/socialPermission";

function useSocialPermission() {

    const setSocialPermission = useSetRecoilState(socialPermissionState);

    function getSocialPermission(userId: number, clientId: number) {
        AuthApi.getSocialPermisson(userId, clientId).then(x => {
            if (x.data) {
                setSocialPermission(x.data);
            }
        });
    }

    return {
        getSocialPermission
    }
}

export default useSocialPermission;