import { Pagination, Spin, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MediaLibraryApi } from "../../../../apis/media-library.api";
import useClient from "../../../../hooks/useClient";
import AppAvatar from "../../../common/app-avatar/app-avatar";
import AppLightbox from "../../../common/light-box";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import { MediaLibraryItems, renderLibraryName } from "../../../../pages/media-library";
import { MediaType } from "../components/media-library-header";
import { UNSPLASH_UTM_SUFFIX_URL } from "../../../../constants/app-constants";
import { MediaModel } from "../../../../models/content.model";
import { useRecoilState, useSetRecoilState } from "recoil";
import { FileSelectedState, ListMediaSelectedState } from "../../../../states/media-library";
import { CommonUtils } from "../../../../utils/common";

interface Props {
  platform: MediaLibraryItems;
  mediaType: MediaType;
  searchMediaKeyword: string;
}

const MEDIA_ITEM_HEIGHT = 150;
const MEDIA_ITEM_PADDING = 10;
const MEDIA_ITEM_MIN_WIDTH = 100;
const MEDIA_LIST_WIDTH = 710;

const MediaLibraryPlatform = (props: Props) => {
  const { platform, mediaType, searchMediaKeyword } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [listMedia, setListMedia] = useState<MediaModel[]>([]);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0);
  const { clientId } = useClient();
  const pageSize = 30;
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const listWidth = useRef<any[]>([]);

  const [fileSelected, setFileSelected] = useRecoilState(FileSelectedState);
  const setListMediaSelected = useSetRecoilState(ListMediaSelectedState);
  const listMediaRef = useRef<HTMLDivElement>(null);

  const openLightBox = (index: number) => {
    setLightBoxOpen(true);
    setLightBoxStartIndex(index);
  };

  function onChangePagination(page: number) {
    getListMedias(page);
  }

  const getListMedias = async (page: number, keyword = "") => {
    setLoading(true);
    try {
      const { data } = await MediaLibraryApi.getLibraryMedias({
        clientId: clientId, page: page, perPage: pageSize, keyword: keyword
      }, platform, mediaType);
      resetWidthCalculation();
      for (let index = 0; index < data.length; index++) {
        updateListImageWidth(index, data[index]);
      }
      setListMedia(data);

      setPageIndex(page);
      if (data.length === pageSize) {
        setTotalRecords(pageSize * page + 1);
      } else {
        setTotalRecords(pageSize * (page - 1) + data.length);
      }
    } catch (error) {
      resetWidthCalculation();
      setListMedia([]);
    }
    setLoading(false);
  };

  function resetWidthCalculation() {
    currentSumWith.current = 0;
    listWidth.current = [];
  }

  const onSelected = (media: MediaModel) => {
    setFileSelected(media);
    setListMediaSelected([media]);
  };

  useEffect(() => {
    getListMedias(1, searchMediaKeyword);
  }, [platform, mediaType, searchMediaKeyword]);

  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return (
        <>
          <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
        </>
      );
    }
    if (type === "next") {
      return (
        <>
          {t("clientSites.next")} <SVGIcons.ChevronRight />
        </>
      );
    }
    return <></>;
  };

  const currentSumWith = useRef(0);
  function updateListImageWidth(index: number, mediaItem: MediaModel) {
    if (listWidth.current.length > index) {
      return;
    }

    let w = 0;
    if (mediaItem.width || mediaItem.height) {
      w = (MEDIA_ITEM_HEIGHT * mediaItem.width) / mediaItem.height;
    } else {
      w = MEDIA_ITEM_HEIGHT;
    }

    w = Math.round(w);
    if (w < MEDIA_ITEM_MIN_WIDTH) {
      w = MEDIA_ITEM_MIN_WIDTH;
    }

    const listMediaWidth = listMediaRef.current?.offsetWidth || MEDIA_LIST_WIDTH;

    if (currentSumWith.current >= listMediaWidth) {
      currentSumWith.current = w + MEDIA_ITEM_PADDING;
    } else if (currentSumWith.current + w + MEDIA_ITEM_MIN_WIDTH < listMediaWidth) {
      currentSumWith.current = currentSumWith.current + w + MEDIA_ITEM_PADDING;
    } else {
      w = listMediaWidth - currentSumWith.current;
      currentSumWith.current = 0;
    }

    listWidth.current.push(w);
  }

  return (
    <div className="media-library-container">
      <div className="title">{renderLibraryName(platform)}</div>
      <div className="description">
        <span>
          The best free stock photos, royalty free images & videos shared by
          creators.
        </span>
      </div>

      <Spin spinning={loading}>
        <div className="list-media" ref={listMediaRef}>
          {fileSelected && (
            <AppLightbox
              listMedia={listMedia.map((m) => m.url)}
              index={lightBoxStartIndex}
              open={lightBoxOpen}
              close={() => setLightBoxOpen(false)}
            />
          )}
          {listMedia.map((media, index) => {
            return (
              <Tooltip title={media.name} placement="top" key={media.sourceId}>
                <div
                  className={`media-item ${fileSelected?.sourceId === media.sourceId ? "selected" : ""
                    }`}
                  key={media.sourceId}
                  onClick={() => onSelected(media)}
                  style={{ width: listWidth.current[index] }}
                >
                  <img src={media.isVideo ? media.thumbUrl : media.urlSmall} alt={media.name} className="media-social-source" />
                  {media.isVideo && <span className="video-duration">{CommonUtils.formatDuration(media.duration ?? 0)}</span>}
                  <div
                    className="overlay"
                    onClick={(e) => {
                      e.detail === 2 && openLightBox(index);
                    }}
                  >
                    <div className="status">
                      <SVGIcons.CheckedNotRoundedIcon />
                    </div>
                    <div className="author"><AppAvatar size={32} src={media.authorAvatar} /> <a href={platform === MediaLibraryItems.unsplash ? media.authorUrl + UNSPLASH_UTM_SUFFIX_URL : media.authorUrl} target="_blank" rel="noreferrer" >{media.authorName}</a></div>
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </div>
        {((listMedia && !!listMedia.length) || pageIndex !== 1) &&
          <Pagination
            current={pageIndex}
            total={totalRecords}
            pageSize={pageSize}
            className="media-library-pagination"
            itemRender={itemRender}
            onChange={onChangePagination}
          />
        }
      </Spin>
    </div>
  );
};

export default MediaLibraryPlatform;
