import { Button } from "antd";
import "./index.scss";
import CheckedAnimation from "../../../../assets/animations/checked.gif";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

function CreateMemberSuccessPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="create-member-success auth__form">
      <div className="create-member-success__animation">
        <img src={CheckedAnimation} />
      </div>
      <div className="header">{t("updatePassword.successTitle")}</div>
      <p className="description">{t("createMember.successDescription")}</p>
      <Button type="primary" block onClick={() => navigate(ROUTE_PATHS.SignIn)}>
        {t("createMember.btnSubmit")}
      </Button>
    </div>
  );
}

export default CreateMemberSuccessPage;
