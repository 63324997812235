import { DashBoardRequestModel } from "../models/ga.model";
import {
  GfAddConnectionRequest,
  GfUpdateConnectionRequest,
} from "../models/gf.model";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/gravityform/api/v1`;

function testConnection(request: any) {
  const url = `${baseUrl}/integration/connect`;
  return postAsync(url, request, false, false);
}

function removeConnection(clientId: number) {
  const url = `${baseUrl}/integration/${clientId}`;
  return deleteAsync(url, false, false);
}

function getReport(request: DashBoardRequestModel) {
  const url = `${baseUrl}/form`;
  return getAsync(url, request, false, false);
}

function addConnection(request: GfAddConnectionRequest) {
  const url = `${baseUrl}/integration`;
  return postAsync(url, request, false, true);
}

function updateConnection(request: GfUpdateConnectionRequest) {
  const url = `${baseUrl}/integration`;
  return putAsync(url, request, false, true);
}

export const GravityFormApi = {
  testConnection,
  getReport,
  removeConnection,
  addConnection,
  updateConnection,
};
