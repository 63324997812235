import { atom } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { PlanType, UserPlanStatus } from "../constants/app.enums";

interface PlanState {
  isShowUpgradeModal: boolean,
  isPlanExpired: boolean,
  isShowLimitedTrialPlanModal: boolean,
  isShowLimitedStorageModal: boolean,
  limitedTrialPlanLabel: string
  planType: PlanType,
  planStatus: UserPlanStatus
}

export const planState = atom<PlanState>({
  key: APP_STATE_KEYS.CLIENT_PLAN,
  default: {
    isShowUpgradeModal: false,
    isPlanExpired: false,
    isShowLimitedTrialPlanModal: false,
    isShowLimitedStorageModal: false,
    limitedTrialPlanLabel: '',
    planType: PlanType.Free,
    planStatus: UserPlanStatus.New
  },
});