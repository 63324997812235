import moment from "moment";
import { PostTypeDashboardEnum, SocialType } from "../constants/app.enums";
import { SocialDateReportRowModel } from "../models/social.model";
import { DateRange } from "../states/dashboard";

const getSocialName = (type: SocialType) => {
  switch (type) {
    case SocialType.Facebook:
      return "Facebook";
    case SocialType.Instagram:
      return "Instagram";
    case SocialType.Tiktok:
      return "Tiktok";
    case SocialType.Google:
      return "Google Business";
    case SocialType.Youtube:
      return "Youtube";
    default:
      return "";
  }
};

function hasFlag(value: number, flag: PostTypeDashboardEnum): boolean {
  return (value & flag) === flag;
}

function createKeyPostType(postType: number): string {
  let stringKey: string = "";

  if (hasFlag(postType, PostTypeDashboardEnum.Status)) {
    stringKey += "Status post ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.Reels)) {
    stringKey += "Reels post ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.Image)) {
    stringKey += "Image post ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.Video)) {
    stringKey += "Video post ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.ShortCaption)) {
    stringKey += "with short caption ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.LongCaption)) {
    stringKey += "with long caption ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.Link)) {
    stringKey += "with link caption ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.Hashtag)) {
    stringKey += "with hashtag ";
  }

  if (hasFlag(postType, PostTypeDashboardEnum.Question)) {
    stringKey += "with question ";
  }

  return stringKey;
}

const createChartDataset = (
  dateRange: DateRange,
  chartData: SocialDateReportRowModel[]
) => {
  let dataChart = [];
  for (
    let dt = moment(dateRange.startDate);
    dt <= moment(dateRange.endDate);
    dt.add(1, "d")
  ) {
    const dtStr = dt.format("YYYY-MM-DDT00:00:00");
    const engagement = chartData.find(
      (e) => moment(e.date).format("YYYY-MM-DDT00:00:00") === dtStr
    );
    if (engagement) dataChart.push({ label: dtStr, value: engagement.value });
    else dataChart.push({ label: dtStr, value: 0 });
  }
  return dataChart;
};

function hashStringToColor(str: string) {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
  }
  let r = (hash & 0xff0000) >> 16;
  let g = (hash & 0x00ff00) >> 8;
  let b = hash & 0x0000ff;
  return (
    "#" +
    ("0" + r.toString(16)).substring(-2) +
    ("0" + g.toString(16)).substring(-2) +
    ("0" + b.toString(16)).substring(-2)
  ).slice(0, 7);
}

const SocialUtils = {
  getSocialName,
  createKeyPostType,
  createChartDataset,
  hashStringToColor,
};

export default SocialUtils;
