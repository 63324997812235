import { Popover } from "antd";
import Emoji from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import SVGIcons from "../../../../../icons/svgs";
import "./index.scss";

interface EmojiPickerProps {
  onEmojiChange: (emoji: any) => void;
}

const EmojiPicker = (props: EmojiPickerProps) => {
  const { onEmojiChange } = props;
  const [openEmoji, setOpenEmoji] = useState(false);
  const emojiRef = useRef<any>(null);

  const onEmojiClick = (value: any) => {
    onEmojiChange(value.emoji);
  };
  const content = (
    <div className="emoji-picker" ref={emojiRef}>
      <Emoji autoFocusSearch={false} onEmojiClick={onEmojiClick} />
    </div>
  );
  const handleClickOutside = (event: any) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setOpenEmoji(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="emoji-wrap">
      <Popover overlayClassName="emoji-popover" placement="bottom" content={content} trigger="click">
        <div className="emoji-icon" >
          <SVGIcons.MoodSmileIcon />
        </div>
      </Popover>
    </div>

  );
};

export default EmojiPicker;
