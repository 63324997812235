import { atom } from "recoil";
import { PAGE_SIZE_DEFAULT } from "../constants/app-constants";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { BEIntegrationModel } from "../models/be.model";
import {
  ClientListModel,
  ClientModel,
  ClientUserModel,
  GetClientParams,
} from "../models/client.model";
import { CRIntegrationModel } from "../models/cr.model";
import { GaConnectionModel, GAIntegrationModel, GaLocationConnectionModel, GaPropertyConnectionModel, GMBIntegrationModel } from "../models/ga.model";
import { GFIntegrationModel } from "../models/gf.model";

interface ClientSitesState {
  clientSelected: ClientListModel | undefined;
  overViewFormValues: ClientUserModel | undefined;
}

const clientSitesState = atom<ClientSitesState>({
  key: APP_STATE_KEYS.CLIENT_SITES_STATE,
  default: {
    clientSelected: undefined,
    overViewFormValues: undefined,
  },
});

interface ListClientState {
  data: {
    records: ClientListModel[];
    totalRecords: number;
  };
  loading: boolean;
  query: GetClientParams;
}

const listClientState = atom<ListClientState>({
  key: APP_STATE_KEYS.LIST_CLIENT_STATE,
  default: {
    data: {
      records: [],
      totalRecords: 0,
    },
    loading: false,
    query: {
      pageSize: PAGE_SIZE_DEFAULT,
      pageIndex: 1,
      keyword: "",
    },
  },
});

interface ConnectionState {
  currentConnection: string;
  listGAConnection: GaConnectionModel[];
  loading: boolean;
  properties: GaPropertyConnectionModel[];
  locations: GaLocationConnectionModel[];
}

const connectionState = atom<ConnectionState>({
  key: APP_STATE_KEYS.CONNECTION_STATE,
  default: {
    currentConnection: "",
    listGAConnection: [],
    loading: false,
    properties: [],
    locations: []
  },
});

interface IntegrationClientStateModel {
  beIntegrations: BEIntegrationModel[];
  crIntegrations: CRIntegrationModel[];
  gaIntegrations: GAIntegrationModel[];
  gfIntegrations: GFIntegrationModel[];
  gmbIntegrations: GMBIntegrationModel[];
  clientId: number;
}

const integrationState = atom<IntegrationClientStateModel>({
  key: "integration_state",
  default: {
    beIntegrations: [],
    crIntegrations: [],
    gaIntegrations: [],
    gfIntegrations: [],
    gmbIntegrations: [],
    clientId: 0,
  },
});

export { clientSitesState, connectionState, listClientState, integrationState };
