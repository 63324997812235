import { Image } from "antd";
import MediaUtils from "../../../../../../utils/media.utils";
import "./index.scss";

interface MediaSocialProps {
  url: string;
  openLightBox?: (countClick?: number) => void;
}
const MediaSocial = (props: MediaSocialProps) => {
  const { url, openLightBox } = props;
  const extensionFile = MediaUtils.getExtension(url);
  return (
    <>
      {!["mp4", "mov"].includes(extensionFile) ? (
        <div className="media-item__bg" style={{ backgroundImage: `url('${url}')` }}>
          <div className="media-item__bg__blur">
            <Image className="media-social-source" src={url} alt="" preview={false} onClick={(e)=>{openLightBox && openLightBox(e.detail)}} />
          </div>
        </div>
      ) : (
        <video height="260" controls key={url} preload="metadata" className="media-social-source">
          <source src={`${url}#t=0.1`} type="video/mp4" />
        </video>
      )}
    </>
  );
};
export default MediaSocial;
