import { Badge, Button, Dropdown, Modal, Pagination, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SocialApi } from "../../../apis/social.api";
import ApprovalIcon from "../../../assets/images/icon-approval.png";
import FilterList from "../../../components/common/filter";
import showNotification from "../../../components/common/notification";
import SVGIcons from "../../../components/icons/svgs";
import CreateApprovalModal from "../../../components/views/social/approvals/create-approval-modal";
import { APPROVAL_FILTER_DATA } from "../../../constants/app-constants";
import useClient from "../../../hooks/useClient";
import useSocial from "../../../hooks/useSocial";
import {
  ContenApprovalModel,
  GetContentApprovaslRequest,
} from "../../../models/approval.model";
import "./index.scss";
import DateUtils from "../../../utils/date.utils";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useService from "../../../hooks/useService";
import { FilterModel } from "../../../models/common.model";
import { BulkApprovalsStatus, ColorTag } from "../../../constants/app.enums";
import ShareLinkModal from "../../../components/views/social/share-link-modal";
import NoResultImage from "../../../assets/images/icon-no-result.png";
import useClientPlan from "../../../hooks/useClientPlan";
import moment from "moment";
import ApprovalActivityDrawer from "../../../components/views/social/approvals/approval-activity-drawer";

const SocialApprovals = () => {
  const { t } = useTranslation();
  const { clientId } = useClient();
  const { SocialService } = useService();
  useSocial();
  const [openCreateApprovalModal, setOpenCreateApprovalModal] = useState(false);
  const [openEditApprovalModal, setOpenEditApprovalModal] = useState(false);
  const [openActivityApprovalDrawer, setOpenActivityApprovalDrawer] =
    useState(false);
  const [currentApproval, setCurrentApproval] = useState<ContenApprovalModel>();
  const [contentShareLink, setContentShareLink] =
    useState<ContenApprovalModel>();
  const { checkEndedTrialPlan } = useClientPlan();
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [listContentApproval, setListContentApproval] = useState<
    ContenApprovalModel[]
  >([]);
  const [filter, setFilter] = useState<FilterModel<any>>();

  const url = `${window.location.origin}${ROUTE_PATHS.Social.ApprovalRequest}?approvalId=${contentShareLink?.id}`;

  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return (
        <>
          <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
        </>
      );
    }
    if (type === "next") {
      return (
        <>
          {t("clientSites.next")} <SVGIcons.ChevronRight />
        </>
      );
    }
    return originalElement;
  };

  const onChangePagination = async (page: number, pageSize: number) => {
    if (clientId) {
      await initListApproval({
        clientId,
        pageIndex: page,
        pageSize: pageSize,
      });
    }
  };

  const createApproval = async () => {
    checkEndedTrialPlan(() => {
      setOpenCreateApprovalModal(true);
    });
  };

  const initListTag = async () => {
    clientId && (await SocialService.getListTag(clientId));
  };

  const initListPostType = async () => {
    clientId && (await SocialService.getListPostType(clientId));
  };

  const initListApproval = async (request?: GetContentApprovaslRequest) => {
    if (!clientId) {
      showNotification("error", "Client not found");
      return;
    }
    setLoading(true);
    try {
      const { data } = await SocialApi.getContentApprovals({
        clientId,
        ...request,
      });
      setListContentApproval(data.records);
      setTotalRecords(data.totalRecords);
      setPageIndex(request?.pageIndex || 1);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const shareLink = (contentApproval: ContenApprovalModel) => {
    setContentShareLink(contentApproval);
  };

  const editApproval = (contentApproval: ContenApprovalModel) => {
    setCurrentApproval(contentApproval);
    setOpenEditApprovalModal(true);
  };

  const notifyApprover = async (contentApproval: ContenApprovalModel) => {
    setLoading(true);
    try {
      await SocialApi.notifyApprover(contentApproval.id);
      showNotification("success", "Approval request email has been sent!");
    } catch (error) {}
    setLoading(false);
  };

  const onClickViewApproval = (approvalId: number) => {
    window.open(
      `${ROUTE_PATHS.Social.ApprovalRequest}?approvalId=${approvalId}`
    );
  };

  const viewActivity = (contentApproval: ContenApprovalModel) => {
    setCurrentApproval(contentApproval);
    setOpenActivityApprovalDrawer(true);
  };

  const confirmDelete = (contentApproval: ContenApprovalModel) => {
    Modal.confirm({
      title: t("social.approval.deleteApprovalModalTitle"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("social.approval.deleteApprovalModalDescription", {
              title: contentApproval.title,
            }),
          }}
        ></span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: async () => {
        setLoading(true);
        try {
          await SocialApi.deleteContentApproval(contentApproval.id);
          showNotification(
            "delete",
            t("social.approval.deleteApprovalSuccess", {
              title: contentApproval.title,
            })
          );
          await initListApproval();
        } catch (error) {}
        setLoading(false);
      },
    });
  };

  const getMenuItems = (contentApproval: ContenApprovalModel) => {
    let menus = [
      {
        onClick: () => shareLink(contentApproval),
        label: <span>{t("social.approval.shareLink")}</span>,
        key: "0",
      },
      {
        label: <span>{t("social.approval.editApproval")}</span>,
        key: "1",
        onClick: () => editApproval(contentApproval),
      },
      {
        label: <span>{t("social.approval.viewActivity")}</span>,
        key: "5",
        onClick: () => viewActivity(contentApproval),
      },
      {
        label: <span>{t("social.approval.notifyApprover")}</span>,
        key: "4",
        onClick: () => notifyApprover(contentApproval),
      },
      {
        type: "divider",
        key: "2",
      },
      {
        label: t("social.approval.deleteApproval"),
        key: "3",
        danger: true,
        onClick: () => confirmDelete(contentApproval),
      },
    ];

    return menus;
  };
  const columns: ColumnsType<ContenApprovalModel> = [
    {
      title: t("social.approval.status"),
      dataIndex: "status",
      sorter: true,
      render: (status, _) => {
        let color, text;
        switch (status) {
          case BulkApprovalsStatus.Pending:
            color = ColorTag.Orange;
            text = t("social.approval.pending");
            break;
          case BulkApprovalsStatus.Approved:
            color = ColorTag.Green;
            text = t("social.approval.approved");
            break;

          case BulkApprovalsStatus.Declined:
            color = ColorTag.Sunset;
            text = t("social.approval.declined");
            break;
          case BulkApprovalsStatus.Expired:
            color = ColorTag.Nobel;
            text = t("social.approval.expired");
            break;
          default:
            break;
        }

        return (
          <span>
            <Badge color={color} text={text} />
          </span>
        );
      },
      width: 150,
    },
    {
      title: t("social.approval.title"),
      dataIndex: "title",
      sorter: true,
      render: (title, record) => (
        <div className="view-approval">
          <a onClick={() => onClickViewApproval(record.id)}>{title}</a>
        </div>
      ),
    },
    {
      title: t("social.approval.approved"),
      dataIndex: "contentCount",
      sorter: true,
      render: (contentCount, record) => (
        <span>{`${record.contentApprovedCount || 0}/${
          contentCount || 0
        }`}</span>
      ),
      width: 120,
    },
    {
      title: t("social.approval.approveAllOn"),
      dataIndex: "autoApprovedDay",
      sorter: true,
      render: (autoApprovedDay, record) => {
        if (!record.createdDate) return "-";
        const dateApprove = moment(record.createdDate)
          .add(autoApprovedDay, "d")
          .format("YYYY-MM-DDThh:mm:ss");
        return (
          <div className="table-cell">
            <p>{DateUtils.convertServerToDateViewFormat(dateApprove)}</p>
            <span>{DateUtils.convertServerToTimeViewFormat(dateApprove)}</span>
          </div>
        );
      },
      width: 120,
    },
    {
      title: t("social.approval.dueDate"),
      dataIndex: "dueDate",
      sorter: true,
      render: (dueDate) =>
        dueDate ? (
          <div className="table-cell">
            <p>{DateUtils.convertServerToDateViewFormat(dueDate)}</p>
            {/* <span>{DateUtils.convertServerToTimeViewFormat(dueDate)}</span> */}
          </div>
        ) : (
          "-"
        ),
      width: 120,
    },
    {
      title: t("social.approval.createdDate"),
      dataIndex: "createdDate",
      sorter: true,
      render: (createdDate) =>
        createdDate ? (
          <div className="table-cell">
            <p>{DateUtils.convertServerToDateViewFormat(createdDate)}</p>
            <span>{DateUtils.convertServerToTimeViewFormat(createdDate)}</span>
          </div>
        ) : (
          "-"
        ),
      width: 140,
    },
    {
      title: t("social.approval.lastModified"),
      dataIndex: "lastModifiedDate",
      sorter: true,
      render: (lastModifiedDate) =>
        lastModifiedDate ? (
          <div className="table-cell">
            <p>{DateUtils.convertServerToDateViewFormat(lastModifiedDate)}</p>
            <span>
              {DateUtils.convertServerToTimeViewFormat(lastModifiedDate)}
            </span>
          </div>
        ) : (
          "-"
        ),
      width: 145,
    },
    // {
    //   title: t("social.approval.comments"),
    //   dataIndex: "comment",
    //   sorter: true,
    // },
    {
      title: "",
      dataIndex: "action",
      width: 60,
      render: (text, record) => (
        <div className="table-approval-action">
          <Dropdown
            menu={{ items: getMenuItems(record) }}
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button className="dropdown-action-button">
              {t("clientSites.action")}
              <SVGIcons.ChevronDown />
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ];

  const filterChange = async (values: any) => {
    await initListApproval({
      clientId,
      pageIndex: 1,
      ...values,
      statuses: values?.approvalStatus,
      approvalStatus: undefined,
    });
  };

  const dataFilter = {
    ...APPROVAL_FILTER_DATA,
  };

  useEffect(() => {
    filterChange(filter);
  }, [filter]);

  useEffect(() => {
    initListPostType();
    initListTag();
  }, []);
  return (
    <div className="social-approvals">
      <div className="page-header">
        <h4 className="page-header__title">{t("social.approval.approvals")}</h4>
      </div>
      <Spin spinning={loading}>
        {listContentApproval.length === 0 && !filter ? (
          <div className="no-approval">
            <img src={ApprovalIcon} alt="" />
            <div className="title">Save your time with bulk approval</div>
            <div className="description">
              Start by clicking the buttons here, and get more virtual thumb-ups
              for your content!
            </div>
            <Button
              type="primary"
              className="ant-btn-primary secondary-btn"
              onClick={createApproval}
            >
              <SVGIcons.PlusIcon /> Create Approval
            </Button>
          </div>
        ) : (
          <div className="social-approvals-wrapper">
            <div className="social-approvals-wrapper__header">
              <FilterList
                data={dataFilter}
                onChange={(value) => setFilter(value)}
              />
              <Button
                type="primary"
                className="ant-btn-primary secondary-btn"
                onClick={createApproval}
              >
                <SVGIcons.PlusIcon /> Create Approval
              </Button>
            </div>
            <div className="social-approvals-wrapper__table">
              {listContentApproval.length !== 0 ? (
                <>
                  <Table
                    className="main-table"
                    columns={columns}
                    dataSource={listContentApproval}
                    pagination={false}
                    // loading={loading}
                    rowKey="id"
                    locale={{ emptyText: "No data to be displayed." }}
                  />

                  <Pagination
                    current={pageIndex}
                    total={totalRecords}
                    pageSize={10}
                    className="approval-pagination"
                    itemRender={itemRender}
                    onChange={onChangePagination}
                  />
                </>
              ) : (
                <div className="no-result">
                  <img src={NoResultImage} alt="" />
                  <div className="no-result-title">
                    {t("social.content.noContentTitle")}
                  </div>
                  <div className="no-result-description">
                    {t("social.content.noContentDescription")}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Spin>
      <CreateApprovalModal
        isEdit
        currentApproval={currentApproval}
        open={openEditApprovalModal}
        close={() => setOpenEditApprovalModal(false)}
        initListApproval={initListApproval}
      />
      <CreateApprovalModal
        open={openCreateApprovalModal}
        close={() => setOpenCreateApprovalModal(false)}
        initListApproval={initListApproval}
      />

      <ShareLinkModal
        content={contentShareLink}
        close={() => {
          setContentShareLink(undefined);
        }}
        url={url}
      />

      <ApprovalActivityDrawer
        open={openActivityApprovalDrawer}
        close={() => setOpenActivityApprovalDrawer(false)}
        approvalInfo={currentApproval}
      />
    </div>
  );
};

export default SocialApprovals;
