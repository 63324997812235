import { Input, Select } from 'antd';
import { useMemo, useState } from 'react';
import countryList from "react-select-country-list";
import { CountriesModel } from '../../../models/countries.model';
import SVGIcons from '../../icons/svgs';
import "./index.scss";

interface AppSelectCountryProps {
    onChange: (values: string) => void;
    value?: string
}

const AppSelectCountry = (props: AppSelectCountryProps) => {
    const { onChange, value } = props
    const [searchCountries, setSearchCountries] = useState<string>("");

    const countries = useMemo(() => countryList().getData(), []);

    function displayCountries(option: CountriesModel) {
        const { label } = option;
        const indexSearch = label
            .toLocaleLowerCase()
            .indexOf(searchCountries.toLocaleLowerCase());
        return (
            <div className="app-select-country__display">
                <div className="app-select-country__display-name">
                    {label.substring(0, indexSearch)}
                    <span className="app-select-country__display-highlight">
                        {label.substring(indexSearch, indexSearch + searchCountries.length)}
                    </span>
                    {label.substring(indexSearch + searchCountries.length)}
                </div>
            </div>
        );
    }

    const onSearchCountries = (e: any) => {
        setSearchCountries(e.target.value);
    };


    function sortCountries() {
        const sortedCountries = [];
        if(countries && countries?.length > 0) {
            for (const country of countries) {
                if (value) {
                    if (country.value === value) {
                        sortedCountries.unshift(country);
                    }
                    else {
                        sortedCountries.push(country);
                    }
                }
                else {
                    sortedCountries.push(country);
                }
            }
        }
        return sortedCountries;
       
    }

    const getSelectedValue = () => {
        if(value) {
            return countries?.find(x => x.value === value)?.value
        }
    }

    return (
        <div className='app-select-country'>
            <Select
                value={getSelectedValue()}
                className='app-select-country__select'
                onChange={(value) => onChange(value)}
                dropdownRender={(menu) => (
                    <>
                        <div className="app-select-country__search">
                            <Input
                                className='app-select-country__input'
                                value={searchCountries}
                                size="small"
                                suffix={
                                    <span className="app-select-country__icon">
                                        <SVGIcons.SearchIcon />
                                    </span>
                                }
                                onChange={onSearchCountries}
                                onBlur={() => setSearchCountries("")}
                            />
                        </div>
                        {menu}
                    </>
                )}
            >
                {sortCountries()
                    ?.filter((item) =>
                        item.label
                            .toLocaleLowerCase()
                            .includes(searchCountries.toLocaleLowerCase())
                    )
                    .map((option, index) => {
                        return (
                            <Select.Option
                                value={option.value}
                                key={index}
                            >
                                {displayCountries(option)}
                            </Select.Option>
                        );
                    })}
            </Select>
        </div>
    )
}

export default AppSelectCountry