import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { AppConfig } from "../configs/app.config";
import { v4 as uuidv4 } from "uuid";
import showNotification from "../components/common/notification";
import { SocialApi } from "../apis/social.api";
import axios from "axios";

class S3Service {
  private static _instance: S3Service;
  private client: S3Client;

  constructor() {
    this.client = new S3Client(AppConfig.awsS3Config);
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  handleUploadWithPresignUrl = async (fileInfo: any) => {
    try {
      const { data } = await SocialApi.getPresignedUrl(fileInfo.name);
      const { getUrl, uploadedUrl } = data;
      await axios.put(uploadedUrl, fileInfo, {
        headers: { "Content-Type": fileInfo.type },
      });
      return getUrl;
    } catch (error) {
      console.log(error);
    }
  };

  handleUpload = async (fileInfo: any) => {
    try {
      const fileName = `${uuidv4()}-${fileInfo.name}`;
      const params = {
        Bucket: "rocketlevel-users-resources",
        Key: `media/${fileName}`,
        Body: fileInfo,
      };

      const command = new PutObjectCommand(params);
      await this.client.send(command);

      return `https://rocketlevel-users-resources.s3.amazonaws.com/media/${fileName}`;
    } catch (error) {
      console.log(error);
      showNotification("error", "Uploading failed");
    }
  };
}

export const S3ServiceInstance = S3Service.Instance;
