import { Checkbox, Col, Form, FormInstance, Input, Modal, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import { AddTeamMemberRequest } from "../../../../../models/client-team-member.model";
import "./index.scss";

interface AddUserTeamModalProps {
  open: boolean;
  close: () => void;
  form: FormInstance<AddTeamMemberRequest>;
  addUser: () => Promise<void>;
}
const AddUserTeamModal = (props: AddUserTeamModalProps) => {
  const { open, close, form, addUser } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onOk = async () => {
    setLoading(true);
    await addUser();
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      title={t(`users.btnAddUser`)}
      className="custom-create-modal add-user-team-modal"
      onCancel={close}
      okText={t("users.btnAddUser")}
      cancelText={t("common.cancel")}
      onOk={onOk}
      confirmLoading={loading}
    >
      <Form
        name="addUserTeam"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        form={form}
        className="add-user-team-form"
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("users.userFirstName")}
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("users.userLastName")}
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t("users.userEmail")}
          name="email"
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label={t("users.defaultPermission")}
          className="form-item-permission"
        >
          <Form.Item
            valuePropName="checked"
            name="canManageContent"
            initialValue={true}
          >
            <Checkbox>
              <div className="check-box-content">
                <div className="label">{t("users.permissionManageLabel")}</div>
                <div className="description">
                  {t("users.permissionManageDescription")}
                </div>
              </div>
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="canApproveContent">
            <Checkbox>
              <div className="check-box-content">
                <div className="label">{t("users.permissionApproveLabel")}</div>
                <div className="description">
                  {t("users.permissionApproveDescription")}
                </div>
              </div>
            </Checkbox>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserTeamModal;
