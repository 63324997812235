import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { AuthApi } from "../apis/auth.api";
import { adminUseGroupsSelector } from "../states/admin";

let isFetchGroupsLoading = false;
function useFetchUserGroups() {
  const [groups, setGroups] = useRecoilState(adminUseGroupsSelector);

  useEffect(() => {
    if (!groups || !groups.length) {
      fetchData();
    }
  }, []);

  async function fetchData() {
    if (isFetchGroupsLoading) {
      return;
    }
    isFetchGroupsLoading = true;

    try {
      const { data } = await AuthApi.getUserGroups();
      setGroups(data);
    } catch {}

    isFetchGroupsLoading = false;
  }

  return groups;
}

export default useFetchUserGroups;
