import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import {
  DATE_TIME_FORMAT,
  EMPTY_DATA_COLOR,
} from "../../../../../../constants/app-constants";
import useMockupData from "../../../../../../hooks/useMockupData";
import { ChartDataModel } from "../../../../../../models/chart.model";
import { NUMBER_UTIL } from "../../../../../../utils/number.utils";

interface Props {
  chartId: string;
  height: number;
  totalFollowerDataChart: ChartDataModel[];
  newFollowerDataChart: ChartDataModel[];
}

function LineFollowerChart(props: Props) {
  const { chartId, height, totalFollowerDataChart, newFollowerDataChart } =
    props;

  const isEmpty = !totalFollowerDataChart || !totalFollowerDataChart.length;
  const mockupData1 = useMockupData();
  const mockupData2 = useMockupData();
  const color = isEmpty
    ? [EMPTY_DATA_COLOR, EMPTY_DATA_COLOR]
    : ["#0185de", "#9bc13c"];

  const series = [
    {
      name: "Total",
      data: (isEmpty ? mockupData1 : totalFollowerDataChart).map((x) => x.value),
    },
    {
      name: "New",
      data: (isEmpty ? mockupData2 : newFollowerDataChart).map((x) => x.value),
    },
  ];

  console.log(series);

  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      categories: (isEmpty ? mockupData1 : totalFollowerDataChart).map((x: any) => x.label),
      labels: {
        show: false,
        format: DATE_TIME_FORMAT.chartDateFormat,
        datetimeUTC: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return NUMBER_UTIL.convertNumericToFormattedString(val);
        },
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
      colors: color,
    },
    fill: {
      colors: color,
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
    legend: {
      show: false,
    },
  };


  return (
    <div style={{ height: height }}
      className={`${isEmpty ? "chart-disabled " : ""}`}>
      <Chart
        options={options}
        series={series}
        type="area"
        width={"100%"}
        height={height}
      />
    </div>
  );
}

export default memo(LineFollowerChart);
