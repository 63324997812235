import "antd/dist/antd.min.css";
import "yet-another-react-lightbox/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-phone-input-2/lib/style.css";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./scss/index.scss";
import "./utils/i18n";
import RecoilNexus from "recoil-nexus";
import { GoogleOAuthProvider } from "@react-oauth/google";
import 'react-trumbowyg/dist/trumbowyg.min.css'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_CLIENT_ID || ""}>
        <App />
      </GoogleOAuthProvider>
    </RecoilRoot>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
