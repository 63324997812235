import { Input } from "antd";
import { useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { SocialExtendReelType } from "../../../../../../../constants/app.enums";
import { ContentModel } from "../../../../../../../models/content.model";
import {
  contentValueState,
  currentContentSelector,
  listPlatformContentSelector,
  SocialContentState,
} from "../../../../../../../states/social";
import useUpdateContent from "../../../hook/useUpdateContent";

export interface ContentCaptionProps {
  setPositionText?: any;
  updatePlatformContent?: (
    typeUpdate: "title" | "post" | "medias" | "shareReelsFeed",
    value: any
  ) => void;
  isReviewing: boolean | undefined;
  contentValue?: {
    isSyncContent: boolean;
    currentSocial?: SocialExtendReelType;
    platformContents: SocialContentState[];
    contentAIGenerated?: string;
    currentContent?: SocialContentState;
  }
}
const ContentCaption = (props: ContentCaptionProps) => {
  const { setPositionText, updatePlatformContent } = props;
  const { getPlatformContent } = useUpdateContent();
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const {
    isSyncContent,
    currentSocial,
    contentAIGenerated,
    platformContents: listPlatformContent,
  } = props.contentValue || contentValue;

  const currentContent = listPlatformContent.find(
    (content) => content?.platformExtendReel === currentSocial
  );

  const captionEditorRef = useRef<any>();

  const onChangeCaption = (e: any) => {
    const newCaption = e.target.value;
    updatePlatformContent && updatePlatformContent("post", newCaption);
    setPositionText && setPositionText(e.target.selectionStart);
  };

  const onClickCaption = (e: any) => {
    setPositionText && setPositionText(e.target.selectionStart);
  };
  const getCaption = () => {
    if (isSyncContent) {
      return currentContent?.post;
    }

    if (props.contentValue) {
      return props.contentValue.currentContent?.post;
    }

    return currentContent?.platformExtendReel !== undefined
      ? getPlatformContent(currentContent?.platformExtendReel)?.post
      : "";
  };

  const caption = useMemo(() => {
    const text = getCaption();
    converterToMask(text || "");
    return text;
  }, [isSyncContent, currentContent, contentValue, currentSocial, props.contentValue]);
  function converterToMask(value: string) {
    let str = value;
    if (contentAIGenerated) {
      str = value.split(contentAIGenerated).join("");
    }
    str = str.replace(/(<)/gi, "&lt;");
    str = str.replace(/(<)/gi, "&lg;");
    str = str.replace(/(?:\r\n|\n\r|\r|\n)/g, " <br />");
    str = str.replace(/#(\S+)(?=[\s.,:]|$)/g, "<span>#$1</span>");
    // str = str.replace(/@(.+?)(?=[\s.,:,]|$)/g, "<span>@$1</span>");
    str = str.replace(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g,
      "<span>$1</span>"
    );
    if (contentAIGenerated) {
      str += '<span class="highlight">' + contentAIGenerated + "</span>";
      setContentValue({ ...contentValue, contentAIGenerated: "" });
    }
    if (captionEditorRef.current) {
      captionEditorRef.current.innerHTML = str;
    }
  }
  return (
    <div className="content-editor-caption">
      <div
        ref={captionEditorRef}
        className="content-editor-caption__mask"
      ></div>
      <Input.TextArea
        placeholder="Write your caption here..."
        autoSize={true}
        value={caption}
        onChange={onChangeCaption}
        onClick={onClickCaption}
      />
    </div>
  );
};

export default ContentCaption;
