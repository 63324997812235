import { Col, Row, Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import "./index.scss";
import MyFile from "../../components/views/media-library/my-file";
import MediaLibraryHeader, {
  MediaType,
} from "../../components/views/media-library/components/media-library-header";
import MediaLibraryPlatform from "../../components/views/media-library/explore";
import FileDetail from "../../components/views/media-library/components/file-detail";
import SVGIcons from "../../components/icons/svgs";
import { UNSPLASH_UTM_SUFFIX_URL } from "../../constants/app-constants";
import { FileSelectedState, MediaLibraryState } from "../../states/media-library";
import { useRecoilState } from "recoil";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { PlanApi } from "../../apis/plan.api";
import useClient from "../../hooks/useClient";
import { PlanUsageType } from "../../constants/app.enums";
import { PlanUsageModel } from "../../models/plan.model";

export enum MediaLibraryItems {
  myFile = "my-file",
  pexels = "pexels",
  unsplash = "unsplash",
  pixabay = "pixabay",
}
interface menuItemsProps {
  value: MediaLibraryItems;
  label: string;
  url?: string;
}

export function renderLibraryName(platform: MediaLibraryItems) {
  switch (platform.toLocaleLowerCase()) {
    case MediaLibraryItems.unsplash.toLocaleLowerCase():
      return "Unsplash";
    case MediaLibraryItems.pexels.toLocaleLowerCase():
      return "Pexels";
    case MediaLibraryItems.pixabay.toLocaleLowerCase():
      return "Pixabay";

    default:
      return "";
  }
}

const MediaLibrary = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<menuItemsProps>();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const keyword = searchParams.get("keyword")
  const { clientId } = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [storageUsage, setStorageUsage] = useState<PlanUsageModel>()
  const [selectedType, setSelectedType] = useState(MediaType.images);
  const [fileSelected, setFileSelected] = useRecoilState(FileSelectedState);
  const [searchMediaKeyword, setSearchMediaKeyword] = useState("");
  const location = useLocation();
  const [mediaLibraryState, setMediaLibraryState] =
  useRecoilState(MediaLibraryState);

  const convertKBtoGB = (kb: number, hasToFixed?: boolean) => {
    if (kb && kb > 0) {
      const gigabytes = kb / 1024 / 1024;
      if (hasToFixed) {
        return gigabytes.toFixed(2);
      }
      return gigabytes
    }
    return 0;
  }

  const getStorage = async () => {
    try {
      setLoading(true);
      const { data } = await PlanApi.checkClientUsagePlan(PlanUsageType.Storage, clientId);
      setStorageUsage(data);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {

    if (keyword) {
      setSearchMediaKeyword(keyword);
    }

    if (tab) {
      const item = [...menuManageItems, ...menuExploreItems].find(
        (x) => x.value.toLocaleLowerCase() === tab.toLocaleLowerCase()
      );
      if (item) {
        setSelected(item);
        if (item.value === MediaLibraryItems.myFile) {
          setSelectedType(MediaType.all);
        } else {
          setSelectedType(MediaType.images);
        }
      }
    } else {
      selectTab(MediaLibraryItems.myFile);
      setSelectedType(MediaType.all);
    }
  }, [searchParams]);

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.MediaLibrary) {
      if (searchMediaKeyword && searchMediaKeyword !== keyword) {
        searchParams.set("keyword", searchMediaKeyword);
        setSearchParams(searchParams);
      } else if (!searchMediaKeyword) {
        searchParams.delete("keyword");
        setSearchParams(searchParams);
      }
    }
  }, [searchMediaKeyword])

  function selectTab(key: MediaLibraryItems) {
    if (location.pathname === ROUTE_PATHS.MediaLibrary) {
      // On media library page
      searchParams.set("tab", key);
      setSearchParams(searchParams);
      setFileSelected(undefined);
    } else {
      // On content popup
      const item = [...menuManageItems, ...menuExploreItems].find(
        (x) => x.value.toLocaleLowerCase() === key.toLocaleLowerCase()
      );
      if (item) {
        setSelected(item);
        if (item.value === MediaLibraryItems.myFile) {
          setSelectedType(MediaType.all);
        } else {
          setSelectedType(MediaType.images);
        }
      }
    }
  }

  const menuManageItems: menuItemsProps[] = [
    {
      value: MediaLibraryItems.myFile,
      label: t("mediaLibrary.myFile"),
    },
  ];

  const menuExploreItems: menuItemsProps[] = [
    {
      value: MediaLibraryItems.pexels,
      label: t("mediaLibrary.pexels"),
      url: "https://www.pexels.com/",
    },
    {
      value: MediaLibraryItems.unsplash,
      label: t("mediaLibrary.unsplash"),
      url: "https://unsplash.com/" + UNSPLASH_UTM_SUFFIX_URL,
    },
    {
      value: MediaLibraryItems.pixabay,
      label: t("mediaLibrary.pixabay"),
      url: "https://pixabay.com/",
    },
  ];

  const renderContent = useCallback(() => {
    if (!selected?.value) return <></>;

    if (selected.value === MediaLibraryItems.myFile) {
      return <MyFile mediaType={selectedType}
        searchMediaKeyword={searchMediaKeyword} />;
    }

    return (
      <MediaLibraryPlatform
        platform={selected.value}
        mediaType={selectedType}
        searchMediaKeyword={searchMediaKeyword}
      />
    );
  }, [selected, fileSelected, selectedType, searchMediaKeyword]);

  useEffect(() => {
    getStorage();
  }, [clientId, mediaLibraryState.triggerReloadUsageStorage])

  return (
    <div className="media-library-page page-content">
      <div className="page-header">
        <Row>
          <Col span={24}>
            <h4 className="page-header__title">{t("mediaLibrary.title")}</h4>
          </Col>
        </Row>
      </div>
      <div className="page-body">
        <div className="sider-bar">
          <div className="sider-bar-social">{t("mediaLibrary.manage")}</div>
          {menuManageItems.map((x) => (
            <div
              className={`sider-bar__item${x.value === selected?.value ? " sider-bar__item__active" : ""
                }`}
              key={x.value}
              onClick={() => selectTab(x.value)}
            >
              <span>{x.label}</span>
            </div>
          ))}
          <div className="sider-bar-social">{t("mediaLibrary.explore")}</div>
          {menuExploreItems.map((x) => (
            <div
              className={`sider-bar__item${x.value === selected?.value ? " sider-bar__item__active" : ""
                }`}
              key={x.value}
              onClick={() => selectTab(x.value)}
            >
              <span>{x.label}</span>
            </div>
          ))}
          <div className="sider-bar__footer">
            {
              loading ? <Skeleton.Input /> :
                <>
                  <div>
                    {convertKBtoGB(storageUsage?.totalUsed || 0, true)}/{convertKBtoGB(storageUsage?.maximum || 0)} GB {" "}
                    {t("mediaLibrary.used")}</div>
                  <div>{t("mediaLibrary.maximumFileUpload")} 150MB</div>
                  <div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.rocketlevel.com/social/manage-and-review-your-content"
                    >
                      Other media guidelines <SVGIcons.ExternalLink />{" "}
                    </a>
                  </div>
                </>
            }
          </div>
        </div>

        <div className="media-library-main">
          <MediaLibraryHeader
            library={selected?.value}
            changeType={setSelectedType}
            searchMediaKeywordChange={(val) => setSearchMediaKeyword(val)}
            searchValue={searchMediaKeyword}
          />
          {renderContent()}
        </div>

        <div className="file-detail">
          <FileDetail
            library={selected?.value}
            libraryUrl={selected?.url}
          />
        </div>
        {/* 
        <div className="setting-sider-bar-mobile">
          <Select
            style={{ width: "100%" }}
            suffixIcon={<SVGIcons.ChevronDown />}
            popupClassName="setting-sider-bar-mobile__dropdown"
            onChange={(value: GeneralSettingItems) => selectTab(value)}
            value={selected?.value}
          >
            {menuItems.map((item: menuItemsProps) => (
              <Option value={item.value} key={item.label}>
                <div className={"setting-sider-bar__item"}>
                  {item.icon} <span>{item.label}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div> */}
      </div>
    </div>
  );
};

export default MediaLibrary;
