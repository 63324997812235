import { Auth } from "aws-amplify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AuthApi } from "../../apis/auth.api";
import showNotification from "../../components/common/notification";
import SignUpConfirm from "../../components/views/sign-up/confirmation";
import SignUpForm from "../../components/views/sign-up/form";
import { ROUTE_PATHS } from "../../constants/router.constants";
import {
  AmplifySignUpModel as AmpplifySignUpModel,
  SignUpModel,
} from "../../models/sign-up";
import DateUtils from "../../utils/date.utils";


function SignUpPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [email, setEmail] = useState("");

  async function signUp(value: any) {
    setEmail(value.email);
    setLoading(true);

    const attributes: AmpplifySignUpModel = {
      email: value.email,
      given_name: value.firstName,
      family_name: value.lastName,
      name: `${value.firstName} ${value.lastName}`,
      website: value.website,
      phone_number: `+${value.businessPhone}`,
      "custom:company_name": value.companyName,
    };

    // Auth.signUp({
    //   username: value.email,
    //   password: value.password,
    //   attributes: attributes,
    // })
    //   .then(() => {
    //     setSubmited(true);
    //   })
    //   .catch((error) => {
    //     showNotification("error", error.message);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });

    const requestModel: SignUpModel = {
      username: value.email,
      password: value.password,
      userAttributes: Object.keys(attributes).map((x) => {
        return {
          name: x,
          value: (attributes as any)[x],
        };
      }),
      timeZone: DateUtils.getCurrentTimezone(),
      category: value.category
    };


    AuthApi.signUp(requestModel)
      .then(() => {
        setSubmited(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  async function resendEmail() {
    Auth.resendSignUp(email)
      .then(() => {
        showNotification("success", t("common.sentEmail"));
      })
      .catch((error) => {
        showNotification("error", error.message);
      });
  }

  return (
    <>
      {submited ? (
        <SignUpConfirm email={email} resendEmail={resendEmail} />
      ) : (
        <SignUpForm loading={loading} submit={signUp} />
      )}

      <Link
        className="auth__backLink"
        to={ROUTE_PATHS.SignIn}
        dangerouslySetInnerHTML={{ __html: t("signUp.backLink") }}
      ></Link>
    </>
  );
}

export default SignUpPage;
