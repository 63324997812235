import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  CHART_COLORS,
  EMPTY_DATA_COLOR
} from "../../../../../constants/app-constants";
import { ChartDataModel } from "../../../../../models/chart.model";
import {
  dashboardGaTrafficState
} from "../../../../../states/dashboard";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import SpinDiv from "../../../../common/spin-div";
import "./index.scss";

interface Props {
  chartId: string;
}

function TopLeadSourcesChart(props: Props) {
  const { t } = useTranslation();
  const { chartId } = props;
  const { data, loading } = useRecoilValue(dashboardGaTrafficState);
  const emptyData: ChartDataModel[] = [
    {
      label: "Organic Search",
      value: 0,
    },
    {
      label: "Paid Search",
      value: 0,
    },
    {
      label: "Email",
      value: 0,
    },
    {
      label: "Social",
      value: 0,
    },
    {
      label: "Display",
      value: 0,
    },
    {
      label: "Direct",
      value: 0,
    },
    {
      label: "Referral",
      value: 0,
    },
    {
      label: "All Other",
      value: 0,
    },
  ];

  const series = data && data.rows ? data.rows.map((x) => x.sessions) : [];
  const colors = CHART_COLORS.filter((x, i) => i < series.length);

  const options: ApexOptions =
    data && data.rows
      ? {
          chart: {
            id: chartId,
            toolbar: {
              show: false,
            },
          },
          labels: data.rows.map((x) => x.name),
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: data.rows.map((x) => x.name),
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
            max: 100,
          },
          fill: {
            colors: colors,
          },
          tooltip: {
            fillSeriesColor: false,
            custom: function ({ series, seriesIndex }) {
              return (
                '<div class="custom-tooltip-wrapper">' +
                `<span class="tooltip-color" style="background-color: ${colors[seriesIndex]}"></span>` +
                `<span class="tooltip-name">${data.rows[seriesIndex].name}:</span>` +
                "<span>" +
                series[seriesIndex] +
                "</span>" +
                "</div>"
              );
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            },
          },
        }
      : {};

  return (
    <div className="top-lead-sources">
      <SpinDiv loading={loading} style={{ height: 222 }}>
        <>
          <div className="top-lead-sources__chartWrapper">
            {data && data.rows && data.rows.length && data.total ? (
              <>
                <Chart
                  options={options}
                  series={series}
                  type="donut"
                  width={232}
                  height={232}
                />
                <div className="top-lead-sources__total">
                  <span>{t("dashboard.sessions")}</span>
                  <span>
                    {NUMBER_UTIL.convertNumericToFormattedString(data.total)}
                  </span>
                </div>
              </>
            ) : (
              <div
                className="top-lead-sources__emptyChart"
                style={{
                  background: EMPTY_DATA_COLOR,
                  width: 222,
                  height: 222,
                }}
              >
                <div className="top-lead-sources__empty__total">
                  <span>{t("dashboard.sessions")}</span>
                  <span>{data?.total || 0}</span>
                </div>
              </div>
            )}
          </div>
          <div className="top-lead-sources__legend">
            {data && data.rows && data.rows.length
              ? data.rows.map((item, index) => (
                  <div key={index} className="top-lead-sources__legend__item">
                    <span
                      style={{ backgroundColor: CHART_COLORS[index] }}
                    ></span>
                    <span className="top-lead-sources__legend__item__name">
                      {item.name}
                    </span>
                    <span>{item.sessions}</span>
                  </div>
                ))
              : emptyData.map((item, index) => (
                  <div key={index} className="top-lead-sources__legend__item">
                    <span
                      style={{ backgroundColor: CHART_COLORS[index] }}
                    ></span>
                    <span className="top-lead-sources__legend__item__name">
                      {item.label}
                    </span>
                    <span>{item.value}</span>
                  </div>
                ))}
          </div>
        </>
      </SpinDiv>
    </div>
  );
}

export default memo(TopLeadSourcesChart);
