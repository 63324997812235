import { Modal } from "antd";
import UploadMedia from "../upload-media";
import "./index.scss";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}
const UploadMediaModal = (props: Props) => {
  const { open, setOpen } = props;
  return (
    <Modal
      title="Upload Media"
      centered
      className="upload-media-modal"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={787}
      destroyOnClose
    >
      <UploadMedia onClose={() => setOpen(false)} />
    </Modal>
  );
};

export default UploadMediaModal;
