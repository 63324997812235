import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT } from "../../../constants/app-constants";
import SVGIcons from "../../icons/svgs";
import "./index.scss";

export interface RangePickerValues {
  startDate: Moment;
  endDate: Moment;
  value: string;
}

interface AppRangePickerProps {
  defaulValue?: moment.Moment;
  onChange: (values: string) => void;
  hasBorder?: boolean;
}

function AppDatePicker(props: AppRangePickerProps) {
  const { onChange, defaulValue } = props;
  const { t } = useTranslation();
  const [dateOpen, setDateOpen] = useState(false);
  const [dateValue, setDateValue] = useState(defaulValue || moment());

  const ref = useRef<any>();
  const forceBlurRef = useRef<boolean>(false);

  function dateChange(date: any) {
    setDateValue(date);
    onChange(
      `${moment.utc(date).format("YYYY-MM-DD")}T${moment
        .utc()
        .format("HH:mm:00")}Z`
    );
    ref.current.blur();
  }

  function setDisabledDate(currentDate: moment.Moment) {
    return (
      currentDate.format(DATE_TIME_FORMAT.isoDate) <
      moment().format(DATE_TIME_FORMAT.isoDate)
    );
  }

  let blurTimeOut: any = undefined;
  function onFocus() {
    if (blurTimeOut) {
      clearTimeout(blurTimeOut);
    }

    if (forceBlurRef.current) {
      forceBlur();
    } else {
      setDateOpen(true);
    }
  }

  function onBlur() {
    if (forceBlurRef.current) {
      return;
    }

    blurTimeOut = setTimeout(() => {
      cancel();
    }, 100);
  }

  function cancel() {
    setDateOpen(false);
  }

  function forceBlur() {
    forceBlurRef.current = true;
    setTimeout(() => {
      forceBlurRef.current = false;
    }, 100);
    ref.current.blur();
  }

  useEffect(() => {
    if (defaulValue) {
      setDateValue(defaulValue);
    } else {
      setDateValue(moment());
    }
  }, [defaulValue]);
  return (
    <div className={`app-date-picker`}>
      <div className="app-date-picker-date">
        <SVGIcons.CalendarIcon />
        <div className="app-date-picker-date__input-wrapper">
          <DatePicker
            format={DATE_TIME_FORMAT.viewFormat}
            suffixIcon={null}
            open={dateOpen}
            onChange={dateChange}
            value={dateValue}
            allowClear={false}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={"mm dd, yyyy"}
            ref={ref}
            showToday={false}
            disabledDate={setDisabledDate}
          />
        </div>
      </div>
    </div>
  );
}

export default AppDatePicker;
