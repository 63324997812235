import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import IconFacebook from "../../../assets/images/app-icon-facebook.png";
import IconInstag from "../../../assets/images/app-icon-instag.png";
import IconTiktok from "../../../assets/images/app-icon-tiktok.png";
import SVGIcons from "../../../components/icons/svgs";
import useDashboardPath from "../../../hooks/useDashboardPath";
import "./index.scss";
import { SUPPORT_EMAIL } from "../../../constants/app-constants";

function AddProfilesPage() {
  const dashboardPath = useDashboardPath();
  const { t } = useTranslation();

  return (
    <div className="add-profiles-page">
      <div className="add-profiles-page__header">
        <Link to={dashboardPath}>
          <img src={logo} />
        </Link>
      </div>

      <div className="add-profiles-page__body">
        <div className="container">
          <div className="title">{t("social.addProfiles.title")}</div>
          <div className="description">
            {t("social.addProfiles.description")}
          </div>
          <div className="list-social">
            <div className="list-social-item facebook">
              <div className="social-icon">
                <img src={IconFacebook} alt="facebook" />
              </div>
              <div className="social-name">
                {t("social.addProfiles.addFacebook")}
              </div>
              <div className="icon-add">
                <SVGIcons.PlusIcon />
              </div>
            </div>
            <div className="list-social-item instagram">
              <div className="social-icon">
                <img src={IconInstag} alt="instag" />
              </div>
              <div className="social-name">
                {t("social.addProfiles.addInstag")}
              </div>
              <div className="icon-add">
                <SVGIcons.PlusIcon />
              </div>
            </div>
            <div className="list-social-item tiktok">
              <div className="social-icon">
                <img src={IconTiktok} alt="tiktok" />
              </div>
              <div className="social-name">
                {t("social.addProfiles.addTiktok")}
              </div>
              <div className="icon-add">
                <SVGIcons.PlusIcon />
              </div>
            </div>
          </div>

          <div className="all-done">
            <div className="all-done-description">
              {t("social.addProfiles.allDoneDescription")}
            </div>
            <Button type="primary">
              <SVGIcons.CheckedNotRoundedIcon />
              {t("social.addProfiles.allDone")}
            </Button>
          </div>

          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className="auth__backLink contact"
            dangerouslySetInnerHTML={{
              __html: t("social.addProfiles.contact"),
            }}
          ></a>
        </div>
      </div>

      <div className="add-profiles-page__footer">{t("common.copyRight")}</div>
    </div>
  );
}

export default AddProfilesPage;
