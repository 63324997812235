import { TimePicker } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { DATE_TIME_FORMAT } from "../../../constants/app-constants";
import SVGIcons from "../../icons/svgs";
import "./index.scss";

export interface RangePickerValues {
  startDate: Moment;
  endDate: Moment;
  value: string;
}

interface AppRangePickerProps {
  defaulValue?: moment.Moment;
  onChange: (values: string) => void;
  hasBorder?: boolean;
  readonly?: boolean;
}

function AppTimePicker(props: AppRangePickerProps) {
  const { onChange, defaulValue, readonly } = props;
  const [dateValue, setDateValue] = useState(defaulValue || moment());
  const [timeOpen, setTimeOpen] = useState(false);
  const [timeValue, setTimeValue] = useState(defaulValue || moment());

  const ref = useRef<any>();
  const forceBlurRef = useRef<boolean>(false);

  const timeRef = useRef<any>();
  const forceBlurTimeRef = useRef<boolean>(false);

  function timeChange(time: any) {
    dateValue.set("h", time.get("h"));
    dateValue.set("m", time.get("m"));
    onChange(moment.utc(dateValue).format("YYYY-MM-DDTHH:mm:00Z"));
    setTimeOpen(false);
    setTimeValue(time);
    timeRef.current.blur();
  }

  let blurTimeOut1: any = undefined;
  function onFocusTime() {
    if (blurTimeOut1) {
      clearTimeout(blurTimeOut1);
    }

    if (forceBlurRef.current) {
      forceBlurTime();
    } else {
      setTimeOpen(true);
    }
  }

  function onBlurTime() {
    if (forceBlurTimeRef.current) {
      return;
    }

    blurTimeOut1 = setTimeout(() => {
      cancelTime();
    }, 100);
  }

  function cancelTime() {
    setTimeOpen(false);
  }

  function forceBlurTime() {
    forceBlurTimeRef.current = true;
    setTimeout(() => {
      forceBlurTimeRef.current = false;
    }, 100);
    timeRef.current.blur();
  }

  useEffect(() => {
    if (defaulValue) {
      setDateValue(defaulValue);
      setTimeValue(defaulValue);
    } else {
      setDateValue(moment());
      setTimeValue(moment());
    }
  }, [defaulValue]);
  return (
    <div className={`app-date-picker`}>
      <div className="app-date-picker-date">
        <SVGIcons.ClockHour4 />
        <div className="app-date-picker-date__input-wrapper">
          <TimePicker
            value={timeValue}
            onChange={timeChange}
            open={!readonly && timeOpen}
            format={DATE_TIME_FORMAT.hourView}
            suffixIcon={null}
            onFocus={onFocusTime}
            onBlur={onBlurTime}
            allowClear={false}
            ref={timeRef}
            inputReadOnly={readonly}
          ></TimePicker>
        </div>
      </div>
    </div>
  );
}

export default AppTimePicker;
