import { Button, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import useClient from "../../../../../hooks/useClient";
import useAdminSite from "../../../../../hooks/useAdminSite";

interface Props {
  removeLoading: boolean;
  testLoading: boolean;
  syncLoading?: boolean;
  saveLoading: boolean;
  onRemove?: () => void;
  onTestConnection: () => void;
  onSyncConnection?: () => void;
  connectionName: string;
  disableTestBtn: boolean;
  disableSyncBtn?: boolean;
  disableSaveBtn?: boolean;
  isAddConnection?: boolean;
}

function GroupBtnIntegration(props: Props) {
  const {
    isAddConnection,
    removeLoading,
    testLoading,
    syncLoading,
    saveLoading,
    onTestConnection,
    connectionName,
    onRemove,
    disableTestBtn,
    disableSaveBtn,
    disableSyncBtn,
    onSyncConnection,
  } = props;
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isExternalUser } = useClient();
  const isAdmin = useAdminSite();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRemove = async () => {
    if(onRemove) {
      await onRemove()
      setIsModalOpen(false)
    }
  }

  return (
    <div
      className={`group-btn-integration ${
        isAddConnection ? "isAddConnection" : ""
      }`}
    >
      {!isAddConnection && (
        <div className="remove-btn">
          <Button disabled={!isAdmin && isExternalUser()} type="text" onClick={showModal}>
            {t("common.remove")}
          </Button>
        </div>
      )}
      {!isAddConnection && onSyncConnection && (
        <Button
          className={`sync-btn ${syncLoading ? "loading" : ""}`}
          type="default"
          loading={syncLoading}
          onClick={onSyncConnection}
          disabled={disableSyncBtn || isExternalUser()}
        >
          {t("clientSites.syncNow")}
        </Button>
      )}
      <Button
        className="test-btn secondary-btn"
        type="primary"
        loading={testLoading}
        onClick={onTestConnection}
        disabled={disableTestBtn || isExternalUser()}
      >
        {t("clientSites.testConnection")}
      </Button>
      <Button
        htmlType="submit"
        className="save-btn primary-btn"
        type="primary"
        loading={saveLoading}
        disabled={disableSaveBtn || isExternalUser()}
      >
        {isAddConnection ? t("clientSites.addConnection") : t("common.save")}
      </Button>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        closable={false}
        maskClosable
        centered={true}
        className="confirm-delete-modal"
        footer={
          <div className="remove-connection-footer">
            <Button type="text" onClick={handleCancel}>
              {t("common.cancel")}
            </Button>
            <Button type="primary" onClick={handleRemove} loading={removeLoading}>
              {t("common.removeConnectionBtn")}
            </Button>
          </div>
        }
      >
        <div className="remove-connection-content">
          <div className="title">
            {t("clientSites.removeConnectionTitle", {
              connection: connectionName,
            })}
          </div>
          <div className="content">
            {t("clientSites.removeConnectionContent", {
              connection: connectionName,
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default GroupBtnIntegration;
