import { Button, Divider, InputNumber, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../../apis/auth.api";
import useClient from "../../../../../hooks/useClient";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";

function SettingTab() {
  const { t } = useTranslation();
  const [averageValue, setAverageValue] = useState(0);
  const [timeZoneSelected, setTimeZoneSelected] = useState<string | undefined>(
    undefined
  );

  const { client, setClient, clientId, isOwner } = useClient();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    updateValueFromClient();
  }, [client]);

  function updateValueFromClient() {
    setAverageValue(client?.averageValueOfNewCustomer || 0);
    setTimeZoneSelected(client?.timeZoneInfo?.id);
  }

  const onChangeValue = (value: number) => {
    setAverageValue(value);
  };

  const onClickCancel = () => {
    updateValueFromClient();
  };

  const onClickSave = async () => {
    setSubmitLoading(true);
    await saveAdvancedSettings();
    setSubmitLoading(false);
  };

  const saveAdvancedSettings = async () => {
    try {
      await AuthApi.updateAverageValueOfNewCustomer(client?.id, averageValue);
      if (client) {
        setClient({
          ...client,
          averageValueOfNewCustomer: averageValue,
        });
        showNotification("success", t(`common.changeSaveSuccess`));
      }
    } catch {}
  };

  return (
    <div className="setting-tab">
      <div className="title">
        <span>{t("generalSettings.returnOnInvestment")}</span>
      </div>
      <div className="average-value">
        <div className="label">{t("generalSettings.averageValue")}</div>
        <div className="average-value__input">
          <InputNumber
            addonBefore={<SVGIcons.DollarIcon />}
            value={averageValue}
            onChange={(value) => onChangeValue(value || 0)}
            disabled={!client || !isOwner()}
            min={0}
          />
          <Tooltip
            overlayClassName="custom-tooltip"
            placement="top"
            title={t("generalSettings.averageValueTooltip")}
          >
            <div className="icon">
              <span>?</span>
            </div>
          </Tooltip>

          <Button
            disabled={!isOwner()}
            className="mid-night-border reset-btn"
            onClick={onClickCancel}
          >
            {t("common.reset")}
          </Button>
        </div>
      </div>
      <Divider />
      <div className="save-btn">
        <Button
          onClick={onClickSave}
          type="primary"
          loading={submitLoading}
          disabled={
            !client ||
            (client.averageValueOfNewCustomer === averageValue &&
              client.timeZoneInfo?.id === timeZoneSelected)
          }
        >
          {t("common.saveChanges")}
        </Button>
      </div>
    </div>
  );
}

export default SettingTab;
