import {
  CrAddConnectionRequest,
  CrUpdateConnectionRequest,
} from "../models/cr.model";
import { DashBoardRequestModel } from "../models/ga.model";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/callrail/api/v1`;

function testConnection(request: any) {
  const url = `${baseUrl}/integration/connect`;
  return postAsync(url, request);
}

function getCalls(request: DashBoardRequestModel) {
  const url = `${baseUrl}/call/report`;
  return getAsync(url, request, false, false);
}

function removeConnection(clientId: number) {
  const url = `${baseUrl}/integration/${clientId}`;
  return deleteAsync(url, false, false);
}

function addConnection(request: CrAddConnectionRequest) {
  const url = `${baseUrl}/integration`;
  return postAsync(url, request, false, false);
}

function updateConnection(request: CrUpdateConnectionRequest) {
  const url = `${baseUrl}/integration`;
  return putAsync(url, request, false, false);
}

export const CallRailApi = {
  testConnection,
  getCalls,
  removeConnection,
  addConnection,
  updateConnection,
};
