import { Button, Drawer, Popover, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ContentModel } from "../../../../../models/content.model";
import { listContentCalendarState } from "../../../../../states/social";
import AppDateTimePicker from "../../../../controls/app-date-time-picker";
import ContentCard from "../../social-content/components/content-card";
import { UseContentModel } from "../../../../../hooks/useContent";
import "./index.scss";
import useClient from "../../../../../hooks/useClient";
import DateUtils from "../../../../../utils/date.utils";

interface ContentDrawerProps {
  open: boolean;
  close: () => void;
  timeSelected?: moment.Moment;
  view: "dayGridMonth" | "timeGridWeek" | "timeGridDay";
  useContentProps: UseContentModel;
}
const ContentDrawer = (props: ContentDrawerProps) => {
  const { open, close, timeSelected, useContentProps, view } = props;
  const { t } = useTranslation();
  const { timezoneId } = useClient();
  const {
    onActionDropdown,
    scheduleNow,
    contentSelected,
    openScheduleModal,
    scheduleTime,
    setScheduleTime,
    setOpenScheduleModal,
    loadingSchedule,
  } = useContentProps;

  const [listContent, setListContent] = useState<ContentModel[]>([]);
  const contentState = useRecoilValue(listContentCalendarState);
  const { records, loading: loadingListContent } = contentState;
  const getListContent = async () => {
    const newListContent = records.filter((content) => {
      if (view === "dayGridMonth") {
        return (
          timeSelected &&
          timeSelected?.format("YYYY-MM-DD") ===
            DateUtils.getDateWithTimezone(content.date, timezoneId).format(
              "YYYY-MM-DD"
            )
        );
      }
      if (view === "timeGridWeek" || "timeGridDay")
        return (
          timeSelected &&
          timeSelected?.format("YYYY-MM-DDTHH") ===
            DateUtils.getDateWithTimezone(content.date, timezoneId).format(
              "YYYY-MM-DDTHH"
            )
        );
    });
    setListContent(newListContent);
  };

  useEffect(() => {
    timeSelected && getListContent();
  }, [timeSelected, records]);
  return (
    <Drawer
      className="calendar-content-drawer"
      title={`Content for ${
        timeSelected ? moment(timeSelected)?.format("MMM DD,YYYY") : ""
      } ${
        view !== "dayGridMonth"
          ? "at " + moment(timeSelected)?.format("ha")
          : ""
      }`}
      open={open}
      width={540}
      onClose={() => {
        setOpenScheduleModal(false);
        close();
      }}
      maskClosable={false}
      bodyStyle={{ background: "#f7f8fb" }}
    >
      <Spin spinning={loadingListContent}>
        {listContent.map((contentItem) => {
          return (
            <Popover
              key={contentItem.id}
              placement="leftTop"
              title={null}
              content={
                <div className="content-card-popover-schedule__content">
                  <p>{t("social.content.scheduleNowDescription")}</p>
                  <AppDateTimePicker
                    onChange={(values) => {
                      setScheduleTime(moment(values));
                    }}
                    defaulValue={scheduleTime}
                  />
                  <div className="group-btn">
                    <Button
                      type="text"
                      onClick={() => setOpenScheduleModal(false)}
                    >
                      {t("common.cancel")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => scheduleNow(contentItem)}
                      loading={loadingSchedule}
                    >
                      {t("common.schedule")}
                    </Button>
                  </div>
                </div>
              }
              overlayClassName="content-card-popover-schedule"
              open={openScheduleModal && contentSelected?.id === contentItem.id}
            >
              <ContentCard
                contentInfo={contentItem}
                onAction={(action) => onActionDropdown(action, contentItem)}
              />
            </Popover>
          );
        })}
      </Spin>
    </Drawer>
  );
};

export default ContentDrawer;
