import { atom, selector } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { SocialPermission } from "../models/social-permission.model";

const socialPermissionState = atom<SocialPermission>({
    key: APP_STATE_KEYS.SOCIAL_PERMISSION,
    default: undefined,
});


const isOnlyManageContentSelector = selector<boolean>({
    key: APP_STATE_KEYS.SOCIAL_PERMISSION_ONLY_MANAGE_CONTENT,
    get: ({ get }) => {
        const socialPermission = get(socialPermissionState);
        return socialPermission && !socialPermission.canApproveContent && socialPermission.canManageContent;
    },
});

export { isOnlyManageContentSelector, socialPermissionState }