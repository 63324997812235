import {
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../../../apis/social.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../../../constants/app-constants";
import { PrivacySettingYoutubeEnum } from "../../../../../../../constants/app.enums";
import useClient from "../../../../../../../hooks/useClient";
import { YoutubeCategoryModel } from "../../../../../../../models/content.model";
import {
  currentContentSelector,
  listPlatformContentSelector,
} from "../../../../../../../states/social";
import SVGIcons from "../../../../../../icons/svgs";
import "./index.scss";

const { Panel } = Collapse;
const madeForKids = ["No", "Yes"];

interface Props {
  disabled?: boolean;
}
const AdvancedSettingYoutube = (props: Props) => {
  const { disabled } = props;
  const { t } = useTranslation();
  const { client } = useClient();
  const [form] = useForm();
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState<YoutubeCategoryModel[]>([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [listTag, setListTag] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState("");
  const [taglength, setTagLength] = useState(0);
  const [activeKey, setActiveKey] = useState([]);

  const [listPlatformContent, setListPlatformContent] = useRecoilState(
    listPlatformContentSelector
  );
  const currentContent = useRecoilValue(currentContentSelector);

  function displaySearchValue(name: string, searchValue: string) {
    const indexSearch = name
      .toLocaleLowerCase()
      .indexOf(searchValue.toLocaleLowerCase());
    return (
      <div className="display-property">
        <div className="property-name">
          {name.substring(0, indexSearch)}
          <span className="highlight">
            {name.substring(indexSearch, indexSearch + searchValue.length)}
          </span>
          {name.substring(indexSearch + searchValue.length)}
        </div>
      </div>
    );
  }

  const onValuesChange = (changedValues: any, values: any) => {
    const listPlatformContentClone = [...listPlatformContent];

    const contentIdx = listPlatformContentClone.findIndex(
      (c) => c.platformExtendReel === currentContent?.platformExtendReel
    );
    if (contentIdx === -1) return;
    /// update content
    listPlatformContentClone[contentIdx] = {
      ...listPlatformContentClone[contentIdx],
      ...values,
      ...(values.makeForKid === undefined
        ? undefined
        : { makeForKid: values.makeForKid === "No" ? false : true }),
    };

    setListPlatformContent(listPlatformContentClone);
  };

  function onSearchCategory(e: any) {
    setSearchCategory(e.target.value);
  }

  const onCloseTag = (index: number) => {
    setListTag(listTag.filter((tag, i) => i !== index));
  };

  const onKeyDownTag = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 188) {
      e.preventDefault();
      if (tagInput) {
        setListTag([...listTag, tagInput]);
        onValuesChange(null, { youtubeTagsList: [...listTag, tagInput] });
        setTagLength(taglength + tagInput.length);
        setTagInput("");
      }
    }
  };

  const getListCategories = async () => {
    setLoadingCategories(true);
    try {
      const { data } = await SocialApi.Content.getYoutubeCategories(
        client?.country || "US"
      );
      setCategories(data);
    } catch (error) {}
    setLoadingCategories(false);
  };

  useEffect(() => {
    getListCategories();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      playListId: "",
      categoryId: undefined,
      ...currentContent,
      visibility: currentContent?.visibility || 0,
      makeForKid: currentContent?.makeForKid ? "Yes" : "No",
    });
    setListTag(currentContent?.youtubeTagsList || []);
  }, [currentContent]);
  return (
    <>
      <Collapse
        className="advanced-setting-collapse"
        bordered={false}
        expandIconPosition={"end"}
        activeKey={activeKey}
        onChange={(value: any) => setActiveKey(value)}
        expandIcon={({ isActive }) => isActive ? <SVGIcons.ChevronUp/> : <SVGIcons.ChevronDown />}
      >
        <Panel header={<>{t("social.content.advancedSettings")}</>} key={0}>
          <Form
            name="advancedSetting"
            layout="vertical"
            validateMessages={VALIDATION_MESSAGE_CONFIG}
            form={form}
            autoComplete="off"
            className="advanced-setting-form"
            onValuesChange={onValuesChange}
          >
            <Row gutter={20}>
              <Col sm={24} xs={24}>
                <Form.Item
                  name="categoryId"
                  label={
                    <>
                      {t("social.content.category")}
                      <Tooltip
                        overlayClassName="custom-tooltip"
                        placement="top"
                        title="The list of categories is different for every country. You can change this by changing the country in General Settings &gt; Company."
                      >
                        <div className="icon">
                          <span>?</span>
                        </div>
                      </Tooltip>
                    </>
                  }
                >
                  <Select
                    loading={loadingCategories}
                    popupClassName="select-category"
                    placeholder="Select the category"
                    disabled={disabled}
                    dropdownRender={(menu) => (
                      <>
                        <div className="search-category">
                          <Input
                            value={searchCategory}
                            suffix={<SVGIcons.SearchIcon />}
                            onChange={onSearchCategory}
                            onBlur={() => setSearchCategory("")}
                          />
                        </div>
                        {menu}
                      </>
                    )}
                  >
                    {categories
                      .filter((x) =>
                        x.cateTitle
                          .toLocaleLowerCase()
                          .includes(searchCategory.toLocaleLowerCase())
                      )
                      .map((x) => {
                        return (
                          <Select.Option key={x.cateId} value={x.cateId}>
                            {displaySearchValue(x.cateTitle, searchCategory)}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Form.Item
                  name="visibility"
                  label={t("social.content.privacy")}
                >
                  <Select
                    popupClassName="select-category"
                    dropdownRender={(menu) => <>{menu}</>}
                    disabled={disabled}
                  >
                    {Object.values(PrivacySettingYoutubeEnum)
                      .filter((e) => typeof e === "number")
                      .map((e) => {
                        return (
                          <Select.Option index={e} value={e} key={e}>
                            {
                              PrivacySettingYoutubeEnum[
                                e as PrivacySettingYoutubeEnum
                              ]
                            }
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Form.Item
                  name="makeForKid"
                  label={
                    <>
                      {t("social.content.madeForKids")}
                      <Tooltip
                        overlayClassName="custom-tooltip"
                        placement="top"
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `If yes, the primary audience for your content is
                                children and more likely to be recommended
                                alongside other kids’ videos. Learn more on
                                <a href="https://support.google.com/youtube/answer/9528076?hl=en" style="color: #ffffff !important; text-decoration: underline;">Youtube Help</a>`,
                            }}
                          ></span>
                        }
                      >
                        <div className="icon">
                          <span>?</span>
                        </div>
                      </Tooltip>
                    </>
                  }
                >
                  <Select
                    popupClassName="select-category"
                    dropdownRender={(menu) => <>{menu}</>}
                    disabled={disabled}
                  >
                    {madeForKids.map((c) => {
                      return (
                        <Select.Option index={c} value={c} key={c}>
                          {c}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24}>
                <Form.Item
                  name="playListId"
                  label={
                    <>
                      {t("social.content.playlist")}
                      <Tooltip
                        overlayClassName="custom-tooltip"
                        placement="top"
                        title=" Open your playlist on a browser, and the playlist ID is
                      the sequence of characters after “list=“ in the URL."
                      >
                        <div className="icon">
                          <span>?</span>
                        </div>
                      </Tooltip>
                    </>
                  }
                >
                  <Input placeholder="Enter playlist ID" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item name="tags" label={t("social.content.tags")}>
                  <div className={`tag-setting ${disabled ? "disabled" : ""}`}>
                    {listTag.map((tag, index) => (
                      <Tag
                        key={index}
                        closable={!disabled}
                        closeIcon={<SVGIcons.CloseIcon />}
                        onClose={() => {
                          onCloseTag(index);
                        }}
                      >
                        <div className="tag-title">{tag}</div>
                      </Tag>
                    ))}
                    <Input
                      value={tagInput}
                      onKeyDown={onKeyDownTag}
                      onChange={(e) => {
                        if (taglength + e.target.value.length > 400) return;
                        setTagInput(e.target.value);
                      }}
                      disabled={disabled}
                    />
                  </div>
                  <span className="setting-description">
                    Separate tags with comma. Maximum characters for all tags:{" "}
                    {taglength + tagInput.length}/400
                  </span>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </>
  );
};

export default AdvancedSettingYoutube;
