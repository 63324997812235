import { Button, Input, Modal, Tooltip } from "antd";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import {
  ApprovalStatusEnum,
  PermissionSocial,
} from "../../../../../../../constants/app.enums";
import {
  AdminViewSitePrefixRouter,
  ROUTE_PATHS,
} from "../../../../../../../constants/router.constants";
import useClient from "../../../../../../../hooks/useClient";
import useUnsaveChange from "../../../../../../../hooks/useUnsaveChange";
import {
  ContentMediaModel,
  ContentModel,
  CreateCommentRequest,
} from "../../../../../../../models/content.model";
import { S3ServiceInstance } from "../../../../../../../services/s3.service";
import {
  authIsSupperAdminSelector,
  authState,
} from "../../../../../../../states/auth";
import {
  listCommentingState,
  requiredGuestUserState,
  showCommentMobileState,
} from "../../../../../../../states/social";
import { isOnlyManageContentSelector } from "../../../../../../../states/socialPermission";
import LocalUtils from "../../../../../../../utils/local.utils";
import AppAvatar from "../../../../../../common/app-avatar/app-avatar";
import SVGIcons from "../../../../../../icons/svgs";
import AddMediaModal from "../../add-media";
import { ApprovalEditStatus } from "../../approval-status";
import EmojiPicker from "../../emoji-picker";
import MediaSocial from "../../media-social";
import "./index.scss";

interface CommentDraftProps {
  parentId?: number;
  isInternalComment?: boolean;
  createComment: (
    request: Omit<CreateCommentRequest, "contentId">
  ) => Promise<void>;
  isReviewing?: boolean;
  inputRef?: any;
  replyRef?: any;
  handleFocus?: () => void;
  setShowDraft?: (value: boolean) => void;
  contentInfo?: ContentModel;
  approvalStatus?: ApprovalStatusEnum;
  onChangeStatus?: (status: ApprovalStatusEnum) => void;
}

const CommentDraft = (props: CommentDraftProps, ref: any) => {
  const { pathname } = useLocation();
  const isAdminViewSite = pathname.startsWith(AdminViewSitePrefixRouter);
  const isContentPage = pathname.startsWith(ROUTE_PATHS.Social.Content);
  const isCalendarPage = pathname.startsWith(ROUTE_PATHS.Social.Calendar);
  const isSupperAdmin = useRecoilValue(authIsSupperAdminSelector);
  const {
    parentId,
    createComment,
    isReviewing,
    isInternalComment,
    handleFocus,
    setShowDraft,
    contentInfo,
    approvalStatus,
    onChangeStatus,
  } = props;
  const { t } = useTranslation();
  const { onlyHasPermissionSocial } = useClient();
  const auth = useRecoilValue(authState);
  const [lock, setLock] = useState(false);
  const [overflowText, setOverflowText] = useState(false);
  const [openModalMedia, setOpenModalMedia] = useState(false);
  const [listMedia, setListMedia] = useState<any>([]);
  const [comment, setComment] = useState("");
  const [uploading, setUploading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showCommentMobile, setShowCommentMobile] = useRecoilState(
    showCommentMobileState
  );
  const [listCommenting, setListCommenting] =
    useRecoilState(listCommentingState);
  const setRequiredGuestUser = useSetRecoilState(requiredGuestUserState);
  const isOnlyManageContent = useRecoilValue(isOnlyManageContentSelector);
  const guestInfo = LocalUtils.getGuestUserInfo();
  const { triggerUnsavedChanges } = useUnsaveChange(
    !!listCommenting.length,
    false,
    {
      title: t("social.leavePageModalTitle"),
      content: t("social.leavePageModalDescription"),
    }
  );

  const commentDetailRef = useRef<any>(null);
  const addMediaRef = useRef<any>(null);
  const addMediaFooterRef = useRef<any>(null);

  const commentDetailMobileRef = useRef<any>(null);

  const inputRef = useRef<any>(null);
  const isCommentingRef = useRef<boolean>(false);
  const commentingId = `${contentInfo?.id}-${parentId}`;

  const showLockComment =
    showAction &&
    (isSupperAdmin ||
      isAdminViewSite ||
      onlyHasPermissionSocial(PermissionSocial.CanManage) ||
      isOnlyManageContent);

  const onResize = ({ height }: any) => {
    comment && height > 28 && setOverflowText(true);
  };

  const onChangeComment = (e: any) => {
    if (checkRequiredGuestUser()) {
      return;
    }
    const value = e.target.value;
    if (!value) setOverflowText(false);
    setComment(value);
  };

  const onUpdateMedia = async (listMediaUrl: ContentMediaModel[]) => {
    setListMedia([...listMedia, ...listMediaUrl.map(m => m.url)]);
    setOpenModalMedia(false);
  };

  const onRemoveMedia = (index: number) => {
    const newListMedia = [...listMedia];
    newListMedia.splice(index, 1);
    setListMedia(newListMedia);
  };

  const onEmojiClick = (emoji: any) => {
    setComment(comment + emoji);
  };

  const onPressEnter = async (e: any) => {
    if (e.key === "Enter" && e.shiftKey) return;
    if (!comment && !listMedia?.length) return;
    try {
      await onCreateComment();
      onCancel();
    } catch (error) {
      console.log(error);
    }
  };

  const onCreateComment = async () => {
    await createComment({
      description: comment,
      parentId: parentId,
      mediaUrls: listMedia,
      isInternalComment: lock,
    });
    onCancel();
    setListCommenting([])
  };

  const onCancel = () => {
    clearDataInput();
    setShowAction(false);
    setOverflowText(false);
    setShowCommentMobile(undefined);
  };

  const clearDataInput = () => {
    setComment("");
    setListMedia([]);
  };

  const onShowCommentMobile = () => {
    if (checkRequiredGuestUser()) {
      return;
    }
    setShowCommentMobile(contentInfo?.id);
  };

  const onChangeDeviceUploadMobile = async (e: any) => {
    const fileList = Array.from(e.target.files);
    setUploading(true);
    try {
      const listFileResponse: any[] = await Promise.all(
        fileList.map(async (file: any) => {
          return await S3ServiceInstance.handleUploadWithPresignUrl(file);
        })
      );
      setListMedia([...listFileResponse, ...listMedia]);
    } catch (error) {
      setUploading(false);
      e.target.value = null;
    }
    e.target.value = null;
    setUploading(false);
  };

  const checkRequiredGuestUser = () => {
    if (isReviewing) {
      if (!guestInfo?.uuid) {
        setRequiredGuestUser(true);
        return true;
      }
    }
    return false;
  };

  const onCancelDraftMobile = () => {
    if (listCommenting.includes(commentingId))
      triggerUnsavedChanges(() => {
        setShowCommentMobile(undefined);
        setShowDraft && setShowDraft(false);
        clearDataInput();
      });
    else {
      setShowCommentMobile(undefined);
      setShowDraft && setShowDraft(false);
      clearDataInput();
    }
  };

  const handleClickOutside = (event: any) => {
    const targetClasses = [
      "epr-search",
      "ant-modal-title",
      "close-modal-icon",
      "ant-modal-header",
      "ant-modal-close-x",
      "status",
      "epr-btn",
      "epr-tone-neutral",
      "epr-tone",
      "epr-active",
      "epr-category-nav",
      "epr-emoji-category-label",
      "epr-emoji-category-content",
      "epr-preview-emoji-label",
      "epr-header-overlay",
      "epr-body",
      "epr-icn-search",
      "epr-skin-tones",
    ];
    const hasTargetClass = targetClasses.some((className) =>
      event.target.classList.contains(className)
    );
    if (
      event.target.tagName === "IMG" || //Check selected icon from emoji
      event.target.tagName === "SPAN" || //Check modal close icon
      event.target.tagName === "svg" || //Check modal close icon
      hasTargetClass
    ) {
      !showCommentMobile && setShowDraft && setShowDraft(true);
    } else if (
      commentDetailRef.current &&
      !commentDetailRef.current.contains(event.target) &&
      !addMediaRef.current?.contains(event.target) &&
      !addMediaFooterRef.current?.contains(event.target)
    ) {
      if (parentId && !isCommentingRef.current)
        !showCommentMobile && setShowDraft && setShowDraft(false);
    } else setShowAction(true);
    // if (
    //   commentDetailMobileRef.current &&
    //   !commentDetailMobileRef.current.contains(event.target)
    // ) {
    //   if (event.target.className !== "reply-mobile") {
    //     setShowCommentMobile(undefined);
    //     setShowDraft && setShowDraft(false);
    //   }
    // }
  };

  const handleClickLock = () => {
    setLock(!lock);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          inputRef.current!.focus();
          setShowAction(true);
        },
      };
    },
    []
  );

  const idInputComment = useMemo(() => {
    return uuidv4();
  }, [contentInfo?.id, parentId]);
  const idInputCommentMobile = `${contentInfo?.id}-${parentId}`;
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document
      .getElementById(idInputComment)
      ?.addEventListener("keydown", (e) => {
        if (e.key === "Enter" && !e.shiftKey) e.preventDefault();
      });
    return () => {
      document.removeEventListener("keydown", () => {});
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isInternalComment) {
      setLock(true);
    }
  }, []);

  useEffect(() => {
    handleFocus && handleFocus();
  }, []);

  useEffect(() => {
    if (comment || listMedia.length) {
      isCommentingRef.current = true;
      if (!listCommenting.includes(commentingId))
        setListCommenting([...listCommenting, commentingId]);
    } else {
      isCommentingRef.current = false;
      const newListCommenting = [...listCommenting];
      const commentingIndex = listCommenting.findIndex(
        (c) => c === commentingId
      );
      if (commentingIndex !== -1) {
        newListCommenting.splice(commentingIndex, 1);
        setListCommenting(newListCommenting);
      }
    }
  }, [comment, listMedia]);

  // handle browser warning unsavechanges
  useEffect(() => {
    if (!!listCommenting.length)
      window.onbeforeunload = (event: any) => {
        return "";
      };
    else {
      clearDataInput();
      window.onbeforeunload = (event: any) => {};
    }
  }, [listCommenting]);

  const idInputFile = uuidv4();

  return (
    <>
      <div
        className={`comment-draft ${parentId ? "comment-draft-mobile" : ""}`}
      >
        <div className="comment-draft-avatar">
          <AppAvatar src={auth.user?.profilePictureUrl} size={36} />
        </div>
        <div className="comment-draft-detail" ref={commentDetailRef}>
          <div
            className={`comment-draft-detail__container ${
              lock ? "lock" : "unlock"
            }`}
          >
            <div
              className={`comment-draft-detail__editor ${
                listMedia.length > 0 ? "" : "centered"
              }`}
            >
              <div
                className={`comment ${overflowText ? "overflow" : ""} ${
                  listMedia.length > 0 ? "" : "fullWidth"
                }`}
              >
                <Input.TextArea
                  ref={inputRef}
                  value={comment}
                  onResize={onResize}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  placeholder="Your comment here..."
                  onChange={onChangeComment}
                  onPressEnter={onPressEnter}
                  id={idInputComment}
                ></Input.TextArea>
                <div
                  className={`action ${
                    (isContentPage || isCalendarPage) && "tools"
                  }`}
                >
                  {listMedia.length === 0 && (
                    <Tooltip title="Add attachment">
                      {!isReviewing ? (
                        <div
                          className="action-item upload"
                          onClick={() => setOpenModalMedia(true)}
                        >
                          <SVGIcons.PaperClipIcon />
                        </div>
                      ) : (
                        <div className="action-item review-upload">
                          <input
                            type="file"
                            id={idInputFile}
                            onChange={onChangeDeviceUploadMobile}
                            multiple
                            accept=".jpg, .jpeg, .bmp, .gif, .tiff, .png, .mp4, .mov, .avi, .webm"
                            hidden
                          />
                          <Button type="text" className="device-upload-btn">
                            <label htmlFor={idInputFile}>
                              <SVGIcons.PaperClipIcon />
                            </label>
                          </Button>
                        </div>
                      )}
                    </Tooltip>
                  )}
                  <Tooltip title="Add emoji">
                    <div className="action-item emoji">
                      <EmojiPicker onEmojiChange={onEmojiClick} />
                    </div>
                  </Tooltip>
                </div>
              </div>
              {listMedia.length > 0 && (
                <div className="list-media">
                  {listMedia.map((media: any, index: number) => {
                    return (
                      <div className="media" key={media}>
                        <MediaSocial url={media} />
                        <Tooltip title="Remove media">
                          <div
                            className="remove-media"
                            onClick={() => onRemoveMedia(index)}
                          >
                            <SVGIcons.CloseIcon />
                          </div>
                        </Tooltip>
                        <div className="overlay"></div>
                      </div>
                    );
                  })}
                  {!isReviewing ? (
                    <div
                      className="media upload-media"
                      onClick={() => setOpenModalMedia(true)}
                    >
                      <SVGIcons.UploadIcon />
                    </div>
                  ) : (
                    <div className="attachment">
                      <input
                        type="file"
                        id={idInputFile}
                        onChange={onChangeDeviceUploadMobile}
                        multiple
                        accept=".jpg, .jpeg, .bmp, .gif, .tiff, .png, .mp4, .mov, .avi, .webm"
                        hidden
                      />
                      <Button
                        type="text"
                        className="device-upload-btn media upload-media"
                        onClick={() =>
                          document.getElementById(idInputCommentMobile)?.focus()
                        }
                      >
                        <label htmlFor={idInputFile}>
                          <SVGIcons.UploadIcon />
                        </label>
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {showLockComment && (
              <Tooltip
                title={
                  lock
                    ? t("social.approval.approverSeeComment")
                    : t("social.approval.allSeeComment")
                }
              >
                <div
                  className={`action-item action-lock ${
                    lock ? "lock" : "unlock"
                  }`}
                  onClick={handleClickLock}
                >
                  {lock ? <SVGIcons.LockIcon /> : <SVGIcons.LockOpenIcon />}
                </div>
              </Tooltip>
            )}
          </div>

          {showAction && (
            <div className="comment-draft-detail__action">
              <span className="cancel" onClick={onCancel}>
                Cancel
              </span>
              <span className="enter">· Press Enter to post.</span>
            </div>
          )}
        </div>

        {!parentId && (
          <div className="btn-comment-mobile-wrapper">
            <Button
              type="default"
              className="btn-comment-mobile-wrapper-left"
              onClick={onShowCommentMobile}
              disabled={
                !!showCommentMobile && showCommentMobile === contentInfo?.id
              }
            >
              <SVGIcons.CommentIcon /> Comment
            </Button>
            {contentInfo && onChangeStatus && (
              <ApprovalEditStatus
                isMobile
                onChangeStatus={onChangeStatus}
                approvalStatus={approvalStatus!}
                contentId={contentInfo.id}
                exceptStatus={[ApprovalStatusEnum.None]}
              />
            )}
          </div>
        )}

        {showCommentMobile &&
          (showCommentMobile === contentInfo?.id ||
            showCommentMobile === parentId) && (
            <div
              className="comment-draft-detail comment-draft-detail-mobile"
              ref={commentDetailMobileRef}
            >
              <div
                className={`comment-draft-detail__container ${
                  lock ? "lock" : "unlock"
                }`}
              >
                <div
                  className={`comment-draft-detail__editor ${
                    listMedia.length > 0 ? "" : "centered"
                  }`}
                >
                  <div
                    className={`comment ${overflowText ? "overflow" : ""} ${
                      listMedia.length > 0 ? "" : "fullWidth"
                    }`}
                  >
                    <Input.TextArea
                      value={comment}
                      onResize={onResize}
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      placeholder="Your comment here..."
                      onChange={onChangeComment}
                      autoFocus
                      id={idInputCommentMobile}
                    ></Input.TextArea>
                  </div>
                  {listMedia.length > 0 && (
                    <div className="list-media">
                      {listMedia.map((media: any, index: number) => {
                        return (
                          <div className="media" key={media}>
                            <MediaSocial url={media} />
                            <Tooltip title="Remove media">
                              <div
                                className="remove-media"
                                onClick={() => onRemoveMedia(index)}
                              >
                                <SVGIcons.CloseIcon />
                              </div>
                            </Tooltip>
                            <div className="overlay"></div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                {showLockComment && (
                  <Tooltip
                    title={
                      lock
                        ? t("social.approval.approverSeeComment")
                        : t("social.approval.allSeeComment")
                    }
                  >
                    <div
                      className={`action-item action-lock ${
                        lock ? "lock" : "unlock"
                      }`}
                      onClick={handleClickLock}
                    >
                      {lock ? <SVGIcons.LockIcon /> : <SVGIcons.LockOpenIcon />}
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="action">
                <Button type="default" onClick={onCancelDraftMobile}>
                  Cancel
                </Button>
                <div className="action-right">
                  <div className="attachment">
                    <input
                      type="file"
                      id={idInputFile}
                      onChange={onChangeDeviceUploadMobile}
                      multiple
                      accept=".jpg, .jpeg, .bmp, .gif, .tiff, .png, .mp4, .mov, .avi, .webm"
                      hidden
                    />
                    <Button
                      type="text"
                      className="device-upload-btn"
                      onClick={() =>
                        document.getElementById(idInputCommentMobile)?.focus()
                      }
                    >
                      <label htmlFor={idInputFile}>
                        <SVGIcons.PaperClipIcon />
                      </label>
                    </Button>
                  </div>
                  <Button
                    type="primary"
                    onClick={onCreateComment}
                    disabled={!listCommenting.includes(commentingId)}
                    className="post-comment-mobile"
                  >
                    Post
                  </Button>
                </div>
              </div>
            </div>
          )}
      </div>

      <div>
        <AddMediaModal
          addMediaRef={addMediaRef}
          addMediaFooterRef={addMediaFooterRef}
          open={openModalMedia}
          close={() => setOpenModalMedia(false)}
          addMedia={onUpdateMedia}
        />
      </div>

      <Modal
        title="Add Images/Videos"
        open={uploading}
        className="custom-create-modal generate-report-loading"
        footer={null}
        onCancel={() => {
          setUploading(false);
        }}
      >
        <div className="uploading">
          <div className="format">{t("social.content.uploadingText")}</div>
          <div className="scroll-upload">
            <div className="scroll-bar"></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.forwardRef(CommentDraft);
