import { Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import {
  PostTypeModel,
  SocialDateReportModel,
} from "../../../../../../models/social.model";
import { PostTypeDashboardEnum } from "../../../../../../constants/app.enums";
import SocialUtils from "../../../../../../utils/social";
import "./index.scss";
import { useEffect, useMemo, useState } from "react";
import { socialDashboardDateRangeState } from "../../../../../../states/social-dashboard";
import useClient from "../../../../../../hooks/useClient";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../../apis/social.api";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  DATE_TIME_FORMAT,
  DefaultSocialDateReport,
} from "../../../../../../constants/app-constants";
import { NUMBER_UTIL } from "../../../../../../utils/number.utils";
import { ROUTE_PATHS } from "../../../../../../constants/router.constants";
import { renderVolatility } from "../../../../dashboard/site-traffic";

interface TotalEngagementTableProps {
  listPostType: PostTypeModel[];
  loading: boolean;
}

function TotalEngagementTable(props: TotalEngagementTableProps) {
  const { listPostType, loading } = props;
  const { t } = useTranslation();
  const { clientId, getRouter } = useClient();
  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const [loadingListEngagement, setLoadingListEngagement] = useState(false);
  const [totalEngagementReport, setTotalEngagementReport] =
    useState<SocialDateReportModel>(DefaultSocialDateReport);

  const { rows: listEngagement, total, volatility } = totalEngagementReport;

  const columns = [
    {
      title: "POST TYPE",
      dataIndex: "postType",
      key: "postType",
      ellipsis: true,
      width: "50%",
      render: (postType: PostTypeDashboardEnum) => {
        return postType
          ? SocialUtils.createKeyPostType(postType)
          : "Post type name";
      },
    },
    {
      title: "LIKES",
      dataIndex: "likeCount",
      key: "likeCount",
      render: (count: number) =>
        NUMBER_UTIL.convertNumericToFormattedString(count),
    },
    {
      title: "COMMENTS",
      dataIndex: "commentsCount",
      key: "commentsCount",
      render: (count: number) =>
        NUMBER_UTIL.convertNumericToFormattedString(count),
    },
    {
      title: "SHARES",
      dataIndex: "sharesCount",
      key: "sharesCount",
      render: (count: number) =>
        NUMBER_UTIL.convertNumericToFormattedString(count),
    },
    {
      title: "IMPRESSIONS",
      dataIndex: "impressionsCount",
      key: "impressionsCount",
      render: (count: number) =>
        NUMBER_UTIL.convertNumericToFormattedString(count),
    },
    {
      title: "",
      dataIndex: "content",
      key: "content",
      render: (_: any, postTypeData: PostTypeModel) => {
        return postTypeData.postType ? (
          <div
            className="see-content"
            onClick={() => seeContent(postTypeData.postType)}
          >
            See contents
          </div>
        ) : (
          "No contents"
        );
      },
    },
  ];

  const seeContent = (postType: number) => {
    const link = getRouter(ROUTE_PATHS.Social.Content);
    window.open(`${link}?postType=${postType}`);
  };

  const engagementDataChart = useMemo(() => {
    return SocialUtils.createChartDataset(dateRange, listEngagement);
  }, [listEngagement]);

  const options: ApexOptions = {
    chart: {
      id: "total-engagement-chart",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      categories: engagementDataChart.map((x) => x.label),
      labels: {
        show: false,
        format: DATE_TIME_FORMAT.chartDateFormat,
        datetimeUTC: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return NUMBER_UTIL.convertNumericToFormattedString(val);
        },
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
      colors: ["#0185de"],
    },
    fill: {
      colors: ["#0185de"],
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
    legend: {
      show: false,
    },
  };
  const initTotalEngagement = async () => {
    const { startDate, endDate } = dateRange;
    const params = { startDate, endDate, clientId };
    if (startDate && endDate && clientId) {
      setLoadingListEngagement(true);
      try {
        const { data } =
          await SocialApi.SocialDashboard.getTotalEngagementDashboard(params);
        setTotalEngagementReport(data);
      } catch (error) {}
      setLoadingListEngagement(false);
    }
  };

  const series = [
    {
      name: "Total Interactions",
      data: engagementDataChart.map((e) => e.value),
    },
  ];

  const totalEngagement = useMemo(() => {
    return listEngagement.reduce((s, value) => s + value.value, 0);
  }, [listEngagement]);

  useEffect(() => {
    initTotalEngagement();
  }, [dateRange]);

  return (
    <div className="rl-card">
      <p className="rl-card-title">
        <span>{t("dashboard.totalEngagement")}</span>
        <span>{dateRange.totalDays}d</span>
      </p>
      <span className="rl-card-count">
        <span className="total">
          {NUMBER_UTIL.convertNumericToFormattedString(total)}
        </span>
        <span className="volatility">{renderVolatility(volatility)}</span>
      </span>
      <div className="total-engagement-table">
        <div className="total-engagement-chart">
          {!!listEngagement.length ? (
            <Spin spinning={loadingListEngagement}>
              <Chart
                options={options}
                series={series}
                type="area"
                width={"100%"}
                height={120}
              />
            </Spin>
          ) : (
            ""
          )}
        </div>
        <Table
          rowKey="key"
          dataSource={listPostType}
          columns={columns}
          pagination={false}
          bordered={false}
          tableLayout="auto"
          loading={loading}
        />
      </div>
    </div>
  );
}

export default TotalEngagementTable;
