import { AxiosResponse } from "axios";
import {
  ConnectionRequestModel,
  DashBoardRequestModel,
  GaAddConnectionRequest,
  GaAddIntegrationRequest,
  GaConnectionModel,
  GaLocationConnectionModel,
  GaPropertyConnectionModel,
  GaReAuthenticateConnectionRequest,
  GaTestIntegrationRequest,
  GaUpdateIntegrationRequest,
  GMBAddIntegrationRequest,
  GMBTestIntegrationRequest,
  GMBUpdateIntegrationRequest,
} from "../models/ga.model";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/gglanalytics/api/v1`;

function testIntegration(request: GaTestIntegrationRequest) {
  const url = `${baseUrl}/integration/connect`;
  return postAsync(url, request, false, false);
}

function getGeneralData(request: DashBoardRequestModel) {
  const url = `${baseUrl}/home/general`;
  return getAsync(url, request);
}

function getGLData(request: DashBoardRequestModel) {
  const url = `${baseUrl}/home/gmb-report`;
  return getAsync(url, request);
}

function getTrafficChanel(request: DashBoardRequestModel) {
  const url = `${baseUrl}/home/traffic-chanel`;
  return getAsync(url, request);
}

function getTotalLeads(request: DashBoardRequestModel) {
  const url = `${baseUrl}/home/total-leads`;
  return getAsync(url, request);
}

function removeIntegration(clientId: number) {
  const url = `${baseUrl}/integration/${clientId}`;
  return deleteAsync(url, false, false);
}

function addIntegration(request: GaAddIntegrationRequest) {
  const url = `${baseUrl}/integration`;
  return postAsync(url, request, false, false);
}

function updateIntegration(request: GaUpdateIntegrationRequest) {
  const url = `${baseUrl}/integration`;
  return putAsync(url, request, false, false);
}

function addConnection(request: GaAddConnectionRequest) {
  const url = `${baseUrl}/connection`;
  return postAsync(url, request);
}

function getGoogleConnections(request: ConnectionRequestModel): Promise<AxiosResponse<GaConnectionModel[], any>> {
  const url = `${baseUrl}/connection`;
  return getAsync(url, request);
}

function adminGetGoogleConnections(request: ConnectionRequestModel): Promise<AxiosResponse<GaConnectionModel[], any>> {
  const url = `${baseUrl}/connection/admin`;
  return getAsync(url, request);
}

function reAuthenConnection(
  request: GaReAuthenticateConnectionRequest
): Promise<AxiosResponse<GaConnectionModel[], any>> {
  const url = `${baseUrl}/connection/re-authenticate`;
  return putAsync(url, request);
}

function syncProperties(id: number) {
  const url = `${baseUrl}/connection/${id}/sync-properties`;
  return postAsync(url);
}

function syncLocations(id: number) {
  const url = `${baseUrl}/connection/${id}/sync-locations`;
  return postAsync(url);
}

function testGMBIntegration(request: GMBTestIntegrationRequest) {
  const url = `${baseUrl}/gmbintegration/connect`;
  return postAsync(url, request, false, false);
}

function removeGMBIntegration(clientId: number) {
  const url = `${baseUrl}/gmbintegration/${clientId}`;
  return deleteAsync(url, false, false);
}

function addGMBIntegration(request: GMBAddIntegrationRequest) {
  const url = `${baseUrl}/gmbintegration`;
  return postAsync(url, request, false, false);
}

function updateGMBIntegration(request: GMBUpdateIntegrationRequest) {
  const url = `${baseUrl}/gmbintegration`;
  return putAsync(url, request, false, false);
}

function getProperties(id: number): Promise<AxiosResponse<GaPropertyConnectionModel[], any>> {
  const url = `${baseUrl}/connection/${id}/properties`;;
  return getAsync(url);
}

function getLocations(id: number): Promise<AxiosResponse<GaLocationConnectionModel[], any>> {
  const url = `${baseUrl}/connection/${id}/locations`;;
  return getAsync(url);
}

export const GaApi = {
  testIntegration,
  getGeneralData,
  getTrafficChanel,
  getTotalLeads,
  getGLData,
  removeIntegration,
  addIntegration,
  updateIntegration,
  addConnection,
  getGoogleConnections,
  adminGetGoogleConnections,
  reAuthenConnection,
  syncProperties,
  syncLocations,
  testGMBIntegration,
  removeGMBIntegration,
  addGMBIntegration,
  updateGMBIntegration,
  getProperties,
  getLocations
};
