import { onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { NotificationApi } from "../../../apis/notification.api";
import { NotificationDeviceType } from "../../../constants/app.enums";
import { getMessagingToken, messaging } from "../../../firebase";
import useService from "../../../hooks/useService";
import { authState } from "../../../states/auth";
import { triggerFetchProfilesState } from "../../../states/social";
import showNotification from "../../common/notification";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const AppNotificationWrapper = (props: ProtectedRouteProps) => {
  const { NotificationService } = useService();
  const [fetchProfilesState, setFetchProfilesState] = useRecoilState(
    triggerFetchProfilesState
  );
  const { isLogined, user } = useRecoilValue(authState);

  async function registerToken() {
    const token = await getMessagingToken();

    if (token) {
      NotificationApi.registerToken({
        token,
        notificationDeviceType: NotificationDeviceType.Web,
      });
    }
  }

  const initListNotification = async () => {
    await NotificationService.getListNotifications();
  };

  useEffect(() => {
    onMessage(messaging, (payload: any) => {
      if (
        payload.data.title.includes(
          "social profiles were successfully connected."
        )
      ) {
        showNotification("success", payload.data.title);
        setFetchProfilesState({
          fetchCount: fetchProfilesState.fetchCount + 1,
        });
      }
      payload.data &&
        NotificationService.addNewNotification({
          body: payload.data.body,
          title: payload.data.title,
          url: payload.data.url,
          adminUrl: payload.data.adminUrl,
          id: parseInt(payload.data.id),
          created: payload.data.created,
          isRead: false,
          type: parseInt(payload.data.type),
          actionType: parseInt(payload.data.actionType),
          actionDate: payload.data.actionDate,
        });
    });
  });

  useEffect(() => {
    if (isLogined) {
      registerToken();
      initListNotification();
    }
  }, [isLogined]);

  return <> {props.children}</>;
};

export default AppNotificationWrapper;
