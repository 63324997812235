import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import { DATE_TIME_FORMAT } from "../../../../../constants/app-constants";
import { formsFullDateSelector } from "../../../../../states/dashboard";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import "./index.scss";

function FormSubmissionChart() {
  const forms = useRecoilValue(formsFullDateSelector);

  const series = [
    {
      name: "Form Submission",
      data: forms.map((x) => x.value),
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: "formSubmissionChart",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      categories: forms.map((x) => x.label),
      labels: {
        show: false,
        datetimeUTC: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return NUMBER_UTIL.convertNumericToFormattedString(val);
        },
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    fill: {
      colors: ["rgba(32, 107, 196, 0.16)"],
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  };

  return (
    <div className="form-submission-chart">
      <Chart
        options={options}
        series={series}
        type="area"
        width={"100%"}
        height={100}
      />
    </div>
  );
}

export default memo(FormSubmissionChart);
