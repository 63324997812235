import { Col, Progress, Row, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import "./index.scss";
import PostTypeDistributionChart from "../../../dashboard/charts/social-dashboard-chart/post-type-distribution";
import TotalEngagementTable from "./total-engagement-table";
import { useEffect, useState } from "react";
import { socialDashboardDateRangeState } from "../../../../../states/social-dashboard";
import useClient from "../../../../../hooks/useClient";
import { SocialApi } from "../../../../../apis/social.api";
import SocialUtils from "../../../../../utils/social";
import { PostTypeModel } from "../../../../../models/social.model";
import { PostTypeDashboardEnum } from "../../../../../constants/app.enums";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import BestTimeToPost from "./best-time-to-post";

const emptyData = [
  {
    postType: 0,
    totalInteractions: 0,
    commentsCount: 0,
    impressionsCount: 0,
    likeCount: 0,
    postsCount: 0,
    sharesCount: 0,
  },
  {
    postType: 0,
    totalInteractions: 0,
    commentsCount: 0,
    impressionsCount: 0,
    likeCount: 0,
    postsCount: 0,
    sharesCount: 0,
  },
  {
    postType: 0,
    totalInteractions: 0,
    commentsCount: 0,
    impressionsCount: 0,
    likeCount: 0,
    postsCount: 0,
    sharesCount: 0,
  },
  {
    postType: 0,
    totalInteractions: 0,
    commentsCount: 0,
    impressionsCount: 0,
    likeCount: 0,
    postsCount: 0,
    sharesCount: 0,
  },
];

function DashboardContent() {
  const { t } = useTranslation();
  const { clientId } = useClient();
  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const [loadingPostType, setLoadingPostType] = useState(false);
  const [listPostType, setListPostType] = useState<PostTypeModel[]>([]);

  const listPostTypeData = [...listPostType, ...emptyData];

  const columns = [
    {
      title: "POST TYPE NAME",
      dataIndex: "postType",
      key: "postType",
      width: "40%",
      ellipsis: true,
      render: (postType: PostTypeDashboardEnum) => {
        return postType
          ? SocialUtils.createKeyPostType(postType)
          : "Post type name";
      },
    },
    {
      title: "ENGAGEMENT",
      dataIndex: "totalInteractions",
      key: "totalInteractions",
      width: "30%",
      render: (totalInteractions: number) => (
        <span>
          {NUMBER_UTIL.convertNumericToFormattedString(totalInteractions)}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "progress",
      key: "progress",
      width: "30%",
      render: (_: any, postData: PostTypeModel) => {
        const { totalInteractions } = postData;
        const maxInteractions = listPostTypeData.length
          ? Math.max(...listPostTypeData.map((p) => p.totalInteractions))
          : 0;
        return (
          <Progress
            percent={
              maxInteractions ? (totalInteractions / maxInteractions) * 100 : 0
            }
            showInfo={false}
            strokeColor="#0185de"
          />
        );
      },
    },
  ];

  const initPostType = async () => {
    const { startDate, endDate } = dateRange;
    const params = { startDate, endDate, clientId };
    if (startDate && endDate && clientId) {
      setLoadingPostType(true);
      try {
        const { data } = await SocialApi.SocialDashboard.getPostTypeDashboard(
          params
        );
        setListPostType(data);
      } catch (error) {}
      setLoadingPostType(false);
    }
  };

  useEffect(() => {
    initPostType();
  }, [dateRange]);

  return (
    <div className="social-dashboard-content">
      <div className="home-page__session">
        <Row gutter={16}>
          <Col xs={24} sm={12} xl={12} className="top-engaged">
            <div className="rl-card">
              <p className="rl-card-title">
                <span>{t("dashboard.topEngagedPostTypes")}</span>
                <span>{dateRange.totalDays}d</span>
              </p>

              <div className="top-engaged-table">
                <Table
                  rowKey="key"
                  dataSource={listPostTypeData.slice(0, 3)}
                  columns={columns}
                  pagination={false}
                  bordered={false}
                  loading={loadingPostType}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} xl={12}>
            <div className="rl-card">
              <p className="rl-card-title mr-bottom-24">
                <span>{t("dashboard.postTypeDistribution")}</span>
                <span>{dateRange.totalDays}d</span>
              </p>
              <PostTypeDistributionChart
                chartId="postTypeDistribution"
                listPostType={listPostTypeData.slice(0, 5)}
                loading={loadingPostType}
                isEmpty={listPostType.length === 0}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="home-page__session">
        <BestTimeToPost />
      </div>

      <div className="home-page__session">
        <TotalEngagementTable
          listPostType={listPostTypeData.slice(0, 5)}
          loading={loadingPostType}
        />
      </div>
    </div>
  );
}

export default DashboardContent;
