import { Spin } from "antd";
import { ApexOptions } from "apexcharts";
import { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../../apis/social.api";
import useClient from "../../../../../../hooks/useClient";
import {
  TimePostModel,
  WeekPostModel,
} from "../../../../../../models/social.model";
import { socialDashboardDateRangeState } from "../../../../../../states/social-dashboard";
import { NUMBER_UTIL } from "../../../../../../utils/number.utils";
import "./index.scss";

const defaultInteractionTime = [
  { x: "12 am", y: 0 },
  { x: "1 am", y: 0 },
  { x: "2 am", y: 0 },
  { x: "3 am", y: 0 },
  { x: "4 am", y: 0 },
  { x: "5 am", y: 0 },
  { x: "6 am", y: 0 },
  { x: "7 am", y: 0 },
  { x: "8 am", y: 0 },
  { x: "9 am", y: 0 },
  { x: "10 am", y: 0 },
  { x: "11 am", y: 0 },
  { x: "12 pm", y: 0 },
  { x: "1 pm", y: 0 },
  { x: "2 pm", y: 0 },
  { x: "3 pm", y: 0 },
  { x: "4 pm", y: 0 },
  { x: "5 pm", y: 0 },
  { x: "6 pm", y: 0 },
  { x: "7 pm", y: 0 },
  { x: "8 pm", y: 0 },
  { x: "9 pm", y: 0 },
  { x: "10 pm", y: 0 },
  { x: "11 pm", y: 0 },
];
const defaultInteractionWeek = [
  { dayOfWeek: "Monday", totalInteractions: 0 },
  { dayOfWeek: "Tuesday", totalInteractions: 0 },
  { dayOfWeek: "Wednesday", totalInteractions: 0 },
  { dayOfWeek: "Thursday", totalInteractions: 0 },
  { dayOfWeek: "Friday", totalInteractions: 0 },
  { dayOfWeek: "Saturday", totalInteractions: 0 },
  { dayOfWeek: "Sunday", totalInteractions: 0 },
];
const BestTimeToPost = () => {
  const { t } = useTranslation();
  const { clientId } = useClient();
  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const [loadingBestTime, setLoadingBestTime] = useState(false);
  const [bestTimeData, setBestTimeData] = useState<TimePostModel[]>([]);
  const [loadingWeekPost, setLoadingWeekPost] = useState(false);
  const [weekPostData, setWeekPostData] = useState<WeekPostModel[]>([]);

  const dataChart = useMemo(() => {
    return defaultInteractionTime.map((value, index) => {
      const interaction = bestTimeData.find((i) => i.postHour === index);
      return interaction
        ? {
            y: interaction.totalInteractions,
            x: value.x,
            strokeColor: "#0185de",
            fillColor: "#0185de",
          }
        : value;
    });
  }, [bestTimeData]);

  const series = [
    {
      name: "ENGAGEMENT",
      data: dataChart,
    },
  ];

  const weekPostInteraction = useMemo(() => {
    return defaultInteractionWeek.map((value) => {
      const interactionWeek = weekPostData.find(
        (i) => i.dayOfWeek === value.dayOfWeek
      );
      return interactionWeek
        ? { ...value, totalInteractions: interactionWeek.totalInteractions }
        : value;
    });
  }, [weekPostData]);

  const options: ApexOptions = {
    chart: {
      id: "best-time-to-post-chart",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: defaultInteractionTime.map((d) => d.x),
    },
    fill: {
      colors: ["#ffffff"],
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {},
    },
  };

  const renderBestTime = () => {
    const maxWeekPost = weekPostData.reduce((result: WeekPostModel, value) => {
      return result.totalInteractions < value.totalInteractions
        ? value
        : result;
    }, weekPostData[0]);
    const maxInteractionTimeIndex = dataChart.reduce(
      (indexResult: number, item, index) => {
        return dataChart[indexResult].y < item.y ? index : indexResult;
      },
      0
    );
    return (
      <span>
        Best time to post is <b>{maxWeekPost?.dayOfWeek}</b> from{" "}
        <b>
          {defaultInteractionTime[(maxInteractionTimeIndex - 1 + 24) % 24].x}
        </b>{" "}
        to <b>{defaultInteractionTime[(maxInteractionTimeIndex + 1) % 24].x}</b>
      </span>
    );
  };

  const initBestTimePost = async () => {
    const { startDate, endDate } = dateRange;
    const params = { startDate, endDate, clientId };
    if (startDate && endDate && clientId) {
      setLoadingBestTime(true);
      try {
        const { data } = await SocialApi.SocialDashboard.getTimePostDashboard(
          params
        );
        setBestTimeData(data);
      } catch (error) {}
      setLoadingBestTime(false);
    }
  };

  const initWeekPost = async () => {
    const { startDate, endDate } = dateRange;
    const params = { startDate, endDate, clientId };
    if (startDate && endDate && clientId) {
      setLoadingWeekPost(true);
      try {
        const { data } = await SocialApi.SocialDashboard.getWeekPostDashboard(
          params
        );
        setWeekPostData(data);
      } catch (error) {}
      setLoadingWeekPost(false);
    }
  };

  useEffect(() => {
    initBestTimePost();
    initWeekPost();
  }, [dateRange]);

  return (
    <div className="rl-card">
      <p className="rl-card-title">
        <span>{t("dashboard.bestTimeToPost")}</span>
        <span>{dateRange.totalDays}d</span>
      </p>
      {!bestTimeData.length ? (
        <p>Data is not available yet</p>
      ) : (
        renderBestTime()
      )}
      <div className={"best-time-to-post"}>
        <div className={"best-time-to-post__chart"}>
          <Spin spinning={loadingBestTime}>
            <Chart
              options={options}
              series={series}
              type="bar"
              width={"100%"}
              height={293}
            />
          </Spin>
        </div>
        <div className="best-time-to-post__legend">
          <Spin spinning={loadingWeekPost}>
            <div className="best-time-to-post__legend-items">
              {weekPostInteraction.map((i) => {
                return (
                  <div className="legend-item" key={i.dayOfWeek}>
                    <span className="legend-item__label">{i.dayOfWeek}</span>
                    <span className="legend-item__value">
                      {NUMBER_UTIL.convertNumericToFormattedString(
                        i.totalInteractions
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default BestTimeToPost;
