import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { TimezonesApi } from "../apis/timezones-api";
import { timezonesState } from "../states/timezones";

let isFetchTimezonesLoading = false;
function useFetchTimezones() {
    const [timezones, setTimezones] = useRecoilState(timezonesState);

    useEffect(() => {
        if (!timezones.records || !timezones.records.length) {
            fetchData();
        }
    }, []);

    async function fetchData() {
        if (isFetchTimezonesLoading) {
            return;
        }
        isFetchTimezonesLoading = true;

        try {

            const { data } = await TimezonesApi.getTimezones();
            setTimezones({
                ...timezones,
                records: data
            });
        } catch { }

        isFetchTimezonesLoading = false;
    }

    return {
        ...timezones, 
        loading: isFetchTimezonesLoading
    };
}

export default useFetchTimezones;
