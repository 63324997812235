import { Button, Form, Input, Modal, Select, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { AuthApi } from "../../../../apis/auth.api";
import {
  CLIENT_CETEGORIES,
  COUNTRIES_STATES,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import useClient from "../../../../hooks/useClient";
import useUnsaveChange from "../../../../hooks/useUnsaveChange";
import { StateModel } from "../../../../models/state.model";
import AppAvatar from "../../../common/app-avatar/app-avatar";
import showNotification from "../../../common/notification";
import AppSelectCountry from "../../../controls/app-select-country";
import AppSelectTimezone from "../../../controls/app-select-timezone";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import { timezonesState } from "../../../../states/timezones";
import { useRecoilState, useRecoilValue } from "recoil";

function GeneralSettingCompany() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { client, setClient } = useClient();
  const [loading, setLoading] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [imgSource, setImgSource] = useState("");
  const [imgBinary, setImgBinary] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [countryWithStates, setCountryWithStates] = useState<StateModel[]>([])
  const timezones = useRecoilValue(timezonesState);

  useUnsaveChange(isEditting);

  const profilePictureUrl = client?.profilePictureUrl;

  const submit = async (values: any) => {
    updateData(values);
  };

  async function updateData(values: any) {
    if (!client?.id) return;
    setLoading(true);
    try {

      const requestModel = {
        ...values,
        profilePicture: imgBinary
      };

      const { data } = await AuthApi.updateClient(client?.id, requestModel);
      if (client) {
        const timezoneOffset = timezones.records.find(x => x.id === values.timeZone)?.offSet

        setClient((prevClient) => ({
          ...prevClient,
          timeZoneInfo: {
            ...prevClient?.timeZoneInfo,
            id: values.timeZone,
            offSet: timezoneOffset
          },
          ...values,
          profilePictureUrl: data.profilePictureUrl,
        }));

        showNotification("success", t("generalSettings.updateAccountSuccess"));
        setIsEditting(false);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  const onChangePicture = async (fileInfo: any) => {
    setLoadingAvatar(true);
    try {
      const { file } = fileInfo;
      setImgSource(URL.createObjectURL(file.originFileObj));
      setImgBinary(await file.originFileObj);
    } catch (error) { }
    setLoadingAvatar(false);
  };

  const onValuesChange = (values: any) => {
    setIsEditting(true);
  };

  useEffect(() => {
    if (client) {
      form.setFieldsValue({
        ...client,
        websiteUrl: client.websiteUrl,
      });
      getStatesByCountry(client.country)
    }
    setNewEmail(newEmail);
  }, [client]);


  const getStatesByCountry = (country: string) => {
    const state = form.getFieldValue("state")

    if (!country) {
      setCountryWithStates([]);
      form.setFieldValue("state", '');
      return;
    }

    const selectedCountry = COUNTRIES_STATES.find(x => x.code === country) || { states: [] };
    const { states } = selectedCountry;

    setCountryWithStates(states);
    const countryState = states.find(x => x.value === state);
    const newStateValue = countryState ? countryState.value : '';

    form.setFieldValue("state", newStateValue);

    form.setFieldValue("country", country)
  }


  return (
    <div className="general-setting-account">
      <Form
        name="general-setting-account"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 10 }}
        labelAlign="left"
        form={form}
        onFinish={submit}
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onValuesChange={onValuesChange}
        colon={false}
      >
        <Form.Item
          label={
            <>
              <div>{t("generalSettings.companyLogo")}</div>
              <div className="format-picture">Format: JPG, JPEG, PNG</div>
              <div className="format-picture">Maximum 250 x 250px</div>
            </>
          }
          colon={false}
          className="label-avatar"
        >
          <div className="avatar">
            <Spin spinning={loadingAvatar}>
              <AppAvatar
                size={100}
                shape="square"
                src={imgSource || profilePictureUrl}
              ></AppAvatar>
            </Spin>
            <div className="btn-edit-avatar">
              <Upload
                onChange={onChangePicture}
                fileList={[]}
                customRequest={() => { }}
                accept=".jpg, .jpeg, .png, .svg"
              >
                <SVGIcons.EditIcon />
              </Upload>
            </div>
          </div>
        </Form.Item>

        <Form.Item
          label={t("signUp.companyName")}
          name="companyName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("users.businessPhone")}
          name="businessPhone"
          rules={[{ required: true }]}
        >
          <PhoneInput country={"us"} enableSearch={true} />
        </Form.Item>


        <Form.Item
          label={t("clientSites.industry")}
          name="category"
          rules={[{ required: true }]}
        >
          <Select showSearch optionFilterProp="children">
            {CLIENT_CETEGORIES.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("signUp.websiteURL")}
          name="websiteUrl"
          rules={[{ required: true }, { type: "url" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("generalSettings.timeZone")}
          name="timeZone"
          rules={[{ required: true }]}
        >
          <AppSelectTimezone
            onChange={(value: string) => form.setFieldValue("timeZone", value)}
          />
        </Form.Item>

        <Form.Item
          label={t("generalSettings.country")}
          name="country"
        >
          <AppSelectCountry
            onChange={(value: string) => {
              getStatesByCountry(value)
            }}
          />
        </Form.Item>

        <Form.Item
          label={t("clientSites.streetAddress")}
          name="streetAddress"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("clientSites.city")}
          name="city">
          <Input />
        </Form.Item>

        <Form.Item label={t("clientSites.state")}
          name="state">
          <Select disabled={countryWithStates.length === 0} >
            {countryWithStates.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.value} - {x.name}
              </Select.Option>
            ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          label={t("clientSites.zipCode")}
          name="zipCode"
        >
          <Input />
        </Form.Item>

        <div className="group-btn-account">
          <Button
            htmlType="submit"
            type="primary"
            loading={!openConfirmModal && loading}
          >
            {t("common.saveChanges")}
          </Button>
        </div>
      </Form>

      <Modal
        title={t("generalSettings.confirmChangeEmailTitle")}
        cancelText={t("common.cancel")}
        okText={t("generalSettings.confirmChangeEmailSubmitButton")}
        open={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onOk={() => updateData(form.getFieldsValue())}
        confirmLoading={loading}
      >
        <p>{t("generalSettings.confirmChangeEmailDescription")}</p>
      </Modal>
    </div>
  );
}

export default GeneralSettingCompany;
