import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthApi } from "../apis/auth.api";
import { PermissionSocial } from "../constants/app.enums";
import {
  AdminViewSitePrefixRouter,
  ROUTE_PATHS,
} from "../constants/router.constants";
import { adminClientSiteSelector } from "../states/admin";
import {
  authClientSelector,
  authIsSupperAdminSelector,
  authState,
} from "../states/auth";
import { CommonUtils } from "../utils/common";
import DateUtils from "../utils/date.utils";
import useAdminSite from "./useAdminSite";

let loading = false;
function useClient() {
  const { pathname } = useLocation();
  const isAdmin = useAdminSite();
  const { isLogined, user } = useRecoilValue(authState);
  const isAdminViewSite = pathname.startsWith(AdminViewSitePrefixRouter);
  const isSupperAdmin = useRecoilValue(authIsSupperAdminSelector);
  const [clientWithCurrentUser, setClientWithCurrentUser] =
    useRecoilState(authClientSelector);
  const [clientWithAdminView, setClientWithAdminView] = useRecoilState(
    adminClientSiteSelector
  );
  const client = isAdminViewSite ? clientWithAdminView : clientWithCurrentUser;
  const setClient = isAdminViewSite
    ? setClientWithAdminView
    : setClientWithCurrentUser;
  const { id } = useParams();
  const navigate = useNavigate();

  function getRouter(path: string) {
    return isAdminViewSite
      ? CommonUtils.getAdminViewClientSiteRouter(path, Number(id))
      : path;
  }

  function isOwner(): boolean {
    if (isSupperAdmin || isAdminViewSite) return true;
    return !!user?.email && clientWithCurrentUser?.email === user?.email;
  }

  function hasPermissionSocial(permission?: PermissionSocial): boolean {
    if (isOwner()) return true;
    if (client?.canApproveContent && client?.canManageContent) return true;
    switch (permission) {
      case PermissionSocial.CanApprove:
        return !!client?.canApproveContent;
      case PermissionSocial.CanManage:
        return !!client?.canManageContent;
      default:
        return false;
    }
  }

  const checkSelectedClient = () => {
    if (isAdmin) return isLogined;
    return isLogined && !!user?.clientSelected;
  };

  function onlyHasPermissionSocial(permission: PermissionSocial): boolean {
    if (!isLogined) return false;
    switch (permission) {
      case PermissionSocial.CanApprove:
        return !hasPermissionSocial(PermissionSocial.CanManage);
      case PermissionSocial.CanManage:
        return !hasPermissionSocial(PermissionSocial.CanApprove);
      default:
        return false;
    }
  }

  const timezoneId = client?.timeZoneInfo?.id || DateUtils.getCurrentTimezone();

  useEffect(() => {
    if (isAdminViewSite && !loading && !client && id) {
      getClient();
    }
  }, []);

  async function getClient() {
    loading = true;
    try {
      const { data } = await AuthApi.getClientInfo(Number(id));
      setClient(data);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        navigate(ROUTE_PATHS.AdminNotFound);
      }
    }
    loading = false;
  }

  function isExternalUser(): boolean {
    return !isAdmin && !!user?.email && clientWithCurrentUser?.email !== user.email;
  }

  return {
    client,
    getRouter,
    setClient,
    isAdminViewSite,
    clientLoading: loading,
    clientId: isAdminViewSite ? Number(id) : client?.id,
    hasPermissionSocial,
    onlyHasPermissionSocial,
    isOwner,
    isExternalUser,
    checkSelectedClient,
    timezoneId,
  };
}

export default useClient;
