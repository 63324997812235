import { AxiosResponse } from "axios";
import { ListDataResponse } from "../models/common.model";
import { GenerateReportRequestModel, GetReportsRequest, SendEmailReportsRequest, UpdatedReportRequestModel } from "../models/report.model";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth/api/v1`;

function getReports(
  request: GetReportsRequest
): Promise<AxiosResponse<ListDataResponse<GenerateReportRequestModel>, any>> {
  const url = `${baseUrl}/report`;
  return getAsync(url, request);
}

function generateReport(model: GenerateReportRequestModel) {
  const url = `${baseUrl}/report`;
  return postAsync(url, model);
}

function updateReport(model: UpdatedReportRequestModel) {
  const url = `${baseUrl}/report`;
  return putAsync(url, model);
}

function deleteReport(id: string) {
  const url = `${baseUrl}/report/${id}`;
  return deleteAsync(url, false, false);
}

function sendEmail(model: SendEmailReportsRequest) {
  const url = `${baseUrl}/report/send-email`;
  return postAsync(url, model);
}

function getViewEmailContent(id: string) {
  const url = `${baseUrl}/report/${id}/view-email-content`;
  return getAsync(url);
}

export const ReportApi = {
  generateReport,
  updateReport,
  deleteReport,
  getReports,
  sendEmail,
  getViewEmailContent
};
