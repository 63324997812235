import { Col, Progress, Rate, Row } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import CitySearchImage from "../../../../assets/images/citysearch.png";
import FacebookImage from "../../../../assets/images/facebook.png";
import GMBImage from "../../../../assets/images/listing-icon-gg.png";
import BingImage from "../../../../assets/images/listing-icon-bing.png";
import YelpImage from "../../../../assets/images/listing-icon-yelp.png";
import AppleImage from "../../../../assets/images/listing-icon-apple.png";
import { REVIEW_NETWORK_DEFAULT_NAME } from "../../../../constants/app-constants";
import {
  dasboardDateRangeState,
  dashboardBirdeyeState,
  reviewBarChartSelector,
  topNetworksSelector,
} from "../../../../states/dashboard";
import SpinDiv from "../../../common/spin-div";
import SVGIcons from "../../../icons/svgs";
import BarChart from "../charts/bar";
import "./index.scss";
import { ColorTag } from "../../../../constants/app.enums";

function ReviewsAndRatings() {
  const { t } = useTranslation();
  const dateRange = useRecoilValue(dasboardDateRangeState);
  const { data, loading } = useRecoilValue(dashboardBirdeyeState);
  const beReport = data;
  const topNetworks = useRecoilValue(topNetworksSelector);
  const reviewBarChartData = useRecoilValue(reviewBarChartSelector);

  const renderIcon = useCallback(
    (sourceType: string) => {
      switch (sourceType) {
        case "Google":
          return <img src={GMBImage} />;
        case "Facebook":
          return <img src={FacebookImage} />;
        case "Bing":
          return <img src={BingImage} />;
        case "Yelp":
          return <img src={YelpImage} />;
        case "Apple":
          return <img src={AppleImage} />;
        default:
          const listColor = Object.values(ColorTag);
          return (
            <div
              className="home-reviews-and-ratings__review__item__emptyImage"
              style={{
                backgroundColor:
                  listColor[Math.floor(Math.random() * listColor.length)],
              }}
            >
              {sourceType.slice(0, 1)}
            </div>
          );
      }
    },
    [beReport]
  );

  return (
    <div className="home-reviews-and-ratings">
      <Row gutter={16}>
        <Col xs={24} sm={12} className="mobile-mr-bottom-20">
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.reviewSummary")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <SpinDiv loading={loading} style={{ height: 170 }}>
              <>
                <div className="home-reviews-and-ratings__sumValue">
                  <p>
                    {beReport.reivewSummary.avgRating
                      ? beReport.reivewSummary.avgRating.toFixed(1)
                      : "0"}
                  </p>
                  <div>
                    <Rate
                      allowHalf
                      value={beReport.reivewSummary.avgRating}
                      disabled
                    />
                    <span>{beReport.reivewSummary.totalReview} Reviews</span>
                  </div>
                </div>

                <Row gutter={16}>
                  <Col xs={24} sm={10}>
                    {beReport.reivewSummary?.ratings.map((item) => (
                      <div
                        className="home-reviews-and-ratings__item"
                        key={item.rating}
                      >
                        <span>{item.rating}</span>
                        <Rate
                          defaultValue={1}
                          count={1}
                          disabled
                          style={{
                            fontSize: 12,
                            color: "#9b9b9b",
                            marginTop: 3,
                            marginRight: 5,
                          }}
                        />
                        <Progress
                          size={"small"}
                          percent={
                            (item.review /
                              (beReport.reivewSummary?.totalReview || 1)) *
                            100
                          }
                          showInfo={false}
                        />
                        <span>{item.review}</span>
                      </div>
                    ))}
                  </Col>
                  <Col xs={24} sm={14}>
                    <BarChart
                      data={reviewBarChartData}
                      chartId="reviewsChart"
                      height={120}
                      title={"review"}
                    />
                  </Col>
                </Row>
              </>
            </SpinDiv>
          </div>
        </Col>

        <Col xs={24} sm={12}>
          <div className="rl-card" style={{ height: "100%" }}>
            <p className="rl-card-title">
              <span>{t("dashboard.topReviewNetwork")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <SpinDiv loading={loading} style={{ height: 170 }}>
              <div className="home-reviews-and-ratings__review">
                {topNetworks.map((item, index) => (
                  <div
                    className="home-reviews-and-ratings__review__item"
                    key={index}
                  >
                    {item.name === REVIEW_NETWORK_DEFAULT_NAME ? (
                      <div className="home-reviews-and-ratings__review__item__emptyImage"></div>
                    ) : (
                      renderIcon(item.name)
                    )}
                    <span className="home-reviews-and-ratings__review__item__name">
                      {item.name}
                    </span>
                    <span className="home-reviews-and-ratings__review__item__value">{`${item.review} Reviews`}</span>
                    <div className="home-reviews-and-ratings__review__item__like">
                      <span>
                        <SVGIcons.ThumbUpIcon />
                        <span>{item.positive}</span>
                      </span>
                      <span>
                        <SVGIcons.ThumbDownIcon />
                        <span>{item.negative}</span>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </SpinDiv>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ReviewsAndRatings;
