import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import backgroundImage from "../../../assets/images/auth-background.png";
import backgroundSignupLeftImage from "../../../assets/images/bg-img-left.png";
import whiteLogo from "../../../assets/images/logo-horizontal.svg";
import logo from "../../../assets/images/logo.svg";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useAdminSite from "../../../hooks/useAdminSite";
import useClient from "../../../hooks/useClient";
import useClientPlan from "../../../hooks/useClientPlan";
import DateUtils from "../../../utils/date.utils";
import LimitedModal from "../countdown/limited-modal";
import UpgradeModal from "../countdown/upgrade-modal";
import "./index.scss";

function AuthLayout() {
  const { pathname } = useLocation();
  const isAdmin = useAdminSite();
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 768;

  const isSignUpPage = pathname === ROUTE_PATHS.SignUp && !isMobile;
  const {setExpiredPlan,  isShowUpgradeModal, isShowLimitedTrialPlanModal, resetShowLimitedModal, resetShowUpgradeModal, isPlanExpired} = useClientPlan()
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenLimited, setIsOpenLimited] = useState<boolean>(false);

  const { client } = useClient();
  const checkExpiredPlan = () => {
    const currentDate = moment();
    const expiredTime = moment(DateUtils.addUTCCharater(client?.userPlan?.expiredTime))

    if (expiredTime < currentDate) {
      setExpiredPlan()
    }
  };

  useEffect(() => {
    if (isShowUpgradeModal && isPlanExpired) {
      setIsOpen(true)
      resetShowUpgradeModal()
     
    }
  }, [isShowUpgradeModal]);
  useEffect(() => {
    if (isShowLimitedTrialPlanModal) {
      setIsOpenLimited(true)
      resetShowLimitedModal()
    }
  }, [isShowLimitedTrialPlanModal]);

  useEffect(()=> {
    checkExpiredPlan()
  }, [])


  return (
    <div className="app-layout-wrap">
      <UpgradeModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <LimitedModal isOpen={isOpenLimited} setIsOpen={setIsOpenLimited} />
      {isSignUpPage && (
        <div
          className="bg-left"
          style={{ backgroundImage: `url(${backgroundSignupLeftImage})` }}
        >
          <img src={logo} className="auth-layout__logo" alt="RocketLevel" />
          <div>Take Your Business</div>
          <div>To The Next Level</div>
        </div>
      )}
      <div
        className="auth-layout"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="auth-layout__formContent">
          {!isSignUpPage && (
            <div className="auth-layout__logo_wrapper">
              <Link to={isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn}>
                <img
                  src={whiteLogo}
                  className="auth-layout__logo"
                  alt="RocketLevel"
                />
              </Link>
              {isAdmin && <span>admin</span>}
            </div>
          )}

          <div className="auth-layout__form">
            <Outlet />
          </div>
        </div>

        <small className="auth-layout__copyright">
          {t("common.copyRight")}
        </small>
      </div>
    </div>
  );
}

export default AuthLayout;
