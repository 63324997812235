import { Col, Row } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useGetAdminStats from "../../../hooks/useGetAdminStats";
import { adminSummarySelector } from "../../../states/admin";
import DateUtils from "../../../utils/date.utils";
import SVGIcons from "../../icons/svgs";
import "./index.scss";

function AdminSummarySection() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {getAdminStats} = useGetAdminStats();
  const state = useRecoilValue(adminSummarySelector);

  useEffect(() => {
    getAdminStats();
  }, []);

  return (
    <div className="page-sumary">
      <Row gutter={16}>
        <Col sm={8} xs={24}>
          <Link to={ROUTE_PATHS.UserList}>
            <div
              className="sumary-card"
              style={{
                borderColor:
                  pathname === ROUTE_PATHS.UserList ? "#0185de" : "#ffffff",
              }}
            >
              <div
                className="sumary-card-icon"
                style={{ backgroundColor: "#0185de" }}
              >
                <SVGIcons.UserCheckIcon />
              </div>
              <div className="sumary-card-content">
                <p className="des-color">
                  {state.userCount} {t("clientSites.summaryUsers")}
                </p>
                <span className="color-nobel">
                  {t("clientSites.lastAddedOn")}{" "}
                  {DateUtils.convertServerToDateViewFormat(
                    state.lastUserCreatedDate
                  )}
                </span>
              </div>
            </div>
          </Link>
        </Col>

        <Col sm={8} xs={24}>
          <Link to={ROUTE_PATHS.UserGroups}>
            <div
              className="sumary-card"
              style={{
                borderColor:
                  pathname === ROUTE_PATHS.UserGroups ? "#9bc13c" : "#ffffff",
              }}
            >
              <div
                className="sumary-card-icon"
                style={{ backgroundColor: "#9bc13c" }}
              >
                <SVGIcons.UsersIcon />
              </div>
              <div className="sumary-card-content">
                <p className="des-color">
                  {state.userGroupCount} {t("clientSites.summaryUserGroups")}
                </p>
                <span className="color-nobel">
                  {t("clientSites.lastAddedOn")}{" "}
                  {DateUtils.convertServerToDateViewFormat(
                    state.lastUserGroupCreatedDate
                  )}
                </span>
              </div>
            </div>
          </Link>
        </Col>

        <Col sm={8} xs={24}>
          <Link to={ROUTE_PATHS.ClientSite}>
            <div
              className="sumary-card"
              style={{
                borderColor:
                  pathname === ROUTE_PATHS.ClientSite ? "#f59f00" : "#ffffff",
              }}
            >
              <div
                className="sumary-card-icon"
                style={{ backgroundColor: "#f59f00" }}
              >
                <SVGIcons.BrowserIcon />
              </div>
              <div className="sumary-card-content">
                <p className="des-color">
                  {state.clientSiteCount} {t("clientSites.summaryClients")}
                </p>
                <span className="color-nobel">
                  {t("clientSites.lastAddedOn")}{" "}
                  {DateUtils.convertServerToDateViewFormat(
                    state.lastClientSiteCreatedDate
                  )}
                </span>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default AdminSummarySection;
