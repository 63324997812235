import { useSetRecoilState } from "recoil";
import { MediaLibraryState } from "../states/media-library";

export function useTriggerReloadUsageStorage() {
  const setMediaLibraryState = useSetRecoilState(MediaLibraryState);

  const triggerReloadStorage = () => {
    setMediaLibraryState((prevState) => ({
      ...prevState,
      triggerReloadUsageStorage: prevState.triggerReloadUsageStorage + 1,
    }));
  };

  return {
    triggerReloadStorage
  };
}
