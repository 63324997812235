import { Button, Checkbox, Dropdown, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { AuthServiceApi } from "../../../../apis/auth.api";
import { ServiceType } from "../../../../constants/app.enums";
import {
  AdminViewSitePrefixRouter,
  ROUTE_PATHS,
} from "../../../../constants/router.constants";
import { ClientServiceModel } from "../../../../models/service.model";
import { authState } from "../../../../states/auth";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
const CheckboxGroup = Checkbox.Group;

const ListService = [
  { label: "Social", value: ServiceType.Social }
];

interface ServiceTabProps {
  open: boolean;
}

const ServiceTab = (props: ServiceTabProps) => {
  const { open } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedList, setCheckedList] = useState<ServiceType[]>([
    ServiceType.Social
  ]);
  const [listClient, setListClient] = useState<ClientServiceModel[]>([]);
  const [loading, setLoading] = useState(false);

  const { user } = useRecoilValue(authState);

  const onChangeDropdown = (values: any) => {
    if (values.length > 0) setCheckedList(values);
  };

  const getListClientService = async () => {
    if (!user?.userId) return;
    setLoading(true);
    try {
      const { data } = await AuthServiceApi.getClientService({
        userId: user?.userId,
        serviceTypes: checkedList,
      });
      setListClient(data.records);
    } catch (error) {}
    setLoading(false);
  };

  const onClickClient = (clientId: number) => {
    window.open(
      `${window.location.origin}${AdminViewSitePrefixRouter}${ROUTE_PATHS.Social.Dashboard}/${clientId}`
    );
  };

  const columns = [
    {
      title: t("users.clientSite"),
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (name: string, record: ClientServiceModel) => {
        return (
          <span
            className="client-name-service-table"
            onClick={() => onClickClient(record.clientId)}
            key={record.assignedOn}
          >
            {name}
          </span>
        );
      },
    },
    {
      title: t("users.assignedOn"),
      dataIndex: "assignedOn",
      key: "assignedOn",
      render: (assignedOn: string) =>
        moment.utc(assignedOn).local().format("MMM DD,YYYY"),
    },
  ];
  const dropdownRender = () => {
    return (
      <div className="list-service-dropdown">
        <CheckboxGroup
          options={ListService}
          value={checkedList}
          onChange={onChangeDropdown}
        />
      </div>
    );
  };

  const renderListService = () => {
    return checkedList
      .map((service) => {
        return ListService.find((s) => s.value === service)?.label;
      })
      .join(",");
  };

  useEffect(() => {
    open && getListClientService();
  }, [checkedList, open]);
  return (
    <div className="profile-service-tab">
      <p>{t("users.servicePanelTitle")}</p>
      <Dropdown trigger={["click"]} dropdownRender={dropdownRender}>
        <Button className="btn-service-dropdown">
          <span className="label">Service</span>{" "}
          <span className="value">{renderListService()}</span>
          <SVGIcons.ChevronDown />
        </Button>
      </Dropdown>

      <Table
        columns={columns}
        dataSource={listClient}
        rowKey="id"
        className="main-table no-bordered"
        pagination={false}
        loading={loading}
        locale={{
          emptyText: "No clients yet.",
        }}
      />
    </div>
  );
};

export default ServiceTab;
