import { useRef, useEffect, useState } from "react";

export function useInfinityScroll(fetchData: () => Promise<void>) {
  const [loadMore, setLoadMore] = useState(false);
  const elRef: any = useRef();
  const preScrollTop: any = useRef();
  useEffect(() => {
    if (loadMore) {
      (async () => {
        await fetchData();
        elRef.current.scrollTop = preScrollTop.current;
        setLoadMore(false);
      })();
    }
  }, [loadMore]);

  useEffect(() => {
    elRef.current?.addEventListener("scroll", async (e: any) => {
      const el = e.target;
      if (el.scrollTop + el.clientHeight === el.scrollHeight) {
        setLoadMore(true);
        preScrollTop.current = el.scrollTop;
      }
    });
    return () => {
      elRef.current?.removeEventListener("scroll", () => {});
    };
  }, [elRef.current]);
  return elRef;
}
