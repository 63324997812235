import { BackTop, Button, Divider, Form, Input, Modal, Skeleton, Space, Spin } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SocialApi } from "../../../../../apis/social.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import {
  ApprovalStatusEnum,
  BulkApprovalsStatus,
} from "../../../../../constants/app.enums";
import useSocialPermission from "../../../../../hooks/useSocialPermission";
import {
  ApproveContentApprovalRequest,
  ContenApprovalModel,
} from "../../../../../models/approval.model";
import { ContentModel } from "../../../../../models/content.model";
import { authIsSupperAdminSelector, authState } from "../../../../../states/auth";
import { isMobileSelector } from "../../../../../states/common";
import { listProfileState, requiredGuestUserState, triggerFetchBulkApprovalState } from "../../../../../states/social";
import { isOnlyManageContentSelector } from "../../../../../states/socialPermission";
import { CommonUtils } from "../../../../../utils/common";
import LocalUtils from "../../../../../utils/local.utils";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import "./index.scss";
import ViewContent from "./view-content";

interface MainApprovalRequestProps { }

const MainApprovalRequest = (props: MainApprovalRequestProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const approvalId = Number(searchParams.get("approvalId"));
  const contentIdParam = Number(searchParams.get("contentId"));
  const guestInfo = LocalUtils.getGuestUserInfo();

  const [pageIndex, setPageIndex] = useState<number>(1);
  const pageSize = 5;
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [listContent, setListContent] = useState<ContentModel[]>([]);
  const [reason, setReason] = useState("");
  const [isOpenDeclineModal, setIsOpenDeclineModal] = useState(false);
  const [approvalDetail, setApprovalDetail] = useState<ContenApprovalModel>();
  const { profiles } = useRecoilValue(listProfileState);
  const { isLogined } = useRecoilValue(authState);
  const [initValue, setInitValue] = useState(false);
  const [firstFetched, setFirstFetched] = useState(false);
  const isMobile = useRecoilValue(isMobileSelector);
  const { user } = useRecoilValue(authState);
  const isSupperAdmin = useRecoilValue(authIsSupperAdminSelector);
  const { getSocialPermission } = useSocialPermission();
  const isOnlyManageContent = useRecoilValue(isOnlyManageContentSelector);
  const triggerFetchBulkApproval = useRecoilValue(triggerFetchBulkApprovalState);

  const setRequiredGuestUser = useSetRecoilState(
    requiredGuestUserState
  );
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState(false);
  const scrollTimeout = useRef<any>(null);

  useEffect(() => {
    if (triggerFetchBulkApproval !== undefined) {
      fetchApprovalDetails();
    }
  }, [triggerFetchBulkApproval])

  const handleCancel = () => {
    setIsOpenDeclineModal(false);
    form.resetFields();
  };

  const decline = () => {
    form.submit();
  };

  const submitDecline = async (data: ApproveContentApprovalRequest) => {
    setLoading(true);
    try {
      if (isLogined) {
        await SocialApi.declineContentApproval(approvalId, {
          comment: data.comment,
        });
      } else {
        if (guestInfo?.uuid) {
          await SocialApi.declineContentApproval(approvalId, {
            comment: data.comment,
            guestUserUuid: guestInfo?.uuid,
          });
        }
      }
      showNotification("delete", t("social.approval.declineAllSuccess"));
      handleCancel();
      setListContent((pre) => {
        pre = pre.map((x) => {
          x.approvalStatus = ApprovalStatusEnum.Declined;
          return x;
        });
        return CommonUtils.deepCopy(pre);
      });

      setApprovalDetail((pre) => {
        if (!pre) {
          return pre;
        }
        return {
          ...pre,
          contentApprovedCount: 0,
          status: BulkApprovalsStatus.Declined,
        };
      });
    } catch (error) { }
    setLoading(false);
  };

  const onChangeReason = (e: any) => {
    setReason(e.target.value);
  };

  const submitApprove = async () => {
    setLoading(true);
    try {
      if (isLogined) {
        await SocialApi.approveContentApproval(approvalId, {
          comment: "",
        });
      } else {
        if (guestInfo?.uuid) {
          await SocialApi.approveContentApproval(approvalId, {
            comment: "",
            guestUserUuid: guestInfo?.uuid,
          });
        }
      }
      showNotification("success", t("social.approval.approvalAllSuccess"));
      setListContent((pre) => {
        pre = pre.map((x) => {
          x.approvalStatus = ApprovalStatusEnum.Approved;
          return x;
        });
        return CommonUtils.deepCopy(pre);
      });

      setApprovalDetail((pre) => {
        if (!pre) {
          return pre;
        }
        return {
          ...pre,
          contentApprovedCount: pre.contentCount,
          status: BulkApprovalsStatus.Approved,
        };
      });
    } catch (error) { }
    setLoading(false);
  };

  const fetchApprovalDetails = async () => {
    const { data: approvalDetail } = await SocialApi.getApprovalDetail(
      approvalId
    );
    setApprovalDetail(approvalDetail);
    return approvalDetail;
  }

  const initData = async () => {
    setLoading(true);
    setFirstFetched(true)
    try {
      if (!approvalId) {
        throw new Error("Approval not found!");
      }

      const approvalDetail = await fetchApprovalDetails();
      //Todo: check social permission on client will refactor after add clientId on Url
      if (user?.userId && !isSupperAdmin) {
        getSocialPermission(user.userId, approvalDetail.clientId);
      }

      await initListContentApproval();
    } catch (error: any) {
      showNotification("error", error.message);
      setLoading(false);
    }
    setLoading(false);
  };
  const initListContentApproval = async (page?: number, loading: boolean = true) => {
    if (loading) {
      setLoading(true);
    }
    try {
      if (!approvalId) {
        throw new Error("Approval not found!");
      }
      const { data: approvalContent } =
        await SocialApi.getContentApprovalDetail({
          id: approvalId,
          pageIndex: page || pageIndex,
          pageSize: pageSize,
        });

      if (firstFetched) {
        setListContent((pre) => pre.concat(approvalContent.records));
      } else {
        setListContent(approvalContent.records);
      }
      setTotalRecords(approvalContent.totalRecords);
      setInitValue(true);
    } catch (error: any) {
      showNotification("error", error.message);
      setLoading(false);
    } finally {
      setIsLoadingSkeleton(false);
    }
    if (loading) {
      setLoading(false);
    }
  };
  const handleScroll = async () => {
    const debounceTime = 300;
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }
    scrollTimeout.current = setTimeout(() => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      if (Math.ceil(windowHeight + scrollTop) >= Math.ceil(documentHeight)) {
        if (initValue && totalRecords > listContent.length) {
          setIsLoadingSkeleton(true);
          setPageIndex(prevPageIndex => {
            const updatedPageIndex = prevPageIndex + 1;
            initListContentApproval(updatedPageIndex, false);
            return updatedPageIndex;
          });
        }
      }
    }, debounceTime);
  };

  const approvalAll = () => {
    if (!guestInfo?.uuid) {
      setRequiredGuestUser(true);
    }
    else {
      Modal.confirm({
        title: t("social.approval.approvalAllTitle"),
        content: (
          <span
            dangerouslySetInnerHTML={{
              __html: t("social.approval.approvalAllDescription", {
                title: approvalDetail?.title,
              }),
            }}
          ></span>
        ),
        okText: t("common.confirm"),
        cancelText: t("common.cancel"),
        cancelButtonProps: { type: "text" },
        okButtonProps: { loading: approveLoading },
        icon: "",
        className: "confirm-modal",
        centered: true,
        onOk: async () => {
          submitApprove();
        },
      });
    }

  };

  const declineAll = () => {
    if (!guestInfo?.uuid) {
      setRequiredGuestUser(true);
    }
    else {
      setIsOpenDeclineModal(true)
    }
  }

  useEffect(() => {
    initData();
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [initValue, totalRecords, listContent]);

  // remove search param if not exist
  useEffect(() => {
    if (listContent.length && contentIdParam) {
      if (!listContent.find(c => c.id === contentIdParam)) {
        searchParams.delete("contentId");
        setSearchParams(searchParams);
      }
    }
  }, [contentIdParam, listContent]);

  return (
    <div className="approval-request">
      <div className="approval-request-header">
        <div className="approval-request-header__left">
          <div className="title">{approvalDetail?.title}</div>
          <div className="info">
            <span className="info-item">
              Approved contents:{" "}
              <span>
                {approvalDetail?.contentApprovedCount}/
                {approvalDetail?.contentCount}
              </span>
            </span>
            {approvalDetail?.dueDate && (
              <span className="info-item">
                Due Date:{" "}
                <span>
                  {moment(approvalDetail?.dueDate).format("MMM DD, YYYY")}
                </span>
              </span>
            )}

            {
              !!approvalDetail?.comment && <span className="info-item">
                Comment: <span>{approvalDetail?.comment}</span>
              </span>
            }

            {
              !!approvalDetail?.isAutoApproved && <span className="info-item">
                Automatically approved on: <span> {moment(approvalDetail.createdDate)
                  .add(approvalDetail.autoApprovedDay, "d").format("MMM DD, YYYY")}</span>
              </span>
            }

          </div>
        </div>

        <div className="approval-request-header__right">
          <Button
            className="btn-dangerous"
            onClick={declineAll}
            disabled={isOnlyManageContent || approvalDetail?.status === BulkApprovalsStatus.Declined}
          >
            {t('social.approval.declineAll')}
          </Button>
          <Button
            className="ant-btn-primary secondary-btn"
            onClick={approvalAll}
            disabled={isOnlyManageContent || approvalDetail?.status === BulkApprovalsStatus.Approved}
          >
            {t('social.approval.approveAll')}
          </Button>
        </div>
      </div>
      <Spin spinning={loading && !!profiles?.length}>
        <div className="approval-request-main">
          <div className="list-content">
            {listContent &&
              listContent.map((contentItem, index) => {
                return (
                  <div key={index}>
                    <ViewContent
                      contentInfo={contentItem}
                      listProfile={profiles}
                    />
                    {
                      isMobile && <Divider />
                    }
                  </div>
                );
              })}

            {listContent.length !== totalRecords && isLoadingSkeleton && (
              <div className="edit-content-container-body">
                <div className="edit-content-container-body__left">
                  <Space>
                    <Skeleton.Button active={isLoadingSkeleton} shape='default' size="small" />
                    <Skeleton.Avatar active={isLoadingSkeleton} shape='circle' size="large" />
                  </Space>
                </div>
                <div className="edit-content-container-body__middle">
                  <Skeleton avatar paragraph={{ rows: 4 }} />
                </div>
                <div className="edit-content-container-body__right">
                  <Skeleton active={isLoadingSkeleton} avatar />
                </div>
              </div>
            )}
          </div>
        </div>

        <BackTop >
          <div className="back-to-top">
            <SVGIcons.BackToTopIcon />
          </div>
        </BackTop>
      </Spin>

      <Modal
        open={isOpenDeclineModal}
        title={t(`social.approval.declineAllTitle`)}
        okText={t("common.decline")}
        className="custom-create-modal"
        onCancel={() => setIsOpenDeclineModal(false)}
        footer={
          <div className="remove-connection-footer">
            <Button type="default" onClick={handleCancel}>
              {t("common.cancel")}
            </Button>
            <Button type="primary" danger onClick={decline} loading={loading}>
              {t("common.decline")}
            </Button>
          </div>
        }
      >
        <div className="modal-decline-content">
          <div className="description">
            <p
              dangerouslySetInnerHTML={{
                __html: t("social.approval.declineAllDescription", {
                  title: approvalDetail?.title,
                }),
              }}
            ></p>
            <p>{t("social.approval.declineAllDescription1")}</p>
          </div>
          <Form
            name="createSiteForm"
            layout="vertical"
            onFinish={submitDecline}
            form={form}
            autoComplete="off"
            validateMessages={VALIDATION_MESSAGE_CONFIG}
          >
            <Form.Item
              label={t("social.approval.comment")}
              name="comment"
              rules={[{ required: true, message: "Comment field is required" }]}
            >
              <Input
                placeholder="Your comment here..."
                onChange={onChangeReason}
                value={reason}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default MainApprovalRequest;
