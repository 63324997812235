import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CLIENT_CETEGORIES,
  PASSWORD_POLYCIES,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import PhoneInput from "react-phone-input-2";
import { ClientCategory } from "../../../../constants/app.enums";

interface Props {
  submit: (values: any) => void;
  loading: boolean;
}

function SignUpForm(props: Props) {
  const { t } = useTranslation();
  const { submit, loading } = props;
  const [form] = Form.useForm();
  const [passwordPolicies, setPasswordPolicies] = useState(
    PASSWORD_POLYCIES.filter((x) => !x.disabled)
  );

  const passwordOnchange = (value: string) => {
    const newArray = [...passwordPolicies];
    newArray.forEach((item) => {
      var reg = new RegExp(item.regex);
      item.valid = reg.test(value);
    });

    setPasswordPolicies(newArray);
  };

  return (
    <div className="sign-up-form">
      <div className="header">{t("signUp.title1")}</div>
      <p className="description">{t("signUp.discription")}</p>
      <Form
        name="signin"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        initialValues={{
          "category": ClientCategory.Uncategorised
        }}
      >
        <Row gutter={17}>
          <Col span={12}>
            <Form.Item
              label={t("signUp.firstName")}
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input placeholder={t("signUp.firstNamePlahoder")} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t("signUp.lastName")}
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input placeholder={t("signUp.lastNamePlahoder")} />
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item
        label={t("signUp.username")}
        name="username"
        rules={[{ required: true }]}
      >
        <Input placeholder={t("signUp.usernamePlahoder")} />
      </Form.Item> */}

        <Form.Item
          label={t("signUp.companyName")}
          name="companyName"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("signUp.companyNamePlahoder")} />
        </Form.Item>

        <Form.Item
          label={t("clientSites.industry")}
          name="category"
          rules={[{ required: true }]}
          style={{textAlign: 'start'}}
        >
          <Select showSearch  optionFilterProp="children">
            {CLIENT_CETEGORIES.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("signUp.businessEmail")}
          name="email"
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input placeholder={t("signUp.businessEmailPlahoder")} />
        </Form.Item>

        <Form.Item
          label={t("signUp.businessPhone")}
          name="businessPhone"
          rules={[{ required: true }]}
        >
          {/* <Input type={"tel"} placeholder={t("signUp.businessPhonePlahoder")} /> */}
          <PhoneInput country={"us"} enableSearch={true} />
        </Form.Item>

        <Form.Item
          label={t("signUp.websiteURL")}
          name="website"
          rules={[
            { required: true },
            { type: "url", message: t("common.websiteValidation") },
          ]}
        >
          <Input placeholder={t("signUp.websiteURLPlahoder")} />
        </Form.Item>

        <Form.Item
          label={t("signUp.password")}
          name="password"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (passwordPolicies.some((x) => !x.valid)) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }),
          ]}
          className="mr-bottom-0 padding-bottom-0"
        >
          <Input.Password
            placeholder={t("signUp.password")}
            onChange={(e) => passwordOnchange(e.target.value)}
          />
        </Form.Item>

        <Row className="password-hint-wrapper">
          {passwordPolicies.map((item) => (
            <Col
              span={12}
              key={item.key}
              className={`password-hint-item${item.valid ? " active" : ""}`}
            >
              <SVGIcons.KeyIcon />
              <span>{item.label}</span>
            </Col>
          ))}
        </Row>

        <Button htmlType="submit" type="primary" block loading={loading}>
          {t("signUp.btnSubmit")}
        </Button>

        <small className="sign-up-form__termsCondition">
          <a
            className="color-nobel"
            href={"https://rocketlevel.com/terms-conditions/"}
            target="_blank"
            dangerouslySetInnerHTML={{ __html: t("signUp.termsConditions") }}
          ></a>
        </small>
      </Form>
    </div>
  );
}

export default SignUpForm;
