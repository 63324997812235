import { Button, Form, Input } from "antd";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import SVGIcons from "../../components/icons/svgs";
import showNotification from "../../components/common/notification";
import { VALIDATION_MESSAGE_CONFIG } from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import "./index.scss";
import useAdminSite from "../../hooks/useAdminSite";

function ForgotPasswordPage() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isAdmin = useAdminSite();

  function handleSubmit() {
    setLoading(true);
    const { email } = form.getFieldsValue();
    Auth.forgotPassword(email)
      .then(() => {
        navigate(`${isAdmin ? ROUTE_PATHS.AdminResetPassword : ROUTE_PATHS.ResetPassword}?email=${email}`);
      })
      .catch(error => {
        showNotification("error", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className="forgot-password-form">
        <div className="forgot-password-form__header">
          {t("forgotPassword.title")}
        </div>
        <div className="forgot-password-form__description">
          {t("forgotPassword.description")}
        </div>
        <Form
          name="signin"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          form={form}
          onFinish={handleSubmit}
          className="hiden-required-mark"
        >
          <Form.Item
            label={t("forgotPassword.email")}
            name="email"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("forgotPassword.emailPlahoder")} />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary" block loading={loading}>
              <i className="email-icon">
                <SVGIcons.EmailIcon />
              </i>
              {t("forgotPassword.btnSubmit")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Link
        className="auth__backLink"
        to={isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn}
        dangerouslySetInnerHTML={{ __html: t("forgotPassword.sendMeBack") }}
      ></Link>
    </>
  );
}

export default ForgotPasswordPage;
