import { Button, Input, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AuthApi } from "../../../apis/auth.api";
import showNotification from "../../../components/common/notification";
import SVGIcons from "../../../components/icons/svgs";
import EditRoleForm from "../../../components/views/roles-permissions/edit-form";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../constants/permission.constants";
import { RoleModel } from "../../../models/role.model";
import { adminRolesSelector } from "../../../states/admin";
import { pageTitleState } from "../../../states/common";
import searchDebounce from "../../../utils/searchDebounce";
import "./index.scss";
import useHasPermission from "../../../hooks/useHasPermission";

function RolePermissionPage() {
  const { t } = useTranslation();
  const [roles, setRoles] = useRecoilState(adminRolesSelector);
  const [rolesFiltered, setRolesFiltered] = useState<RoleModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const setPageTile = useSetRecoilState(pageTitleState);
  const [roleEditing, setRoleEditing] = useState<RoleModel>();
  const { hasPermission } = useHasPermission();

  useEffect(() => {
    setPageTile(t("rolePermission.title"));
    if (!roles || !roles.length) {
      getRoles();
    }
  }, []);

  useEffect(() => {
    if (roles) {
      setRolesFiltered(roles);
    }
  }, [roles]);

  const getRoles = async () => {
    setLoading(true);

    try {
      const { data } = await AuthApi.getRoles();
      setRoles(data);
    } catch { }
    setLoading(false);
  };

  function createRole() {
    if (roleEditing) {
      setRoleEditing(undefined);
    }
    setOpenCreateModal(true);
  }

  function edit(role: RoleModel) {
    setRoleEditing(role);
    setOpenCreateModal(true);
  }

  function confirmDelete(role: RoleModel) {
    Modal.confirm({
      title: t("rolePermission.deleteRoleTitle"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("rolePermission.deleteRoleDescription", {
              role: role.roleName,
            }),
          }}
        ></span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => deleteRole(role),
    });
  }

  async function deleteRole(role: RoleModel) {
    setLoading(true);
    try {
      await AuthApi.deleteRole(role.roleId);
      showNotification("delete", t("rolePermission.deleteSuccess", { name: role.roleName }));
      getRoles();
    } catch { }
    setLoading(false);
  }

  function renderFunctionName(item: RoleModel, permission: PermissionEnum) {
    let permissions = item.permissions
      ? item.permissions.filter(
        (x) => x.right === permission || x.right === PermissionEnum.All
      )
      : [];

    if (!permissions.length) {
      return "Nothing";
    }

    let name = "";
    for (let p of permissions) {
      if (p.functionCode === FunctionEnum.Everything) return p.functionName;
      name = name === "" ? p.functionName : `${name}, ${p.functionName}`;
    }

    return name;
  }

  const columns: ColumnsType<RoleModel> = [
    {
      title: t("rolePermission.role"),
      dataIndex: "roleName",
      sorter: (a, b) => 1,
      render: (text, _, index) => text,
    },
    {
      title: t("rolePermission.view"),
      dataIndex: "view",
      render: (text, record) => renderFunctionName(record, PermissionEnum.View),
    },
    {
      title: t("rolePermission.create"),
      dataIndex: "create",
      render: (text, record) =>
        renderFunctionName(record, PermissionEnum.Create),
    },
    {
      title: t("rolePermission.update"),
      dataIndex: "update",
      render: (text, record) => renderFunctionName(record, PermissionEnum.Edit),
    },
    {
      title: t("rolePermission.delete"),
      dataIndex: "delete",
      render: (text, record) =>
        renderFunctionName(record, PermissionEnum.Delete),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record) => (
        <Space size={"small"}>
          {
            hasPermission({
              function: FunctionEnum.Role,
              permission: PermissionEnum.Edit
            }) &&

            <Button
              className="color-blue"
              type="text"
              onClick={() => edit(record)}
            >
              {t("users.btnEdit")}
            </Button>
          }

          {record.canDelete &&
            hasPermission({
              function: FunctionEnum.Role,
              permission: PermissionEnum.Delete
            }) &&
            <Button
              className="delete-ghost-button"
              type="text"
              onClick={() => confirmDelete(record)}
            >
              {t("users.btnDelete")}
            </Button>
          }
        </Space>
      ),
    },
  ];

  function filterData(value: string) {
    let newRoles = [...roles];
    if (value) {
      newRoles = newRoles.filter(
        (x) =>
          x.roleName && x.roleName.toLowerCase().includes(value.toLowerCase())
      );
    }

    setRolesFiltered(newRoles);
  }

  function searchChange(value: string) {
    searchDebounce(value, () => filterData(value));
  }

  return (
    <div className="users-page">
      <div className="users-page__tableWrapper">
        <div className="users-page__tableHeader">
          <div className="table-action">
            <div className="table-action__left">
              <Input
                placeholder={t("common.searchPlahoder")}
                suffix={<SVGIcons.SearchIcon />}
                className="search-input"
                onChange={(e) => searchChange(e.target.value)}
              />
            </div>

            <div className="table-action__right">
              {
                hasPermission({
                  function: FunctionEnum.Role,
                  permission: PermissionEnum.Create
                }) &&
                <Button
                  type="primary"
                  icon={<SVGIcons.PlusIcon />}
                  onClick={() => createRole()}
                >
                  {t("rolePermission.btnAddNew")}
                </Button>
              }
            </div>
          </div>
        </div>

        <div className="users-page__table">
          <Table
            className="main-table"
            columns={columns}
            dataSource={rolesFiltered}
            pagination={false}
            rowKey="roleId"
            loading={loading}
          />
        </div>
      </div>

      <EditRoleForm
        open={openCreateModal}
        close={() => setOpenCreateModal(false)}
        item={roleEditing}
        getRoles={getRoles}
      />
    </div >
  );
}

export default RolePermissionPage;
