import moment from "moment";
import { useEffect, useState } from "react";
import { ChartDataModel } from "../models/chart.model";

function useMockupData() {
  const [data, setData] = useState<ChartDataModel[]>([]);

  useEffect(() => {
    const mockupValue: ChartDataModel[] = [];
    for (let index = 1; index <= 31; index++) {
      mockupValue.push({
        label: moment().date(index).format(),
        value: Math.floor(Math.random() * 100),
        // value: 0,
      });
    }

    setData(mockupValue);
  }, []);

  return data;
}

export default useMockupData;