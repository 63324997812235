import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PlanType } from "../../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import useClientPlan from "../../../../hooks/useClientPlan";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function LimitedModal(props: Props) {
    const { isOpen, setIsOpen } = props
    const { t } = useTranslation();
    const { limitedTrialPlanLabel, getUpgradePremiumUrl, isGetPremiumUrlLoading, userPlan } = useClientPlan()
    const navigate = useNavigate();

    const upgradeToPremium = async () => {
        await getUpgradePremiumUrl()
        setIsOpen(false)
    }

    return (
        <>
            <Modal
                centered
                className="upgrade-modal"
                open={isOpen}
                closable={false}
                onCancel={() => setIsOpen(false)}
                footer={null}
                zIndex={1009}
            >
                <div className="limited-modal">
                    <div className="limited-modal__header" >
                        <SVGIcons.LimitedIcon />
                        <div
                            onClick={() => setIsOpen(false)}
                            className="limited-modal__closeIcon"
                        >
                            <SVGIcons.UpgradeModalCloseIcon />
                        </div>
                    </div>

                    <div className="limited-modal__content" >
                        <div className="title" >
                            {t("usageAndBilling.limitedTitle", {
                                label: limitedTrialPlanLabel
                            })}
                        </div>
                        <div className="desc">
                            <div dangerouslySetInnerHTML={{
                                __html:
                                    userPlan?.planType === PlanType.Trial ?
                                        t("usageAndBilling.trialLimitedDesc", {
                                            label: limitedTrialPlanLabel
                                        })
                                        :
                                        t("usageAndBilling.premiumPlanLimitedDesc", {
                                            label: limitedTrialPlanLabel
                                        })
                            }}
                            />
                        </div>
                    </div>

                    <div className="limited-modal__footer" >
                        {
                            userPlan?.planType === PlanType.Trial ?
                                <Button loading={isGetPremiumUrlLoading} onClick={upgradeToPremium} className="understandBtn" type="primary">
                                    {t("upgradeModal.upgradeToPremium")}
                                </Button>
                                :
                                <Button onClick={() => setIsOpen(false)} className="understandBtn" type="primary">
                                    {t("usageAndBilling.understandBtn")}
                                </Button>
                        }

                        <Button onClick={() => {
                            setIsOpen(false)
                            navigate(ROUTE_PATHS.UsageAndBilling)
                        }} className="checkUsageBtn" type="text">
                            {t("usageAndBilling.checkYourUsage")}
                        </Button>
                    </div>

                </div>
            </Modal>
        </>
    );
}

export default LimitedModal;
