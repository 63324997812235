import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Switch,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../apis/social.api";
import EmptyContentPNG from "../../../../../assets/images/empty-content-approval.png";
import { CONTENT_FILTER_DATA } from "../../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import useAdminSite from "../../../../../hooks/useAdminSite";
import useClient from "../../../../../hooks/useClient";
import { ContenApprovalModel } from "../../../../../models/approval.model";
import {
  ContentModel,
  GetListContentForApprovalRequestModel,
} from "../../../../../models/content.model";
import { listPostTypeState, listTagState } from "../../../../../states/social";
import { CommonUtils } from "../../../../../utils/common";
import SocialUtils from "../../../../../utils/social";
import FilterList from "../../../../common/filter";
import showNotification from "../../../../common/notification";
import AppDatePicker from "../../../../controls/app-date-picker";
import SVGIcons from "../../../../icons/svgs";
import ContentCard from "../../social-content/components/content-card";
import "./index.scss";

enum ErrorAlert {
  None,
  RequiredContent,
  DateInvalid,
}

const defaultDueDate = `${moment
  .utc()
  .add(3, "days")
  .format("YYYY-MM-DDTHH:mm:ss")}Z`;

interface CreateApprovalModalProps {
  open: boolean;
  close: () => void;
  initListApproval: () => Promise<void>;
  currentApproval?: ContenApprovalModel;
  isEdit?: boolean;
}

const CreateApprovalModal = (props: CreateApprovalModalProps) => {
  const { open, close, initListApproval, currentApproval, isEdit } = props;
  const { t } = useTranslation();
  const { clientId } = useClient();
  const navigate = useNavigate();
  const isAdminSite = useAdminSite();
  const [form] = Form.useForm();
  const isDueDate = Form.useWatch("isDueDate", form);
  const isAutoApproved = Form.useWatch("isAutoApproved", form);

  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(1);
  const [listContent, setListContent] = useState<ContentModel[]>([]);
  const [listContentSelected, setListContentSelected] = useState<
    ContentModel[]
  >([]);
  const [dueDate, setDueDate] = useState(defaultDueDate);
  const [autoApprovedDay, setAutoApprovedDay] = useState(1);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>(ErrorAlert.None);
  const [query, setQuery] = useState<GetListContentForApprovalRequestModel>({
    pageIndex: 1,
    pageSize: 12,
  });
  const listTag = useRecoilValue(listTagState);
  const listPostType = useRecoilValue(listPostTypeState);

  const { pageIndex } = query;

  const gotoContent = () => {
    if (isAdminSite)
      clientId &&
        navigate(
          CommonUtils.getAdminViewClientSiteRouter(
            ROUTE_PATHS.Social.Content,
            clientId
          )
        );
    else navigate(ROUTE_PATHS.Social.Content);
  };

  const initListContent = async (
    request?: GetListContentForApprovalRequestModel
  ) => {
    setLoading(true);
    try {
      const formData = { ...query, clientId, ...request };
      if (currentApproval?.id) formData.edittingId = currentApproval?.id;
      const { data } = await SocialApi.getContentForApproval(formData);
      setQuery(formData);
      setListContent(data.records);
      setTotalRecords(data.totalRecords);
    } catch (error) {}
    setLoading(false);
  };

  const onSelectContent = (content: ContentModel) => {
    const contentIndex = listContentSelected.findIndex(
      (c) => c.id === content.id
    );
    if (contentIndex === -1) {
      setListContentSelected([...listContentSelected, content]);
      return;
    }
    const newListContentSelected = [...listContentSelected];
    newListContentSelected.splice(contentIndex, 1);
    setListContentSelected(newListContentSelected);
  };

  const onChangeDueDate = (values: string) => {
    setDueDate(`${moment.utc(values).format("YYYY-MM-DD")}T00:00:00Z`);
  };

  const selectAll = () => {
    setListContentSelected(listContent);
  };

  const deselectAll = () => {
    setListContentSelected([]);
  };

  const confirmCreate = async () => {
    const { title } = form.getFieldsValue();
    await form.validateFields();
    if (listContentSelected.length === 0) {
      setErrorAlert(ErrorAlert.RequiredContent);
      return;
    }
    Modal.confirm({
      title: t("social.approval.confirmApproval"),
      content: (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                "social.approval.createApprovalModalConfirmDescription",
                {
                  title,
                }
              ),
            }}
          ></span>

          <div className="confirmApproval-modal">
            <ul>
              <li>
                <div className="confirmApproval-modal-icon">
                  <SVGIcons.WarningIcon />
                </div>{" "}
                <div>{t("social.approval.markApprovalRequired")}</div>
              </li>
              <li>
                <div className="confirmApproval-modal-icon">
                  <SVGIcons.WarningIcon />
                </div>{" "}
                <div>{t("social.approval.notPublishAfterDueDate")}</div>
              </li>
            </ul>
          </div>
          <div>{t("social.approval.confirmProceed")}</div>
        </>
      ),
      okText: t("common.confirm"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",

      className: "confirm-modal confirm-success-modal",
      centered: true,
      onOk: async () => {
        onSubmit();
      },
    });
  };

  const onSubmit = async () => {
    // check valid
    await form.validateFields();
    if (listContentSelected.length === 0) {
      setErrorAlert(ErrorAlert.RequiredContent);
      return;
    }
    const isDueDateInvalid = listContentSelected.some((content) => {
      if (
        content.status === 1 &&
        moment(content.date).format("YYYY-MM-DD") <
          moment(dueDate).format("YYYY-MM-DD")
      )
        return true;
    });
    if (isDueDate && isDueDateInvalid) {
      setErrorAlert(ErrorAlert.DateInvalid);
      return;
    }

    if (isEdit) {
      await updateApproval();
    } else {
      await createApproval();
    }
    setErrorAlert(ErrorAlert.None);
  };

  const onClose = () => {
    setListContentSelected([]);
    form.resetFields();
    setQuery({ ...query, pageIndex: 1 });
    setDueDate(defaultDueDate);
    close();
  };

  const createApproval = async () => {
    if (!clientId) return;
    setCreateLoading(true);
    try {
      const { title, comment, isDueDate, notifyImmediately, isAutoApproved } =
        form.getFieldsValue();
      await SocialApi.createContentApproval({
        title,
        comment,
        dueDate: isDueDate ? dueDate : undefined,
        clientId,
        contentIds: listContentSelected.map((c) => c.id),
        notifyImmediately: notifyImmediately,
        isAutoApproved,
        autoApprovedDay,
      });
      showNotification(
        "success",
        t("social.approval.createContentApprovalSuccess", { name: title })
      );

      onClose();
      initListApproval();
    } catch (error) {
    } finally {
      setCreateLoading(false);
    }
  };

  const handlePrev = async () => {
    if (!pageIndex) return;
    if (pageIndex === 1) return;
    setQuery({ ...query, pageIndex: pageIndex - 1 });
  };

  const handleNext = async () => {
    if (!pageIndex) return;
    if (Math.ceil(totalRecords / 12) === pageIndex) return;
    setQuery({ ...query, pageIndex: pageIndex + 1 });
  };

  const updateApproval = async () => {
    if (!clientId) return;
    setCreateLoading(true);
    try {
      const { title, comment, isDueDate, isAutoApproved } = form.getFieldsValue();
      if (isEdit && currentApproval) {
        await SocialApi.updateContentApproval({
          id: currentApproval.id,
          title,
          comment,
          dueDate: isDueDate ? dueDate : undefined,
          contentIds: listContentSelected.map((c) => c.id),
          isAutoApproved,
          autoApprovedDay
        });
        showNotification(
          "success",
          t("social.approval.editContentApprovalSuccess", { name: title })
        );
      }

      onClose();
      initListApproval();
    } catch (error) {
    } finally {
      setCreateLoading(false);
    }
  };

  const renderAlert = () => {
    switch (errorAlert) {
      case ErrorAlert.RequiredContent:
        return (
          <Alert
            message={t("social.approval.requiredContentError")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case ErrorAlert.DateInvalid:
        return (
          <Alert
            message={t("social.approval.dateInvalidError")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case ErrorAlert.None:
        return "";

      default:
        return "";
    }
  };

  const filterChange = async (values: any) => {
    await initListContent({
      platforms: undefined,
      approvalStatus: undefined,
      statuses: undefined,
      tagIds: undefined,
      postTypes: undefined,
      pageIndex: 1,
      ...values,
    });
  };

  const dataFilter = {
    platforms: CONTENT_FILTER_DATA["platforms"],
    statuses: CONTENT_FILTER_DATA["statuses"],
    postTypes: listPostType.map((postType) => {
      return { name: SocialUtils.createKeyPostType(postType), value: postType };
    }),
    approvalStatus: CONTENT_FILTER_DATA["approvalStatus"],
    tagIds: listTag.map((tag) => {
      return {
        name: tag.name,
        value: tag.id,
        color: tag.color,
      };
    }),
  };

  useEffect(() => {
    open && initListContent({ pageIndex });
  }, [open, pageIndex]);

  useEffect(() => {
    if (errorAlert !== ErrorAlert.None) {
      setTimeout(function () {
        setErrorAlert(ErrorAlert.None);
      }, 5000);
    }
  }, [errorAlert]);

  // Edit
  const setEditValue = async () => {
    form.setFieldsValue({
      title: currentApproval?.title,
      comment: currentApproval?.comment,
      isDueDate: !!currentApproval?.dueDate,
      isAutoApproved: currentApproval?.isAutoApproved,
    });
    if (!!currentApproval?.dueDate) {
      onChangeDueDate(currentApproval?.dueDate);
    }
    if (!!currentApproval?.autoApprovedDay) {
      setAutoApprovedDay(currentApproval?.autoApprovedDay);
    }
    if (currentApproval?.contentIds) {
      const arrSelectedContent = await Promise.all(
        currentApproval?.contentIds.map(async (item) => {
          const { data } = await SocialApi.getContentDetail(item);
          return data;
        })
      );
      setListContentSelected(arrSelectedContent);
    }
  };

  useEffect(() => {
    if (isEdit && currentApproval && open) {
      setEditValue();
    }
  }, [currentApproval, open]);

  const isQuerying =
    query.approvalStatus || query.statuses || query.platforms || query.tagIds;
  return (
    <Modal
      title={
        !isEdit
          ? t("social.approval.modalCreateTitle")
          : `${t("social.approval.modalEditTitle")} - ${currentApproval?.title}`
      }
      open={open}
      onCancel={onClose}
      closable={true}
      maskClosable
      mask={false}
      centered={true}
      className="custom-move-action-modal create-approval-modal"
      footer={
        <div className="create-approval-modal-footer">
          <Button type="default" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <Button
            type="primary"
            onClick={isEdit ? onSubmit : confirmCreate}
            loading={createLoading}
          >
            {!isEdit
              ? t("social.approval.btnCreateApproval")
              : t("social.approval.btnEditApproval")}
          </Button>
        </div>
      }
    >
      <div className="create-approval-container">
        <div className="create-approval-side-bar">
          <Form name="create-approval-form" layout="vertical" form={form}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Title field is required" }]}
            >
              <Input placeholder="e.g. Content for upcoming summer" />
            </Form.Item>
            <Form.Item label="Comment" name="comment">
              <Input.TextArea />
            </Form.Item>

            {!isEdit && (
              <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col sm={20}>
                  <div className="date-title">
                    {t("social.approval.notifyTitle")}
                  </div>
                  <div className="date-description">
                    {t("social.approval.notifyDescription")}
                  </div>
                </Col>
                <Col sm={4} style={{ textAlign: "right" }}>
                  <Form.Item
                    name="notifyImmediately"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Switch size="small" />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row justify="space-between" style={{ marginBottom: 16 }}>
              <Col sm={20}>
                <div className="date-title">
                  {t("social.approval.dueDateTitle")}
                </div>
                <div className="date-description">
                  {t("social.approval.dueDateDescription")}
                </div>
              </Col>
              <Col sm={4} style={{ textAlign: "right" }}>
                <Form.Item name="isDueDate" initialValue={false}>
                  <Switch size="small" checked={isDueDate} />
                </Form.Item>
              </Col>
            </Row>
            {isDueDate && (
              <AppDatePicker
                onChange={onChangeDueDate}
                defaulValue={moment(dueDate)}
              />
            )}
            <Row
              justify="space-between"
              style={{ marginBottom: 16, marginTop: 16 }}
            >
              <Col sm={20}>
                <div className="date-title">
                  {t("social.approval.approvalAllAutoTitle")}
                </div>
                <div className="date-description">
                  {t("social.approval.approvalAllAutoDescription")}
                </div>
              </Col>
              <Col sm={4} style={{ textAlign: "right" }}>
                <Form.Item name="isAutoApproved" initialValue={false}>
                  <Switch size="small" checked={isAutoApproved} />
                </Form.Item>
              </Col>
            </Row>
            {isAutoApproved && (
              <Input
                suffix="day(s)"
                value={autoApprovedDay}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  console.log(value);
                  !isNaN(value) && setAutoApprovedDay(value);
                }}
                className="auto-approve-input"
              />
            )}
          </Form>
        </div>
        <div className="create-approval-main">
          <Row
            justify={{ sm: "space-between" }}
            className="create-approval-main-header"
          >
            <Col className="create-approval-main-header__left">
              <div className="title">
                {t("social.approval.modalContainerTitle")}
              </div>
              <div
                className={`action ${
                  listContent.length === 0 ? "action-no-content" : ""
                }`}
              >
                <div className="detail">
                  {t("social.approval.numberSelected", {
                    amount: listContentSelected.length,
                  })}
                </div>
                <div className={`select-all`} onClick={selectAll}>
                  {t("social.approval.selectAll")}
                </div>
                <div className={`deselect-all`} onClick={deselectAll}>
                  {t("social.approval.deselectAll")}
                </div>
              </div>
            </Col>
            <Col className="create-approval-main-header__right">
              {listContent.length !== 0 && (
                <div className="switch-page">
                  <Button
                    className="prev"
                    onClick={handlePrev}
                    disabled={pageIndex === 1}
                  >
                    <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
                  </Button>
                  <Button
                    className="next"
                    onClick={handleNext}
                    disabled={Math.ceil(totalRecords / 12) === pageIndex}
                  >
                    {t("clientSites.next")} <SVGIcons.ChevronRight />
                  </Button>
                </div>
              )}
              <FilterList
                data={dataFilter}
                onChange={filterChange}
                disabled={!isQuerying && listContent.length === 0}
              />
            </Col>
          </Row>

          {renderAlert()}

          <Spin spinning={loading}>
            {listContent.length === 0 ? (
              <div className="no-content">
                <img src={EmptyContentPNG} alt="" />
                <div className="title">
                  {t("social.approval.noContentTitle")}
                </div>
                <div className="description">
                  {t("social.approval.noContentDescription")}
                </div>
                <Button type="primary" onClick={gotoContent}>
                  {t("social.approval.btnGotoContent")}
                </Button>
              </div>
            ) : (
              <div className="list-content">
                <Row gutter={[20, 20]}>
                  {listContent.map((contentItem) => {
                    return (
                      <Col
                        lg={6}
                        md={8}
                        sm={12}
                        key={contentItem.id}
                        onClick={() => onSelectContent(contentItem)}
                      >
                        <ContentCard
                          contentInfo={contentItem}
                          isApprovalView={true}
                        />
                        <div
                          className={`overlay ${
                            listContentSelected.find(
                              (c) => c.id === contentItem.id
                            )
                              ? "checked"
                              : ""
                          }`}
                        >
                          <div className="status">
                            <SVGIcons.CheckedNotRoundedIcon />
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}
          </Spin>
        </div>
      </div>
    </Modal>
  );
};

export default CreateApprovalModal;
