import { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { SocialApi } from "../apis/social.api";
import { listProfileState, triggerFetchProfilesState } from "../states/social";
import useClient from "./useClient";

function useSocial() {
  const location = useLocation();
  const { clientId } = useClient();
  const [listProfile, setListProfile] = useRecoilState(listProfileState);
  const [loading, setLoading] = useState(false);
  const [loadingConnect, setLoadingConnect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isResyncSearch = searchParams.get("isResync") === "true" || false;
  const { fetchCount } = useRecoilValue(triggerFetchProfilesState);
  const { profiles, clientId: profileClientId } = listProfile;
  const loadingRef = useRef(false);

  const getSsoUrl = async () => {
    try {
      const res = await SocialApi.getSsoUrl({
        redirectPath: location.search ? `${location.pathname}${location.search}&isResync=true` : `${location.pathname}?isResync=true`,
        clientId,
      });
      return res.data;
    } catch (error) { return "" }
  };

  const connectProfile = async () => {
    setLoadingConnect(true);
    try {
      const url = await getSsoUrl();
      if (url) {
        window.location.href = url;
      }
    } catch (error) { }
    setLoadingConnect(false);
  };

  let removeResyncParamTimeOut: NodeJS.Timeout
  const retrieveProfiles = async (isResync?: boolean) => {
    if(loadingRef.current) {
      return
    }
    loadingRef.current = true;
    setLoading(true);
    try {
      const res = await SocialApi.retrieveConnectProfile({
        clientId,
        isResync: isResync || isResyncSearch,
      });
      const profiles = res.data?.profiles || [];
      setListProfile({ profiles, clientId });

      removeResyncParamTimeOut = setTimeout(() => {
        // setTimeout because ignore resync After receiving the notification
        if (isResyncSearch) {
          searchParams.delete('isResync');
          setSearchParams(searchParams);
        }
      }, 5000)
    } catch (error) { }
    setLoading(false);
    loadingRef.current = false;
  };

  const checkProfileConnected = async () => {
    if (clientId !== profileClientId) {
      await retrieveProfiles();
    }
  };
  useEffect(() => {
    (async () => {
      await checkProfileConnected();
    })();
  }, [clientId]);

  useEffect(() => {
    if (fetchCount > 0 && !isResyncSearch) {
      retrieveProfiles();
    }

    return () => {
      clearTimeout(removeResyncParamTimeOut)
    }
  }, [fetchCount])

  return {
    isProfileConnected: !!profiles.length,
    connectProfile,
    loading,
    loadingConnect,
    getSsoUrl,
    retrieveProfiles,
  };
}

export default useSocial;
