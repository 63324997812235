import { useTranslation } from "react-i18next";
import CheckedAnimation from "../../../../assets/animations/checked.gif";
import "./index.scss";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { SocialApi } from "../../../../apis/social.api";
import { ROUTE_PATHS } from "../../../../constants/router.constants";


function ConnectedProfileSuccessPage() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const clienIdStr = searchParams.get("clientId") || "";
  const navigate = useNavigate();

  useEffect(() => {
    const clientId = Number(clienIdStr);
    if (clientId) {
      SocialApi.syncConnectedProfiles(clientId);
    } else {
      navigate(ROUTE_PATHS.ClientNotFound);
    }
  }, [clienIdStr])

  return (
    <div className="connected-profiles-success auth__form">
      <div className="connected-profiles-success__animation">
        <img src={CheckedAnimation} alt="success" />
      </div>
      <div className="header">{t("connectedProfileSuccess.title")}</div>
      <p className="description" dangerouslySetInnerHTML={{
        __html: t("connectedProfileSuccess.description"),
      }}>
      </p>
    </div>
  );
}

export default ConnectedProfileSuccessPage;
