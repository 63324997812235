import { Button } from "antd";
import { useTranslation } from "react-i18next";
import useZoho from "../../../../../hooks/useZoho";
import SVGIcons from "../../../../icons/svgs";
import "./index.scss";

interface Props {
    children: JSX.Element;
    isEmpty?: boolean;
    onAdd?: () => void;
}
function ChartWrapper(props: Props) {
    const { children, isEmpty, onAdd } = props;
    const { t } = useTranslation();
    const { connectZoho, connectZohoLoading, zohoConnected } = useZoho();
    const onAddData = async () => {
        if (!zohoConnected.isConnected) {
            await connectZoho();
        }
        else {
            onAdd?.();
        }
    }

    return (
        <div className="chart-wrapper">
            {isEmpty && <div className="empty-overlay">
                <Button
                    loading={connectZohoLoading}
                    onClick={onAddData}
                    type="primary" ghost>
                    <SVGIcons.PlusIcon />
                    <span className="mr-right-4">{t("salesAnalytics.addData")}</span>
                </Button>
            </div>}
            {children}
        </div>
    );
}

export default ChartWrapper;
