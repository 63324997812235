import SVGIcons from "../../../../../icons/svgs";
import AvatarSocial from "../../../social-content/components/avatar-social";
import AvatarDefault from "../../../../../../assets/images/avatar-default.svg";
import { SocialContentModel } from "../../../../../../models/content.model";
import "./index.scss";
import { SocialType } from "../../../../../../constants/app.enums";
import { useRecoilValue } from "recoil";
import { listProfileSelector } from "../../../../../../states/social";
import { NetworkModel } from "../../../../../../models/social.model";
import ListMedia from "../../../social-content/components/list-media";

interface TopPostItemInterface {
  topPostInfo?: SocialContentModel;
}
const TopPostItem = (props: TopPostItemInterface) => {
  const { topPostInfo } = props;
  const listProfile = useRecoilValue(listProfileSelector);

  const renderPost = () => {
    const profile =
      topPostInfo &&
      listProfile.find((p) => p.platform === topPostInfo.platform);
    if (!profile) return renderPostDefault();
    switch (topPostInfo?.platform) {
      case SocialType.Facebook:
        return renderPostFacebook(profile);
      case SocialType.Instagram:
        return renderPostInstagram(profile);
      case SocialType.Tiktok:
        return renderPostTiktok(profile);
      case SocialType.Youtube:
        return renderPostYoutube(profile);
      default:
        return renderPostDefault();
    }
  };

  const renderPostDefault = () => {
    return (
      <div className="toppost-info">
        <div className="toppost-info-profile">
          <div className="toppost-info-profile__left">
            <AvatarSocial avatar={AvatarDefault} size={52} />
          </div>
          <div className="toppost-info-profile__right">
            <div className="name">Business Name</div>
            <div className="network">Social Network</div>
          </div>
        </div>
      </div>
    );
  };

  const renderPostFacebook = (profile: NetworkModel) => {
    return (
      <div className="toppost-info">
        <div className="toppost-info-profile">
          <div className="toppost-info-profile__left">
            <AvatarSocial
              avatar={profile.userImage || AvatarDefault}
              size={52}
            />
          </div>
          <div className="toppost-info-profile__right">
            <div className="name">{profile.name || "Business Name"}</div>
            <div className="network">Facebook Page</div>
          </div>
        </div>
        <div className="toppost-info-content">
          <div className="caption">{topPostInfo?.post}</div>
          {topPostInfo?.medias?.length ? (
            <div className="media">
              <ListMedia.ListMediaContentCard
                listMedia={topPostInfo?.medias || []}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const renderPostYoutube = (profile: NetworkModel) => {
    return (
      <div className="toppost-info">
        <div className="toppost-info-profile">
          <div className="toppost-info-profile__left">
            <AvatarSocial
              avatar={profile.userImage || AvatarDefault}
              size={52}
            />
          </div>
          <div className="toppost-info-profile__right">
            <div className="name">{profile.name || "Business Name"}</div>
            <div className="network">Youtube</div>
          </div>
        </div>
        <div className="toppost-info-content">
          <div className="caption">{topPostInfo?.post}</div>
          {topPostInfo?.medias?.length ? (
            <div className="media">
              <ListMedia.ListMediaContentCard
                listMedia={topPostInfo?.medias || []}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const renderPostInstagram = (profile: NetworkModel) => {
    const { isReels } = topPostInfo || {};
    return (
      <div className="toppost-info">
        <div className="toppost-info-profile">
          <div className="toppost-info-profile__left">
            <AvatarSocial
              avatar={profile.userImage || AvatarDefault}
              size={52}
            />
          </div>
          <div className="toppost-info-profile__right">
            <div className="name">{profile.name || "Business Name"}</div>
            <div className="network">Instagram Business</div>
          </div>
        </div>
        <div className="toppost-info-content">
          {isReels && <div className="caption">{topPostInfo?.post}</div>}
          {topPostInfo?.medias?.length ? (
            <div className="media">
              <ListMedia.ListMediaContentCard
                listMedia={topPostInfo?.medias || []}
              />
            </div>
          ) : (
            ""
          )}
          {!isReels && (
            <>
              <div className="interactions">
                <div className="interaction-left">
                  <SVGIcons.InstagramLikeIcon />
                  <SVGIcons.InstagramRepIcon />
                  <SVGIcons.InstagramDMIcon />
                </div>
                <div className="interaction-right">
                  <SVGIcons.InstagramBookmarkIcon />
                </div>
              </div>
              <div className="caption">
                <span className="profile-name">{profile.name}</span>{" "}
                {topPostInfo?.post}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderPostTiktok = (profile: NetworkModel) => {
    return (
      <div className="toppost-info toppost-info-tiktok">
        <div className="toppost-info-profile">
          <div className="toppost-info-profile__left">
            <AvatarSocial
              avatar={profile.userImage || AvatarDefault}
              size={52}
            />
          </div>
          <div className="toppost-info-profile__right">
            <div className="name">{profile.name || "Business Name"}</div>
            <div className="network">
              <div className="caption">{topPostInfo?.post}</div>

              {topPostInfo?.medias?.length ? (
                <div className="media">
                  <ListMedia.ListMediaContentCard
                    listMedia={topPostInfo?.medias || []}
                  />
                  <div className="interactions">
                    <div className="interaction-item">
                      <div className="interaction-item__icon">
                        <SVGIcons.TiktokLikeIcon />
                      </div>
                      <span>0</span>
                    </div>
                    <div className="interaction-item">
                      <div className="interaction-item__icon">
                        <SVGIcons.TiktokCommentIcon />
                      </div>
                      <span>0</span>
                    </div>
                    <div className="interaction-item">
                      <div className="interaction-item__icon">
                        <SVGIcons.TiktokShareIcon />
                      </div>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="top-post-item">
      {renderPost()}
      <div className="toppost-table">
        <div className="engagement">Engagement</div>
        <div className="statitics">
          <div className="statitic-item">
            <div className="statitic-item-left">
              <SVGIcons.ThumbUpIcon /> Likes
            </div>
            <div className="statitic-item-right">
              {topPostInfo?.likeCount || 0}
            </div>
          </div>
          <div className="statitic-item">
            <div className="statitic-item-left">
              <SVGIcons.MessageCircleIcon /> Comments
            </div>
            <div className="statitic-item-right">
              {topPostInfo?.commentsCount || 0}
            </div>
          </div>
          {
            topPostInfo?.platform !== SocialType.Youtube ?
              <div className="statitic-item">
                <div className="statitic-item-left">
                  <SVGIcons.ArrowBackUpIcon /> Shares
                </div>
                <div className="statitic-item-right">
                  {topPostInfo?.sharesCount || 0}
                </div>
              </div> :
              <div className="statitic-item">
                <div className="statitic-item-left">
                  <SVGIcons.BookmarkIcon /> Saved
                </div>
                <div className="statitic-item-right">
                  {topPostInfo?.saved || 0}
                </div>
              </div>
          }

          {(!topPostInfo?.isReels && topPostInfo?.platform !== SocialType.Instagram && topPostInfo?.platform !== SocialType.Tiktok) && (
            <div className="statitic-item">
              <div className="statitic-item-left">
                <SVGIcons.ClickIcon /> Clicks
              </div>
              <div className="statitic-item-right">{0}</div>
            </div>
          )}
          <div className="statitic-item">
            <div className="statitic-item-left">
              <SVGIcons.EyeCheckIcon /> {topPostInfo?.platform !== SocialType.Tiktok && topPostInfo?.platform !== SocialType.Youtube ? "Impressions" : "Video Views"}
            </div>
            <div className="statitic-item-right">
              {topPostInfo?.impressionsCount || 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPostItem;
