const nodeEnv = process.env.REACT_APP_CUSTOM_NODE_ENV || "development";

export const config: any = {
  development: {
    awsConfig: {
      aws_project_region: "us-east-1",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_Qi8uoq4dt",
      aws_user_pools_web_client_id: "25ldn142sbrim1irt62vc8st0d",
      oauth: {
        domain: "dev-rocketlevel.auth.us-east-1.amazoncognito.com",
        scope: ["email", "openid", "profile"],
        redirectSignIn: "http://localhost:3000",
        redirectSignOut: "http://localhost:3000/sign-in",
        responseType: "code",
      },
    },
    firebaseConfig: {
      apiKey: "AIzaSyA4K5rE9hiYRbfDNLPxm3ym8zOMAZtLri0",
      authDomain: "stunning-strand-371807.firebaseapp.com",
      projectId: "stunning-strand-371807",
      storageBucket: "stunning-strand-371807.appspot.com",
      messagingSenderId: "330136481280",
      appId: "1:330136481280:web:010abd311406a4ce6c72e5",
      measurementId: "G-K2JMD5KN1Q",
    },
    awsS3Config: {
      region: process.env.REACT_APP_S3_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
      },
    },
  },
  staging: {
    awsConfig: {
      aws_project_region: "us-east-1",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_QhWSbpkGZ",
      aws_user_pools_web_client_id: "14546vhtbtq09v1bjpbikslg0p",
      oauth: {
        domain: "https://staging-rocketlevel.auth.us-east-1.amazoncognito.com",
        scope: ["email", "openid", "profile"],
        redirectSignIn: "https://appv2-stg.rocketlevel.com/",
        redirectSignOut: "https://appv2-stg.rocketlevel.com/sign-in",
        responseType: "code",
      },
    },
    firebaseConfig: {
      apiKey: "AIzaSyA4K5rE9hiYRbfDNLPxm3ym8zOMAZtLri0",
      authDomain: "stunning-strand-371807.firebaseapp.com",
      projectId: "stunning-strand-371807",
      storageBucket: "stunning-strand-371807.appspot.com",
      messagingSenderId: "330136481280",
      appId: "1:330136481280:web:010abd311406a4ce6c72e5",
      measurementId: "G-K2JMD5KN1Q",
    },
    awsS3Config: {
      region: process.env.REACT_APP_S3_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
      },
    },
  },
  production: {
    awsConfig: {
      aws_project_region: "us-east-1",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_b7sQkmPIi",
      aws_user_pools_web_client_id: "1eiiietj015dds558j5e4o5ago",
      oauth: {
        domain: "https://prod-rocketlevel.auth.us-east-1.amazoncognito.com",
        scope: ["email", "openid", "profile"],
        redirectSignIn: "https://appv2.rocketlevel.com/",
        redirectSignOut: "https://appv2.rocketlevel.com/sign-in",
        responseType: "code",
      },
    },
    firebaseConfig: {
      apiKey: "AIzaSyAOrKWbru3A7iJvQkGUj5rOwjIbSoKaNb4",
      authDomain: "rocketlevel-2023.firebaseapp.com",
      projectId: "rocketlevel-2023",
      storageBucket: "rocketlevel-2023.appspot.com",
      messagingSenderId: "849746022003",
      appId: "1:849746022003:web:dfda63924e13f9ed81a406",
    },
    awsS3Config: {
      region: process.env.REACT_APP_S3_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
      },
    },
  },
};

export const AppConfig = config[nodeEnv];
