import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { StripesApi } from "../../apis/stripes.api";
import { ROUTE_PATHS } from "../../constants/router.constants";
import "./index.scss";

function StripePage() {
    const [loading, setLoading] = useState<boolean>(false);
    const getStripeLink = async () => {
        setLoading(true);
        try {
            const { data } = await StripesApi.getManageBillingUrl()
            if(data.url) {
                window.location.replace(data.url)
            }
        } catch (error) {
            window.location.replace(ROUTE_PATHS.ClientNotFound)
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getStripeLink()
    }, [])

    return (
        <div className="general-settings-page page-content">
            {loading && (
                <div className="stripe-page">
                    <Spin
                        className="spin"
                        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                    ></Spin>
                </div>
            )}
        </div>
    );
}

export default StripePage;
