import { Button, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { IntegrationType } from "../../../../../constants/app.enums";
import {
  clientSitesState,
  connectionState,
  integrationState,
} from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import Birdeye from "../birdeye";
import CallRail from "../call-rail";
import GoogleAnalytics from "../google-analytics";
import GravityForm from "../gravity-form";
import useGoogle from "../../../../../hooks/useGoogle";
import GoogleMyBusiness from "../google-my-business";
import { useEffect, useState } from "react";
import "./index.scss";

const TitleAddConnection: { [key: string]: string } = {
  [IntegrationType.GAIntegrations]: "Google Analytics",
  [IntegrationType.GMBIntegrations]: "Google My Business",
  [IntegrationType.BEIntegrations]: "Birdeye",
  [IntegrationType.CRIntegrations]: "CallRail",
  [IntegrationType.GFIntegrations]: "Gravity Forms",
};
const ListConnection = [
  {
    label: TitleAddConnection[IntegrationType.GAIntegrations],
    key: IntegrationType.GAIntegrations,
    icon: <SVGIcons.LogoGAIcon />,
  },
  {
    label: TitleAddConnection[IntegrationType.GMBIntegrations],
    key: IntegrationType.GMBIntegrations,
    icon: <SVGIcons.LogoGMBIcon />,
  },
  {
    label: TitleAddConnection[IntegrationType.BEIntegrations],
    key: IntegrationType.BEIntegrations,
    icon: <SVGIcons.LogoBEIcon />,
  },
  {
    label: TitleAddConnection[IntegrationType.CRIntegrations],
    key: IntegrationType.CRIntegrations,
    icon: <SVGIcons.LogoCRIcon />,
  },
  {
    label: TitleAddConnection[IntegrationType.GFIntegrations],
    key: IntegrationType.GFIntegrations,
    icon: <SVGIcons.LogoGFIcon />,
  },
];
function AddConnection() {
  const { t } = useTranslation();
  const { loginGoogle } = useGoogle({});

  const [open, setOpen] = useState(false);
  const [connection, setConnection] = useRecoilState(connectionState);
  const integrationValues = useRecoilValue(integrationState);

  const { currentConnection, listGAConnection } = connection;

  const handleCancel = () => {
    setConnection({ ...connection, currentConnection: "" });
    setOpen(false);
  };

  const onAddConnection = () => {
    setOpen(true);
  };

  const handleMenuClick = (key: IntegrationType) => {
    if (!integrationValues) return;
    switch (key) {
      case IntegrationType.GAIntegrations:
        break;
      case IntegrationType.BEIntegrations:
        break;
      case IntegrationType.CRIntegrations:
        break;
      case IntegrationType.GFIntegrations:
        if (integrationValues[IntegrationType.GFIntegrations].length === 3) {
          return showNotification("error", t("clientSites.limitConnectionGF"));
        }
        break;
      default:
        break;
    }
    if (
      key !== IntegrationType.GFIntegrations &&
      integrationValues[key as IntegrationType]?.length
    ) {
      return showNotification("error", t("clientSites.connectionAlready"));
    }
    setConnection({ ...connection, currentConnection: key });
  };

  const renderContent = () => {
    switch (currentConnection) {
      case IntegrationType.GAIntegrations:
        return <GoogleAnalytics isAddConnection={true} isOpenAddConnection={open} />;
      case IntegrationType.GMBIntegrations:
        return <GoogleMyBusiness isAddConnection={true} isOpenAddConnection={open} />;
      case IntegrationType.BEIntegrations:
        return <Birdeye isAddConnection={true} />;
      case IntegrationType.CRIntegrations:
        return <CallRail isAddConnection={true} />;
      case IntegrationType.GFIntegrations:
        return <GravityForm isAddConnection={true} />;
      default:
        break;
    }
  };
  const renderListConnection = () => {
    if (currentConnection) return renderContent();
    return (
      <Space size={12} direction="vertical" className="list-connection">
        {ListConnection.map((connection) => {
          return (
            <div
              className="connection-item"
              key={connection.key}
              onClick={() => handleMenuClick(connection.key)}
            >
              <div className="icon">{connection.icon}</div>
              <div className="label">{connection.label}</div>
            </div>
          );
        })}
      </Space>
    );
  };

  useEffect(() => {
    if (!currentConnection) setOpen(false);
  }, [currentConnection]);
  return (
    <div className="add-connection">
      <Button onClick={onAddConnection}>
        <SVGIcons.PlusIcon />
        <span className="mr-right-4">{t("clientSites.addConnection")}</span>
      </Button>
      <Modal
        title={
          `${t("clientSites.addConnection")}` +
          (currentConnection
            ? ` - ${TitleAddConnection[currentConnection]}`
            : "")
        }
        open={open}
        onCancel={handleCancel}
        closable
        maskClosable
        footer={
          !currentConnection && (
            <Button type="text" onClick={handleCancel}>
              {t("common.close")}
            </Button>
          )
        }
        className="custom-create-modal add-connection-modal"
      >
        {renderListConnection()}
      </Modal>
    </div>
  );
}

export default AddConnection;
