import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanType } from "../../../constants/app.enums";
import useClient from "../../../hooks/useClient";
import useClientPlan from "../../../hooks/useClientPlan";
import DateUtils from "../../../utils/date.utils";
import "./index.scss";
import UpgradeModal from "./upgrade-modal";
import { TRIAL_REMINDER } from "../../../constants/app-constants";
import useAdminSite from "../../../hooks/useAdminSite";

type TimeLeft = {
  days: number;
  daysDifference: number;
  hours: string | number;
  minutes: string | number;
  seconds: string | number;
};

function Countdown() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { client } = useClient();
  const isAdminSite = useAdminSite();
  const [initialCalculationDone, setInitialCalculationDone] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    daysDifference: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const { setExpiredPlan } = useClientPlan()

  const openModal = () => {
    setIsOpen(true);
  };

  const calculateTimeLeft = () => {
    const currentDate = moment();
    const expiredTime = moment(DateUtils.addUTCCharater(client?.userPlan?.expiredTime));
    
    if (expiredTime > currentDate) {
      const timeDiff = expiredTime.diff(currentDate);
      const duration = moment.duration(timeDiff);
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      const daysDifference = Math.ceil(duration.asDays());


      setTimeLeft({
        days: days,
        daysDifference: daysDifference,
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft();
      setInitialCalculationDone(true);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const renderCountdown = () => {
    const currentDatetime = moment();
    const expiredTime =     moment(DateUtils.addUTCCharater(client?.userPlan?.expiredTime));

    const firstConditionExpiredTime = expiredTime.clone().add(0 - TRIAL_REMINDER.FIRST, 'd');
    const secondConditionExpiredTime = expiredTime.clone().add(0 - TRIAL_REMINDER.SECOND, 'h');
    
    if (!expiredTime || expiredTime.diff(currentDatetime) < 0 || isNaN(expiredTime.diff(currentDatetime))) {
      return null;
    }
  
    if (firstConditionExpiredTime.diff(currentDatetime) > 0)  {
      return (
        <div className="countdown__banner green">
          {t("upgradeModal.freeMessage", { days: timeLeft.daysDifference })}{" "}
          <span className="countdown__premium" onClick={openModal}>
            {t("upgradeModal.ourPremiumPlan")}
          </span>
        </div>
      );
    }
  
    if (secondConditionExpiredTime.diff(currentDatetime) > 0) {
      return (
        <div className="countdown__banner orange">
          {t("upgradeModal.trialMessage", { days: timeLeft.daysDifference})}{" "}
          <span className="countdown__premium" onClick={openModal}>
            {t("upgradeModal.upgradeToPremium")}
          </span>
        </div>
      );
    }
  
    return (
       <div className="countdown__banner red">
        {t("upgradeModal.premiumMessage1")}{" "}
        <span className="countdown__timer">{timeLeft.days * 24 + Number(timeLeft.hours)}</span> :{" "}
        <span className="countdown__timer">{timeLeft.minutes}</span> :{" "}
        <span className="countdown__timer">{timeLeft.seconds}</span> .{" "}
        {t("upgradeModal.premiumMessage2")}
        <span className="countdown__premium" onClick={openModal}>
          {t("upgradeModal.upgradeNow")}
        </span>
      </div>
    );
    
  };

  useEffect(() => {
    if (initialCalculationDone && renderCountdown() === null && client?.userPlan?.planType === PlanType.Trial) {
      // openModal();
      setExpiredPlan()
    }
  }, [timeLeft, initialCalculationDone]);

  return (
    <>
      {
          client?.userPlan?.planType === PlanType.Trial && initialCalculationDone && !isAdminSite && <div className="countdown">{renderCountdown()}</div>
      }
      <UpgradeModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default Countdown;
