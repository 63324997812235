import { Button, Dropdown, Form, Input, Modal, Spin, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../../apis/social.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../../constants/app-constants";
import { ApprovalStatusEnum } from "../../../../../../constants/app.enums";
import { requiredGuestUserState } from "../../../../../../states/social";
import { isOnlyManageContentSelector } from "../../../../../../states/socialPermission";
import i18n from "../../../../../../utils/i18n";
import LocalUtils from "../../../../../../utils/local.utils";
import showNotification from "../../../../../common/notification";
import SVGIcons from "../../../../../icons/svgs";
import "./index.scss";

const ApprovalItem = {
  [ApprovalStatusEnum.Pending]: {
    key: ApprovalStatusEnum.Pending,
    label: "Require Approval",
    status: "Pending for approval",
    iconaction: <SVGIcons.UserExclamationIcon />,
    tooltip: i18n.t("social.content.pendingApprovalTooltip"),
    className: "pending",
  },
  [ApprovalStatusEnum.NotRequied]: {
    key: ApprovalStatusEnum.None,
    label: "Not required",
    status: "Not required",
    iconaction: <SVGIcons.UserCheckIcon />,
    tooltip: i18n.t("social.content.notRequiredTooltip"),
    className: "not_required",
  },
  [ApprovalStatusEnum.Approved]: {
    key: ApprovalStatusEnum.Approved,
    label: "Approve",
    status: "Approved",
    iconaction: <SVGIcons.UserCheckIcon />,
    tooltip: i18n.t("social.content.approvedApprovalTooltip"),
    className: "approved",
  },
  [ApprovalStatusEnum.Declined]: {
    key: ApprovalStatusEnum.Declined,
    label: "Decline",
    status: "Declined",
    iconaction: <SVGIcons.UserXIcon />,
    tooltip: i18n.t("social.content.declinedApprovalTooltip"),
    className: "declined",
  },
};

interface ApprovalEditStatusProps {
  approvalStatus: ApprovalStatusEnum;
  onChangeStatus: (status: ApprovalStatusEnum) => void;
  contentId: number;
  exceptStatus?: ApprovalStatusEnum[];
  isMobile?: boolean;
}

const ApprovalEditStatus = (props: ApprovalEditStatusProps) => {
  const { approvalStatus, onChangeStatus, contentId, exceptStatus, isMobile } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openModalApprovalStatus, setOpenModalApprovalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [requiredGuestUser, setRequiredGuestUser] = useRecoilState(
    requiredGuestUserState
  );
  const guestInfo = LocalUtils.getGuestUserInfo();
  const isOnlyManageContent = useRecoilValue(isOnlyManageContentSelector);

  const approvalStatusRender =
    approvalStatus === ApprovalStatusEnum.None
      ? ApprovalStatusEnum.NotRequied
      : approvalStatus;

  const onChangeMenu = async (menuItem: any) => {
    if (!guestInfo?.uuid) {
      setRequiredGuestUser(true);

      if (openModalApprovalStatus) {
        setOpenModalApprovalStatus(false)
      }
    }
    else {
      const value = isMobile ? menuItem : menuItem.key
      setLoading(true);
      try {
        switch (Number(value)) {
          case ApprovalStatusEnum.Pending:
            await SocialApi.toggleRequireApproval(contentId, true);
            showNotification(
              "success",
              t("social.content.pendingApprovalNotify")
            );
            break;
          case ApprovalStatusEnum.Approved:
            await SocialApi.approveContent(contentId);
            showNotification("success", t("social.content.approvedNotify"));
            break;
          case ApprovalStatusEnum.Declined:
            setIsOpenModal(true);
            setLoading(false);
            return;
          case ApprovalStatusEnum.None:
            await SocialApi.toggleRequireApproval(contentId, false);
            showNotification("success", t("social.content.notRequiredNotify"));
            break;
          default:
            break;
        }
        onChangeStatus(Number(value));
      } catch (error) { }
      setOpenModalApprovalStatus(false);
      setLoading(false);
    }
  };

  const decline = () => {
    form.submit();
  };

  const submit = async () => {
    setLoading(true);
    try {
      await SocialApi.rejectContent(contentId, reason);
      onChangeStatus(ApprovalStatusEnum.Declined);
      showNotification("delete", t("social.content.declinedNotify"));
      setOpenModalApprovalStatus(false);
      handleCancel();
    } catch (error) { }
    setLoading(false);
  };

  const handleCancel = () => {
    setIsOpenModal(false);
    form.resetFields();
  };

  const onChangeReason = (e: any) => {
    setReason(e.target.value);
  };

  const renderMenuOptions = (approvalStatus: ApprovalStatusEnum) => {
    const menuOptions = Object.values(ApprovalItem).filter((item) => {
      if (
        [
          ApprovalStatusEnum.Approved,
          ApprovalStatusEnum.Declined,
        ].includes(approvalStatus) &&
        Number(item.key) === ApprovalStatusEnum.Pending
      )
        return false;
      if (exceptStatus?.includes(Number(item.key))) return false;
      return Number(item.key) !== approvalStatus;
    })
    return menuOptions.sort((a, b) => a.key - b.key).map((item, index) => {
      return (
        <div
          key={index}
          onClick={() => onChangeMenu(item.key)}
          className={`modal-approval-status-item ${item.key === ApprovalStatusEnum.Declined && 'decline-text'}`}>{item.label}</div>
      )
    })
  }

  return (
    <>
      <div className="edit-approval">
        <div className="edit-approval-status">
          {ApprovalItem[approvalStatusRender]?.status}
        </div>
        <Spin spinning={loading}>
          <Dropdown
            menu={{
              items: Object.values(ApprovalItem).filter((item) => {
                if (
                  [
                    ApprovalStatusEnum.Approved,
                    ApprovalStatusEnum.Declined,
                  ].includes(approvalStatus) &&
                  Number(item.key) === ApprovalStatusEnum.Pending
                )
                  return false;
                if (exceptStatus?.includes(Number(item.key))) return false;
                return Number(item.key) !== approvalStatus;
              }),
              onClick: onChangeMenu,
            }}
            trigger={["click"]}
            placement="bottomRight"
            disabled={isOnlyManageContent}
          >
            <Tooltip
              title={ApprovalItem[approvalStatusRender]?.tooltip}
              placement="left"
            >
              <div
                className={`icon-approval ${ApprovalItem[approvalStatusRender]?.className}`}
              >
                {ApprovalItem[approvalStatusRender]?.iconaction}
              </div>
            </Tooltip>
          </Dropdown>
        </Spin>
      </div>

      <div className="mobile-action" >
        <Button
          className={`btn-comment-mobile-wrapper-right ${ApprovalItem[approvalStatusRender]?.className}`}
          onClick={() =>
            setOpenModalApprovalStatus(true)
          }
          style={isOnlyManageContent ? { pointerEvents: "none", opacity: 0.6 } : {}}
        >
          {ApprovalItem[approvalStatusRender]?.iconaction}
          {ApprovalItem[approvalStatusRender]?.status}
        </Button>
      </div>


      <Modal
        wrapClassName="approval-buttons-modal"
        open={openModalApprovalStatus}
        className="custom-modal-approval-status"
        footer={null}
        closeIcon={undefined}
        centered
        width='90vw'
        onCancel={() => {
          setOpenModalApprovalStatus(false)
        }}
      >
        <Spin spinning={loading}>

          {renderMenuOptions(approvalStatus)}
        </Spin>
      </Modal>
      <Modal
        open={isOpenModal}
        title={t(`social.content.declinedModalTitle`)}
        okText={t("common.move")}
        className="custom-create-modal"
        onCancel={() => setIsOpenModal(false)}
        footer={
          <div className="remove-connection-footer">
            <Button type="default" onClick={handleCancel}>
              {t("common.cancel")}
            </Button>
            <Button type="primary" danger onClick={decline} loading={loading}>
              {t("common.decline")}
            </Button>
          </div>
        }
      >
        <div className="modal-decline-content">
          <div className="description">
            {t("social.content.declinedModalDescription")}
          </div>
          <Form
            name="createSiteForm"
            layout="vertical"
            onFinish={submit}
            form={form}
            autoComplete="off"
            validateMessages={VALIDATION_MESSAGE_CONFIG}
          >
            <Form.Item
              label={t("social.content.yourComment")}
              name="yourComment"
              rules={[{ required: true, message: "Comment field is required" }]}
            >
              <Input
                placeholder="Your comment here..."
                onChange={onChangeReason}
                value={reason}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export { ApprovalEditStatus };
