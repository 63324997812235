import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AuthApi } from "../../apis/auth.api";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../constants/permission.constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import useAdminSite from "../../hooks/useAdminSite";
import {
  authErrorSelector,
  authFunctionsSelector,
  authState,
  authUserSelector,
  profileUpdatedState,
} from "../../states/auth";
import { accessServiceState } from "../../states/social";
import { ServiceType } from "../../constants/app.enums";

interface ProtectedRouteProps {
  children: JSX.Element;
  allowFunctions?: string[];
  service?: ServiceType
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { isLogined, user } = useRecoilValue(authState);
  const setUserState = useSetRecoilState(authUserSelector);
  const functions = useRecoilValue(authFunctionsSelector);
  const setAuthError = useSetRecoilState(authErrorSelector);
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const clientIdParams = Number(searchParams.get("clientId"));
  const isAdmin = useAdminSite();
  const noPermission = useRef(false);
  const navigate = useNavigate();
  const [profileUpdated, setProfileUpdated] =
    useRecoilState(profileUpdatedState);
    const [accessServices] =
    useRecoilState(accessServiceState);

  const updateCurrentProfile = async () => {
    if (profileUpdated || !isLogined || !user || !user.userId) return;

    const { data: userProfile } = await AuthApi.getUserProfile();

    // Check if user login with client's site and
    if (!isAdmin) {
      if (!userProfile.clients || !userProfile.clients.length) {
        navigate(ROUTE_PATHS.SignIn);
        return;
      }

      let selectedClient = undefined;
      if (!clientIdParams) {
        selectedClient = userProfile.clients.find(
          (x) => x.id === user?.clientSelected?.id
        );
      } else
        selectedClient = userProfile.clients.find(
          (x) => x.id === clientIdParams
        );

      if (!selectedClient) {
        navigate(ROUTE_PATHS.SelectCompany);
        return;
      }

      userProfile.clientSelected = selectedClient;
    }

    setUserState(userProfile);
    setProfileUpdated(true);
  };
  useEffect(() => {
    updateCurrentProfile();
    return () => {
      if (noPermission.current) {
        setAuthError(t("common.noPermission"));
      }
    };
  }, []);

  useEffect(()=> {
    if (accessServices.isFetched) {
      if (
        props.service && accessServices.data.filter(x => x === props.service).length === 0
      ) {
        navigate(ROUTE_PATHS.Home);
      }
    };
  }, [accessServices, navigate, props.service])

  if (!isLogined || !user || !user.userId) {
    return (
      <Navigate
        to={isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn}
        state={{ redirectUrl: pathname + search }}
        replace
      />
    );
  }

  if (
    isLogined &&
    !isAdmin &&
    pathname !== ROUTE_PATHS.SelectCompany &&
    !user?.clientSelected
  ) {
    return (
      <Navigate
        to={
          user?.clients?.length > 0
            ? ROUTE_PATHS.SelectCompany
            : ROUTE_PATHS.SignIn
        }
        state={{ redirectUrl: pathname + search }}
        replace
      />
    );
  }

  if (!props.allowFunctions || !props.allowFunctions.length) {
    return props.children;
  }

  if (
    functions.some(
      (x) =>
        x.functionCode === FunctionEnum.Everything &&
        x.permissions &&
        x.permissions.some((p) => p > PermissionEnum.None)
    )
  ) {
    return props.children;
  }

  for (let index = 0; index < props.allowFunctions.length; index++) {
    const element = props.allowFunctions[index];

    if (
      !functions.some(
        (x) =>
          x.functionCode === element &&
          x.permissions.some((p) => p > PermissionEnum.None)
      )
    ) {
      noPermission.current = true;
      return (
        <Navigate
          to={isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn}
          replace
        />
      );
    }
  }

  return props.children;
};

export default ProtectedRoute;
