import { Col, Progress, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../apis/social.api";
import useClient from "../../../../../hooks/useClient";
import {
  DashboardCategoryModel,
  GetDashboardHashTagParams,
} from "../../../../../models/social.model";
import { socialDashboardDateRangeState } from "../../../../../states/social-dashboard";
import "./index.scss";
import TagSocial from "../../social-content/components/tag-social";

const emptyData: DashboardCategoryModel[] = [
  {
    category: "No tag",
  },
  {
    category: "No tag",
  },
  {
    category: "No tag",
  },
  {
    category: "No tag",
  },
  {
    category: "No tag",
  },
];

const Categories = () => {
  const { clientId } = useClient();
  const { t } = useTranslation();
  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const [mostUsed, setMostUsed] = useState<DashboardCategoryModel[]>(emptyData);
  const [loadingMostUsed, setLoadingMostUsed] = useState(false);
  const [mostViewed, setMostViewed] =
    useState<DashboardCategoryModel[]>(emptyData);
  const [loadingMostViewed, setLoadingMostViewed] = useState(false);
  const [mostEngaged, setMostEngaged] =
    useState<DashboardCategoryModel[]>(emptyData);
  const [loadingMostEngaged, setLoadingMostEngaged] = useState(false);

  const initCategoryData = async () => {
    const { startDate, endDate } = dateRange;
    const params = { startDate, endDate, clientId };
    if (startDate && endDate && clientId) {
      initMostUsedCategories(params);
      initMostViewedCategories(params);
      initMostEngagedCategories(params);
    }
  };

  const initMostUsedCategories = async (params: GetDashboardHashTagParams) => {
    setLoadingMostUsed(true);
    try {
      const { data } = await SocialApi.SocialDashboard.getMostUsedCategory(
        params
      );
      setMostUsed([...data, ...emptyData].slice(0, 5));
    } catch (error) { }
    setLoadingMostUsed(false);
  };

  const initMostViewedCategories = async (params: GetDashboardHashTagParams) => {
    setLoadingMostViewed(true);
    try {
      const { data } = await SocialApi.SocialDashboard.getMostViewedCategory(
        params
      );
      setMostViewed([...data, ...emptyData].slice(0, 5));
    } catch (error) { }
    setLoadingMostViewed(false);
  };

  const initMostEngagedCategories = async (params: GetDashboardHashTagParams) => {
    setLoadingMostEngaged(true);
    try {
      const { data } = await SocialApi.SocialDashboard.getMostEngagedCategory(
        params
      );
      setMostEngaged([...data, ...emptyData].slice(0, 5));
    } catch (error) { }
    setLoadingMostEngaged(false);
  };

  useEffect(() => {
    initCategoryData();
  }, [dateRange]);

  const CategoryItem = ({
    category,
    count,
    maxCount,
    color
  }: DashboardCategoryModel & { maxCount?: number }) => {
    const percent =
      count !== undefined && maxCount ? (count / maxCount) * 100 : 0;
    return (
      <div className={`category-item ${count !== undefined ? "active" : ""}`}>
        <div className="category-item-info">
          <span className="category-item-info__name"> {color ? <TagSocial size="custom" text={category} color={color} canDelete={false} /> : category}</span>
          <span className="category-item-info__count">{count || 0}</span>
        </div>
        <Progress percent={percent} showInfo={false} />
      </div>
    );
  };

  return (
    <div className="social-dashboard-categories">
      <Row gutter={16}>
        <Col xl={8} sm={24} xs={24}>
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.mostUsedCategories")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <Spin spinning={loadingMostUsed}>
              <div className="rl-card-category most-used-category">
                {mostUsed.map((item, index) => {
                  return (
                    <CategoryItem
                      {...item}
                      maxCount={mostUsed[0]?.count}
                      key={index}
                    />
                  );
                })}
              </div>
            </Spin>
          </div>
        </Col>
        <Col xl={8} sm={24} xs={24}>
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.mostViewedCategories")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <Spin spinning={loadingMostViewed}>
              <div className="rl-card-category most-viewed-category">
                {mostViewed.map((item, index) => {
                  return (
                    <CategoryItem
                      {...item}
                      maxCount={mostViewed[0]?.count}
                      key={index}
                    />
                  );
                })}
              </div>
            </Spin>
          </div>
        </Col>
        <Col xl={8} sm={24} xs={24}>
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.mostEngagedCategories")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <Spin spinning={loadingMostEngaged}>
              <div className="rl-card-category most-engaged-category">
                {mostEngaged.map((item, index) => {
                  return (
                    <CategoryItem
                      {...item}
                      maxCount={mostEngaged[0]?.count}
                      key={index}
                    />
                  );
                })}
              </div>
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Categories;
