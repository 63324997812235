import { Card, Col, Row, Select } from "antd";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import SVGIcons from "../../components/icons/svgs";
import GeneralSettingCompany from "../../components/views/general-settings/company";
import GeneralSettingAccount from "../../components/views/general-settings/account";
import GeneralSettingAdvanced from "../../components/views/general-settings/advanced";
import GeneralSettingReports from "../../components/views/general-settings/reports";
import GeneralSettingSocialProfiles from "../../components/views/general-settings/social-profiles";
import GeneralSettingTeam from "../../components/views/general-settings/team";
import { ServiceType } from "../../constants/app.enums";
import { accessServiceState } from "../../states/social";
import "./index.scss";

const { Option } = Select;

export enum GeneralSettingItems {
  account = "account",
  company = "company",
  businessProfile = "businessProfile",
  reports = "reports",
  advanced = "advanced",
  socialProfiles = "socialProfiles",
  team = "team",
}

interface menuItemsProps {
  value: GeneralSettingItems;
  label: string;
  icon: ReactNode;
}

function GeneralSettingsPage() {
  const { t } = useTranslation();
  const accessServices = useRecoilValue(accessServiceState);
  const [selected, setSelected] = useState<menuItemsProps>();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  useEffect(() => {
    if (tab) {
      const item = [...menuItems, ...menuItemsSocial].find(
        (x) => x.value === tab
      );
      if (item) {
        setSelected(item);
      }
    } else {
      selectTab(GeneralSettingItems.account);
    }
  }, [searchParams]);

  function selectTab(key: GeneralSettingItems) {
    setSearchParams({ tab: key });
  }

  const menuItems: menuItemsProps[] = [
    {
      value: GeneralSettingItems.account,
      label: t("generalSettings.account"),
      icon: <SVGIcons.UsersIcon />,
    },
    {
      value: GeneralSettingItems.company,
      label: t("generalSettings.company"),
      icon: <SVGIcons.BuildingIcon />,
    },
    // {
    //   value: GeneralSettingItems.businessProfile,
    //   label: t("generalSettings.businessProfile"),
    //   icon: <SVGIcons.BriefcaseIcon />,
    // },
    // {
    //   value: GeneralSettingItems.reports,
    //   label: t("generalSettings.reports"),
    //   icon: <SVGIcons.ClipBoardTextIcon />,
    // },
    {
      value: GeneralSettingItems.advanced,
      label: t("generalSettings.advanced"),
      icon: <SVGIcons.AdjustmentsHorizontalIcon />,
    },
  ];

  const menuItemsSocial: menuItemsProps[] = [
    {
      value: GeneralSettingItems.socialProfiles,
      label: t("generalSettings.socialProfiles"),
      icon: <SVGIcons.PolygonIcon />,
    },
    {
      value: GeneralSettingItems.team,
      label: t("generalSettings.team"),
      icon: <SVGIcons.UsersIcon />,
    },
  ];

  const renderContent = useCallback(() => {
    switch (selected?.value) {
      case GeneralSettingItems.reports:
        return <GeneralSettingReports />;
      case GeneralSettingItems.advanced:
        return <GeneralSettingAdvanced />;
      case GeneralSettingItems.account:
        return <GeneralSettingAccount />;
      case GeneralSettingItems.company:
        return <GeneralSettingCompany />;
      case GeneralSettingItems.socialProfiles:
        return <GeneralSettingSocialProfiles />;
      case GeneralSettingItems.team:
        return <GeneralSettingTeam />;
      default:
        return <></>;
    }
  }, [selected]);

  return (
    <div className="general-settings-page page-content">
      <div className="page-header">
        <Row>
          <Col span={24}>
            <h4 className="page-header__title">{t("generalSettings.title")}</h4>
          </Col>
        </Row>
      </div>
      <div className="page-body">
        <div className="setting-sider-bar">
          {menuItems.map((x) => (
            <div
              className={`setting-sider-bar__item${
                x.value === selected?.value
                  ? " setting-sider-bar__item__active"
                  : ""
              }`}
              key={x.value}
              onClick={() => selectTab(x.value)}
            >
              {x.icon}
              <span>{x.label}</span>
            </div>
          ))}
          {accessServices.data.includes(ServiceType.Social) && (
            <>
              <div className="setting-sider-bar-social">
                {t("generalSettings.social")}
              </div>
              {menuItemsSocial.map((x) => (
                <div
                  className={`setting-sider-bar__item${
                    x.value === selected?.value
                      ? " setting-sider-bar__item__active"
                      : ""
                  }`}
                  key={x.value}
                  onClick={() => selectTab(x.value)}
                >
                  {x.icon}
                  <span>{x.label}</span>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="setting-sider-bar-mobile">
          <Select
            style={{ width: "100%" }}
            suffixIcon={<SVGIcons.ChevronDown />}
            popupClassName="setting-sider-bar-mobile__dropdown"
            onChange={(value: GeneralSettingItems) => selectTab(value)}
            value={selected?.value}
          >
            {menuItems.map((item: menuItemsProps) => (
              <Option value={item.value} key={item.label}>
                <div className={"setting-sider-bar__item"}>
                  {item.icon} <span>{item.label}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <Card title={selected?.label}>{renderContent()}</Card>
      </div>
    </div>
  );
}

export default GeneralSettingsPage;
