import { Col, Row, Tooltip } from "antd";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { CallStatusType, ZohoReportType } from "../../../../constants/app.enums";
import useZoho, { ChartMockupData } from "../../../../hooks/useZoho";
import SpinDiv from "../../../common/spin-div";
import SVGIcons from "../../../icons/svgs";
import { renderVolatility } from "../../dashboard/site-traffic";
import ReportCardForm from "../report-card-form";
import ChartWrapper from "./chart-wrapper";
import ColumnChart from "./column";
import "./index.scss";
import StackedColumn from "./stacked-column";
import { ZohoDataSeries } from "../../../../models/zoho.model";

type Props = {
  selectedRangeName: string;
}

function SalesAnalyticsCharts(props: Props) {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const [isNew, setIsNew] = useState(false);
  const { selectedRangeName } = props;

  const [selectedCardType, setSelectedCardType] = useState<ZohoReportType>();
  const {
    totalLeadWorked,
    callCount,
    callStatus,
    totalCalls,
    totalDemoBook,
    connectionRate,
    bookingCount,
    connectedBookingDate,
    getCardIdByType,
    getCardTitleByType
  } = useZoho();

  function calculateVolatility(newNumber: number, preNumber: number): number {
    if(preNumber === 0 && newNumber > 0) {
      return 100;
    }
    return preNumber === 0 ? 0 : ((newNumber - preNumber) / preNumber) * 100;
  }

  const onOpenDrawer = (type: ZohoReportType) => {
    setSelectedCardType(type);
    setIsOpenDrawer(true);
  };

  const renderIcons = (type: ZohoReportType) => {
    return (
      <div className="icons">
        {/* <Tooltip
          overlayClassName="custom-tooltip"
          title={t("salesAnalytics.refreshLatestData")}
        >
          <Fragment>
            <SVGIcons.NightRefreshIcon />
          </Fragment>
        </Tooltip> */}

        <div onClick={() => {
          onOpenDrawer(type)
          setIsNew(false)
        }}>
          <Tooltip
            overlayClassName="custom-tooltip"
            title={<span>{t("salesAnalytics.editCard")}</span>}
          >
            <Fragment>
              <SVGIcons.NightEditIcon />
            </Fragment>
          </Tooltip>
        </div>
      </div>
    );
  };

  function mapCallStatusData(callStatusType: CallStatusType) {
    if (!callStatus.data?.length) return [];
    return callStatus.data.map(
      (item) => item.items.find((i) => i.result === callStatusType)?.count || 0
    );
  }

  const getCallStatusChartData = () => {
    if (!getCardIdByType(ZohoReportType.CALL_STATUS)) return {
      categories: ChartMockupData.CallStatus.categories,
      data: ChartMockupData.CallStatus.data
    };

    if (!callStatus.data?.length) {
      return {
        categories: ChartMockupData.CallStatus.categories,
        data: ChartMockupData.CallStatus.emptyData
      };
    }
   
    return {
      categories: callStatus.data?.map((item) => item.ownerName) || [],
      data: [
        {
          name: "None",
          data: mapCallStatusData(CallStatusType.NONE),
        },
        {
          name: "Conversation",
          data: mapCallStatusData(CallStatusType.CONVERSATION),
        },
        {
          name: "Dead Line",
          data: mapCallStatusData(CallStatusType.DEAD_LINE),
        },
        {
          name: "Do Not Call",
          data: mapCallStatusData(CallStatusType.DO_NOT_CALL),
        },
        {
          name: "Hung Up",
          data: mapCallStatusData(CallStatusType.HUNG_UP),
        },
        {
          name: "No Answer",
          data: mapCallStatusData(CallStatusType.NO_ANSWER),
        },
        {
          name: "Not Interested",
          data: mapCallStatusData(CallStatusType.NOT_INTERESTED),
        },
        {
          name: "Voice Mail",
          data: mapCallStatusData(CallStatusType.VOICE_MAIL),
        },
        {
          name: "Wrong ICP",
          data: mapCallStatusData(CallStatusType.WRONG_ICP),
        },
        {
          name: "Wrong Number",
          data: mapCallStatusData(CallStatusType.WRONG_NUMBER),
        },
      ]
    };
  };

  function getConnectionRateChartData() {
    if (!getCardIdByType(ZohoReportType.CONNECTION_RATE)) return {
      categories: ChartMockupData.ConnectionRate.categories,
      data: ChartMockupData.ConnectionRate.data
    }
    if (!connectionRate.data?.length) return {
      categories: ChartMockupData.ConnectionRate.categories,
      data: ChartMockupData.ConnectionRate.emptyData
    };
    
  const categories = connectionRate.data.map((item) => item.ownerName);
  const dataSeries: ZohoDataSeries = {};

  connectionRate.data.forEach((item) => {
    item.items.forEach((i) => {
      if (!dataSeries[i.name]) {
        dataSeries[i.name] = [];
      }
      dataSeries[i.name].push(i.value || 0);
    });
  });

  const data = Object.keys(dataSeries).map((key) => ({
    name: key,
    data: dataSeries[key],
  }));

  return {
    categories,
    data,
  };

  }

  function getConnectedBookingDateChartData() {
    if (!getCardIdByType(ZohoReportType.CONNECTED_BOOKING_RATE)) return {
      categories: ChartMockupData.ConnectedBookingDate.categories,
      data: ChartMockupData.ConnectedBookingDate.data
    };

    if (!connectedBookingDate.data?.length) return {
      categories: ChartMockupData.ConnectedBookingDate.categories,
      data: ChartMockupData.ConnectedBookingDate.emptyData
    };

    const categories = connectedBookingDate.data.map((item) => item.ownerName);
    const dataSeries: ZohoDataSeries = {};
  
    connectedBookingDate.data.forEach((item) => {
      item.items.forEach((i) => {
        if (!dataSeries[i.name]) {
          dataSeries[i.name] = [];
        }
        dataSeries[i.name].push(i.value || 0);
      });
    });
  
    const data = Object.keys(dataSeries).map((key) => ({
      name: key,
      data: dataSeries[key],
    }));
  
    return {
      categories,
      data,
    };
  }

  function getBookingCountChartData() {
    if (!getCardIdByType(ZohoReportType.BOOKING_COUNT)) return ChartMockupData.BookingCount;
    if (!bookingCount.data?.length) return ChartMockupData.BookingCountEmpty;

    return bookingCount.data?.map((item) => {
      return {
        label: item.ownerName,
        value: item.count,
      };
    })

  }

  function getCallCountChartData() {
    if (!getCardIdByType(ZohoReportType.CALL_COUNT)) return ChartMockupData.CallCount;
    if (!callCount.data?.length) return ChartMockupData.CallCountEmpty;

    return callCount.data?.map((item) => {
      return {
        label: item.ownerName,
        value: item.count,
      };
    })
  }

  const getTotalLeadWorkedChartData = () => {
    if (!getCardIdByType(ZohoReportType.TOTAL_LEADS_WORKED)) return ChartMockupData.CardSum;
    return {
      currentValue: totalLeadWorked.data?.currentValue,
      previousValue: totalLeadWorked.data?.previousValue,
    }
  }

  const getTotalCallChartData = () => {
    if (!getCardIdByType(ZohoReportType.TOTAL_CALL)) return ChartMockupData.CardSum;
    return {
      currentValue: totalCalls.data?.currentValue,
      previousValue: totalCalls.data?.previousValue,
    }
  }

  const getTotalDemoBookedChartData = () => {
    if (!getCardIdByType(ZohoReportType.TOTAL_DEMO_BOOKED)) return ChartMockupData.CardSum;
    return {
      currentValue: totalDemoBook.data?.currentValue,
      previousValue: totalDemoBook.data?.previousValue,
    }
  }

  return (
    <div className="sales-analytics-charts">
      <Row gutter={16}>
        <Col xs={24} sm={12} xl={8} className="col-total-lead">
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.TOTAL_LEADS_WORKED)
              setIsNew(true)
            }}
            isEmpty={!totalLeadWorked.data && !totalLeadWorked.loading}
          >
            <div className="rl-card">
              <div className="rl-card-title">
                {getCardTitleByType(ZohoReportType.TOTAL_LEADS_WORKED)}
                {renderIcons(ZohoReportType.TOTAL_LEADS_WORKED)}
              </div>

              <SpinDiv
                loading={totalLeadWorked.loading}
                style={{ height: 76 }}
              >
                <>
                  <div className="sales-analytics-charts__cardSum">
                    <p>{getTotalLeadWorkedChartData().currentValue}</p>
                    {renderVolatility(
                      calculateVolatility(
                        getTotalLeadWorkedChartData().currentValue || 0,
                        getTotalLeadWorkedChartData().previousValue || 0
                      )
                    )}
                  </div>
                  <div className="sales-analytics-charts__cardSum-time">
                    {selectedRangeName}:{" "}
                    {getTotalLeadWorkedChartData().previousValue}
                  </div>
                </>
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>

        <Col xs={24} sm={12} xl={8} className="col-total-lead">
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.TOTAL_CALL)
              setIsNew(true)
            }}
            isEmpty={!totalCalls.data && !totalCalls.loading}
          >
            <div className="rl-card">
              <div className="rl-card-title">
                {getCardTitleByType(ZohoReportType.TOTAL_CALL)}
                {renderIcons(ZohoReportType.TOTAL_CALL)}
              </div>

              <SpinDiv loading={totalCalls.loading} style={{ height: 76 }}>
                <>
                  <div className="sales-analytics-charts__cardSum">
                    <p>{getTotalCallChartData().currentValue}</p>
                    {renderVolatility(
                      calculateVolatility(
                        getTotalCallChartData().currentValue || 0,
                        getTotalCallChartData().previousValue || 0
                      )
                    )}
                  </div>
                  <div className="sales-analytics-charts__cardSum-time">
                    {selectedRangeName}:{" "}
                    {getTotalCallChartData().previousValue}
                  </div>
                </>
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>

        <Col xs={24} sm={12} xl={8} className="col-total-lead">
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.TOTAL_DEMO_BOOKED)
              setIsNew(true)
            }}
            isEmpty={!totalDemoBook.data && !totalDemoBook.loading}
          >
            <div className="rl-card">
              <div className="rl-card-title">
                {getCardTitleByType(ZohoReportType.TOTAL_DEMO_BOOKED)}
                {renderIcons(ZohoReportType.TOTAL_DEMO_BOOKED)}
              </div>

              <SpinDiv
                loading={totalDemoBook.loading}
                style={{ height: 76 }}
              >
                <>
                  <div className="sales-analytics-charts__cardSum">
                    <p>{getTotalDemoBookedChartData().currentValue}</p>
                    {renderVolatility(
                      calculateVolatility(
                        getTotalDemoBookedChartData().currentValue || 0,
                        getTotalDemoBookedChartData().previousValue || 0
                      )
                    )}
                  </div>
                  <div className="sales-analytics-charts__cardSum-time">
                    {selectedRangeName}:{" "}
                    {getTotalDemoBookedChartData().previousValue}
                  </div>
                </>
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} xl={12}>
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.CALL_COUNT)
              setIsNew(true)
            }}
            isEmpty={!callCount.data && !callCount.loading}
          >
            <div className="rl-card">
              <div className="rl-card-title mr-bottom-24">
                {getCardTitleByType(ZohoReportType.CALL_COUNT)}
                {renderIcons(ZohoReportType.CALL_COUNT)}
              </div>
              <SpinDiv loading={callCount.loading} style={{ height: 230 }}>
                <ColumnChart
                  title={t("salesAnalytics.callCount")}
                  height={230}
                  chartId="callCountChart"
                  data={getCallCountChartData()}
                />
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>

        <Col xs={24} sm={24} md={12} xl={12}>
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.CONNECTION_RATE)
              setIsNew(true)
            }}
            isEmpty={!connectionRate.data && !connectionRate.loading}
          >
            <div className="rl-card">
              <div className="rl-card-title mr-bottom-24">
                {getCardTitleByType(ZohoReportType.CONNECTION_RATE)}
                {renderIcons(ZohoReportType.CONNECTION_RATE)}
              </div>
              <SpinDiv loading={connectionRate.loading} style={{ height: 230 }}>
                <StackedColumn
                  categories={
                    getConnectionRateChartData().categories
                  }
                  height={230}
                  chartId="connectionRateChart"
                  colors={["#f59f00", "#1e85de", "#f35848"]}
                  data={getConnectionRateChartData().data}
                />
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.CALL_STATUS)
              setIsNew(true)
            }}
            isEmpty={!callStatus.data && !callStatus.loading}
          >
            <div className="rl-card">
              <div className="rl-card-title mr-bottom-24">
                {getCardTitleByType(ZohoReportType.CALL_STATUS)}
                {renderIcons(ZohoReportType.CALL_STATUS)}
              </div>
              <SpinDiv loading={callStatus.loading} style={{ height: 230 }}>
                <StackedColumn
                  categories={getCallStatusChartData().categories}
                  height={230}
                  chartId="connectionRateChart"
                  data={getCallStatusChartData().data}
                  colors={[
                    "#f59f00",
                    "#d899cb",
                    "#738fbd",
                    "#0185de",
                    "#9bc13c",
                    "#f35848",
                    "#fac364",
                    "#5cbae6",
                    "#c13584",
                    "#b956c2",
                  ]}
                />
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} xl={12}>
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.BOOKING_COUNT)
              setIsNew(true)
            }}
            isEmpty={!bookingCount.data && !bookingCount.loading}
          >
            <div className="rl-card">
              <div className="rl-card-title mr-bottom-24">
                {getCardTitleByType(ZohoReportType.BOOKING_COUNT)}
                {renderIcons(ZohoReportType.BOOKING_COUNT)}
              </div>
              <SpinDiv loading={bookingCount.loading} style={{ height: 230 }}>
                <ColumnChart
                  title={t("salesAnalytics.bookingCount")}
                  height={230}
                  chartId="bookingCountChart"
                  data={
                    getBookingCountChartData()
                  }
                />
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>

        <Col xs={24} sm={24} md={12} xl={12}>
          <ChartWrapper
            onAdd={() => {
              onOpenDrawer(ZohoReportType.CONNECTED_BOOKING_RATE)
              setIsNew(true)
            }}
            isEmpty={
              !connectedBookingDate.data && !connectedBookingDate.loading
            }
          >
            <div className="rl-card">
              <div className="rl-card-title mr-bottom-24">
                {getCardTitleByType(ZohoReportType.CONNECTED_BOOKING_RATE)}
                {renderIcons(ZohoReportType.CONNECTED_BOOKING_RATE)}
              </div>
              <SpinDiv
                loading={connectedBookingDate.loading}
                style={{ height: 230 }}
              >
                <StackedColumn
                  categories={getConnectedBookingDateChartData().categories}
                  height={230}
                  chartId="connectedToBookingDateChart"
                  colors={["#738fbd", "#9bc13c"]}
                  data={getConnectedBookingDateChartData().data}
                />
              </SpinDiv>
            </div>
          </ChartWrapper>
        </Col>
        <ReportCardForm
          isNew={isNew}
          type={selectedCardType}
          open={isOpenDrawer}
          setOpen={setIsOpenDrawer}
        />
      </Row>
    </div>
  );
}

export default SalesAnalyticsCharts;
