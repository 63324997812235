import { getAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth/api/v1`;
function getTimezones() {
    const url = `${baseUrl}/home/time-zones`;
    return getAsync(url);
}

export const TimezonesApi = {
    getTimezones
}