import { Button, Checkbox, Form, Modal, Table, Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { AuthApi } from "../../../../apis/auth.api";
import useClient from "../../../../hooks/useClient";
import useClientPlan from "../../../../hooks/useClientPlan";
import {
  AddTeamMemberRequest,
  ClientTeamMemberModel,
} from "../../../../models/client-team-member.model";
import { planState } from "../../../../states/plan";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import AddUserTeamModal from "./add-user";
import "./index.scss";
import { LimitedTrialPlanType, PlanUsageType } from "../../../../constants/app.enums";

function GeneralSettingTeam() {
  const { t } = useTranslation();
  const [form] = Form.useForm<AddTeamMemberRequest>();
  const { clientId, isOwner } = useClient();
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const {checkUserPlan, checkLimitedPlanLoading, checkPremiumPlan} = useClientPlan()
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [listTeamMember, setListTeamMember] = useState<ClientTeamMemberModel[]>(
    []
  );
  const [listTeamMemberUpdate, setListTeamMemberUpdate] = useState<{
    [key: number]: ClientTeamMemberModel | undefined;
  }>({});

  const addUser = async () => {
    if (!clientId) return;
    try {
      const values = await form.validateFields();
      const { data } = await AuthApi.addTeamMember({ ...values, clientId });
      setOpenModalAddUser(false);
      setListTeamMember([...listTeamMember, data]);
      showNotification(
        "success",
        t("generalSettings.addTeamMemberSuccess", {
          name: data.firstName + " " + data.lastName,
        })
      );
      form.resetFields();
    } catch (error) {}
  };

  const removeMember = (member: ClientTeamMemberModel) => {
    Modal.confirm({
      title: t("generalSettings.removeMemberTitle", {
        name: member.firstName + " " + member.lastName,
      }),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("generalSettings.removeMemberDescription"),
          }}
        ></span>
      ),
      okText: t("common.remove"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: async () => {
        await AuthApi.deleteTeamMember(member.id);
        showNotification(
          "delete",
          t("generalSettings.removeMemberSuccess", {
            name: member.firstName + " " + member.lastName,
          })
        );
        const memberIndex = listTeamMember.findIndex((m) => m.id === member.id);
        const newListMember = [...listTeamMember];
        newListMember.splice(memberIndex, 1);
        setListTeamMember(newListMember);
      },
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, member: ClientTeamMemberModel) => {
        return (
          <div className="owner">
            <div>
              {member.firstName + " " + member.lastName}
            </div>
            <div>
              {member.isOwner && <Tag color="blue" className="owner-icon" >{t("generalSettings.owner")}</Tag>}
            </div>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: (
        <span className="team-table-title">
          <span>{t("common.manage")}</span>
          <Tooltip title="User can create, edit and publish the content">
            <span>
              <SVGIcons.InfoCircleIcon />
            </span>
          </Tooltip>
        </span>
      ),
      dataIndex: "canManageContent",
      align: 'center' as const,
      key: "canManageContent",
      render: (isManage: boolean, member: ClientTeamMemberModel) => {
        return (
          <Checkbox
            defaultChecked={isManage}
            onChange={(e) =>
              updatePermission({
                ...(listTeamMemberUpdate[member.id] || member),
                canManageContent: e.target.checked,
              })
            }
            disabled={member.isOwner || !isOwner()}
          />
        );
      },
    },
    {
      title: (
        <span className="team-table-title">
          <span>{t("common.approve")}</span>
          <Tooltip title="User can only approve or decline the content.">
            <span>
              <SVGIcons.InfoCircleIcon />
            </span>
          </Tooltip>
        </span>
      ),
      dataIndex: "canApproveContent",
      align: 'center' as const,
      key: "canApproveContent",
      render: (isApprove: boolean, member: ClientTeamMemberModel) => {
        return (
          <Checkbox
            defaultChecked={isApprove}
            onChange={(e) =>
              updatePermission({
                ...(listTeamMemberUpdate[member.id] || member),
                canApproveContent: e.target.checked,
              })
            }
            disabled={member.isOwner || !isOwner()}
          />
        );
      },
    },
    {
      key: "action",
      render: (_: any, member: ClientTeamMemberModel) => {
        return (
          !member.isOwner &&
          isOwner() && (
            <Button type="text" danger onClick={() => removeMember(member)}>
              {t("common.remove")}
            </Button>
          )
        );
      },
    },
  ];

  const updatePermission = (newMember: ClientTeamMemberModel) => {
    let newListMemberUpdate = { ...listTeamMemberUpdate };
    const memberUpdate = listTeamMember.find((m) => m.id === newMember.id);
    if (
      memberUpdate?.canApproveContent === newMember.canApproveContent &&
      memberUpdate.canManageContent === newMember.canManageContent
    ) {
      newListMemberUpdate = {
        ...newListMemberUpdate,
        [newMember.id]: undefined,
      };
    } else {
      newListMemberUpdate = {
        ...newListMemberUpdate,
        [newMember.id]: newMember,
      };
    }
    setListTeamMemberUpdate(newListMemberUpdate);
  };

  const updatePermissionTeamMember = async () => {
    setLoadingSave(true);
    try {
      const request = Object.values(listTeamMemberUpdate)
        .filter((m) => m !== undefined)
        .map((m) => {
          if (!m) return undefined;
          return {
            id: m.id,
            canApproveContent: m.canApproveContent,
            canManageContent: m.canManageContent,
          };
        });
      await AuthApi.updatePermissionTeamMember({ members: request });
      await initListTeamMember();
      setListTeamMemberUpdate({});
    } catch (error) {}
    setLoadingSave(false);
  };

  const initListTeamMember = async () => {
    if (!clientId) return;
    setLoading(true);
    try {
      const { data } = await AuthApi.getTeamMember(clientId);
      setListTeamMember(data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    initListTeamMember();
  }, []);
  return (
    <div className="general-setting-team">
      <p className="title">{t("generalSettings.teamTitle")}</p>
      <div className="general-setting-team-table">
        <Table
          className="main-table"
          columns={columns}
          dataSource={listTeamMember}
          pagination={false}
          loading={loading}
          rowKey="id"
          locale={{ emptyText: "No data to be displayed." }}
        />
        <div className="group-btn">
          <Button
            loading={checkLimitedPlanLoading}
            type="default"
            onClick={()=> {
              checkPremiumPlan(async()=> {
                await checkUserPlan(() => {
                  setOpenModalAddUser(true)
                }, PlanUsageType.TeamMember);
              }, PlanUsageType.TeamMember)
            }}
            disabled={!isOwner()}
          >
            <SVGIcons.PlusIcon /> {t("users.btnAddUser")}
          </Button>
          <Button
            type="primary"
            onClick={updatePermissionTeamMember}
            loading={loadingSave}
            disabled={
              !isOwner() ||
              !Object.values(listTeamMemberUpdate).filter((m) => m).length
            }
          >
            {t("common.saveChanges")}
          </Button>
        </div>

        <AddUserTeamModal
          open={openModalAddUser}
          close={() => {
            setOpenModalAddUser(false);
            form.resetFields();
          }}
          form={form}
          addUser={addUser}
        />
      </div>
    </div>
  );
}

export default GeneralSettingTeam;
