import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  dasboardDateRangeState,
  dashboardCallsState,
  dashboardFormsState,
} from "../../../../states/dashboard";
import DateUtils from "../../../../utils/date.utils";
import { NUMBER_UTIL } from "../../../../utils/number.utils";
import SpinDiv from "../../../common/spin-div";
import FormSubmissionChart from "../charts/form-submissions";
import LineCallsChart from "../charts/line-calls";
import "./index.scss";

const columns = [
  {
    title: "TOP ACTIVE form name",
    dataIndex: "name",
    key: "name",
    width: '80%',
    ellipsis: true,
    render: (text: string) => <span className="color-blue">{text}</span>,
  },
  {
    title: "entries",
    dataIndex: "entries",
    key: "entries",
    width: '20%',
  },
];

function CallWebForm() {
  const tableData = [
    {
      key: 1,
      name: "-",
      entries: 0,
    },
    {
      key: 2,
      name: "-",
      entries: 0,
    },
    {
      key: 3,
      name: "-",
      entries: 0,
    },
  ];

  const { t } = useTranslation();
  const dateRange = useRecoilValue(dasboardDateRangeState);
  const callsState = useRecoilValue(dashboardCallsState);
  const callReport = callsState.data;
  const formsState = useRecoilValue(dashboardFormsState);
  const forms = formsState.data?.topActiveForms;
  const [dataSource, setDataSource] = useState([...tableData]);

  useEffect(() => {
    const newArray = [...tableData];
    if (forms) {
      for (let index = 0; index < forms.length && index < 3; index++) {
        const { entries, title, integrationDescription } = forms[index];
        newArray[index].entries = entries;
        newArray[index].name = `${
          integrationDescription ? `[${integrationDescription}]` : ""
        } ${title}`;
      }
    }

    setDataSource(newArray);
  }, [forms]);

  return (
    <div className="home-call-web-form">
      <Row gutter={16}>
        <Col xs={24} sm={16} className="mobile-mr-bottom-20">
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.calls")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <SpinDiv loading={callsState.loading} style={{ height: 258 }}>
              <>
                <p className="home-call-web-form__sumValue">
                  {callReport
                    ? NUMBER_UTIL.convertNumericToFormattedString(
                        callReport.total
                      )
                    : 0}
                </p>

                <div className="home-call-web-form__details">
                  <div>
                    <div>
                      <span style={{ backgroundColor: "#0185de" }}></span>
                      <span>{t("dashboard.missedCall")}</span>
                    </div>
                    <p>
                      {callReport
                        ? NUMBER_UTIL.convertNumericToFormattedString(
                            callReport.totalMissed
                          )
                        : 0}
                    </p>
                  </div>

                  <div>
                    <div>
                      <span style={{ backgroundColor: "#9bc13c" }}></span>
                      <span>{t("dashboard.answeredCalls")}</span>
                    </div>
                    <p>
                      {callReport
                        ? NUMBER_UTIL.convertNumericToFormattedString(
                            callReport.totalAnswered
                          )
                        : 0}
                    </p>
                  </div>
                </div>

                <LineCallsChart />
              </>
            </SpinDiv>
          </div>
        </Col>

        <Col xs={24} sm={8}>
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.totalFormSubmissions")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <SpinDiv loading={formsState.loading} style={{ height: 258 }}>
              <>
                <p className="home-call-web-form__sumValue">
                  {formsState.data?.total || 0}
                </p>

                <FormSubmissionChart />

                <div className="form-submission-table">
                  <Table
                    rowKey="key"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    bordered={false}
                  />
                </div>
              </>
            </SpinDiv>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CallWebForm;
