import { Navigate } from "react-router-dom";
import useDashboardPath from "../../../hooks/useDashboardPath";

function AdminHome() {
  const dashboard = useDashboardPath();

  return <Navigate to={dashboard} replace={true} />;
}

export default AdminHome;
