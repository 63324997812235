import { Button, Input, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AuthApi } from "../../../apis/auth.api";
import FilterList from "../../../components/common/filter";
import showNotification from "../../../components/common/notification";
import SVGIcons from "../../../components/icons/svgs";
import BulkAction from "../../../components/views/users/bulk-action";
import CreateUserForm from "../../../components/views/users/create-form";
import EditUserForm from "../../../components/views/users/edit-form";
import {
  ANT_LOCALE,
  FILTER_MAPPING_VALUE,
  PAGE_SIZE_DEFAULT,
  SORTER_MAPPING_VALUE
} from "../../../constants/app-constants";
import { BulkActionType } from "../../../constants/app.enums";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../constants/permission.constants";
import useFetchRoles from "../../../hooks/useFetchRoles";
import useGetAdminStats from "../../../hooks/useGetAdminStats";
import useHasPermission from "../../../hooks/useHasPermission";
import {
  CreateUserRequestModel,
  GetUserParams,
  UserListModel,
} from "../../../models/user";
import { UserGroupModel } from "../../../models/user-group.model";
import { adminUseGroupsSelector } from "../../../states/admin";
import { authState } from "../../../states/auth";
import { pageTitleState } from "../../../states/common";
import DateUtils from "../../../utils/date.utils";
import searchDebounce from "../../../utils/searchDebounce";
import "./index.scss";

function UserListPage() {
  const { t } = useTranslation();
  const roles = useFetchRoles();
  const [users, setUsers] = useState<UserListModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState<UserListModel>();
  const [bulkAction, setBulkAction] = useState<BulkActionType | null>(null);
  const [selected, setSelected] = useState<UserListModel[]>([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sorter, setSorter] = useState<{
    orderBy?: string;
    orderDirection?: string;
  }>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState({});
  const { hasPermission } = useHasPermission();
  const { getAdminStats } = useGetAdminStats();

  const auth = useRecoilValue(authState);
  const setPageTile = useSetRecoilState(pageTitleState);
  const [groups, setGroups] = useRecoilState(adminUseGroupsSelector);

  useEffect(() => {
    setPageTile(t("users.title"));
    getUsers();
  }, []);

  const getUsers = async (params?: GetUserParams) => {
    setLoading(true);

    try {
      const request = {
        pageSize: params?.pageSize || pageSize,
        pageNumber: params?.pageNumber || 1,
        searchTerm:
          params?.searchTerm !== undefined ? params?.searchTerm : searchTerm,
        orderBy:
          params?.orderDirection !== null
            ? params?.orderBy || sorter.orderBy
            : undefined,
        orderDirection:
          params?.orderDirection !== null
            ? params?.orderDirection || sorter.orderDirection
            : undefined,
        ...(params?.filter || filter),
      };
      const { data } = await AuthApi.getUsers(request);
      const { users, totalRecords } = data;
      setUsers(users);
      setTotalRecords(totalRecords);
    } catch {}
    setLoading(false);
  };

  async function createUser(model: CreateUserRequestModel) {
    await AuthApi.createUser(model);
    getUsers();
    getAdminStats();
  }

  function editUser(user: UserListModel) {
    setEditingUser({ ...user });
    setOpenEditModal(true);
  }

  function confirmDelete(user: UserListModel) {
    Modal.confirm({
      title: t("users.deleteTitle"),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("users.deleteDescription", { param1: user.fullName }),
          }}
        ></span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => deleteUser(user),
    });
  }

  async function deleteUser(user: UserListModel) {
    await AuthApi.deleteUser(user.id);
    showNotification(
      "delete",
      t("users.deleteSuccess", { param1: user.fullName })
    );

    const newArray = users.filter((x) => x.id !== user.id);
    setUsers(newArray);
    getAdminStats();
  }

  async function deleteUsers() {
    setLoading(true);

    try {
      await AuthApi.deleteUsers({
        userIds: selected.map((user) => user.id),
      });
      showNotification("delete", t("users.deleteClient"));
      getAdminStats();
      await getUsers();

      setBulkAction(null);
    } catch {}

    setLoading(false);
  }

  async function moveUsers(group: UserGroupModel) {
    setLoading(true);

    try {
      await AuthApi.moveUsers({
        userIds: selected.map((user) => user.id),
        groupIds: [group.id],
      });
      showNotification(
        "success",
        t("users.moveClient", { group: group?.name })
      );
      await getUsers();
      setBulkAction(null);
    } catch {}

    setLoading(false);
  }

  const columns: ColumnsType<UserListModel> = [
    {
      title: t("users.userId"),
      dataIndex: "id",
      sorter: true,
      render: (text, _, index) => text,
      width: 100,
    },
    {
      title: t("users.user"),
      dataIndex: "fullName",
      sorter: true,
      render: (text, record) => (
        <div className="table-cell">
          <p>{text}</p>
          <span>{record.email}</span>
        </div>
      ),
    },
    {
      title: t("users.userGroup"),
      dataIndex: "userGroups",
      sorter: true,
      render: (text) => Object.values(text).toString(),
    },
    {
      title: t("users.role"),
      dataIndex: "roles",
      sorter: true,
      render: (text) => Object.values(text).toString(),
    },
    {
      title: t("users.lastLogin"),
      dataIndex: "lastLoginDate",
      sorter: true,
      render: (text) => (
        <div className="table-cell">
          <p>{DateUtils.convertServerToDateViewFormat(text)}</p>
          <span>{DateUtils.convertServerToTimeViewFormat(text)}</span>
        </div>
      ),
    },
    {
      title: t("clientSites.dateCreated"),
      dataIndex: "createdDate",
      sorter: true,
      render: (text) => (
        <div className="table-cell">
          <p>{DateUtils.convertServerToDateViewFormat(text)}</p>
          <span>{DateUtils.convertServerToTimeViewFormat(text)}</span>
        </div>
      ),
    },
    {
      title: "",
      align: "right",
      dataIndex: "action",
      render: (_, record) =>
        record.id !== auth.user?.userId && (
          <Space size={"small"}>
            {hasPermission({
              function: FunctionEnum.Users,
              permission: PermissionEnum.Delete,
            }) &&
              record.canDelete && (
                <Button
                  className="delete-ghost-button table-row-action"
                  type="text"
                  onClick={() => confirmDelete(record)}
                >
                  {t("users.btnDelete")}
                </Button>
              )}

            {hasPermission({
              function: FunctionEnum.Users,
              permission: PermissionEnum.Edit,
            }) && (
              <Button
                className="color-blue table-row-action"
                type="text"
                onClick={() => editUser(record)}
              >
                {t("users.btnEdit")}
              </Button>
            )}

            {hasPermission({
              function: FunctionEnum.Users,
              permission: PermissionEnum.View,
            }) &&
              !hasPermission({
                function: FunctionEnum.Users,
                permission: PermissionEnum.Edit,
              }) && (
                <Button
                  className="color-blue table-row-action"
                  type="text"
                  onClick={() => editUser(record)}
                >
                  {t("users.btnView")}
                </Button>
              )}
          </Space>
        ),
    },
  ];

  const onChangeRow = (selectedRowKeys: any, selectedRows: any, info: any) => {
    setSelected(selectedRows);
  };

  const onTableChange = async (pagination: any, filter: any, sorter: any) => {
    const { current: pageNumber, pageSize } = pagination;
    const { field, order } = sorter;
    const orderBy = SORTER_MAPPING_VALUE[field];
    const orderDirection = SORTER_MAPPING_VALUE[order] || null;
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    setSorter(orderDirection ? { orderBy, orderDirection } : {});
    await getUsers({ pageSize, pageNumber, orderBy, orderDirection });
  };

  const onChangeSearch = async (e: any) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    setPageNumber(1);
    searchDebounce(searchTerm, () => getUsers({ searchTerm, pageNumber: 1 }));
  };

  const onChangeFilter = async (value: any) => {
    const filter = Object.keys(value).reduce((result, key) => {
      return { ...result, [FILTER_MAPPING_VALUE[key]]: value[key] };
    }, {});
    setFilter(filter);
    await getUsers({ filter });
  };

  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return (
        <>
          <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
        </>
      );
    }
    if (type === "next") {
      return (
        <>
          {t("clientSites.next")} <SVGIcons.ChevronRight />
        </>
      );
    }
    return originalElement;
  };

  const getUserGroups = async () => {
    try {
      const { data } = await AuthApi.getUserGroups();
      setGroups(data);
    } catch {}
  };

  useEffect(() => {
    if (!bulkAction) setSelected([]);
  }, [bulkAction]);

  useEffect(() => {
    getUserGroups();
  }, []);

  const dataFilter = {
    role: roles.map((role) => {
      return { name: role.roleName, value: role.roleName };
    }),
    userGroup: groups.map((group) => {
      return { name: group.name, value: group.name };
    }),
  };

  return (
    <div className="users-page">
      <div className="users-page__tableWrapper">
        <div className="users-page__tableHeader">
          <div className="table-action">
            <div className="table-action__left">
              {hasPermission({
                function: FunctionEnum.Users,
                permission: PermissionEnum.Edit,
              }) && (
                <BulkAction
                  typeBulkAction="users"
                  bulkAction={bulkAction}
                  onSetBulkAction={setBulkAction}
                  amount={selected.length}
                  onDeleteAction={deleteUsers}
                  onMoveAction={moveUsers}
                />
              )}
              <Input
                placeholder={t("common.searchPlahoder")}
                suffix={<SVGIcons.SearchIcon />}
                className="search-input"
                onChange={onChangeSearch}
                value={searchTerm}
              />
            </div>

            <div className="table-action__right">
              <FilterList data={dataFilter} onChange={onChangeFilter} />
              {hasPermission({
                function: FunctionEnum.Users,
                permission: PermissionEnum.Create,
              }) && (
                <Button
                  type="primary"
                  icon={<SVGIcons.PlusIcon />}
                  onClick={() => setOpenCreateModal(true)}
                >
                  {t("users.addNewUser")}
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="users-page__table">
          <Table
            className="main-table"
            columns={
              bulkAction
                ? columns.filter((col: any) => col.dataIndex !== "id")
                : columns
            }
            dataSource={users}
            pagination={{
              pageSize: pageSize,
              current: pageNumber,
              total: totalRecords,
              itemRender: itemRender,
            }}
            rowKey="id"
            loading={loading}
            locale={ANT_LOCALE}
            rowSelection={bulkAction ? { onChange: onChangeRow } : undefined}
            onChange={onTableChange}
          />
        </div>
      </div>

      <CreateUserForm
        open={openCreateModal}
        close={() => setOpenCreateModal(false)}
        onSubmit={(value) => createUser(value)}
      />

      <EditUserForm
        open={openEditModal}
        close={() => setOpenEditModal(false)}
        user={editingUser}
        getUsers={getUsers}
      />
    </div>
  );
}

export default UserListPage;
