import { Button, Drawer, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../../constants/permission.constants";
import useFetchRoles from "../../../../hooks/useFetchRoles";
import useHasPermission from "../../../../hooks/useHasPermission";
import { EditUserGroupModel } from "../../../../models/sign-in.model";
import { UserGroupModel } from "../../../../models/user-group.model";
import "./index.scss";

interface EditUserFormProps {
  open: boolean;
  close: () => void;
  item?: UserGroupModel;
  onEdit: (values: EditUserGroupModel) => Promise<void>;
}

function EditGroupForm(props: EditUserFormProps) {
  const { open, close, item, onEdit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const roles = useFetchRoles();
  const { hasPermission } = useHasPermission();
  const [loading, setLoading] = useState(false);

  const hasPermissionInput = hasPermission({
    permission: PermissionEnum.Edit,
    function: FunctionEnum.UserGroups,
  });

  async function submit(values: any) {
    setLoading(true);
    try {
      await onEdit({ ...values, id: item?.id, roleId: values.role });
    } catch (error) { }
    setLoading(false);
    cancel();
  }
  function okClick() {
    form.submit();
  }

  function cancel() {
    form.resetFields();
    close();
  }

  useEffect(() => {
    form.setFieldsValue(item);
    if (item?.roles && item?.roles.length) {
      form.setFieldValue("role", Number(item?.roles[0]?.id));
    }
  }, [item]);

  return (
    <Drawer
      className="app-edit-drawer"
      title={t("userGroups.editTitle")}
      open={open}
      width={540}
      onClose={() => cancel()}
      maskClosable={false}
      footer={
        <div className="drawer-footer-wrapper">
          <Button type="text" onClick={() => cancel()}>
            {t("common.close")}
          </Button>

          {
            hasPermission({
              permission: PermissionEnum.Edit,
              function: FunctionEnum.UserGroups,
            }) &&
            <Button type="primary" onClick={okClick} loading={loading}>
              {t("common.saveChanges")}
            </Button>
          }
        </div>
      }
    >
      <Form
        name="createUserForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
      >
        <Form.Item>
          <span className="des-color">User Group ID: {item?.id}</span>
        </Form.Item>
        <Form.Item
          label={t("userGroups.userGroupName")}
          rules={[{ required: true }]}
          name="name"
        >
          <Input disabled={!hasPermissionInput} />
        </Form.Item>

        <Form.Item
          label={t("userGroups.description")}
          name="description"
          rules={[{ required: true }]}
        >
          <Input.TextArea
            maxLength={200}
            rows={4}
            showCount
            disabled={!hasPermissionInput}
          ></Input.TextArea>
        </Form.Item>

        <Form.Item
          label={t("userGroups.role")}
          name="role"
          rules={[{ required: true }]}
          style={{ marginBottom: 0 }}
        >
          <Select disabled={!hasPermissionInput}>
            {roles.map((x) => (
              <Select.Option key={x.roleId} value={x.roleId}>
                {x.roleName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default EditGroupForm;
