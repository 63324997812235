import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Alert, Button, Checkbox, Form, Input, Row } from "antd";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AuthApi } from "../../../apis/auth.api";
import SVGIcons from "../../../components/icons/svgs";
import { VALIDATION_MESSAGE_CONFIG } from "../../../constants/app-constants";
import { DetailedMessage } from "../../../constants/app.enums";
import { ROLES } from "../../../constants/permission.constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { UserProfileModel } from "../../../models/user";
import { authErrorSelector, authState, profileUpdatedState } from "../../../states/auth";
import LocalUtils from "../../../utils/local.utils";
import "./index.scss";

interface Props {
  isAdmin: boolean;
}

function SignInForm(props: Props) {
  const { isAdmin } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const state: any = location.state;
  const navigate = useNavigate();
  const setAuthState = useSetRecoilState(authState);
  const [errorMessage, setErrorMessage] = useRecoilState(authErrorSelector);
  const setProfileUpdated = useSetRecoilState(profileUpdatedState);

  useEffect(() => {
    if (state?.email) form.setFieldValue("username", state.email);
    return () => setErrorMessage("");
  }, []);

  function getRedirectUrl(isSA: boolean, userProfile: UserProfileModel) {
    if (state && state.redirectUrl && state.redirectUrl !== ROUTE_PATHS.Home) {
      return state.redirectUrl;
    }

    if (isAdmin) {
      return isSA ? ROUTE_PATHS.UserList : ROUTE_PATHS.ClientSite;
    }

    return ROUTE_PATHS.SelectCompany;
    // if (userProfile.clients?.length && userProfile.clients?.length > 1)
    //   return ROUTE_PATHS.SelectCompany;
    // else {
    //   if (userProfile.clients && userProfile.clients[0]) {
    //     userProfile.clientSelected = userProfile.clients[0];
    //   }
    //   return ROUTE_PATHS.Home;
    // }
  }

  async function SignIn() {
    setLoading(true);
    try {
      const value = form.getFieldsValue();
      const { data } = await AuthApi.signIn(value);

      if (data.detailedMessage?.value === DetailedMessage.NEW_PASSWORD_REQUIRED)
        return navigate(
          `${isAdmin
            ? ROUTE_PATHS.AdminUpdatePassword
            : ROUTE_PATHS.UpdatePassword
          }?userName=${value.username}`
        );

      LocalUtils.setAuthenticatedData(data, value.is_remember);

      const { data: userProfile } = await AuthApi.getUserProfile();

      if (!isAdmin && (!userProfile.clients || !userProfile.clients.length)) {
        setErrorMessage(t("common.noPermission"));
        setLoading(false);
        return;
      }

      const isSupperAdmin =
        userProfile.roles &&
        !!userProfile.roles.length &&
        userProfile.roles[0].name === ROLES.supperAdmin;

      const redirectUrl = getRedirectUrl(isSupperAdmin, userProfile);
      LocalUtils.setUserInfo(userProfile);
      setAuthState({ isLogined: true, user: userProfile });
      setProfileUpdated(true);
      navigate(redirectUrl);
    } catch (error: any) {
      let message = t("common.serverError");
      if (error?.response?.data && typeof error?.response?.data === "string") {
        if (error?.response?.data === "User is disabled.") {
          message = t("common.noPermission");
        } else {
          message = error?.response?.data;
        }
      }

      setErrorMessage(message);
    }

    setLoading(false);
  }

  return (
    <div className="sign-in-form">
      <div className="header">{t("login.title")}</div>

      {state && state.resetPassword && (
        <Alert
          className="sign-in-form__alertSuccess"
          message={t("login.passwordSuccessfullyTitle")}
          description={t("login.passwordSuccessfullyDescription")}
          type="success"
          showIcon
          icon={<SVGIcons.CheckedIcon />}
        />
      )}

      {!!errorMessage && (
        <Alert
          className="sign-in-form__alertSuccess"
          message={errorMessage}
          type="error"
          showIcon
          icon={<SVGIcons.ErrorIcon />}
        />
      )}

      <Form
        name="signin"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={SignIn}
        form={form}
        className="hiden-required-mark"
      >
        <Form.Item
          label={t("login.email")}
          name="username"
          rules={[{ required: true, type: "email" }]}
        >
          <Input placeholder={t("login.emailPlahoder")} />
        </Form.Item>

        <Row className="forgot-div">
          <Link
            tabIndex={-1}
            to={
              isAdmin
                ? ROUTE_PATHS.AdminForgotPassword
                : ROUTE_PATHS.ForgotPassword
            }
            className="forgot-link"
          >
            {t("login.forgotYourPassword")}
          </Link>
        </Row>

        <Form.Item
          label={t("login.password")}
          name="password"
          rules={[{ required: true }]}
          className="mr-bottom-5"
        >
          <Input.Password placeholder={t("login.passwordPlahoder")} />
        </Form.Item>

        <Form.Item name="is_remember" valuePropName="checked">
          <Checkbox>{t("login.rememberMe")}</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {t("login.continue")}
          </Button>
        </Form.Item>

        {/* {!isAdmin && (
          <>
            <p className="text-center color-nobel">
              {t("login.accessQuickly")}
            </p>

            <Button
              htmlType="button"
              type="default"
              onClick={googleSignIn}
              block
            >
              <i className="sign-in-form__google">
                <SVGIcons.GoogleIcon />
              </i>
              {t("login.googleAccount")}
            </Button>
          </>
        )} */}
      </Form>
    </div>
  );
}

export default SignInForm;
