import { Modal, Skeleton, Spin } from "antd";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { SocialApi } from "../../../../../../apis/social.api";
import { ApprovalStatusEnum } from "../../../../../../constants/app.enums";
import {
  CommentModel,
  ContentModel,
  CreateCommentRequest,
  GetListCommentRequest,
} from "../../../../../../models/content.model";
import {
  contentValueState,
  listProfileState,
  newCommentState,
  requiredGuestUserState,
} from "../../../../../../states/social";
import LocalUtils from "../../../../../../utils/local.utils";
import CommentDraft from "../../components/comment/comment-draft";
import CommentPosted from "../../components/comment/comment-posted";
import "./index.scss";
import ViewContent from "../../../approvals/main-approval-request/view-content";
import { isMobileSelector } from "../../../../../../states/common";
import { CommonUtils } from "../../../../../../utils/common";
import { useSearchParams } from "react-router-dom";

interface CommentTabProps {
  isReviewing?: boolean;
  contentId?: number;
  contentInfo?: ContentModel;
  showViewAllButton?: boolean;
  contentHeight?: any;
  onSetApprovalStatus?: (value: ApprovalStatusEnum) => void;
  approvalStatus?: ApprovalStatusEnum;
  onChangeStatus?: (status: ApprovalStatusEnum) => void;
  isInModal?: boolean;
}

const CommentTab = forwardRef((props: CommentTabProps, ref) => {
  const {
    isReviewing,
    contentInfo,
    showViewAllButton,
    contentHeight,
    onSetApprovalStatus,
    approvalStatus,
    onChangeStatus,
    isInModal,
  } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const contentIdParam = Number(searchParams.get("contentId"));
  const { profiles } = useRecoilValue(listProfileState);
  const isMobile = useRecoilValue(isMobileSelector);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [lessCommentsMobile, setLessCommentsMobile] = useState<CommentModel[]>(
    []
  );
  const [isNewComment, setIsNewComment] =
    useRecoilState(newCommentState);
  const commentListRef = useRef<HTMLDivElement | null>(null);
  const VIEW_ALL_PAGE_INDEX = 1;
  const VIEW_ALL_PAGE_SIZE = 10;
  const PAGE_SIZE_MOBILE = 4;
  const [allCommentPageIndex, setAllCommentPageIndex] =
    useState<number>(VIEW_ALL_PAGE_INDEX);

  const [query, setQuery] = useState<GetListCommentRequest>({
    pageIndex: 1,
    pageSize: 5,
    contentId: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [allComments, setAllComments] = useState<number>(0);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const commentElements = useRef<(HTMLDivElement | null)[]>([]);
  const prevScrollTop = useRef(0);
  const scrollDebounce = useRef<any>(null);

  const setRequiredGuestUser = useSetRecoilState(requiredGuestUserState);
  const contentValue = useRecoilValue(contentValueState);
  const { t } = useTranslation();

  const guestInfo = LocalUtils.getGuestUserInfo();
  const contentId = props.contentId || contentValue.id;
  const [isViewAllMobile, setIsViewAllMobile] = useState(false);

  const initListComment = async (
    contentId: number,
    pageIndex: number,
    pageSize: number
  ) => {
    setLoading(true);
    try {
      const { data } = await SocialApi.getListComment({
        contentId,
        pageIndex,
        pageSize,
      });
      setComments(data.records);
      setAllComments(data.allComments);
      setTotalRecords(data.totalRecords);
    } catch (error) {}
    setLoading(false);
  };

  const onLoadMoreComments = async (
    contentId: number,
    pageIndex: number,
    pageSize: number
  ) => {
    try {
      const { data } = await SocialApi.getListComment({
        contentId,
        pageIndex,
        pageSize,
      });
      setComments((pre) => pre.concat(data.records));
      setAllComments(data.allComments);
      setTotalRecords(data.totalRecords);
    } catch (error) {}
  };

  const createComment = async (
    request: Omit<CreateCommentRequest, "contentId">
  ) => {
    if (!contentId) return;
    setLoading(true);
    try {
      let tempComment: CommentModel;
      let formData = { ...request };
      // add contentId to param url request
      let url = window.location.href;
      if (!contentIdParam) {
        if (searchParams.toString()) {
          url += `&contentId=${contentInfo?.id}`;
        } else url += `?contentId=${contentInfo?.id}`;
      }
      formData.url = url;

      if (isReviewing) {
        if (!guestInfo?.uuid) {
          setRequiredGuestUser(true);
          setLoading(false);
          return;
        }
        if (guestInfo?.isUser) {
          const { data } = await SocialApi.createComment({
            ...formData,
            contentId,
          });
          tempComment = data;
        } else {
          const { data } = await SocialApi.createGuestComment({
            ...formData,
            contentId,
            guestUuid: guestInfo?.uuid,
          });
          tempComment = data;
        }
      } else {
        const { data } = await SocialApi.createComment({
          ...formData,
          contentId,
        });
        tempComment = data;
      }
      setIsNewComment(true)

      if (!formData.parentId) {
        const newListComment = [tempComment, ...comments];
        setComments(newListComment);
        setTotalRecords((prev) => prev + 1);
        setAllComments((prev) => prev + 1);

      } else {
        // add child comment
        const newListComment = comments.map((comment) => {
          if (comment.id === formData.parentId)
            return {
              ...comment,
              childs:
                comment.childs?.length > 0
                  ? [...comment.childs, tempComment]
                  : [tempComment],
            };
          else return comment;
        });
        setComments(newListComment);
      }
      // await initListComment(contentId, query.pageIndex, query.pageSize);
      // if(commentListRef.current) {
      //   setIsConcat(false)
      //   commentListRef.current.scrollTop = 0
      //   prevScrollTop.current = 0;
      //   setIsConcat(true)
      // }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const removeContent = async (id: number) => {
    await SocialApi.deleteComment(id);
    let newComments = [...comments]; //CommonUtils

    for (let index = 0; index < newComments.length; index++) {
      const element = newComments[index];
      if (element.id === id) {
        newComments = newComments.filter((x) => x.id !== id);
        break;
      } else if (element.childs && element.childs.find((x) => x.id === id)) {
        element.childs = element.childs.filter((x) => x.id !== id);
      }
    }

    setComments(newComments);
    setTotalRecords((pre) => pre - 1);
    setIsNewComment(true)
  };

  const confirmRemoveComment = (id: number) => {
    Modal.confirm({
      title: t("comment.removeTitle"),
      content: (
        <div className="remove-profile-modal-content">
          <p>{t("comment.removeDescriptiont")}</p>
        </div>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal confirm-delete-comment",
      centered: true,
      onOk: () => removeContent(id),
    });
  };

  useImperativeHandle(ref, () => ({
    async reloadComment() {
      contentId && initListComment(contentId, query.pageIndex, query.pageSize);
    },
  }));

  useEffect(() => {
    if (!isMobile) {
      const resizeObserver = new ResizeObserver((entries) => {
        let heightSum = 0;
        entries.forEach((entry) => {
          if (entry.target instanceof HTMLElement) {
            heightSum += entry.target.offsetHeight;
          }
        });
        if (heightSum + 40 >= totalHeight) {
          setTotalHeight(heightSum + 40);
        }
      });

      commentElements.current.forEach((element) => {
        if (element) {
          resizeObserver.observe(element);
        }
      });
      return () => {
        commentElements.current.forEach((element) => {
          if (element) {
            resizeObserver.unobserve(element);
          }
        });
      };
    }
  }, [comments, totalHeight, isMobile]);

  const isShowAll = !showViewAllButton && totalHeight > contentHeight;
  const height = showViewAllButton
    ? ""
    : contentHeight && isShowAll
    ? contentHeight - 100
    : contentHeight - 55;

  useEffect(() => {
    if (contentId) {
      if (showViewAllButton) {
        initListComment(contentId, VIEW_ALL_PAGE_INDEX, VIEW_ALL_PAGE_SIZE);
        if (commentListRef.current) {
          commentListRef.current.scrollTop = 0;
        }
        setAllCommentPageIndex(VIEW_ALL_PAGE_INDEX);
      }
       else { 
        initListComment(
          contentId,
          query.pageIndex,
          isMobile ? PAGE_SIZE_MOBILE : query.pageSize
        );
      }
    }
  }, [showViewAllButton, contentId]);

  const handleViewMobile = async () => {
    if (!contentId) return;
    if (comments.length < totalRecords) {
      await initListComment(contentId, VIEW_ALL_PAGE_INDEX, totalRecords);
    }
    setIsViewAllMobile((pre) => !pre);
  };

  const handleViewAll = () => {
    setOpenModal(true);
    setIsNewComment(false)
    if (contentId) {
      searchParams.append("contentId", contentId?.toString());
      setSearchParams(searchParams);
    }
  };

  const handleScroll = () => {
    const commentList = commentListRef.current;
    if (commentList && showViewAllButton) {
      const { scrollTop, clientHeight, scrollHeight } = commentList;
      const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
      if (comments.length < totalRecords && scrollPercentage >= 0.99) {
        if (scrollDebounce.current) {
          clearTimeout(scrollDebounce.current);
        }
        scrollDebounce.current = setTimeout(() => {
          if (contentId) {
            setAllCommentPageIndex((pre) => {
              const incrementedPageIndex = pre + 1;
              onLoadMoreComments(contentId, pre + 1, VIEW_ALL_PAGE_SIZE);
              return incrementedPageIndex;
            });
          }
        }, 500);
        prevScrollTop.current = scrollTop;
      }
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    searchParams.delete("contentId");
    setSearchParams(searchParams);
    if(isNewComment && contentId) {
      initListComment(
        contentId,
        query.pageIndex,
        isMobile ? PAGE_SIZE_MOBILE : query.pageSize
      );
    }
  }

  useEffect(() => {
    const commentList = commentListRef.current;
    if (commentList) {
      commentList.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (commentList) {
        commentList.removeEventListener("scroll", handleScroll);
      }
    };
  }, [comments, totalRecords]);

  useEffect(() => {
    if (contentInfo && onSetApprovalStatus) {
      onSetApprovalStatus(contentInfo?.approvalStatus);
    }
  }, [openModal, contentInfo, onSetApprovalStatus]);

  useEffect(() => {
    if (comments && comments.length) {
      const newArray: CommentModel[] = [];
      const cloneComments = CommonUtils.deepCopy(comments);
      let commentCounter = 0;
      for (let index = 0; index < comments.length; index++) {
        if (commentCounter >= 4) break;
        const element = comments[index];
        const cloneElement = cloneComments[index];
        cloneElement.childs = [];
        commentCounter++;
        if (element.childs && element.childs.length && commentCounter < 4) {
          for (let index = 0; index < element.childs.length; index++) {
            if (commentCounter >= 4) break;
            const child = element.childs[index];
            cloneElement.childs.push(child);
            commentCounter++;
          }
        }

        newArray.push(cloneElement);
      }

      setLessCommentsMobile(newArray);
    } else {
      setLessCommentsMobile([]);
    }
  }, [comments]);

  useEffect(() => {
    if (!isInModal && isReviewing && contentIdParam === props.contentId)
      setOpenModal(true);
  }, [contentIdParam]);


  return (
    <>
      <Spin spinning={loading}>
        <div className="comment-tab">
          <CommentDraft
            onChangeStatus={onChangeStatus}
            approvalStatus={approvalStatus}
            contentInfo={contentInfo && contentInfo}
            createComment={createComment}
            isReviewing={isReviewing}
          />
          <div
            ref={commentListRef}
            style={{ height: !isMobile ? height : "100%", overflowY: "auto" }}
            className="list-comment"
          >
           {(!isMobile || isViewAllMobile ? comments : lessCommentsMobile).map((comment, index) => {
              return (
                <div
                  ref={(el) => (commentElements.current[index] = el)}
                  key={comment.id}
                >
                  <CommentPosted
                    commentInfo={comment}
                    createComment={createComment}
                    remove={confirmRemoveComment}
                    isReviewing={isReviewing}
                  />
                </div>
              );
            })}
            {showViewAllButton && comments.length < totalRecords && (
              <>
                <Skeleton style={{ width: "98%" }} active avatar />
              </>
            )}
          </div>
          {isShowAll && !isMobile && (
            <div className="view-all-comment">
              <p onClick={handleViewAll}>
                {t("comment.viewAllComments")} ({allComments})
              </p>
            </div>
          )}

          {isMobile && allComments > PAGE_SIZE_MOBILE && (
            <div className="view-all-comment">
              <p onClick={() => handleViewMobile()}>
                {isViewAllMobile
                  ? t("comment.viewLess")
                  : `${t("comment.viewAllComments")} (${allComments})`}
              </p>
            </div>
          )}
        </div>
      </Spin>
      <Modal
        bodyStyle={{ backgroundColor: "#F7F8FB" }}
        title={t("comment.viewAllComments")}
        open={openModal}
        onCancel={onCloseModal}
        width="90vw"
        footer={null}
      >
        {contentInfo && (
          <ViewContent
            isInModal={true}
            showViewAllButton={true}
            contentInfo={contentInfo}
            listProfile={profiles}
          />
        )}
      </Modal>
    </>
  );
});

export default CommentTab;
