import { Modal } from "antd";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import "./index.scss";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  imageUrl?: string;
  onSaveImage: (fileInfo?: any) => Promise<boolean>;
}

const EditImageModal = (props: Props) => {
  const { open, setOpen, imageUrl, onSaveImage } = props;

  return (
    <Modal
      centered
      className="edit-image-modal"
      open={open}
      closeIcon={null}
      footer={null}
      width={787}
      bodyStyle={{ height: 600 }}
    >
      {open && (
        <FilerobotImageEditor
          resetOnImageSourceChange={true}
          // noCrossOrigin={true}
          savingPixelRatio={4}
          previewPixelRatio={window.devicePixelRatio}
          source={imageUrl + "?not-from-cache-please" || ""}
          onSave={(editedImageObject, designState) => onSaveImage(editedImageObject)}
          onClose={() => setOpen(false)}
          annotationsCommon={{
            fill: "#ff0000",
          }}
          Text={{ text: "Text..." }}
          Rotate={{ angle: 90, componentType: "slider" }}
          Crop={{
            presetsItems: [
              {
                titleKey: "classicTv",
                descriptionKey: "4:3",
                ratio: 4 / 3,
                // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
              },
              {
                titleKey: "cinemascope",
                descriptionKey: "21:9",
                ratio: 21 / 9,
                // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
              },
            ],
            presetsFolders: [
              {
                titleKey: "socialMedia", // will be translated into Social Media as backend contains this translation key
                // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                groups: [
                  {
                    titleKey: "facebook",
                    items: [
                      {
                        titleKey: "profile",
                        width: 180,
                        height: 180,
                        descriptionKey: "fbProfileSize",
                      },
                      {
                        titleKey: "coverPhoto",
                        width: 820,
                        height: 312,
                        descriptionKey: "fbCoverPhotoSize",
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          tabsIds={[
            TABS.ADJUST,
            TABS.FINETUNE,
            TABS.FILTERS,
            TABS.ANNOTATE,
            TABS.RESIZE,
          ]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ADJUST} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />
      )}
    </Modal>
  );
};

export default EditImageModal;
