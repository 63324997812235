import { Input, Select } from "antd";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { GMB_ACTION_BUTTONS } from "../../../../../../../../constants/app-constants";
import {
  GmbActionType,
  PlatformStatusType,
} from "../../../../../../../../constants/app.enums";
import useClient from "../../../../../../../../hooks/useClient";
import {
  currentContentSelector,
  SocialContentState,
} from "../../../../../../../../states/social";
import SVGIcons from "../../../../../../../icons/svgs";
import useUpdateContent from "../../../../hook/useUpdateContent";

interface Props {
  isReviewing: boolean | undefined;
  currentContent?: SocialContentState;
}

const GbpUpdateTab = (props: Props) => {
  const { isReviewing } = props;
  const { client } = useClient();
  const { onValuesPlatformChange } = useUpdateContent();
  const currentContent = useRecoilValue(currentContentSelector);

  const currentContentState = props.currentContent || currentContent;

  const isDisabled =
    currentContentState?.status === PlatformStatusType.Success || isReviewing;

  function onChangeCTAButton(value: GmbActionType) {
    onValuesPlatformChange({
      gmbActionType: value,
      gmbActionUrl: "",
      gmbActionUrlError: "",
    });
  }

  function onChangeUrl(e: any) {
    onValuesPlatformChange({
      gmbActionUrl: e.target.value,
      gmbActionUrlError: "",
    });
  }

  function clearUrl() {
    onValuesPlatformChange({
      gmbActionUrl: "",
      gmbActionUrlError: "",
    });
  }
  return (
    <div className="update-button">
      <div className="add-button">
        <p>Add A Button</p>
        <Select
          value={currentContentState?.gmbActionType}
          onChange={onChangeCTAButton}
          disabled={isDisabled}
        >
          {GMB_ACTION_BUTTONS.map((action) => {
            return (
              <Select.Option key={action.value} value={action.value}>
                {action.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {currentContentState?.gmbActionType !== GmbActionType.CallNow ? (
        <div className="link-button">
          <p>
            Link For Your Button{" "}
            <span
              className={
                currentContentState?.gmbActionType === GmbActionType.None
                  ? ""
                  : "error"
              }
            >
              *
            </span>
          </p>
          <Input
            value={currentContentState?.gmbActionUrl}
            onChange={onChangeUrl}
            placeholder="Enter URL"
            disabled={
              currentContentState?.gmbActionType === GmbActionType.None ||
              isDisabled
            }
            status={currentContentState?.gmbActionUrlError ? "error" : ""}
            suffix={
              currentContentState?.gmbActionUrlError ? (
                <span onClick={clearUrl}>
                  <SVGIcons.XIcon />
                </span>
              ) : (
                ""
              )
            }
          ></Input>
          <div className="error">{currentContentState?.gmbActionUrlError}</div>
        </div>
      ) : (
        <div className="link-button">
          <p>Phone Number</p>
          <Input value={""} disabled></Input>
        </div>
      )}
    </div>
  );
};

export default GbpUpdateTab;
