import { useTranslation } from "react-i18next";
import FailedAnimation from "../../../../../assets/animations/failed.gif";
import { SUPPORT_EMAIL } from "../../../../../constants/app-constants";
import "./index.scss";

function ConnectProfilesError() {
  const { t } = useTranslation();
  return (
    <>
      <div className="connect-profiles-error auth__form">
        <div className="connect-profiles-error__animation">
          <img src={FailedAnimation} alt="icon" />
        </div>
        <div className="header">{t("connectProfiles.errorTitle")}</div>
        <p className="description">
          {t('connectProfiles.generalError')}
        </p>

      </div>

      <a
        href={`mailto:${SUPPORT_EMAIL}`}
        className="auth__backLink"
        dangerouslySetInnerHTML={{
          __html: t("accountVerification.contactLink"),
        }}
      ></a>
    </>
  );
}

export default ConnectProfilesError;
