import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ZohoApi } from "../../apis/zoho.api";
import "./index.scss";
import { ROUTE_PATHS } from "../../constants/router.constants";
import { CommonUtils } from "../../utils/common";
import useAdminSite from "../../hooks/useAdminSite";
import { ZohoRedirectUrl } from "../../constants/app-constants";

function ZohoReturnPage() {
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState<boolean>(false);
    const isAdmin = useAdminSite();

    const getClientAccess = async () => {
        setLoading(true);
        try {
            const state = searchParams.get("state");
            const code = searchParams.get("code");
            if (code && state && state.split("_").length === 2) {
                const cId = Number(state.split("_")[0]);
                await ZohoApi.getClientAccess(code, state ? cId : 0, isAdmin);
                const customRedirect = state.split("_")[1];
                let url = "";
                switch (customRedirect) {
                    case ZohoRedirectUrl.SaleAnalytic:
                        url = ROUTE_PATHS.SalesAnalytics;
                        break;
                    case ZohoRedirectUrl.AdminViewSaleAnalytic:
                        url = CommonUtils.getAdminViewClientSiteRouter(ROUTE_PATHS.SalesAnalytics, cId)
                        break;
                    case ZohoRedirectUrl.GeneralSettings:
                        url = ROUTE_PATHS.GeneralSettings + "?tab=advanced";
                        break;
                    case ZohoRedirectUrl.AdminViewGeneralSettings:
                        url = CommonUtils.getAdminViewClientSiteRouter(ROUTE_PATHS.GeneralSettings, cId) + "?tab=advanced"
                        break;
                    case ZohoRedirectUrl.AdminEditSite:
                        url = ROUTE_PATHS.ClientSite + `?clientId=${cId}&tab=Integration`;
                        break;
                    default:
                        break;
                }
                window.location.replace(url);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getClientAccess()
    }, [])

    return (
        <div className="general-settings-page page-content">
            <div className="zoho-return">
                {loading && <Spin
                    className="spin"
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                ></Spin>}

            </div>
        </div>
    );
}

export default ZohoReturnPage;
