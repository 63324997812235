/* eslint-disable react/jsx-no-target-blank */
import { Dropdown, Menu } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthServiceApi } from "../../../apis/auth.api";
import { ServiceType } from "../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import useClient from "../../../hooks/useClient";
import { navbarOpenState } from "../../../states/common";
import { accessServiceState } from "../../../states/social";
import { CommonUtils } from "../../../utils/common";
import SVGIcons from "../../icons/svgs";
import "./index.scss";

function SecondHeader() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [accessServices, setAccessServices] =
    useRecoilState(accessServiceState);
  const navigate = useNavigate();
  const menuOpen = useRecoilValue(navbarOpenState);


  const { clientId, client, getRouter, isAdminViewSite } = useClient();

  const onClickMenuSocial = ({ key }: any) => {
    navigate(`${getRouter(key)}`);
  };

  const menuSocial = (
    <Menu
      onClick={onClickMenuSocial}
      items={[
        { key: ROUTE_PATHS.Social.Dashboard, label: "Social Dashboard" },
        { key: ROUTE_PATHS.Social.Calendar, label: "Calendar" },
        { key: ROUTE_PATHS.Social.Content, label: "Content" },
        { key: ROUTE_PATHS.Social.Approvals, label: "Approvals" },
      ]}
    />
  );

  const menuItems = [
    {
      icon: <SVGIcons.AtomFilledIcon />,
      text: t("common.insightsHub"),
      href: getRouter(ROUTE_PATHS.Home),
    },
    {
      icon: <SVGIcons.ChartAreaIcon />,
      text: t("common.salesAnalytics"),
      href: getRouter(ROUTE_PATHS.SalesAnalytics),
    },
    {
      icon: <SVGIcons.PolygonIcon />,
      text: (
        <Dropdown
          overlay={menuSocial}
          placement="bottom"
          overlayClassName="social-tab-dropdown"
        >
          <span className="social-dropdown">
            {t("common.social")}
            <SVGIcons.ChevronDown />
          </span>
        </Dropdown>
      ),
      href: getRouter(ROUTE_PATHS.Social.Dashboard),
      disabled: !accessServices.data.includes(ServiceType.Social),
    },
    {
      icon: <SVGIcons.ClipBoardTextIcon />,
      text: t("generalSettings.reports"),
      href: getRouter(ROUTE_PATHS.Reports),
    },
    {
      icon: <SVGIcons.TriangleSquareCircleIcon />,
      text: t("mediaLibrary.title"),
      href: getRouter(ROUTE_PATHS.MediaLibrary),
    },
    // {
    //   icon: <SVGIcons.Map2Icon />,
    //   text: t("common.listings"),
    //   href: ROUTE_PATHS.Listings,
    // },
    // {
    //   icon: <SVGIcons.StartIcon />,
    //   text: t("common.reviews"),
    //   href: ROUTE_PATHS.Reviews,
    // },
    // {
    //   icon: <SVGIcons.MessageIcon />,
    //   text: t("common.inbox"),
    //   href: ROUTE_PATHS.Inbox,
    // },
  ].filter((item) => !item.disabled);

  const onClickLink = (e: any, title: string) => {
    if (title === getRouter(ROUTE_PATHS.Social.Dashboard)) e.preventDefault();
  };

  const getAccessService = async () => {

    if (!clientId) return;
    const { data } = await AuthServiceApi.getAccessServices({
      clientId,
    });

    //RLV2-2358 If the Social service is disabled, the content page after reload should redirect to Insight Hubs
    if(data && !data.includes(ServiceType.Social) && pathname.includes("/social/")) {
      navigate(getRouter(ROUTE_PATHS.Home))
    }

    setAccessServices({
      data,
      isFetched: true
    });
  };

  useEffect(() => {
    getAccessService();
  }, []);


  return (
    <>
      <div className={`second-header mobile-hidden`}>
        <div className="second-header__left">
          {menuItems.map((item, index) => (
            <Link
              to={item.href}
              onClick={(e) => onClickLink(e, item.href)}
              key={index}
              className={item.href && item.href === pathname ? "active" : ""}
            >
              {item.icon}
              <span>{item.text}</span>
            </Link>
          ))}
        </div>

        <div className="second-header__right">
          <span
            className="second-header__website"
            onClick={() => !isAdminViewSite && navigate(ROUTE_PATHS.SelectCompany)}
          >
            <span>{CommonUtils.removeProtocol(client?.companyName)}</span>
          </span>
        </div>
      </div>

      <div
        className={`desktop-hidden second-header collapse${menuOpen ? " show" : ""
          }`}
        style={{ height: menuOpen ? 46 * menuItems.length : "" }}
      >
        <div className="second-header__left">
          {menuItems.map((item, index) => (
            <Link
              to={item.href}
              onClick={(e) => onClickLink(e, item.href)}
              key={index}
              className={item.href && item.href === pathname ? "active" : ""}
            >
              {item.icon}
              <span>{item.text}</span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default SecondHeader;
