import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"
import { SocialApi } from "../../../apis/social.api";
import ConnectProfilesError from "../../../components/views/social/connect-profiles/error";
import { LoadingOutlined } from "@ant-design/icons";

import { Spin } from "antd";
import { ROUTE_PATHS } from "../../../constants/router.constants";

const SocialConnectProfilePage = () => {
    const [searchParams] = useSearchParams();
    const confirmationCode = searchParams.get("confirmationCode") || "";
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (confirmationCode) {
            redirectLink();
        } else {
            setError(true)
        }
    }, [confirmationCode])

    const redirectLink = async () => {
        setLoading(true);
        setError(false)
        try {
            const res = await SocialApi.getConnectProfileLink(confirmationCode, ROUTE_PATHS.Social.ConnectedProfilesSuccess);
            window.location.replace(res.data);
        } catch (error) {
            setError(true)
        }
        setLoading(false);
    }

    return loading ?
        <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        ></Spin>
        : error ? <ConnectProfilesError /> : <></>
}

export default SocialConnectProfilePage;