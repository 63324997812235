import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  MenuProps,
  Modal
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AuthApi } from "../../../../apis/auth.api";
import { SocialApi } from "../../../../apis/social.api";
import logoMobile from "../../../../assets/images/logo-mobile.svg";
import logo from "../../../../assets/images/logo.svg";
import AppAvatar from "../../../../components/common/app-avatar/app-avatar";
import showNotification from "../../../../components/common/notification";
import SVGIcons from "../../../../components/icons/svgs";
import MainApprovalRequest from "../../../../components/views/social/approvals/main-approval-request";
import {
  SUPPORT_EMAIL,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { isMobileSelector } from "../../../../states/common";
import {
  listProfileState,
  requiredGuestUserState,
} from "../../../../states/social";
import LocalUtils from "../../../../utils/local.utils";
import "./index.scss";

enum MenuCollaboratorEnum {
  EditName,
  ContactSupport,
}

const ApprovalRequestPage = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const approvalId = Number(searchParams.get("approvalId"));
  const setListProfile = useSetRecoilState(listProfileState);
  const [createGuestLoading, setCreateGuestLoading] = useState(false);
  const [requiredGuestUser, setRequiredGuestUser] = useRecoilState(
    requiredGuestUserState
  );

  const isMobile = useRecoilValue(isMobileSelector);

  const [clientName, setClientName] = useState<string>();
  const [openDropdown, setOpenDropdown] = useState<string[]>([]);

  const guestInfo = LocalUtils.getGuestUserInfo();

  const initData = async () => {
    try {
      if (!approvalId) {
        throw new Error("Approval not found!");
      }
      const { data: approvalProfile } =
        await SocialApi.getProfileApprovalDetail(approvalId);
      setClientName(approvalProfile?.companyName);
      setListProfile({ profiles: approvalProfile?.profiles });
    } catch (error: any) {
      showNotification("error", error.message);
    }
  };

  const initGuestUser = () => {
    if (!guestInfo?.uuid) setRequiredGuestUser(true);
  };

  const confirmGuestUser = () => {
    form.submit();
  };

  const submit = async () => {
    setCreateGuestLoading(true);
    try {
      const { yourName } = form.getFieldsValue();
      const { data } = await AuthApi.createGuestUser(yourName);
      LocalUtils.setGuestUserInfo(data);
      handleCancel();
    } catch (error) {}
    setCreateGuestLoading(false);
  };

  const handleCancel = () => {
    setRequiredGuestUser(false);
    setOpenDropdown([]);
  };

  const onChangeMenuCollaborator = (info: any) => {
    const { key } = info;
    switch (Number(key)) {
      case MenuCollaboratorEnum.EditName:
        setRequiredGuestUser(true);
        break;
      default:
        break;
    }
  };

  const menuCollaborator: MenuProps = {
    items: [
      {
        key: MenuCollaboratorEnum.EditName,
        label: t("social.content.editYourName"),
      },
      {
        type: "divider",
      },
      {
        key: MenuCollaboratorEnum.ContactSupport,
        label: (
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            dangerouslySetInnerHTML={{
              __html: t("social.content.contactSupport"),
            }}
          ></a>
        ),
      },
    ],
    onClick: onChangeMenuCollaborator,
  };

  useEffect(() => {
    initData();
    initGuestUser();
  }, []);

  const items: any[] = [
    {
      key: "menu",
      label: "",
      children: [
        {
          key: "menu1",
          label: t("social.content.editYourName"),
          onClick: () => setRequiredGuestUser(true),
        },
        {
          key: "menu2",
          label: (
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              dangerouslySetInnerHTML={{
                __html: t("social.content.contactSupport"),
              }}
            ></a>
          ),
        },
      ],
    },
  ];

  return (
    <div className={`main-layout main-layout-review`}>
      <div className={`header-wrapper`}>
        <div className="page-container-width">
          <div className="main-header-review">
            <div className="main-header__logo">
              <img src={isMobile ? logoMobile : logo} className="auth-layout__logo approve-request" alt="RocketLevel" />
              <div className="main-header__client-name">{clientName}</div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className={`page-container-width`}>
          <div className="second-header-review">
            <div className="second-header-review-left">
              <span>Approval Request</span>
            </div>
            <div className="second-header-review-right">
              <Dropdown
                menu={menuCollaborator}
                placement="bottomRight"
                arrow
                overlayClassName="dropdown-menu-collaborator"
              >
                <div
                  className="collapborator"
                  onClick={() =>
                    setOpenDropdown(openDropdown.length ? [] : ["menu"])
                  }
                >
                  <AppAvatar
                    src={guestInfo?.profilePictureUrl}
                    shape="square"
                  />
                  <span className="user-name">
                    {guestInfo?.fullName || "Guest"}
                  </span>
                  <span className="user-name-mobile">
                    {openDropdown.length ? (
                      <SVGIcons.ChevronUp />
                    ) : (
                      <SVGIcons.ChevronDown />
                    )}
                  </span>
                </div>
              </Dropdown>
            </div>
          </div>
          {!!openDropdown.length && (
            <Menu
              style={{ width: "100%" }}
              openKeys={openDropdown}
              mode={"inline"}
              items={items}
              selectedKeys={["menu"]}
            />
          )}
        </div>
        <div className="line"></div>
      </div>
      <div className={`page-container-width app-body`}>
        <div className="review-content-wrap">
          <MainApprovalRequest />
        </div>
      </div>
      <div className="app-footer">
        <small className="copy-right">{t("common.copyRight")}</small>
      </div>

      <Modal
        open={requiredGuestUser}
        title={t(`social.content.createGuestUserModalTitle`)}
        okText={t("common.move")}
        centered
        className="custom-create-modal create-guest-modal"
        onCancel={handleCancel}
        footer={
          <div className="confirm-modal-footer">
            <Button type="default" onClick={handleCancel}>
              {t("common.cancel")}
            </Button>
            <Button
              type="primary"
              onClick={confirmGuestUser}
              loading={createGuestLoading}
            >
              {t("common.confirm")}
            </Button>
          </div>
        }
      >
        <div className="modal-decline-content">
          <div className="description">
            {t("social.content.createGuestUserModalDescription", {
              clientName,
            })}
          </div>
          <Form
            name="createSiteForm"
            layout="vertical"
            onFinish={submit}
            form={form}
            autoComplete="off"
            validateMessages={VALIDATION_MESSAGE_CONFIG}
            initialValues={{ yourName: guestInfo?.fullName }}
          >
            <Form.Item
              label={t("social.content.yourName")}
              name="yourName"
              rules={[
                { required: true, message: "Your Name field is required" },
              ]}
            >
              <Input placeholder="Your name here..." />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ApprovalRequestPage;
