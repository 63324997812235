import { useRecoilState, useRecoilValue } from "recoil";
import { GaApi } from "../apis/ga.api";
import { clientSitesState, connectionState } from "../states/clientSites";
import useAdminSite from "./useAdminSite";
import useClient from "./useClient";

function useFetchConnection() {
  const [connectionValues, setConnectionValues] =
    useRecoilState(connectionState);
  const isAdmin = useAdminSite();
  const { clientId, isAdminViewSite } = useClient();
  const { clientSelected } = useRecoilValue(clientSitesState);

  async function fetchConnection() {
    setConnectionValues({ ...connectionValues, loading: true });
    try {

      const { data } = isAdmin ? await GaApi.adminGetGoogleConnections({ clientId: isAdminViewSite ? clientId : clientSelected?.id }) : await GaApi.getGoogleConnections({ clientId });
      setConnectionValues({
        ...connectionValues,
        listGAConnection: data,
        loading: false
      });
    } catch {
      setConnectionValues({ ...connectionValues, loading: false });
    }
  }

  return { fetchConnection, connectionValues };
}

export default useFetchConnection;
