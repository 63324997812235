import { useTranslation } from "react-i18next";
import CheckedAnimation from "../../../../assets/animations/checked.gif";
import "./index.scss";

interface Props {
  email: string;
}

function EmailVerificationSuccess({email}: Props) {
  const { t } = useTranslation();

  return (
    <div className="account-verification-success auth__form">
      <div className="account-verification-success__animation">
        <img src={CheckedAnimation} />
      </div>
      <div className="header">{t("emailVerification.successTitle")}</div>
      <p className="description"   dangerouslySetInnerHTML={{
          __html: t("emailVerification.successDescription", {param1: email}),
        }}>
        </p>
    </div>
  );
}

export default EmailVerificationSuccess;
