import { Button, Modal, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonUtils } from "../../../../utils/common";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";

interface Props {
  content?: any;
  close: Function;
  url: string
}

export const ShareLinkModal = (props: Props) => {
  const { content, close, url } = props;
  const [isCopyLink, setIsCopyLink] = useState(false);
  const [shortenUrl, setShortenUrl] = useState("")
  const { t } = useTranslation();

  const copyLink = () => {
    navigator.clipboard.writeText(shortenUrl);
    setIsCopyLink(true);
  };

  useEffect(() => {
    if (content && url) {
      CommonUtils.shortenUrl({url}).then((res) => {
        if (res) {
          setShortenUrl(res)
        } else {
          setShortenUrl(url)
        }
      })
    }
  }, [content, url])

  return (
    <Modal
      open={!!content}
      title={t(`social.shareLink.title`)}
      className="custom-create-modal"
      onCancel={() => close()}
      footer={null}
    >
      <div className="share-link-modal">
        <p>{t(`social.shareLink.description`)}</p>
        <div className="get-link-block">
          <Button className="btn-link-icon">
            <SVGIcons.LinkIcon />
          </Button>
          <Button loading={!shortenUrl} disabled={!shortenUrl} className="btn-link-text">{shortenUrl}</Button>
          <Tooltip title={isCopyLink ? "Link copied!" : "Copy link to share"}>
            <Button type="primary" className="btn-copy" onClick={copyLink}>
              <SVGIcons.CopyIcon /> {t("common.copy")}
            </Button>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

export default ShareLinkModal;
