import { useTranslation } from "react-i18next";
import Billing from "./billing";
import Usage from "./usage";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { PlanType } from "../../constants/app.enums";
import useClientPlan from "../../hooks/useClientPlan";
import { useEffect, useRef, useState } from "react";
import { GetClientUsageResponse } from "../../models/plan.model";
import useClient from "../../hooks/useClient";


const UsageAndBilling = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const status = searchParams.get("upgrade-status");
    const isReloaded = searchParams.get("reloaded");
    const { userPlan, getUsage} = useClientPlan();
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [usage, setUsage] = useState<GetClientUsageResponse>();
    const [loading, setLoading] = useState<boolean>(false)
    const {clientId } = useClient();

    useEffect(()=> {
        if(clientId) {
            getUsageData();
        }
    },[clientId])

    const getUsageData = async () => {
        setLoading(true)
        try {
            const data = await getUsage();
            setUsage(data);
        } finally {
            setLoading(false)
        }
    }

    if (status === "success" && userPlan?.planType !== PlanType.Premium && !isReloaded) {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
        timeoutRef.current = setTimeout(() => {
            if (window.location.href.includes("?upgrade-status=success")) {
                window.location.href = `${window.location.href}&reloaded=true`;
            }
        }, 3000);
        return <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 260px)' }}>
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            ></Spin>
        </div>
    }

    return (
        <div>
            <div className="page-header">
                <h4 className="page-header__title">{t("dashboard.usageAndBilling")}</h4>
            </div>
            <Usage usage={usage} loading={loading} />
            <Billing usage={usage} />
        </div>
    );
};

export default UsageAndBilling;
