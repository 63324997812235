import { Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../../../../apis/social.api";
import { PlatformStatusType } from "../../../../../../../../constants/app.enums";
import { GBPCategoryModel } from "../../../../../../../../models/content.model";
import { currentContentSelector, SocialContentState } from "../../../../../../../../states/social";
import useUpdateContent from "../../../../hook/useUpdateContent";
import "./index.scss";

interface Props {
  isReviewing: boolean | undefined;
  currentContent?: SocialContentState;
}

const PhotoVideoTab = (props: Props) => {
  const { isReviewing } = props;
  const { onValuesPlatformChange } = useUpdateContent();
  const currentContent = useRecoilValue(currentContentSelector);

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<GBPCategoryModel[]>([]);

  const currentContentState = props.currentContent || currentContent;

  const isPublished = currentContent?.status === PlatformStatusType.Success;
  const isDisabled = isReviewing || isPublished;

  const onChangeCategory = (value: string) => {
    onValuesPlatformChange({
      gmbCategory: value,
    });
  };

  const handleClear = () => {
    onValuesPlatformChange({
      gmbCategory: undefined,
    });
  };

  const initListGBPCategories = async () => {
    setLoading(true);
    try {
      const { data } = await SocialApi.Content.getGBPCategories();
      setCategories(data);
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    initListGBPCategories();
  }, []);
  if (loading) {
    return <Spin />;
  }
  return (
    <div className="photo-video-tab">
      <Select
        value={currentContentState?.gmbCategory}
        onChange={onChangeCategory}
        placeholder="Select category"
        loading={loading}
        allowClear
        onClear={handleClear}
        disabled={isDisabled}
      >
        {categories.map((c) => {
          return (
            <Select.Option key={c.cateId} value={c.cateId}>
              {c.cateTitle}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default PhotoVideoTab;
