import { Button, Dropdown } from "antd";
import { useEffect, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../../../apis/social.api";
import useClient from "../../../../../../../hooks/useClient";
import {
  contentValueState,
  currentContentSelector,
} from "../../../../../../../states/social";
import SVGIcons from "../../../../../../icons/svgs";
import "./index.scss";
import WriteWithAIModal from "./wirte-with-ai-modal";

enum MenuAction {
  Generate_New = "Generate_New",
  Complete = "Complete",
}
interface WriteWithAIProps {
  addContent: (content: string, isNew?: boolean) => void;
}
const WriteWithAI = (props: WriteWithAIProps) => {
  const { addContent } = props;
  const { t } = useTranslation();
  const { client } = useClient();
  const { showWriteWithAI } = useRecoilValue(contentValueState);
  const currentContent = useRecoilValue(currentContentSelector);

  const [open, setOpen] = useState(false);
  const [loadingCompleteContent, setLoadingCompleteContent] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const completeContent = async () => {
    if (!client?.id || !currentContent?.post) return;
    setLoadingCompleteContent(true);
    try {
      const currentPost = currentContent?.post || "";
      const { data } = await SocialApi.AI.generateAIContent({
        clientId: client.id,
        prompt: `complete the sentence "${currentPost}"`,
        approximate: 50,
        isIncludeEmoji: false,
        isIncludeHashTags: false,
      });
      const contentGenerate = data.results.find((r) =>
        r.result.startsWith(currentPost)
      );
      if (contentGenerate) addContent(" " + contentGenerate.result, true);
      else addContent(data.results[0]?.result || "");
    } catch (error) {}
    setLoadingCompleteContent(false);
  };

  const onClickMenu = (menuItem: any) => {
    const { key } = menuItem;
    switch (key) {
      case MenuAction.Generate_New:
        openModal();
        break;
      case MenuAction.Complete:
        completeContent();
        break;

      default:
        break;
    }
  };
  const menuAction = {
    items: [
      { key: MenuAction.Generate_New, label: "Generate New Content" },
      {
        key: MenuAction.Complete,
        label: "Complete Content",
      },
    ],
    onClick: onClickMenu,
  };

  useEffect(() => {
    if (showWriteWithAI) setOpen(true);
  }, [showWriteWithAI]);
  
  return (
    <div className="write-with-ai">
      <Dropdown menu={menuAction} trigger={["click"]} placement="bottomLeft">
        <Button type="default" className="btn-write-with-ai">
          {loadingCompleteContent ? (
            <Dots color="#b956c2" size={16} speed={1} animating={true} />
          ) : (
            <>
              <SVGIcons.SparklesIcon />
              {t("social.aiFeature.writeWithAI")}
            </>
          )}
        </Button>
      </Dropdown>

      <WriteWithAIModal
        open={open}
        close={closeModal}
        addContent={addContent}
      />
    </div>
  );
};

export default WriteWithAI;
