import { AxiosResponse } from "axios";
import { PlanType, PlanUsageType } from "../constants/app.enums";
import { getAsync, putAsync } from "./http-client";
import { GetClientUsageResponse, GetPlanByTypeResponse, GetUpgradePremiumUrlResponse, PlanUsageModel } from "../models/plan.model";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth/api/v1`;

function getPlanByType(type: PlanType): Promise<AxiosResponse<GetPlanByTypeResponse, any>> {
    const url = `${baseUrl}/plans/${type}`;
    return getAsync(url);
}

function getClientUsagePlan(clientId?: number): Promise<AxiosResponse<GetClientUsageResponse, any>> {
    const url = `${baseUrl}/plans/client-usages?clientId=${clientId}`;
    return getAsync(url);
}

function checkClientUsagePlan(usageType:PlanUsageType, clientId?: number ): Promise<AxiosResponse<PlanUsageModel, any>> {
    const url = `${baseUrl}/plans/check-client-usage-plan?usageType=${usageType}&clientId=${clientId}`;
    return getAsync(url);
}

function getUpgradePremiumUrl(): Promise<AxiosResponse<GetUpgradePremiumUrlResponse, any>> {
    const url = `${baseUrl}/plans/get-url-upgrade-premium`;
    return getAsync(url);
}

function cancelPlan(clientId: number) {
    const url = `${baseUrl}/plans/cancel?clientId=${clientId}`;
    return putAsync(url);
}

function renewPlan(clientId: number, planType?: PlanType) {
    const url = `${baseUrl}/plans/renew?clientId=${clientId}&planType=${planType}`;
    return putAsync(url);
}

export const PlanApi = {
    getPlanByType,
    getClientUsagePlan,
    checkClientUsagePlan,
    getUpgradePremiumUrl,
    cancelPlan,
    renewPlan
}