import { Button, Input, Radio } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcons from "../../../../icons/svgs";
import UploadMediaModal from "../upload-media-modal";
import "./index.scss";
import { MediaLibraryItems } from "../../../../../pages/media-library";

interface Props {
  library?: MediaLibraryItems;
  changeType: (type: MediaType) => void;
  searchMediaKeywordChange: (keyword: string) => void;
  searchValue?: string;
}

export enum MediaType {
  all = "all",
  images = "photos",
  videos = "videos"
}

const MediaLibraryHeader = (props: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { library, changeType, searchMediaKeywordChange, searchValue } = props;
  const [selectedType, setSelectedType] = useState(MediaType.images);
  const [keyword, setKeyword] = useState(searchValue);

  useEffect(() => { setKeyword(searchValue) }, [searchValue])

  useEffect(() => {
    if (library === MediaLibraryItems.myFile) {
      setSelectedType(MediaType.all);
    } else {
      setSelectedType(MediaType.images);
    }
  }, [library]);

  function onChangeType(type: MediaType) {
    setSelectedType(type);
    changeType(type);
  }


  const timoutRef = useRef<NodeJS.Timeout>();
  function onSearchChange(val: string) {
    if (timoutRef.current) {
      clearTimeout(timoutRef.current);
      timoutRef.current = undefined;
    }
    timoutRef.current = setTimeout(() => {
      searchMediaKeywordChange(val)
    }, 500);
  }

  function handleSearch(event: any) {
    if (event.key === "Enter") {
      onSearchChange(event.target.value)
    };
  }

  return (
    <div className="media-library-header">
      <Radio.Group value={selectedType} className="select-type" onChange={(v) => onChangeType(v.target.value)}>
        <Radio.Button value={MediaType.all} disabled={library !== MediaLibraryItems.myFile}>All</Radio.Button>
        <Radio.Button value={MediaType.images}>Images</Radio.Button>
        <Radio.Button value={MediaType.videos} disabled={library === MediaLibraryItems.unsplash}>Videos</Radio.Button>
      </Radio.Group>
      <Input
        placeholder={t("mediaLibrary.searchPlaceholder")}
        suffix={<SVGIcons.SearchIcon />}
        className="search-input"
        allowClear={keyword !== ""}
        onKeyUp={(e) => handleSearch(e)}
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
      />

      {/* <Button type="link">
        <SVGIcons.CloseIcon />
      </Button> */}

      {/* <Select
        defaultValue="all"
        style={{ width: 200 }}
        // onChange={handleChange}
        options={[
          { value: "all", label: "All Media" },
          { value: "image", label: "Images Only" },
          { value: "video", label: "Video Only" },
        ]}
      /> */}

      <Button type="primary" onClick={() => setOpen(true)}>
        <SVGIcons.UploadIcon /> Upload Media
      </Button>

      <UploadMediaModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default MediaLibraryHeader;
