import { atom } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { TimezonesModel } from "../models/timezones.model";

interface TimezonesState {
  records: TimezonesModel[];
}

const timezonesState = atom<TimezonesState>({
  key: APP_STATE_KEYS.LIST_TIMEZONES,
  default: {
    records: [],
  },
});


export { timezonesState };
