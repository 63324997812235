import { Button, Col, Drawer, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { PASSWORD_POLYCIES, VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import SVGIcons from "../../../../icons/svgs";
import "./index.scss";
import { AuthApi } from "../../../../../apis/auth.api";
import LocalUtils from "../../../../../utils/local.utils";
import showNotification from "../../../../common/notification";
import useClient from "../../../../../hooks/useClient";

interface Props {
  showDrawer: boolean;
  onSetShowDrawer: any;
}
const ResetPasswordDrawer = (props: Props) => {
  const { showDrawer, onSetShowDrawer } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isAdminViewSite, client } = useClient();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [passwordPolicies, setPasswordPolicies] = useState(
    PASSWORD_POLYCIES.filter((x) => !x.disabled)
  );

  const passwordOnchange = (value: string) => {
    const newArray = [...passwordPolicies];
    newArray.forEach((item) => {
      var reg = new RegExp(item.regex);
      item.valid = reg.test(value);
    });

    setPasswordPolicies(newArray);

    form.getFieldValue("confirm_password") && form.validateFields(["confirm_password"])
  };

  useEffect(() => {
    form.resetFields();
    setPasswordPolicies(
      PASSWORD_POLYCIES.filter((x) => !x.disabled).map((x) => {
        return { ...x, valid: false };
      })
    );
  }, [showDrawer]);

  async function submit() {
    setSubmitLoading(true);
    try {
      form
        .validateFields()
        .then(async (values) => {
          const { oldPassword, newPassword } = values;

          if (isAdminViewSite) {
            if (!client?.user?.userId) {
              return;
            }
            await AuthApi.adminChangeClientPassword({
              username: client?.user?.userId,
              newPassword: newPassword
            });
          } else {
            const accessToken = LocalUtils.get("ACCESS_TOKEN") || "";
            await AuthApi.changePassword({
              oldPassword,
              newPassword,
              accessToken,
            });
          }

          showNotification(
            "success",
            t("generalSettings.updatePasswordSuccessTitle"),
            t("generalSettings.updatePasswordSuccessDescription")
          );
          onSetShowDrawer(false);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    } catch (error) {
      setSubmitLoading(false);
    }
  }
  return (
    <Drawer
      className="app-edit-drawer reset-pw-drawer"
      title="Reset Password"
      open={showDrawer}
      width={540}
      onClose={() => onSetShowDrawer(false)}
      maskClosable={false}
      footer={
        <div className="drawer-footer-wrapper">
          <Button type="text" onClick={() => onSetShowDrawer(false)}>
            {t("clientSites.btnClose")}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            onClick={submit}
            loading={submitLoading}
          >
            {t("clientSites.btnSave")}
          </Button>
        </div>
      }
    >
      <Form
        layout="vertical"
        form={form}
        validateMessages={VALIDATION_MESSAGE_CONFIG}
      >
        {
          !isAdminViewSite &&
          <Form.Item
            label={t("resetPassword.currentPassword")}
            colon={false}
            name="oldPassword"
            rules={[{ required: true }]}
          >
            <Input.Password
              placeholder={t("resetPassword.currentPassword")}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              onChange={() => form.getFieldValue("newPassword") && form.validateFields(["newPassword"])}
            />
          </Form.Item>
        }

        <Form.Item
          label={t("resetPassword.newPassword")}
          colon={false}
          name="newPassword"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (value && value === form.getFieldValue("oldPassword")) {
                  return Promise.reject(t("updatePassword.passwordSame"));
                }
                if (passwordPolicies.some((x) => !x.valid)) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t("resetPassword.newPassword")}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            onChange={(e) => passwordOnchange(e.target.value)}
          />
        </Form.Item>

        <Row className="password-hint-wrapper">
          {passwordPolicies.map((item) => (
            <Col
              span={12}
              key={item.key}
              className={`password-hint-item${item.valid ? " active" : ""}`}
            >
              {item.valid ? (
                <SVGIcons.CheckedNotRoundedIcon />
              ) : (
                <SVGIcons.KeyIcon />
              )}
              <span>{item.label}</span>
            </Col>
          ))}
        </Row>

        <Form.Item
          label={t("resetPassword.confirmNewPassword")}
          colon={false}
          name="confirm_password"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (value && form.getFieldValue("newPassword") !== value)
                  return Promise.reject(t("resetPassword.passwordsNotMatch"));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t("resetPassword.confirmNewPassword")}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ResetPasswordDrawer;
