import moment, { Moment } from "moment";
import { DATE_TIME_FORMAT } from "../constants/app-constants";
import momentTimezone from "moment-timezone";
import { TimezonesModel } from "../models/timezones.model";

const addUTCCharater = (value?: string) => {

  if(!value || value.includes("Z")) {
    return value;
  }

  return value + "Z";
};

const convertServerToDateViewFormat = (
  value: string | undefined | null
): string => {
  if (!value) {
    return "";
  }
  return moment(addUTCCharater(value)).format(DATE_TIME_FORMAT.dateView);
};

const convertServerToTimeViewFormat = (
  value: string | undefined | null
): string => {
  if (!value) {
    return "";
  }
  return moment(addUTCCharater(value)).format(DATE_TIME_FORMAT.timeView);
};

function dateView(value: Moment | string): string {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.dateView);
}

function dateAndMonthView(value: string): string {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.dateMonthView);
}

function monthView(value: Moment | string): string {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.monthView);
}

const timeView = (value: string): string => {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.timeView);
};

const isoDate = (value: string): string => {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.isoDate);
};

function getMonths(startDate: string, endDate: string) {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
}

// 2023-09-07T00:00:00Z => 2023-09-06T17:00:00Z
function convertLocalUTCToUTC(value: string, timezone: string) {
  const dateLocalStr = value.slice(0, -1); // remove Z
  return momentTimezone.tz(dateLocalStr, timezone).utc();
}

const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

function convertTimeToTimezone(targetTimezone?: string, time?: any) {
  const getTimeByTimezone = (timezone: string) => {
    if (time) {
      return momentTimezone(time).tz(timezone);
    } else {
      return momentTimezone().tz(timezone);
    }
  };

  const timezoneToUse = targetTimezone || getCurrentTimezone();
  return getTimeByTimezone(timezoneToUse);
}

function convertUTCTimeToTimezone(targetTimezone?: string, time?: any) {
  const getTimeByTimezone = (timezone: string) => {
    if (time) {
      return momentTimezone(addUTCCharater(time)).tz(timezone);
    } else {
      return momentTimezone().tz(timezone);
    }
  };

  const timezoneToUse = targetTimezone || getCurrentTimezone();
  return getTimeByTimezone(timezoneToUse);
}

function getDateWithTimezone(
  date: string | undefined | moment.Moment,
  timezone: string | undefined
) {
  if (timezone) {
    return momentTimezone.utc(date).tz(timezone);
  } else {
    return moment.utc(date).local();
  }
}

function isBeforeUTC(dateUTC: moment.Moment) {
  return (
    dateUTC.utc().format("YYYY-MM-DDTHH:mm:ss") <
    momentTimezone.utc().format("YYYY-MM-DDTHH:mm:ss")
  );
}

function getDefaultTimeZone(timezones: TimezonesModel[]) {
  const offset = new Date().getTimezoneOffset() / -60;
  const defaultTimeZone = timezones.find((tz) => tz.offSet === offset);
  if (defaultTimeZone) return defaultTimeZone.id;
  return "America/New_York";
}

const DateUtils = {
  dateView,
  timeView,
  getMonths,
  monthView,
  isoDate,
  dateAndMonthView,
  convertServerToDateViewFormat,
  convertServerToTimeViewFormat,
  convertLocalUTCToUTC,
  convertTimeToTimezone,
  getDateWithTimezone,
  getCurrentTimezone,
  isBeforeUTC,
  getDefaultTimeZone,
  convertUTCTimeToTimezone,
  addUTCCharater
};

export default DateUtils;
