import { Col, Row, Spin } from "antd";
import { ApexOptions } from "apexcharts";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "../../../../../constants/app-constants";
import Chart from "react-apexcharts";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import worldCountriesGeojs from "./countries-110m.json";
import countriesCode from "./countries.json";
import "./index.scss";
import { useRecoilValue } from "recoil";
import { socialDashboardDateRangeState } from "../../../../../states/social-dashboard";
import useClient from "../../../../../hooks/useClient";
import { useEffect, useMemo, useState } from "react";
import { AgesReportRowModel } from "../../../../../models/social.model";
import { SocialApi } from "../../../../../apis/social.api";
import SocialUtils from "../../../../../utils/social";

const categoriesAges = [
  "13-17",
  "18-24",
  "25-34",
  "35-44",
  "45-54",
  "55-64",
  "65+",
];

interface CountryMapData {
  id?: number | undefined;
  color?: string;
  regionName: string;
  value: number;
}

const Audience = () => {
  const { t } = useTranslation();
  const { clientId } = useClient();
  const [loadingAges, setLoadingAges] = useState(false);
  const [loadingTopRegion, setLoadingTopRegion] = useState(false);
  const [totalAgesReport, setTotalAgesReport] = useState<AgesReportRowModel[]>(
    []
  );
  const [countriesReport, setCountriesReport] = useState<CountryMapData[]>([]);
  const dateRange = useRecoilValue(socialDashboardDateRangeState);

  const { startDate, endDate } = dateRange;
  const params = { startDate, endDate, clientId, isAllTime: true };
  const emptyData: CountryMapData[] = [
    {
      regionName: "Top region",
      value: 0,
    },
    {
      regionName: "Top region",
      value: 0,
    },
    {
      regionName: "Top region",
      value: 0,
    },
    {
      regionName: "Top region",
      value: 0,
    },
    {
      regionName: "Top region",
      value: 0,
    },
  ];

  const series = useMemo(() => {
    const data = categoriesAges.map((metric) => {
      const ageData = totalAgesReport.find((i) => i.metric === metric);
      return ageData ? ageData.value : 0;
    });
    return [{ name: "Age", data }];
  }, [totalAgesReport]);

  const options: ApexOptions = {
    chart: {
      id: "ages-chart",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: categoriesAges,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {},
    },
  };

  const initAges = async () => {
    setLoadingAges(true);
    try {
      const { data } = await SocialApi.SocialDashboard.getAgesAudienceDashboard(
        params
      );
      setTotalAgesReport(data);
    } catch (error) {}
    setLoadingAges(false);
  };

  const getCountries = async () => {
    const { data } =
      await SocialApi.SocialDashboard.getCountriesAudienceDashboard(params);
    setCountriesReport([
      ...data.map((d) => {
        const countryFind = countriesCode.find((c) => c.alpCode2 === d.metric);
        return {
          id: countryFind?.countryCode || -1,
          color:
            SocialUtils.hashStringToColor(
              `${countryFind?.alpCode2}${countryFind?.countryCode}`
            ) || "#DDD",
          regionName: countryFind?.name || "",
          value: d.value || 0,
        };
      }),
      ...emptyData,
    ]);
  };

  const initTopRegion = async () => {
    setLoadingTopRegion(true);
    try {
      await Promise.all([getCountries()]);
    } catch (error) {}
    setLoadingTopRegion(false);
  };

  useEffect(() => {
    if (startDate && endDate && clientId) {
      initAges();
      initTopRegion();
    }
  }, [dateRange]);

  return (
    <div className="social-dashboard-audience">
      <Spin spinning={loadingTopRegion}>
        <div className="rl-card">
          <p className="rl-card-title">
            <span>{t("dashboard.topRegions")}</span>
            {/* <span>{dateRange.totalDays}d</span> */}
          </p>
          <div className="rl-card-top-regions">
            <Row gutter={16}>
              <Col xl={18} className="top-regions-map">
                <ComposableMap height={400}>
                  <ZoomableGroup center={[0, 0]} zoom={1}>
                    <Geographies geography={worldCountriesGeojs}>
                      {({ geographies }) =>
                        geographies.map((geo) => {
                          const country = countriesReport.find(
                            (c) => Number(c.id) === Number(geo.id)
                          );
                          return (
                            <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              fill={country ? country.color : "#DDD"}
                            />
                          );
                        })
                      }
                    </Geographies>
                    {/* <Geographies geography={usCitiesGeojs}>
                    {({ geographies }) =>
                      geographies.map((geo) => (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill="#BBB"
                        />
                      ))
                    }
                  </Geographies> */}
                  </ZoomableGroup>
                </ComposableMap>
              </Col>
              <Col xl={6}>
                <div className="top-regions-legends">
                  {countriesReport
                    .slice(0, 5)
                    .sort((a, b) => b.value - a.value)
                    .map((item, index) => (
                      <div key={index} className="top-regions-legends__item">
                        <span
                          style={{
                            backgroundColor: item.color || CHART_COLORS[index],
                          }}
                        ></span>
                        <span className="top-regions-legends__item__name">
                          {item.regionName}
                        </span>
                        <span>{item.value}</span>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
      <Spin spinning={loadingAges}>
        <div className="rl-card">
          <p className="rl-card-title">
            <span>{t("dashboard.ages")}</span>
            {/* <span>{dateRange.totalDays}d</span> */}
          </p>
          <div className={"ages-chart"}>
            <Chart
              options={options}
              series={series}
              type="bar"
              width={"100%"}
              height={293}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Audience;
