import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { NotificationModel } from "../../../models/notification.model";
import { notificationState } from "../../../states/notification";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton, Spin } from "antd";
import moment from "moment";
import { NotificationApi } from "../../../apis/notification.api";
import {
  NotificationActionType,
  NotificationType,
} from "../../../constants/app.enums";
import { useInfinityScroll } from "../../../hooks/useInfinityScroll";
import "./index.scss";
import useService from "../../../hooks/useService";
import React from "react";
import useAdminSite from "../../../hooks/useAdminSite";
import DateUtils from "../../../utils/date.utils";
import useClient from "../../../hooks/useClient";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ListNotification = () => {
  const { t } = useTranslation();
  const { NotificationService } = useService();
  const isAdminSite = useAdminSite();

  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [notification, setNotification] = useRecoilState(notificationState);

  const { records, totalRecords } = notification;
  const { client } = useClient();


  const readNotification = async (notificationItem: NotificationModel) => {
    setLoading(true);
    try {
      !notificationItem.isRead &&
        (await NotificationService.readNotification(notificationItem.id));
    } catch (error) {
      console.log(error);
    }

    setLoading(false);

    if (isAdminSite && notificationItem.adminUrl) {
      window.location.replace(notificationItem.adminUrl);
    } else if (notificationItem.url) {
      window.location.replace(notificationItem.url);
    }
  };

  const clearAll = async () => {
    if (!records.length) return;
    setLoading(true);
    await NotificationService.clearAllNotification();
    setLoading(false);
  };

  const readAll = async () => {
    if (!records.length) return;
    setLoading(true);
    await NotificationService.readAllNotification();
    setLoading(false);
  };

  const loadMoreNotification = async () => {
    if (Math.ceil(totalRecords / 10) <= pageIndex) return;
    const newPageIndex = pageIndex + 1;
    const { data } = await NotificationApi.getNotifications({
      pageIndex: newPageIndex,
    });
    setNotification({
      ...notification,
      records: [...records, ...data.records],
    });
    setPageIndex(newPageIndex);
  };

  const getNotificationType = (type: NotificationType) => {
    switch (type) {
      case NotificationType.Info:
        return "info";
      case NotificationType.Warning:
        return "warning";
      case NotificationType.Error:
        return "error";
      default:
        return "";
    }
  };

  const getClassnameDescription = (
    actionType: NotificationActionType
  ): string => {
    switch (actionType) {
      case NotificationActionType.PublishContentFailure:
        return "content-error";
      case NotificationActionType.PublishContentSuccess:
        return "content-success";
      case NotificationActionType.ScheduleContent:
        return "content-schedule";
      default:
        return "";
    }
  };

  const renderDescription = (body: string) => {
    return body?.split(/\n/).map((line: any, index) => {
      return (
        <React.Fragment key={index}>
          <span dangerouslySetInnerHTML={{ __html: line }}></span>
          <br />
        </React.Fragment>
      );
    });
  };

  const renderTitle = (notificationItem: NotificationModel) => {
    const { title, actionType, actionDate } = notificationItem;
    if (actionType === NotificationActionType.ScheduleContent)
      return `Content is scheduled on ${DateUtils.getDateWithTimezone(actionDate, client?.timeZoneInfo?.id)
        .format("ddd, MMM DD, h:mma")}`; 
    return title;
  };

  const NotificationItem = (notificationItem: NotificationModel) => {
    const { isRead, title, type, body, actionType } = notificationItem;
    const classNameDescription = getClassnameDescription(actionType);
    return (
      <div
        className={`notification-item ${!isRead ? "unread" : ""
          } ${getNotificationType(type)}`}
        onClick={() => readNotification(notificationItem)}
      >
        <div className="container">
          <div className="title">{renderTitle(notificationItem)}</div>
          {body && (
            <div className={`description ${classNameDescription}`}>
              {renderDescription(body)}
            </div>
          )}
          <div className="time">
            {moment.utc(notificationItem.created).fromNow()}
          </div>
        </div>
        <div className="btn-unread"></div>
      </div>
    );
  };

  const listRef: any = useInfinityScroll(loadMoreNotification);

  return (
    <div className="notification">
      <div className="notification-title">
        <span className="title">{t("notification.lastUpdates")}</span>
        <div className={`action ${!records.length ? "no-data" : ""}`}>
          <div onClick={clearAll}>{t("notification.clearAll")}</div>
          <div onClick={readAll}>{t("notification.readAll")}</div>
        </div>
      </div>
      <Spin indicator={antIcon} spinning={loading}>
        <div className="notification-list" ref={listRef}>
          {records.length ? (
            <>
              {records.map((notificationItem: NotificationModel) => {
                return (
                  <NotificationItem
                    {...notificationItem}
                    key={notificationItem.id}
                  />
                );
              })}
              {totalRecords > records.length && (
                <div className="skeleton">
                  <Skeleton.Button
                    active
                    size={"default"}
                    block
                    className="title"
                  />
                  <Skeleton.Button
                    active
                    size={"default"}
                    block
                    className="title"
                  />
                  <Skeleton.Button active size={"small"} className="time" />
                </div>
              )}
            </>
          ) : (
            <div className="no-data">{t("notification.noData")}</div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default ListNotification;
