import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { useEffect } from "react";
import { Modal, ModalFuncProps } from "antd";

function useUnsaveChange(
  isEditting: boolean,
  manualTrigger?: boolean,
  modalProps?: ModalFuncProps
) {
  let blocker = useBlocker(isEditting);

  const triggerUnsavedChanges = (callback?: () => void) => {
    Modal.confirm({
      title: modalProps?.title || "Unsaved changes",
      content: (
        <span>
          {modalProps?.content ||
            "Changes you made may not be saved. Do you want to leave?"}
        </span>
      ),
      okText: "Leave anyway",
      cancelText: "Stay on this page",
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => {
        blocker.proceed?.();
        callback && callback();
      },
    });
  };

  useEffect(() => {
    if (!manualTrigger)
      if (blocker.state === "blocked" && isEditting) {
        triggerUnsavedChanges();
      }
  }, [blocker, isEditting]);

  return { triggerUnsavedChanges };
}

export default useUnsaveChange;
