import { ApexOptions } from "apexcharts";
import { memo, useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import { glChartSelector } from "../../../../../states/dashboard";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";
import { DATE_TIME_FORMAT } from "../../../../../constants/app-constants";
import { GLChartModel, GLChartType } from "../../google-listings";
import "./index.scss";

interface Props {
  chartId: string;
  chartData: GLChartModel[];
}

function GLProfileChart(props: Props) {
  const { chartId, chartData } = props;
  const glChartData = useRecoilValue(glChartSelector);
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([]);

  useEffect(() => {
    getSeriesData();
  }, [chartData, glChartData]);

  const valueFormater = useCallback(
    (value: number) => {
      return NUMBER_UTIL.convertNumericToFormattedString(value);
    },
    [chartData]
  );

  function getSeriesData() {
    let data: { name: GLChartType; data: number[] }[] = [];
    if (glChartData) {
      data = chartData.map((chart) => {
        switch (chart.key) {
          case GLChartType.Maps:
            return {
              name: chart.key,
              data: glChartData.map((x) => x.impressionsMaps),
            };
          case GLChartType.Search:
            return {
              name: chart.key,
              data: glChartData.map((x) => x.impressionsSearch),
            };
          default:
            return {
              name: GLChartType.Maps,
              data: [],
            };
        }
      });
    }
    setSeries(data);
  }

  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: glChartData.map((x) => x.date),
      type: "datetime",
      labels: {
        format: DATE_TIME_FORMAT.chartDateFormat,
        datetimeUTC: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return valueFormater(value);
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return valueFormater(val);
        },
      },
      marker: {
        show: true,
        fillColors: chartData.map((chart) => chart.color),
      },
    },
    markers: {
      colors: chartData.map((chart) => chart.color),
    },
    stroke: {
      width: 2,
      curve: "smooth",
      colors: chartData.map((chart) => chart.color),
    },
    fill: {
      colors: ["#ffffff"],
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {},
    },
    legend: {
      show: false,
    },
  };

  return (
    <div style={{ height: 202 }} className="gl-profile-chart">
      <Chart
        options={options}
        series={series}
        type="area"
        width={"100%"}
        height={202}
      />
    </div>
  );
}

export default memo(GLProfileChart);
