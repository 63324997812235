import { atom, DefaultValue, selector } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { AdminSummary } from "../models/common.model";
import { RoleModel } from "../models/role.model";
import { ClientModel } from "../models/user";
import { UserGroupModel } from "../models/user-group.model";

interface AdminState {
  summary: AdminSummary;
  roles: RoleModel[];
  groups: UserGroupModel[];
  client?: ClientModel;
}

const inintSummary: AdminSummary = {
  clientSiteCount: 0,
  userCount: 0,
  userGroupCount: 0,
  lastClientSiteCreatedDate: "",
  lastUserCreatedDate: "",
  lastUserGroupCreatedDate: "",
};

const adminState = atom<AdminState>({
  key: APP_STATE_KEYS.ADMIN_STATE,
  default: {
    summary: inintSummary,
    roles: [],
    groups: [],
  },
});

const adminSummarySelector = selector<AdminSummary>({
  key: "adminSummarySelector",
  get: ({ get }) => {
    const { summary } = get(adminState);
    return summary;
  },
  set: ({ set, get }, newValue) => {
    const state = get(adminState);

    set(adminState, {
      ...state,
      summary: newValue instanceof DefaultValue ? inintSummary : newValue,
    });
  },
});

const adminRolesSelector = selector<RoleModel[]>({
  key: "adminRolesSelector",
  get: ({ get }) => {
    const { roles } = get(adminState);
    return roles;
  },
  set: ({ set, get }, newValue) => {
    const state = get(adminState);

    set(adminState, {
      ...state,
      roles: newValue instanceof DefaultValue ? [] : newValue,
    });
  },
});

const adminUseGroupsSelector = selector<UserGroupModel[]>({
  key: "adminUseGroupsSelector",
  get: ({ get }) => {
    const { groups } = get(adminState);
    return groups;
  },
  set: ({ set, get }, newValue) => {
    const state = get(adminState);

    set(adminState, {
      ...state,
      groups: newValue instanceof DefaultValue ? [] : newValue,
    });
  },
});

const adminClientSiteSelector = selector<ClientModel | undefined>({
  key: "adminClientSiteSelector",
  get: ({ get }) => {
    const { client } = get(adminState);
    return client;
  },
  set: ({ set, get }, newValue) => {
    const state = get(adminState);

    set(adminState, {
      ...state,
      client: newValue instanceof DefaultValue ? undefined : newValue,
    });
  },
});

export {
  adminState,
  adminSummarySelector,
  adminRolesSelector,
  adminUseGroupsSelector,
  adminClientSiteSelector,
};
