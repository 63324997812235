import { LoadingOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";
import "./index.scss";

interface Props {
  size?: number;
  children: JSX.Element;
  loading?: boolean;
  style?: CSSProperties;
}

function SpinDiv(props: Props) {
  const { size, children, loading, style } = props;

  return loading ? (
    <div className="spin-div-wrapper" style={style}>
      <div className="spin-div-wrapper__loading-div">
        <LoadingOutlined
          style={{
            fontSize: size || 52,
            color: "rgb(1, 133, 222)",
            fontWeight: "bolder",
          }}
          spin
        />
      </div>
    </div>
  ) : (
    children
  );
}

export default SpinDiv;
