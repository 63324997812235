import { Button, Modal, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthServiceApi } from "../../../../../apis/auth.api";
import { ServiceType } from "../../../../../constants/app.enums";
import { UserServiceModel } from "../../../../../models/service.model";
import { clientSitesState } from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import AddUserServiceModal from "../add-user-modal";
import AvatarDefault from "../../../../../assets/images/avatar-default.svg";
import useGetClients from "../../../../../hooks/useGetClients";
import moment from "moment";
import "./index.scss";
import AppAvatar from "../../../../common/app-avatar/app-avatar";

const SocialServicePanel = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { getClients } = useGetClients();
  const [loading, setLoading] = useState(false);
  const [listUserService, setListUserService] = useState<UserServiceModel[]>(
    []
  );
  const [listAddableUser, setListAddableUser] = useState<UserServiceModel[]>(
    []
  );
  const { clientSelected } = useRecoilValue(clientSitesState);
  const [state, setState] = useRecoilState(clientSitesState);

  const handleCancel = () => {
    setOpen(false);
  };

  const addUserService = async (userIds: number[]) => {
    if (!clientSelected?.id) return;
    await AuthServiceApi.addUserService({
      serviceType: ServiceType.Social,
      clientId: clientSelected?.id,
      userIds,
    });
    const [newUser, newAddableUser] = listAddableUser.reduce(
      ([user, addableUser]: UserServiceModel[][], currentUser) => {
        return userIds.includes(currentUser.id)
          ? [[...user, currentUser], addableUser]
          : [user, [...addableUser, currentUser]];
      },
      [[], []]
    );
    setListAddableUser(newAddableUser);
    setListUserService([...listUserService, ...newUser]);
    showNotification("success", t("clientSites.addUserServiceSuccess"));
    handleCancel();
  };

  const getListUserService = async () => {
    if (!clientSelected?.id) return;
    setLoading(true);
    try {
      const { data } = await AuthServiceApi.getUserService({
        serviceType: ServiceType.Social,
        clientId: clientSelected?.id,
      });
      setListUserService(data);
    } catch (error) {}
    setLoading(false);
  };

  const onRemoveUser = async (user: UserServiceModel) => {
    Modal.confirm({
      title: t("clientSites.removeUserServiceTitle", { name: user.fullName }),
      content: <span>{t("clientSites.removeUserServiceContent")}</span>,
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: async () => {
        if (!clientSelected?.id) return;
        setLoading(true);
        try {
          await AuthServiceApi.deleteUserService({
            serviceType: ServiceType.Social,
            clientId: clientSelected?.id,
            userId: user.id,
          });
          const newListUser = listUserService.filter((u) => u.id !== user.id);
          setListUserService(newListUser);
          setListAddableUser([...listAddableUser, user]);
        } catch (error) {}
        setLoading(false);
        showNotification(
          "delete",
          t("clientSites.removeUserServiceSuccess", { name: user.fullName })
        );
      },
    });
  };

  const disableSocialService = () => {
    Modal.confirm({
      title: t(`clientSites.disableSocialTitle`),
      content: <span>{t(`clientSites.disableSocialContent`)}</span>,
      okText: t("common.disable"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: async () => {
        if (!clientSelected?.id) return;
        await AuthServiceApi.toggleService({
          isEnable: false,
          serviceType: ServiceType.Social,
          clientId: clientSelected.id,
        });
        setState({
          ...state,
          clientSelected: {
            ...clientSelected,
            isEnableSocial: false,
          },
        });
        getClients();
        showNotification(
          "delete",
          t(`clientSites.disableSocialSuccess`, { id: clientSelected.id })
        );
      },
    });
  };

  const getListAddableUser = async () => {
    if (!clientSelected?.id) return;
    const { data } = await AuthServiceApi.getAddableUserService({
      serviceType: ServiceType.Social,
      clientId: clientSelected?.id,
      pageSize: 9999,
    });
    setListAddableUser(data.records);
  };

  useEffect(() => {
    getListUserService();
    getListAddableUser();
  }, [clientSelected]);

  return (
    <Spin spinning={loading}>
      <div className="social-panel">
        <p>
          {t(`clientSites.serviceEnabledOn`)}:{" "}
          {clientSelected?.socialEnabledDate &&
            moment
              .utc(clientSelected?.socialEnabledDate)
              .local()
              .format("DD MMM, YYYY hh:mm a")}
        </p>
        <p>{t(`clientSites.entitledUsers`)}:</p>
        <div className="list-user">
          {listUserService.map((user) => {
            return (
              <Tooltip title={user.fullName} key={user.id}>
                <div className="user-image" onClick={() => onRemoveUser(user)}>
                  <AppAvatar
                    src={user.profilePictureUrl}
                    shape="square"
                    size={36}
                  />
                  <div className="remove-icon">x</div>
                </div>
              </Tooltip>
            );
          })}
          <Tooltip title="Add users">
            <Button
              className="add-user user-image"
              onClick={() => setOpen(true)}
            >
              <SVGIcons.PlusIcon />
            </Button>
          </Tooltip>
        </div>

        <div className="disable-social">
          <Button type="text" danger onClick={disableSocialService}>
            {t("common.disable")}
          </Button>
        </div>
      </div>

      <AddUserServiceModal
        open={open}
        close={handleCancel}
        addUserService={addUserService}
        listAddableUser={listAddableUser}
      />
    </Spin>
  );
};

export default SocialServicePanel;
