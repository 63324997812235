import { Col, Collapse, Form, Input, Modal, Row } from "antd";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../../../../constants/app-constants";
import { GmbPostType } from "../../../../../../../../constants/app.enums";
import {
  contentValueState,
  currentContentSelector,
  SocialContentState,
} from "../../../../../../../../states/social";
import showNotification from "../../../../../../../common/notification";
import AppDatePicker from "../../../../../../../controls/app-date-picker";
import SVGIcons from "../../../../../../../icons/svgs";
import useUpdateContent from "../../../../hook/useUpdateContent";
import "./index.scss";

interface Props {
  isReviewing: boolean | undefined;
  currentContent?: SocialContentState;
}

const OfferTab = (props: Props) => {
  const { onValuesPlatformChange } = useUpdateContent();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const startDate = Form.useWatch("startDate", form);
  const endDate = Form.useWatch("endDate", form);

  const [open, setOpen] = useState(false);
  const currentContent = useRecoilValue(currentContentSelector);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);

  const [offerTitle, setOfferTitle] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [offerDetail, setOfferDetail] = useState("");
  const [termsConditions, setTermsConditions] = useState("");

  const { eventPostModalStatus } = contentValue;

  const {
    gmbPostType,
    gmbTitle,
    post,
    gmbStartDate,
    gmbEndDate,
    gmbCouponCode,
    gmbRedeemOnlineUrl,
    gmbTermsConditions,
  } = props.currentContent || currentContent || {};
  const onOK = async () => {
    await form.validateFields([
      "gmbTitle",
      "gmbCouponCode",
      "gmbRedeemOnlineUrl",
    ]);
    const values = form.getFieldsValue();
    onValuesPlatformChange({
      gmbCouponCode: values.gmbCouponCode,
      gmbRedeemOnlineUrl: values.gmbRedeemOnlineUrl,
      gmbTermsConditions: values.gmbTermsConditions,
      gmbTitle: values.gmbTitle,
      gmbStartDate: !values.startDate
        ? moment().format()
        : moment(values.startDate).format(),
      gmbEndDate: !values.endDate
        ? moment().add(7, "day").format()
        : moment(values.endDate).format(),
      post: values.offerDetail,
    });

    if (eventPostModalStatus === "edit") {
      showNotification("success", t("common.changeSaveSuccess"));
    }
    cancel();
  };
  const cancel = () => {
    form.resetFields();
    setOfferTitle("");
    setVoucherCode("");
    setOfferDetail("");
    setTermsConditions("");
    setOpen(false);
  };

  const onChangeStartTime = (value: any) => {
    form.setFields([{ name: "startDate", value: moment(value) }]);
  };

  const onChangeEndTime = (value: any) => {
    form.setFields([{ name: "endDate", value: moment(value) }]);
  };

  const onOpenModal = () => {
    setContentValue({ ...contentValue, eventPostModalStatus: "add" });
  };

  const renderPost = (content?: string) => {
    return content?.split(/\n/).map((line: any, index) => {
      let str = line;
      return (
        <React.Fragment key={index}>
          <span dangerouslySetInnerHTML={{ __html: str }}></span>
          <br />
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    if (!open)
      setContentValue({ ...contentValue, eventPostModalStatus: undefined });
  }, [open]);
  useEffect(() => {
    if (eventPostModalStatus) setOpen(true);
    if (eventPostModalStatus === "edit") {
      form.setFields([
        { name: "gmbTitle", value: gmbTitle },
        { name: "offerDetail", value: post },
        { name: "startDate", value: moment(gmbStartDate) },
        { name: "endDate", value: moment(gmbEndDate) },
        { name: "gmbCouponCode", value: gmbCouponCode },
        { name: "gmbRedeemOnlineUrl", value: gmbRedeemOnlineUrl },
        { name: "gmbTermsConditions", value: gmbTermsConditions },
      ]);
      setOfferTitle(gmbTitle || "");
      setVoucherCode(gmbCouponCode || "");
      setOfferDetail(post || "");
      setTermsConditions(gmbTermsConditions || "");
    }
    if (eventPostModalStatus === "add") {
      form.setFields([
        { name: "startDate", value: moment() },
        { name: "endDate", value: moment().add(7, "day") },
      ]);
    }
  }, [eventPostModalStatus]);

  return (
    <div className="offer-tab">
      {!gmbTitle && (
        <div className="add-post" onClick={onOpenModal}>
          Click to add post
        </div>
      )}

      {gmbTitle && (
        <div className="offer-content">
          <p className="title">{gmbTitle}</p>
          <p className="date">
            {moment(gmbStartDate).format("MMM DD")} -{" "}
            {moment(gmbEndDate).format("MMM DD")}
          </p>
          <p className="caption">{renderPost(post)}</p>

          <a
            onClick={() => {
              window.open(gmbRedeemOnlineUrl);
            }}
          >
            REDEEM ONLINE
          </a>

          <div className="add-post">
            <div>Show this code at the shop</div>
            <div className="code">{gmbCouponCode}</div>
            <div>
              Valid {moment(gmbStartDate).format("MMM DD, YYYY")} -{" "}
              {moment(gmbEndDate).format("MMM DD, YYYY")}
            </div>
          </div>
          <Collapse
            ghost
            expandIconPosition="end"
            expandIcon={({ isActive }) =>
              isActive ? (
                <SVGIcons.CaretUpFilledIcon />
              ) : (
                <SVGIcons.CaretDownFilledIcon />
              )
            }
          >
            <Collapse.Panel key="1" header="Terms and Conditions">
              {renderPost(gmbTermsConditions)}
            </Collapse.Panel>
          </Collapse>
        </div>
      )}

      <Modal
        open={!!eventPostModalStatus && gmbPostType === GmbPostType.OFFER}
        title={
          eventPostModalStatus === "add"
            ? t("social.content.addOfferPost")
            : t("social.content.editOfferPost")
        }
        okText={
          eventPostModalStatus === "add"
            ? t("social.content.addPost")
            : t("common.saveChanges")
        }
        onOk={onOK}
        className="custom-create-modal add-event-post-modal"
        onCancel={cancel}
      >
        <Form
          name="addOfferPost"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label={
              <div>
                <div>
                  <span>{t("social.content.offerTitle")}</span>
                  <span style={{ color: "red" }}> *</span>
                </div>
                <span>{offerTitle?.length || 0}/58</span>
              </div>
            }
            name="gmbTitle"
            rules={[
              { required: true, message: "Offer Title field is required." },
            ]}
            className="event-title"
          >
            <div>
              <Input
                placeholder={"Example: 20% off in store or online"}
                value={offerTitle}
                onChange={(e) => {
                  if (e.target.value.length > 58) return;
                  setOfferTitle(e.target.value);
                }}
              />
            </div>
          </Form.Item>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t("social.content.startDate")}
                rules={[{ required: true }]}
                name="startDate"
              >
                <AppDatePicker
                  onChange={onChangeStartTime}
                  defaulValue={moment(startDate)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t("social.content.endDate")}
                rules={[{ required: true }]}
                name="endDate"
              >
                <AppDatePicker
                  onChange={onChangeEndTime}
                  defaulValue={moment(endDate)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={
              <div>
                <div>
                  <span>{t("social.content.voucherCode")}</span>
                  <span style={{ color: "red" }}> *</span>
                </div>
                <span>{voucherCode?.length || 0}/58</span>
              </div>
            }
            name="gmbCouponCode"
            rules={[
              { required: true, message: "Voucher Code field is required." },
            ]}
            className="event-title"
          >
            <div>
              <Input
                placeholder={"Example: BOGO-JET-CODE"}
                value={voucherCode}
                onChange={(e) => {
                  if (e.target.value.length > 58) return;
                  setVoucherCode(e.target.value);
                }}
              />
            </div>
          </Form.Item>

          <Form.Item
            label={t("social.content.linkToRedeemOffer")}
            name="gmbRedeemOnlineUrl"
            rules={[
              {
                required: true,
                message: "Link To Redeem Offer field is required.",
              },
              {
                message: "Invalid URL format.",
                type: "url",
              },
            ]}
          >
            <Input placeholder={"Enter URL"} />
          </Form.Item>

          <Form.Item
            label={
              <div>
                <div>
                  <span>{t("social.content.offerDetail")}</span>
                </div>
                <span>{offerDetail?.length || 0}/1500</span>
              </div>
            }
            name="offerDetail"
            className="event-title"
          >
            <div>
              <Input.TextArea
                placeholder={"Enter your Offer Details here..."}
                value={offerDetail}
                onChange={(e) => {
                  if (e.target.value.length > 1500) return;
                  setOfferDetail(e.target.value);
                }}
                maxLength={1500}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </div>
          </Form.Item>

          <Form.Item
            label={
              <div>
                <div>
                  <span>{t("social.content.termCondition")}</span>
                </div>
                <span>{termsConditions?.length || 0}/5000</span>
              </div>
            }
            name="gmbTermsConditions"
            className="event-title"
          >
            <div>
              <Input.TextArea
                placeholder={"Enter your Terms & Conditions here…"}
                value={termsConditions}
                onChange={(e) => {
                  if (e.target.value.length > 5000) return;
                  setTermsConditions(e.target.value);
                }}
                maxLength={5000}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OfferTab;
