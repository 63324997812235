import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportApi } from "../../../apis/report.api";
import showNotification from "../../../components/common/notification";
import "./index.scss";

const ReportRequestPage = () => {
  const { id } = useParams();
  const [reportContent, setReportContent] = useState("");
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    setLoading(true);
    try {
      if (!id) {
        return;
      }
      const { data } = await ReportApi.getViewEmailContent(id);
      setReportContent(data);
    } catch (error: any) {
      showNotification("error", error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [id]);

  return (
    <>
      {loading ? (
        <div>
          <Spin className="spin-loading" />
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: reportContent,
          }}
          className="view-report-wrapper"
        ></div>
      )}
    </>
  );
};

export default ReportRequestPage;
