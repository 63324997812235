import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import useClientPlan from "../../../../hooks/useClientPlan";
import SVGIcons from "../../../icons/svgs";
import "../limited-modal/index.scss";

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function LimitedStorageModal(props: Props) {
    const { isOpen, setIsOpen } = props
    const { t } = useTranslation();
    const {  getUpgradePremiumUrl, isGetPremiumUrlLoading, isFreeTrial } = useClientPlan()
    const navigate = useNavigate();

    const upgradeToPremium = async () => {
        await getUpgradePremiumUrl()
        setIsOpen(false)
    }

    const getModalDesc = () => {
        if(isFreeTrial) {
            return t("usageAndBilling.freeTrialStorageLimitedDesc")
        }
        return t("usageAndBilling.freePlanPremiumStorageLimitedDesc")
    }

    return (
        <>
            <Modal
                centered
                className="upgrade-modal"
                open={isOpen}
                closable={false}
                onCancel={() => setIsOpen(false)}
                footer={null}
                zIndex={1009}
            >
                <div className="limited-modal">
                    <div className="limited-modal__header" >
                        <SVGIcons.LimitedIcon />
                        <div
                            onClick={() => setIsOpen(false)}
                            className="limited-modal__closeIcon"
                        >
                            <SVGIcons.UpgradeModalCloseIcon />
                        </div>
                    </div>

                    <div className="limited-modal__content" >
                        <div className="title" >
                            {t("usageAndBilling.storageIsFull")}
                        </div>
                        <div className="desc">
                            <div dangerouslySetInnerHTML={{
                                __html: getModalDesc()
                            }}
                            />
                        </div>
                    </div>

                    <div className="limited-modal__footer" >
                      

                        {
                            isFreeTrial ?
                                <Button loading={isGetPremiumUrlLoading} onClick={upgradeToPremium} className="understandBtn" type="primary">
                                    {t("upgradeModal.upgradeToPremium")}
                                </Button>
                                :
                                <Button onClick={() => setIsOpen(false)} className="understandBtn" type="primary">
                                    {t("usageAndBilling.understandBtn")}
                                </Button>
                        }

                        <Button onClick={() => {
                            setIsOpen(false)
                            navigate(ROUTE_PATHS.UsageAndBilling)
                        }} className="checkUsageBtn" type="text">
                            {t("usageAndBilling.manageStorage")}
                        </Button>
                    </div>

                </div>
            </Modal>
        </>
    );
}

export default LimitedStorageModal;
