import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  dasboardDateRangeState,
  dashboardGLState,
} from "../../../../states/dashboard";
import "./index.scss";
import GLProfileChart from "../charts/gl-profile";
import GLUserActionChart from "../charts/gl-user-action";
import { renderVolatility } from "../site-traffic";
import SpinDiv from "../../../common/spin-div";
import { NUMBER_UTIL } from "../../../../utils/number.utils";

export enum GLChartType {
  Maps = "Google Maps",
  Search = "Google Search",
  Requests = "Request direction",
  CallClicks = "Call you",
  WebsiteClicks = "Visit your website",
}

export interface GLChartModel {
  key: GLChartType;
  label: string;
  color: string;
  value: string | number | undefined;
  volatility: number | undefined;
}

function GoogleListings() {
  const { t } = useTranslation();
  const dateRange = useRecoilValue(dasboardDateRangeState);
  const { data, loading } = useRecoilValue(dashboardGLState);

  const chartItems = [
    {
      key: GLChartType.Maps,
      label: "Google Maps",
      color: "#0185de",
      value: undefined,
      volatility: undefined,
    },
    {
      key: GLChartType.Search,
      label: "Google Search",
      color: "#9bc13c",
      value: undefined,
      volatility: undefined,
    },
    {
      key: GLChartType.Requests,
      label: "Request Direction",
      color: "#f59f00",
      value: undefined,
      volatility: undefined,
    },
    {
      key: GLChartType.CallClicks,
      label: "Call Click",
      color: "#9bc13c",
      value: undefined,
      volatility: undefined,
    },
    {
      key: GLChartType.WebsiteClicks,
      label: "Website Click",
      color: "#0185de",
      value: undefined,
      volatility: undefined,
    },
  ];
  return (
    <div className="home-google-listing">
      <Row gutter={16}>
        <Col xs={24} sm={12} className="mobile-mr-bottom-20">
          <div className="rl-card">
            <p className="rl-card-title">
              <span>{t("dashboard.profileImpressions")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>
            <SpinDiv loading={loading} style={{ height: 284 }}>
              <>
                <div className="home-google-listing-profile__details">
                  <div>
                    <div>
                      <span style={{ backgroundColor: "#0185de" }}></span>
                      <span>{t("dashboard.googleMaps")}</span>
                    </div>
                    <p className="total">
                      {NUMBER_UTIL.convertNumericToFormattedString(
                        data?.total?.impressionsMaps || 0
                      )}
                    </p>
                    <p className="volatility">
                      {data?.rows?.length
                        ? renderVolatility(data?.volatility?.impressionsMaps)
                        : ""}
                    </p>
                  </div>

                  <div>
                    <div>
                      <span style={{ backgroundColor: "#9bc13c" }}></span>
                      <span>{t("dashboard.googleSearch")}</span>
                    </div>
                    <p className="total">
                      {NUMBER_UTIL.convertNumericToFormattedString(
                        data?.total?.impressionsSearch || 0
                      )}
                    </p>
                    <p className="volatility">
                      {data?.rows?.length
                        ? renderVolatility(data?.volatility?.impressionsSearch)
                        : ""}
                    </p>
                  </div>
                </div>
                <GLProfileChart
                  chartId="siteTrafficChart"
                  chartData={[chartItems[0], chartItems[1]]}
                />
              </>
            </SpinDiv>
          </div>
        </Col>

        <Col xs={24} sm={12}>
          <div className="rl-card" style={{ height: "100%" }}>
            <p className="rl-card-title">
              <span>{t("dashboard.userActions")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <SpinDiv loading={loading} style={{ height: 284 }}>
              <div className="home-google-listing-user__details">
                <Row gutter={10}>
                  <Col xs={24} sm={18}>
                    <GLUserActionChart
                      chartId="siteTrafficChart"
                      chartData={[chartItems[2], chartItems[3], chartItems[4]]}
                    />
                  </Col>
                  <Col xs={24} sm={6} className="gl-user-action-detail">
                    <div>
                      <div>
                        <span style={{ backgroundColor: "#0185de" }}></span>
                        <span>{t("dashboard.visitYourWebsite")}</span>
                      </div>
                      <p>
                        {NUMBER_UTIL.convertNumericToFormattedString(
                          data?.total?.websiteClicks || 0
                        )}
                      </p>
                    </div>

                    <div>
                      <div>
                        <span style={{ backgroundColor: "#f59f00" }}></span>
                        <span>{t("dashboard.requestDirection")}</span>
                      </div>
                      <p>
                        {NUMBER_UTIL.convertNumericToFormattedString(
                          data?.total?.directionRequests || 0
                        )}
                      </p>
                    </div>

                    <div>
                      <div>
                        <span style={{ backgroundColor: "#9bc13c" }}></span>
                        <span>{t("dashboard.callYou")}</span>
                      </div>
                      <p>
                        {NUMBER_UTIL.convertNumericToFormattedString(
                          data?.total?.callClicks || 0
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </SpinDiv>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default GoogleListings;
