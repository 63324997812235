import { Form, Input, Modal, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import useFetchRoles from "../../../../hooks/useFetchRoles";
import { CreateUserGroupModel } from "../../../../models/sign-in.model";
import showNotification from "../../../common/notification";
import "./index.scss";

interface CreateGroupFormProps {
  open: boolean;
  close: () => void;
  onSubmit?: (model: CreateUserGroupModel) => Promise<void>;
}

function CreateGroupForm(props: CreateGroupFormProps) {
  const { open, close, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const roles = useFetchRoles();
  const [loading, setLoading] = useState(false);

  async function submit() {
    if (onSubmit) {
      setLoading(true);
      try {
        const values = form.getFieldsValue();
        const model: CreateUserGroupModel = {
          name: values.name,
          description: values.description,
          roleId: parseInt(values.role),
        };
        await onSubmit(model);

        showNotification(
          "success",
          t("userGroups.addNewUserGroupSuccessTitle", { name: values.name }),
          t("userGroups.addNewUserGroupSuccessDescription")
        );

        cancel();
      } catch (error) {}
      setLoading(false);
    }
  }

  function okClick() {
    form.submit();
  }

  function cancel() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      open={open}
      title={t("userGroups.createModalTitle")}
      okText={t("userGroups.createSubmitButton")}
      onOk={okClick}
      className="custom-create-modal"
      onCancel={cancel}
      confirmLoading={loading}
    >
      <Form
        name="createGroupForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
      >
        <Form.Item
          label={t("userGroups.userGroupName")}
          rules={[{ required: true }]}
          name="name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("userGroups.description")}
          name="description"
          rules={[{ required: true }]}
        >
          <Input.TextArea maxLength={200} rows={4} showCount></Input.TextArea>
        </Form.Item>

        <Form.Item
          label={t("userGroups.role")}
          name="role"
          rules={[{ required: true }]}
          style={{ marginBottom: 0 }}
        >
          <Select>
            {roles.map((x) => (
              <Select.Option key={x.roleId}>{x.roleName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateGroupForm;
