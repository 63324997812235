import { useSetRecoilState } from "recoil";
import { AuthApi } from "../apis/auth.api";
import { adminSummarySelector } from "../states/admin";


function useGetAdminStats() {
    const setState = useSetRecoilState(adminSummarySelector);

    const getAdminStats = async () => {
        const { data } = await AuthApi.getAdminStats();
        setState(data);
    };

    return {
        getAdminStats,
    };
}

export default useGetAdminStats;
