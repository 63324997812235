import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { EMPTY_DATA_COLOR } from "../../../../../constants/app-constants";
import { ChartStackedColumnDataModel } from "../../../../../models/chart.model";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";

interface Props {
  chartId: string;
  height: number;
  data: ChartStackedColumnDataModel[];
  colors: string[];
  categories: string[];
}

function StackedColumn(props: Props) {
  const { chartId, data, height, colors, categories } = props;

  const isEmpty = !props.data || !props.data.length;

  const options: ApexOptions = {
    chart: {
      id: chartId,
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "48px",
      },
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      offsetY: -3,

      axisTicks: {
        show: false,
      },
      labels: {
        show: data.length > 0,
        style: {
          colors: "#4b546e",
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        formatter: function (value) {
          return value + "%";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      labels: {
        colors: ["#4b546e"],
      },
      position: "bottom",
      fontSize: "10px",
      markers: {
        width: 6,
        height: 6,
        strokeWidth: 0,
        radius: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      strokeDashArray: 4,
      borderColor: "#e3e4e6",
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
    tooltip: {
      y: {
        formatter: function (
          value: number,
          { series, dataPointIndex }: { series: number[][]; dataPointIndex: number }
        ) {
          const sum = series.reduce((acc, currSeries) => acc + currSeries[dataPointIndex], 0);
          const percent = (value / sum) * 100;
          if (percent % 1 === 0) {
            return NUMBER_UTIL.converNumberToPercentage(percent);
          } else {
            return NUMBER_UTIL.converNumberToPercentage(percent, 2);
          }
        },
      },
    },
  };

  return (
    <div
      className={`${isEmpty ? "chart-disabled " : ""}`}
      style={{ marginBottom: -13, marginLeft: -18 }}
    >
      <Chart options={options} series={data} type="bar" height={height} />
    </div>
  );
}

export default StackedColumn;
