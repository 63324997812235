import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SignInForm from "../../components/views/sign-in";
import { ROUTE_PATHS } from "../../constants/router.constants";
import useAdminSite from "../../hooks/useAdminSite";

function SignInPage() {
  const { t } = useTranslation();
  const isAdmin = useAdminSite();

  return (
    <>
      <SignInForm isAdmin={isAdmin} />

      {
        !isAdmin && <Link
          className="auth__backLink"
          to={ROUTE_PATHS.SignUp}
          dangerouslySetInnerHTML={{ __html: t("login.doNotHaveAccount") }}
        ></Link>
      }

    </>
  );
}

export default SignInPage;
