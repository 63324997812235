import { Button, Divider, Form, Input, Select, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { GaApi } from "../../../../../apis/ga.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import { IntegrationStatus } from "../../../../../constants/app.enums";
import useGetClients from "../../../../../hooks/useGetClients";
import useGoogle from "../../../../../hooks/useGoogle";
import { GaConnectionModel, GaPropertyConnectionModel } from "../../../../../models/ga.model";
import {
  connectionState,
  integrationState,
} from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import IntegrationAlert from "../alert";
import GroupBtnIntegration from "../group-btn-integration";
import "./index.scss";
import useFetchConnection from "../../../../../hooks/useFetchConnection";
import useAdminSite from "../../../../../hooks/useAdminSite";

interface Props {
  isAddConnection?: boolean;
  isOpenAddConnection?: boolean;
}

function GoogleAnalytics(props: Props) {
  const { isAddConnection, isOpenAddConnection } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { fetchConnection } = useFetchConnection();
  const [status, setStatus] = useState(IntegrationStatus.None);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [enableTestBtn, setEnableTestBtn] = useState(false);
  const [accountSelected, setAccountSelected] = useState<number | undefined>(
    undefined
  );
  const [searchProperty, setSearchProperty] = useState("");
  const [properties, setProperties] = useState<GaPropertyConnectionModel[]>([])
  const [disabledProperties, setDisabledProperties] = useState<GaPropertyConnectionModel[]>([])
  const [disabledGaConnection, setDisabledGaConnection] = useState<GaConnectionModel[]>([])
  const [connection, setConnection] = useRecoilState(connectionState);
  const { currentConnection, listGAConnection } = connection;
  const [isDisabled, setIsDisabled] = useState(false);
  const [integrationValues, setIntegrationValues] =
    useRecoilState(integrationState);

  const {refreshClientList} = useGetClients();

  const { loginGoogle, reAuthenticateGoogle } = useGoogle({
    idConnection: accountSelected,
  });
  const isAdmin = useAdminSite();

  const getProperties = async(id: number | undefined) => {
    setPropertiesLoading(true)
    try {
      if(id) {
        const { data } = await GaApi.getProperties(id);
        setProperties(data)
      }
    } finally {
      setPropertiesLoading(false)
    }
  }

  async function testConnection() {
    setTestLoading(true);
    try {
      const values = form.getFieldsValue();
      await GaApi.testIntegration({
        propertyId: values.propertyId,
      });
      setStatus(IntegrationStatus.Success);
    } catch {
      setStatus(IntegrationStatus.Error);
    }
    setTestLoading(false);
  }

  async function removeConnection() {
    setRemoveLoading(true);
    try {
      const integrationId = form.getFieldValue("id");
      if (!integrationId) throw new Error("");

      await GaApi.removeIntegration(integrationId);

      setIntegrationValues({
        ...integrationValues,
        gaIntegrations: [],
      });

      showNotification(
        "delete",
        t("clientSites.connectionRemoved", { name: "Google Analytics" })
      );

      refreshClientList();
    } catch {}
    setRemoveLoading(false);
  }
  

  async function submit(values: any) {
    setSaveLoading(true);
    try {
      if (!integrationValues) throw new Error("");

      if (currentConnection) {
        // add connection
        const values = form.getFieldsValue();
        const GaResponse = await GaApi.addIntegration({
          gaPropertyId: values.propertyId,
          clientId: integrationValues.clientId,
        });
        if (!GaResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          gaIntegrations: [GaResponse.data],
        });

        setConnection({ ...connection, currentConnection: "" });

        showNotification("success", "Google Analytics successfully connected. It’ll take some time to sync data.");
      } else {
        //update connection
        const integrationId = form.getFieldValue("id");
        const values = form.getFieldsValue();
        const GaResponse = await GaApi.updateIntegration({
          id: integrationId,
          gaPropertyId: values.propertyId,
        });
        if (!GaResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          gaIntegrations: [GaResponse.data],
        });

        setConnection({ ...connection, currentConnection: "" });
      }

      refreshClientList();
    } catch {}
    setSaveLoading(false);
  }

  function onChangeAccount(value: number) {
    setAccountSelected(value);
    setEnableTestBtn(false);
    form.resetFields(["propertyId"]);
    getProperties(value)
  }

  function onChangeProperty(value: number) {
    setEnableTestBtn(!!value);
  }

  function onSearchProperty(e: any) {
    setSearchProperty(e.target.value);
  }

  function onBlurSearch() {
    setSearchProperty("");
  }

  async function onReAuthenticate() {
    reAuthenticateGoogle();
  }

  function addConnection() {
    loginGoogle();
  }

  function initGAIntegration() {
    if (currentConnection && isOpenAddConnection) form.resetFields();
    else {
      if (!integrationValues?.gaIntegrations[0]) return;
      form.setFieldsValue(integrationValues?.gaIntegrations[0]);
      const { gaProperty } = integrationValues?.gaIntegrations[0];
      if (!gaProperty) return;
      setAccountSelected(gaProperty.googleConnection.id);
      form.setFieldValue("account", gaProperty.googleConnection.id);
      if (gaProperty.id) {
        form.setFieldValue("propertyId", gaProperty.id);
        setEnableTestBtn(true);
      }
      if(!gaProperty.googleConnection.clientId && !isAdmin) {
        setIsDisabled(true)
        if(gaProperty) {
          setDisabledProperties([{
            displayName: gaProperty.displayName,
            propertyId: gaProperty.propertyId,
            id: gaProperty.id
          }])
          if(gaProperty.googleConnection) {
            setDisabledGaConnection([
              {
                familyName: gaProperty.googleConnection.familyName,
                id: gaProperty.googleConnection.id,
                givenName: gaProperty.googleConnection.givenName
              }
            ])
          }
        }
      }
      else {
        getProperties(gaProperty.googleConnection.id)
      }
    }
    if (
      integrationValues?.gaIntegrations[0]?.gaProperty?.googleConnection
        ?.isInvalid
    )
      setStatus(IntegrationStatus.ConnectionGALostError);
    else if (integrationValues?.gaIntegrations[0]?.gaProperty?.isInvalid)
      setStatus(IntegrationStatus.ConnectionLostError);
    else setStatus(IntegrationStatus.None);
  }


  function displayProperty(property: GaPropertyConnectionModel) {
    const { displayName, propertyId } = property;
    const indexSearch = displayName
      .toLocaleLowerCase()
      .indexOf(searchProperty.toLocaleLowerCase());
    return (
      <div className="display-property">
        <div className="property-name">
          {displayName.substring(0, indexSearch)}
          <span className="highlight">
            {displayName.substring(
              indexSearch,
              indexSearch + searchProperty.length
            )}
          </span>
          {displayName.substring(indexSearch + searchProperty.length)}
        </div>
        <div className="property-id">{propertyId}</div>
      </div>
    );
  }

  async function syncProperties() {
    if (!accountSelected) return;
    setSyncLoading(true);
    try {
      await GaApi.syncProperties(accountSelected);
      await fetchConnection();
    } catch (error) {}
    setSyncLoading(false);
  }

  useEffect(() => {
    initGAIntegration();
  }, [currentConnection, integrationValues]);

  useEffect(() => {
    if (
      status !== IntegrationStatus.None &&
      status !== IntegrationStatus.ConnectionGALostError
    ) {
      setTimeout(function () {
        if (
          integrationValues?.gaIntegrations[0]?.gaProperty?.googleConnection
            ?.isInvalid
        )
          setStatus(IntegrationStatus.ConnectionGALostError);
        else if (integrationValues?.gaIntegrations[0]?.gaProperty?.isInvalid)
          setStatus(IntegrationStatus.ConnectionLostError);
        else setStatus(IntegrationStatus.None);
      }, 5000);
    }
  }, [status]);
  return (
    <div className="integration-form__section">
      <IntegrationAlert status={status} onReAuthenticate={onReAuthenticate} />

      <Form
        name="googleAnalyticsIntegration"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        className={`custom-connection-form ${
          !currentConnection ? "hiden-required-mark" : ""
        }`}
      >
        <Form.Item
          name="account"
          label={t("clientSites.account")}
          rules={[{ required: true }]}
        >
          <Select
            disabled={isDisabled}
            onChange={onChangeAccount}
            value={accountSelected}
            popupClassName="select-account-ga"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Button
                  type="text"
                  className="add-connection"
                  icon={<SVGIcons.LogoGoogleIcon />}
                  onClick={addConnection}
                >
                  {t("clientSites.signInWithGoogle")}
                </Button>
              </>
            )}
          >
            {(isDisabled ? disabledGaConnection : listGAConnection).map((connection) => {
              return (
                <Select.Option value={connection.id} key={connection.id}>
                  {`${connection.givenName} ${connection.familyName}`}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="propertyId"
          label={t("clientSites.property")}
          rules={[{ required: true }]}
        >
          <Select
            disabled={isDisabled}
            loading={propertiesLoading}
            onChange={onChangeProperty}
            popupClassName="select-property-ga"
            dropdownRender={(menu) => (
              <>
                <div className="search-property">
                  <Input
                    value={searchProperty}
                    suffix={<SVGIcons.SearchIcon />}
                    onChange={onSearchProperty}
                    onBlur={onBlurSearch}
                  />
                  <Tooltip
                    title="Click to get the latest properties"
                    placement="topRight"
                  >
                    <Button
                      type="default"
                      className="refresh-btn"
                      onClick={syncProperties}
                    >
                      <SVGIcons.RefreshIcon />
                    </Button>
                  </Tooltip>
                </div>
                <Spin spinning={syncLoading}>{menu}</Spin>
              </>
            )}
          >
            {(isDisabled ? disabledProperties : properties).filter(
                (p) =>
                  p.displayName &&
                  p.displayName
                    .toLocaleLowerCase()
                    .includes(searchProperty.toLocaleLowerCase())
              )
              .map((property) => {
                return (
                  <Select.Option value={property.id} key={property.id}>
                    {displayProperty(property)}
                  </Select.Option>
                );
              })} 
          </Select>
        </Form.Item>

        <GroupBtnIntegration
          removeLoading={removeLoading}
          testLoading={testLoading}
          saveLoading={saveLoading}
          onTestConnection={testConnection}
          connectionName="Google Analytics"
          onRemove={removeConnection}
          disableTestBtn={!enableTestBtn}
          isAddConnection={isAddConnection}
          disableSaveBtn={isDisabled}
        />
      </Form>
    </div>
  );
}

export default GoogleAnalytics;
