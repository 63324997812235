import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import EditContent from "..";
import { ApprovalStatusEnum } from "../../../../../../constants/app.enums";
import useUnsaveChange from "../../../../../../hooks/useUnsaveChange";
import { ContentModel } from "../../../../../../models/content.model";
import { listCommentingState } from "../../../../../../states/social";
import "./index.scss";

interface EditContentModalProps {
  open: boolean;
  close: () => void;
  contentInfo: ContentModel;
  onEditContent: (contentId: number) => Promise<void>;
  onEditApprovalContent?: (
    contentId: number,
    approvalStatus: ApprovalStatusEnum
  ) => void;
}
const EditContentModal = (props: EditContentModalProps) => {
  const { contentInfo, onEditContent, onEditApprovalContent, open, close } =
    props;
  const { t } = useTranslation();
  const [listCommenting, setListCommenting] =
    useRecoilState(listCommentingState);
  const { triggerUnsavedChanges } = useUnsaveChange(
    !!listCommenting.length,
    false,
    {
      title: t("social.leavePageModalTitle"),
      content: t("social.leavePageModalDescription"),
    }
  );

  const onClose = () => {
    setListCommenting([]);
    close();
  };

  const onCancel = () => {
    if (!!listCommenting.length) triggerUnsavedChanges(onClose);
    else onClose();
  };

  return (
    <Modal
      open={open}
      title={t("social.content.editModalTitle")}
      className="custom-create-modal edit-content-modal content-modal"
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
    >
      <EditContent
        contentInfo={contentInfo}
        onEditContent={onEditContent}
        onEditApprovalContent={onEditApprovalContent}
      />
    </Modal>
  );
};

export default EditContentModal;
