import { Col, Row, Spin } from "antd";
import "./index.scss";
import { useRecoilValue } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { socialDashboardDateRangeState } from "../../../../../states/social-dashboard";
import useClient from "../../../../../hooks/useClient";
import { SocialApi } from "../../../../../apis/social.api";
import { SocialContentModel } from "../../../../../models/content.model";
import { SocialType } from "../../../../../constants/app.enums";
import TopPostItem from "./top-post-item";

const TopPosts = () => {
  const { clientId } = useClient();

  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const [loadingTopPosts, setLoadingTopPosts] = useState(false);
  const [listTopPost, setListTopPost] = useState<SocialContentModel[]>([]);

  const initTopPosts = async () => {
    const { startDate, endDate } = dateRange;
    const params = { startDate, endDate, clientId };
    if (startDate && endDate && clientId) {
      setLoadingTopPosts(true);
      try {
        const { data } = await SocialApi.SocialDashboard.getTopPlatformContents(
          params
        );
        setListTopPost(data);
      } catch (error) { }
      setLoadingTopPosts(false);
    }
  };

  useEffect(() => {
    initTopPosts();
  }, [dateRange]);

  const topPostFacebook = useMemo(() => {
    return listTopPost.find((p) => p.platform === SocialType.Facebook);
  }, [listTopPost]);

  const topPostInstagram = useMemo(() => {
    return listTopPost.find((p) => p.platform === SocialType.Instagram);
  }, [listTopPost]);

  const topPostTiktok = useMemo(() => {
    return listTopPost.find((p) => p.platform === SocialType.Tiktok);
  }, [listTopPost]);

  const topPostYoutube = useMemo(() => {
    return listTopPost.find((p) => p.platform === SocialType.Youtube);
  }, [listTopPost]);

  return (
    <div className="social-dashboard-topposts">
      {/* <Row gutter={[16,16]}>
        {[topPostFacebook, topPostInstagram, topPostTiktok, topPostYoutube].map(
          (topPostInfo, index) => {
            return (
              <Col xl={8} sm={24} xs={24} key={index}>
                <Spin spinning={loadingTopPosts}>
                  <div className="rl-card">
                    <div className="rl-card-toppost">
                      <TopPostItem topPostInfo={topPostInfo} />
                    </div>
                  </div>
                </Spin>
              </Col>
            );
          }
        )}
      </Row> */}

      <Row gutter={[16, 16]}>
        <Col xl={8} sm={24} xs={24}>
          <Spin spinning={loadingTopPosts}>
            <div className="rl-card">
              <div className="rl-card-toppost">
                <TopPostItem topPostInfo={topPostFacebook} />
              </div>
            </div>
          </Spin>
          <br />
          <Spin spinning={loadingTopPosts}>
            <div className="rl-card">
              <div className="rl-card-toppost">
                <TopPostItem topPostInfo={topPostYoutube} />
              </div>
            </div>
          </Spin>
        </Col>

        <Col xl={8} sm={24} xs={24}>
          <Spin spinning={loadingTopPosts}>
            <div className="rl-card">
              <div className="rl-card-toppost">
                <TopPostItem topPostInfo={topPostInstagram} />
              </div>
            </div>
          </Spin>
        </Col>

        <Col xl={8} sm={24} xs={24}>
          <Spin spinning={loadingTopPosts}>
            <div className="rl-card">
              <div className="rl-card-toppost">
                <TopPostItem topPostInfo={topPostTiktok} />
              </div>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default TopPosts;
