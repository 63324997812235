import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { GravityFormApi } from "../../../../../apis/gravityform.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import { IntegrationStatus } from "../../../../../constants/app.enums";
import useGetClients from "../../../../../hooks/useGetClients";
import { GFIntegrationModel } from "../../../../../models/gf.model";
import {
  connectionState,
  integrationState,
} from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import IntegrationAlert from "../alert";
import GroupBtnIntegration from "../group-btn-integration";

interface Props {
  isAddConnection?: boolean;
  integrationInfo?: GFIntegrationModel;
}

function GravityForm(props: Props) {
  const { isAddConnection, integrationInfo } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { refreshClientList } = useGetClients();

  const [status, setStatus] = useState(IntegrationStatus.None);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [enableTestBtn, setEnableTestBtn] = useState(false);
  const [connection, setConnection] = useRecoilState(connectionState);
  const { currentConnection } = connection;

  const [integrationValues, setIntegrationValues] =
    useRecoilState(integrationState);

  useEffect(() => {
    currentConnection
      ? form.resetFields()
      : form.setFieldsValue(integrationInfo);

    if (integrationInfo?.isInvalid)
      setStatus(IntegrationStatus.ConnectionLostError);
    else setStatus(IntegrationStatus.None);
  }, [currentConnection, integrationValues]);

  async function testConnection() {
    setTestLoading(true);
    try {
      const values = form.getFieldsValue();
      await GravityFormApi.testConnection({
        ...values,
      });
      setStatus(IntegrationStatus.Success);
    } catch {
      setStatus(IntegrationStatus.Error);
    }
    setTestLoading(false);
  }

  async function removeConnection() {
    setRemoveLoading(true);
    try {
      const integrationId = form.getFieldValue("id");
      if (!integrationId) throw new Error("");

      await GravityFormApi.removeConnection(integrationId);

      setIntegrationValues({
        ...integrationValues,
        gfIntegrations: integrationValues?.gfIntegrations.filter(
          (i) => i.id !== integrationId
        ),
      });

      showNotification(
        "delete",
        t("clientSites.connectionRemoved", { name: "Gravity Forms" })
      );

      refreshClientList();
    } catch {}
    setRemoveLoading(false);
  }

  async function submit(values: any) {
    setSaveLoading(true);
    try {
      if (!integrationValues) throw new Error("");

      if (currentConnection) {
        // add connection
        const GfResponse = await GravityFormApi.addConnection({
          ...values,
          clientId: integrationValues.clientId,
        });
        if (!GfResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          gfIntegrations: [
            ...integrationValues?.gfIntegrations,
            GfResponse.data,
          ],
        });

        setConnection({ ...connection, currentConnection: "" });
        setStatus(IntegrationStatus.Success);

        showNotification("success", "Gravity Forms successfully connected. It’ll take some time to sync data.");
      } else {
        //update connection
        const integrationId = form.getFieldValue("id");
        const GfResponse = await GravityFormApi.updateConnection({
          ...values,
          clientId: integrationValues.clientId,
          id: integrationId,
        });
        if (!GfResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          gfIntegrations: integrationValues.gfIntegrations.map((item) =>
            item.id === integrationId ? GfResponse.data : item
          ),
        });

        setConnection({ ...connection, currentConnection: "" });
        setStatus(IntegrationStatus.Success);
      }

      refreshClientList();
    } catch {}
    setSaveLoading(false);
  }

  function onFormChanges() {
    updateTestBtn();
  }

  function updateTestBtn() {
    setEnableTestBtn(form.getFieldValue("consumerKey") &&
            form.getFieldValue("consumerSecret"));
  }

  useEffect(() => {
    updateTestBtn();
  }, [form.getFieldsValue()]);

  useEffect(() => {
    if (
      status !== IntegrationStatus.None &&
      status !== IntegrationStatus.ConnectionLostError
    ) {
      setTimeout(function () {
        if (integrationInfo?.isInvalid)
          setStatus(IntegrationStatus.ConnectionLostError);
        else setStatus(IntegrationStatus.None);
      }, 5000);
    }
  }, [status]);
  return (
    <div className="integration-form__section">
      <IntegrationAlert status={status} />

      <Form
        name="gravityFormIntegration"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        className={`custom-connection-form ${
          !currentConnection ? "hiden-required-mark" : ""
        }`}
        onValuesChange={onFormChanges}
      >
        <Form.Item
          name="description"
          label={t("userGroups.description")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("clientSites.websiteURL")}
          name="websiteUrl"
          rules={[{ required: true }, { type: "url" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="consumerKey"
          label={t("clientSites.consumerKey")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="consumerSecret"
          label={t("clientSites.consumerSecret")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <GroupBtnIntegration
          removeLoading={removeLoading}
          testLoading={testLoading}
          saveLoading={saveLoading}
          onTestConnection={testConnection}
          connectionName="Gravity Forms"
          onRemove={removeConnection}
          disableTestBtn={!enableTestBtn}
          isAddConnection={isAddConnection}
        />
      </Form>
    </div>
  );
}

export default GravityForm;
