import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthApi } from "../../apis/auth.api";
import EmailVerificationError from "../../components/views/email-verification/error";
import EmailVerificationSuccess from "../../components/views/email-verification/success";
import { CommonUtils } from "../../utils/common";

type AccountVerificationStatus = "None" | "Success" | "Error";

function EmailVerificationPage() {
  const [searchParams] = useSearchParams();
  const fromEmail = CommonUtils.queryParamReplaceSpace(searchParams.get("fromEmail") || "");
  const toEmail = CommonUtils.queryParamReplaceSpace(searchParams.get("toEmail") || "");
  const confirmationCode = searchParams.get("confirmationCode") || "";
  const [status, setStatus] = useState<AccountVerificationStatus>("None");

  useEffect(() => {
    if (fromEmail && toEmail && confirmationCode) {
      AuthApi.confirmChangeEmail({ fromEmail, toEmail, confirmationCode })
        .then(() => {
          setStatus("Success");
        })
        .catch(() => {
          setStatus("Error");
        });
    } else {
      setStatus("Error");
    }
  }, []);

  async function resend() {
    await AuthApi.resendEmailVerification({ fromEmail, confirmationCode });
  }

  return (
    <>
      {status === "None" ? (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        ></Spin>
      ) : status === "Success" ? (
        <EmailVerificationSuccess email={toEmail} />
      ) : (
        <EmailVerificationError resend={resend} email={toEmail} />
      )}
    </>
  );
}

export default EmailVerificationPage;
