import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link, useNavigate,
  useSearchParams
} from "react-router-dom";
import { AuthApi } from "../../apis/auth.api";
import SVGIcons from "../../components/icons/svgs";
import {
  PASSWORD_POLYCIES,
  VALIDATION_MESSAGE_CONFIG
} from "../../constants/app-constants";
import { ROUTE_PATHS } from "../../constants/router.constants";
import useAdminSite from "../../hooks/useAdminSite";
import { CommonUtils } from "../../utils/common";
import "./index.scss";

function UpdatePasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isAdmin = useAdminSite();

  let userName = CommonUtils.queryParamReplaceSpace(searchParams.get("userName") || "");

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [passwordPolicies, setPasswordPolicies] = useState(
    PASSWORD_POLYCIES.filter((x) => !x.disabled)
  );

  function passwordOnchange(value: string) {
    const newArray = [...passwordPolicies];
    newArray.forEach((item) => {
      var reg = new RegExp(item.regex);
      item.valid = reg.test(value);
    });

    setPasswordPolicies(newArray);
  }

  async function handleSubmit() {
    setLoading(true);

    try {
      const { oldPassword, newPassword } = form.getFieldsValue();
      await AuthApi.updatePasswordNewUser({
        oldPassword,
        newPassword,
        userName,
      });
      navigate(isAdmin ? ROUTE_PATHS.AdminUpdatePasswordSuccess : ROUTE_PATHS.UpdatePasswordSuccess);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  return (
    <>
      <div className="update-password-form auth__form">
        <div className="update-password-form__header">
          {t("updatePassword.title")}
        </div>

        <div className="update-password-form__description">
          <span
            dangerouslySetInnerHTML={{
              __html: t("updatePassword.description"),
            }}
          ></span>
        </div>

        <Form
          name="updatePassword"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          onFinish={handleSubmit}
          form={form}
          className="hiden-required-mark"
        >
          <Form.Item
            label={t("updatePassword.currentPassword")}
            name="oldPassword"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder={t("updatePassword.currentPassword")} />
          </Form.Item>
          <Form.Item
            label={t("updatePassword.newPassword")}
            name="newPassword"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (passwordPolicies.some((x) => !x.valid)) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className="mr-bottom-0"
          >
            <Input.Password
              placeholder={t("updatePassword.password")}
              onChange={(e) => passwordOnchange(e.target.value)}
            />
          </Form.Item>
          <Row className="password-hint-wrapper">
            {passwordPolicies.map((item) => (
              <Col
                span={12}
                key={item.key}
                className={`password-hint-item${item.valid ? " active" : ""}`}
              >
                <SVGIcons.KeyIcon />
                <span>{item.label}</span>
              </Col>
            ))}
          </Row>
          <Form.Item
            label={t("updatePassword.confirmNewPassword")}
            name="confirmPassword"
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (value !== form.getFieldValue("newPassword")) {
                    return Promise.reject(
                      t("updatePassword.passwordsNotMatch")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password placeholder={t("updatePassword.password")} />
          </Form.Item>

          <Button htmlType="submit" type="primary" block loading={loading}>
            {t("updatePassword.btnUpdate")}
          </Button>
        </Form>
      </div>

      <Link
        className="auth__backLink"
        to={isAdmin ? ROUTE_PATHS.AdminSignIn : ROUTE_PATHS.SignIn}
        dangerouslySetInnerHTML={{ __html: t("forgotPassword.sendMeBack") }}
      ></Link>
    </>
  );
}

export default UpdatePasswordPage;
