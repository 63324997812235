import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import mailbox from "../../../../assets/images/icon-mailbox.svg";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";

interface Props {
  email: string;
  resendEmail: ()=> void
}

function SignUpConfirm(props: Props) {
  const { email, resendEmail } = props;
  const { t } = useTranslation();

  return (
    <div className="sign-up-confirm">
      <div className="sign-up-confirm__submited">
        <img src={mailbox} alt="mailbox" /> 
        <div className="sign-up-confirm__header">
          {t("signUp.submitedTitle")}
        </div>
        <div className="sign-up-confirm__description">
          <span
            dangerouslySetInnerHTML={{
              __html: t("signUp.submitedDescription", {
                param1: email,
              }),
            }}
          ></span>
        </div>

        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            block
            onClick={() => (window.location.href = "mailto:")}
          >
            <i className="sign-up-confirm__emailIcon">
              <SVGIcons.EmailIcon />
            </i>
            {t("signUp.btnOpenMail")}
          </Button>
        </Form.Item>
        <p>{t("signUp.checkSpam")}</p>
        <a href="#" onClick={resendEmail}  >{t("signUp.sendMailAgain")}</a>
      </div>
    </div>
  );
}

export default SignUpConfirm;
