import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { authState } from "../../../../states/auth";
import AppAvatar from "../../../common/app-avatar/app-avatar";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";

interface UserProfileFormProps {
  form: FormInstance<any>;
}

const UserProfile = (props: UserProfileFormProps) => {
  const { form } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [imgSource, setImgSource] = useState("");
  const { user } = useRecoilValue(authState);

  function onClickChangePassword() {
    navigate(ROUTE_PATHS.AdminChangePassword);
  }

  const onChangePicture = async (fileInfo: any) => {
    setLoadingAvatar(true);
    try {
      const { file } = fileInfo;
      setImgSource(URL.createObjectURL(file.originFileObj));
      form.setFieldsValue({ profilePicture: await file.originFileObj });
    } catch (error) {}
    setLoadingAvatar(false);
  };
  return (
    <Form
      name="myprofileForm"
      layout="vertical"
      validateMessages={VALIDATION_MESSAGE_CONFIG}
      form={form}
      className="hiden-required-mark user-profile-form"
    >
      <Form.Item>
        <label>
          {t("users.userId")}: {user?.userId}
        </label>
      </Form.Item>
      <Form.Item
        label={
          <>
            <div>{t("users.userProfilePicture")}</div>
            <div className="format-picture">Format: JPG, JPEG, PNG</div>
            <div className="format-picture">Maximum 250 x 250px</div>
          </>
        }
        colon={false}
        className="label-avatar"
      >
        <div className="avatar">
          <Spin spinning={loadingAvatar}>
            <AppAvatar
              size={100}
              shape="square"
              src={imgSource || user?.profilePictureUrl}
            ></AppAvatar>
          </Spin>
          <div className="btn-edit-avatar">
            <Upload
              onChange={onChangePicture}
              fileList={[]}
              customRequest={() => {}}
              accept=".jpg, .jpeg, .png, .svg"
            >
              <SVGIcons.EditIcon />
            </Upload>
          </div>
        </div>
      </Form.Item>

      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <Form.Item
            label={t("users.firstName")}
            rules={[{ required: true }]}
            name="firstName"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label={t("users.lastName")}
            rules={[{ required: true }]}
            name="lastName"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label={t("users.email")} name="email">
        <Input disabled placeholder={t("signUp.businessEmailPlahoder")} />
      </Form.Item>

      <Form.Item label={t("users.password")}>
        <Button
          className="mid-night-border"
          icon={<SVGIcons.KeyIcon />}
          onClick={onClickChangePassword}
        >
          {t("users.changePassword")}
        </Button>
      </Form.Item>

      <Form.Item
        label={t("users.userGroup")}
        name="userGroup"
        style={{ marginBottom: 0 }}
      >
        <Select disabled></Select>
      </Form.Item>
    </Form>
  );
};

export default UserProfile;
