import { Alert, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { IntegrationStatus, ZohoSyncStatus } from "../../../../../constants/app.enums";
import { LoadingOutlined } from "@ant-design/icons";
import SVGIcons from "../../../../icons/svgs";
import "./index.scss";

interface Props {
  status: IntegrationStatus | ZohoSyncStatus;
  onReAuthenticate?: () => void;
  reAuthenticateLoading?: boolean;
  onSyncDataZoho?: () => void;
}

function IntegrationAlert(props: Props) {
  const { status, onReAuthenticate, reAuthenticateLoading, onSyncDataZoho } = props;
  const { t } = useTranslation();

  function renderContent() {
    switch (status) {
      case IntegrationStatus.Success:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationSuccessAlert")}
            type="success"
            showIcon
            icon={<SVGIcons.CheckedIcon />}
          />
        );
      case IntegrationStatus.Error:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationErrorAlert")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case IntegrationStatus.SyncSuccess:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationSyncSuccessAlert")}
            type="success"
            showIcon
            icon={<SVGIcons.CheckedIcon />}
          />
        );
      case IntegrationStatus.SyncError:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationSyncErrorAlert")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case IntegrationStatus.ConnectionGALostError:
        return (
          <Alert
            className="integration-form__alert"
            message={
              <>
                {t("clientSites.connectLostErrorAlert")}{" "}
                <span
                  className="reAuthen"
                  onClick={() => onReAuthenticate && onReAuthenticate()}
                >
                  {t("clientSites.pleaseReAuthenticate")}
                </span>
              </>
            }
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case IntegrationStatus.ConnectionLostError:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.integrationConnectLostErrorAlert")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case IntegrationStatus.ConnectionZohoLostError:
        return (
          <Alert
            className="integration-form__alert"
            message={
              <Spin spinning={reAuthenticateLoading}>
                {t("clientSites.connectionFailed")}{" "}
                <span
                  className="reAuthen"
                  onClick={() => onReAuthenticate && onReAuthenticate()}
                >
                  {t("clientSites.reAuthenticate")}{" "}
                </span>
                {t("clientSites.thenTryAgain")}
              </Spin>
            }
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );

      case ZohoSyncStatus.ZohoSyncFailed:
        return (
          <Alert
            className="integration-form__alert"
            message={
              <>
                {t("clientSites.zohoSyncFailed")}{" "}
                <span className="reAuthen" onClick={() => onSyncDataZoho && onSyncDataZoho()}>
                  {t("clientSites.syncHere")}{" "}
                </span>
              </>
            }
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      case ZohoSyncStatus.ZohoSyncing:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.zohoSyncing")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
            closable
            closeIcon={
              <LoadingOutlined
                style={{
                  fontSize: 20,
                  color: "rgb(1, 133, 222)",
                  fontWeight: "bolder",
                }}
                spin
              />
            }
          />
        );
      case ZohoSyncStatus.ZohoSynced:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.zohoSynced")}
            type="success"
            showIcon
            icon={<SVGIcons.CheckedIcon />}
          />
        );
      case ZohoSyncStatus.ZohoMissingOwner:
        return (
          <Alert
            className="integration-form__alert"
            message={t("clientSites.zohoMissingOwner")}
            type="error"
            showIcon
            icon={<SVGIcons.ErrorIcon />}
          />
        );
      default:
        return "";
    }
  }

  return <>{renderContent()}</>;
}

export default IntegrationAlert;
