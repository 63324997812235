import { Tooltip } from "antd";
import moment from "moment";
import {
  ActivityType,
  SocialType,
} from "../../../../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../../../../constants/router.constants";
import useClient from "../../../../../../hooks/useClient";
import { AcvitityModel } from "../../../../../../models/content.model";
import DateUtils from "../../../../../../utils/date.utils";
import SocialUtils from "../../../../../../utils/social";
import SVGIcons from "../../../../../icons/svgs";
import "./index.scss";

interface ActivityItemProps {
  activityInfo: AcvitityModel;
}
const ActivityItem = (props: ActivityItemProps) => {
  const { activityInfo } = props;
  const { client } = useClient();

  const {
    activityType,
    scheduleOn,
    date,
    user,
    errorPlatform,
    description: approvalName,
    referenceId,
    guestUser
  } = activityInfo;

  const fullName = user ? `${user?.firstName} ${user?.lastName}` : guestUser?.fullName;

  const moveToApprovalRequest = (approvalId: number) => {
    window.open(
      `${ROUTE_PATHS.Social.ApprovalRequest}?approvalId=${approvalId}`
    );
  };

  const getSocialIcon = (platform: SocialType) => {
    switch (platform) {
      case SocialType.Facebook:
        return <SVGIcons.BrandFacebookNotFillIcon />;
      case SocialType.Instagram:
        return <SVGIcons.BrandInstagramNotFillIcon />;
      case SocialType.Tiktok:
        return <SVGIcons.BrandTiktokNotFillIcon />;
      case SocialType.Youtube:
        return <SVGIcons.BrandYoutubeNotFillIcon />
      case SocialType.Google: 
        return <SVGIcons.BrandGoogleIcon />
      default:
        return <></>;
    }
  };

  const [icon, description] = (() => {
    switch (activityType) {
      case ActivityType.CREATED:
        return [<SVGIcons.HandRockIcon />, `Content created by ${fullName}`];
      case ActivityType.EDITED:
        return [<SVGIcons.PencilMinusIcon />, `Content updated by ${fullName}`];
      case ActivityType.PUBLISHED:
        return [<SVGIcons.SendIcon />, `Content published successfully!`];
      case ActivityType.SCHEDULE:
        return [
          <SVGIcons.ClockEditIcon />,
          `Date & time changed to ${DateUtils.getDateWithTimezone(scheduleOn, client?.timeZoneInfo?.id)
            .format("MMM DD, YYYY hh:mm a")}`,
        ];
      case ActivityType.ERROR:
        if (errorPlatform !== null)
          return [
            getSocialIcon(errorPlatform),
            `Content failed to publish to ${SocialUtils.getSocialName(
              errorPlatform
            )}. Please check the error icons and try again later.`,
          ];
        return [
          <SVGIcons.AlertTriangleIcon />,
          `Content failed to publish. Please check the error icons and
          try again later.`,
        ];
      case ActivityType.APPROVED:
        return [<SVGIcons.UserCheckIcon />, `Content approved by ${fullName}`];
      case ActivityType.DECLINED:
        return [<SVGIcons.UserXIcon />, `Content declined by ${fullName}`];
      case ActivityType.APPROVAL_TURN_ON:
        return [
          <SVGIcons.UserExclamationIcon />,
          `Approval turned on by ${fullName}`,
        ];
      case ActivityType.APPROVAL_NOT_REQUIRE:
        return [
          <SVGIcons.UserMinusIcon />,
          `Approval not required by ${fullName}`,
        ];
      case ActivityType.SCHEDULED_PUBLISHING_ERROR:
        return [
          <SVGIcons.CalendarOffIcon />,
          `Content failed to publish as scheduled date has passed without approval`,
        ];
      case ActivityType.ADDED_TO_APPROVAL:
        return [
          <SVGIcons.RubberStampIcon />,
          <span>
            Content added to approval -{" "}
            <span onClick={() => moveToApprovalRequest(referenceId)}>
              {approvalName}
            </span>
          </span>,
        ];
      case ActivityType.EXPIRED:
        return [
          <SVGIcons.PlugConnectXIcon />,
          `Content failed to publish due to expired connection`,
        ];
      case ActivityType.UNKNOWN:
        return [
          <SVGIcons.AlertTriangleIcon />,
          `Content failed to save due to unknown error`,
        ];
      default:
        return [<></>, ""];
    }
  })();

  const isRedItem = [
    ActivityType.EXPIRED,
    ActivityType.SCHEDULED_PUBLISHING_ERROR,
    ActivityType.UNKNOWN,
    ActivityType.ERROR,
  ].includes(activityType);

  return (
    <div className={`activity-item ${isRedItem ? "activity-item-red" : ""}`}>
      <div className="activity-item__icon">
        <div className="overlay"></div>
        <div className="icon">{icon}</div>
      </div>
      <div className="activity-item__detail">
        <div className="activity-item__detail-description">{description}</div>
        <Tooltip
          title={DateUtils.getDateWithTimezone(date, client?.timeZoneInfo?.id).format("MMM DD, YYYY, hh:mma")}
        >
          <span className="activity-item__detail-time">
            {moment.utc(date).fromNow()}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default ActivityItem;
