import { atom } from "recoil";
import { MediaModel } from "../models/content.model";

interface MediaLibraryStateModel {
  fileSelected?: MediaModel;
  data: {
    records: MediaModel[];
    totalRecords: number;
  };
  loading: boolean;
  triggerReloadMediaList: number;
  triggerReloadUsageStorage: number;
}

export const MediaLibraryState = atom<MediaLibraryStateModel>({
  key: "media-library-state",
  default: {
    fileSelected: undefined,
    data: {
      records: [],
      totalRecords: 0,
    },
    loading: false,
    triggerReloadMediaList: 0,
    triggerReloadUsageStorage: 0,
  },
});

export const FileSelectedState = atom<MediaModel | undefined>({
  key: "file-selected-state",
  default: undefined,
});

export const ListMediaSelectedState = atom<MediaModel[]>({
  key: "list-media-selected-state",
  default: [],
});