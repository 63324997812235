import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../apis/auth.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import {
  FunctionEnum,
  FUNCTIONS,
  PermissionEnum,
} from "../../../../constants/permission.constants";
import { RoleModel } from "../../../../models/role.model";
import showNotification from "../../../common/notification";
import SelectPermissons from "../select-permission";
import "./index.scss";

interface Props {
  open: boolean;
  close: () => void;
  item?: RoleModel;
  getRoles: () => Promise<void>;
}

function EditRoleForm(props: Props) {
  const { open, close, item, getRoles } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [viewPermissionIsNothing, setViewPermissionIsNothing] = useState(true);

  useEffect(() => {
    if (open) {
      form.resetFields();

      if (item) {
        let viewPermissions = item.permissions
          .filter(
            (x) =>
              x.right === PermissionEnum.View || x.right === PermissionEnum.All
          )
          .map((x) => x.functionCode);
        viewPermissions = viewPermissions.length
          ? viewPermissions
          : [FunctionEnum.Nothing];

        setViewPermissionIsNothing(viewPermissions && viewPermissions.includes(FunctionEnum.Nothing));

        let createPermissions = item.permissions
          .filter(
            (x) =>
              x.right === PermissionEnum.Create ||
              x.right === PermissionEnum.All
          )
          .map((x) => x.functionCode);
        createPermissions = createPermissions.length
          ? createPermissions
          : [FunctionEnum.Nothing];

        let editPermissions = item.permissions
          .filter(
            (x) =>
              x.right === PermissionEnum.Edit || x.right === PermissionEnum.All
          )
          .map((x) => x.functionCode);
        editPermissions = editPermissions.length
          ? editPermissions
          : [FunctionEnum.Nothing];

        let deletePermissions = item.permissions
          .filter(
            (x) =>
              x.right === PermissionEnum.Delete || x.right === PermissionEnum.All
          )
          .map((x) => x.functionCode);
        deletePermissions = deletePermissions.length
          ? deletePermissions
          : [FunctionEnum.Nothing];

        form.setFieldsValue({
          name: item.roleName,
          [PermissionEnum.View]: viewPermissions,
          [PermissionEnum.Create]: createPermissions,
          [PermissionEnum.Edit]: editPermissions,
          [PermissionEnum.Delete]: deletePermissions,
        });
      } else {
        form.setFieldsValue({
          [PermissionEnum.View]: [FunctionEnum.Nothing],
          [PermissionEnum.Create]: [FunctionEnum.Nothing],
          [PermissionEnum.Edit]: [FunctionEnum.Nothing],
          [PermissionEnum.Delete]: [FunctionEnum.Nothing],
        });

        setViewPermissionIsNothing(true);
      }
    }
  }, [open, item, form]);

  async function submit(values: any) {
    setLoading(true);

    try {
      const { name, ...objectPermission } = values;
      const listPermission = Object.keys(objectPermission).reduce(
        (result: any, key) => {
          const permissions = values[key];
          if (permissions.includes(FunctionEnum.Nothing)) return result;
          if (permissions.includes(FunctionEnum.Everything))
            return [
              ...result,
              { functionCode: FunctionEnum.Everything, right: Number(key) },
            ];
          const newPermission = permissions.map((p: FunctionEnum) => {
            return { functionCode: p, right: Number(key) };
          });
          return [...result, ...newPermission];
        },
        []
      );
      if (item) {
        // edit
        await AuthApi.updateRole({
          roleName: name,
          id: item.roleId,
          permissions: listPermission,
        });
        showNotification(
          "success",
          t("rolePermission.editRoleSuccess", { name: name })
        );
      } else {
        // create
        await AuthApi.createRole({
          roleName: name,
          permissions: listPermission,
        });
        showNotification(
          "success",
          t("rolePermission.createRoleSuccess", { name: name })
        );
      }
      close();

      await getRoles();
    } catch { }
    setLoading(false);
  }

  function okClick() {
    form.submit();
  }

  function cancel() {
    close();
  }

  function viewPermissionChange(values: FunctionEnum[]) {
    setViewPermissionIsNothing(values && values.includes(FunctionEnum.Nothing));
    form.setFieldsValue({
      [PermissionEnum.Create]: [FunctionEnum.Nothing],
      [PermissionEnum.Edit]: [FunctionEnum.Nothing],
      [PermissionEnum.Delete]: [FunctionEnum.Nothing],
    });
  }

  return (
    <Modal
      open={open}
      title={
        item
          ? t("rolePermission.editPopupTitle")
          : t("rolePermission.createPopupTitle")
      }
      okText={
        item ? t("common.saveChanges") : t("rolePermission.btnCreateSubmit")
      }
      onOk={okClick}
      className="custom-create-modal modal-edit-role"
      onCancel={cancel}
      maskClosable={false}
      confirmLoading={loading}
    >
      <Form
        name="createUserForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label={t("rolePermission.roleName")}
          rules={[{ required: true }]}
          name="name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("rolePermission.viewPermissions")}
          name={PermissionEnum.View}
          rules={[{ required: true }]}
        >
          <SelectPermissons onChange={viewPermissionChange} />
        </Form.Item>

        <Form.Item
          label={t("rolePermission.createPermissions")}
          name={PermissionEnum.Create}
          rules={[{ required: true }]}
        >
          <SelectPermissons disabled={viewPermissionIsNothing} />
        </Form.Item>

        <Form.Item
          label={t("rolePermission.editPermissions")}
          name={PermissionEnum.Edit}
          rules={[{ required: true }]}
        >
          <SelectPermissons disabled={viewPermissionIsNothing} />
        </Form.Item>

        <Form.Item
          label={t("rolePermission.deletePermissions")}
          name={PermissionEnum.Delete}
          rules={[{ required: true }]}
          style={{ marginBottom: 0 }}
        >
          <SelectPermissons disabled={viewPermissionIsNothing} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditRoleForm;
