import {
  BeAddConnectionRequest,
  BeUpdateConnectionRequest,
} from "../models/be.model";
import { DashBoardRequestModel } from "../models/ga.model";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/birdeye/api/v1`;

function testConnection(request: any) {
  const url = `${baseUrl}/integration/connect`;
  return postAsync(url, request, false, false);
}

function getReport(request: DashBoardRequestModel) {
  const url = `${baseUrl}/review/report`;
  return getAsync(url, request, false, false);
}

function removeConnection(clientId: number) {
  const url = `${baseUrl}/integration/${clientId}`;
  return deleteAsync(url, false, false);
}

function addConnection(request: BeAddConnectionRequest) {
  const url = `${baseUrl}/integration`;
  return postAsync(url, request, false, false);
}

function updateConnection(request: BeUpdateConnectionRequest) {
  const url = `${baseUrl}/integration`;
  return putAsync(url, request, false, false);
}

function syncConnection(clientId: number) {
  const url = `${baseUrl}/integration/${clientId}/sync-data`;
  return postAsync(url, {}, false, false);
}

export const BirdeyeApi = {
  testConnection,
  getReport,
  removeConnection,
  addConnection,
  updateConnection,
  syncConnection,
};
