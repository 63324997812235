import { atom } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { NotificationModel } from "../models/notification.model";

interface NotificationState {
  records: NotificationModel[];
  totalRecords: number;
  isNewNotification: boolean;
  loading: boolean;
  unread: number;
}

const notificationState = atom<NotificationState>({
  key: APP_STATE_KEYS.NOTIFICATION_STATE,
  default: {
    records: [],
    totalRecords: 0,
    isNewNotification: false,
    loading: false,
    unread: 0,
  },
});

export { notificationState };
