import styled from "styled-components";

export const TagSocialStyled: any = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius:  ${(props: any) => props.properties["border-radius"] || "6px"};
  cursor: pointer;
  width: fit-content;
  height: ${(props: any) => props.properties.heightDiv};
  background-color: ${(props: any) => `${props.color}19`};
  transition: 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${(props: any) => `${props.color}4D`};
  }

  span {
    color: ${(props: any) => props.color};
    font-size: ${(props: any) => props.properties["font-size"]};
    font-weight: 500;
    height: ${(props: any) => props.properties.heightSpan};
    line-height: 1.43;
    letter-spacing: 0.03px;
    display: flex;
    align-items: center;
  }

  svg {
    width: 10px;
    height: 11px;
    color: ${(props: any) => props.color};
    line-height: 1.6;
    letter-spacing: 0.02px;
    margin-left: 2px;
    margin-bottom: 1px;
    font-weight: 500;
  }
`;
