import { Form, FormInstance, Input, Modal, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import AppSelectTimezone from "../../../components/controls/app-select-timezone";
import { CLIENT_CETEGORIES, VALIDATION_MESSAGE_CONFIG } from "../../../constants/app-constants";
import { ClientCategory } from "../../../constants/app.enums";
import { CreateCompanyRequestModel } from "../../../models/client.model";
import "./index.scss";

interface CreateCompanyFormProps {
  open: boolean;
  close: () => void;
  onSubmit?: (model: CreateCompanyRequestModel) => Promise<boolean>;
  form: FormInstance;
}

function CreateCompanyForm(props: CreateCompanyFormProps) {
  const { open, close, onSubmit, form } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  async function submit(values: CreateCompanyRequestModel) {
    if (onSubmit) {
      setLoading(true);
      try {
        const res = await onSubmit(values);
        if (res) form.resetFields();
      } catch (error) {}
      setLoading(false);
    }
  }

  function okClick() {
    form.submit();
  }

  function cancel() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      open={open}
      title={t("clientSites.createNewCompanyModalTitle")}
      okText={t("clientSites.btnAddCompany")}
      onOk={okClick}
      className="custom-create-modal create-site-modal"
      onCancel={cancel}
      confirmLoading={loading}
    >
      <Form
        name="CreateCompanyForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label={t("clientSites.companyName")}
          name="companyName"
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                if (form.getFieldValue("existName")) {
                  return Promise.reject("The Company Name is already in use.");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            placeholder={t("clientSites.companyName")}
            onChange={() => form.setFieldValue("existName", false)}
          />
        </Form.Item>

        <Form.Item
          label={t("clientSites.businessPhone")}
          name="businessPhone"
          rules={[{ required: true }]}
        >
          <PhoneInput country={"us"} enableSearch={true} />
        </Form.Item>

        <Form.Item
          label={t("clientSites.industry")}
          name="category"
          rules={[{ required: true }]}
        >
          <Select showSearch  optionFilterProp="children" defaultValue={ClientCategory.Uncategorised}>
            {CLIENT_CETEGORIES.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("clientSites.websiteURL")}
          name="website"
          rules={[{ required: true }, { type: "url" }]}
        >
          <Input placeholder={t("clientSites.websiteURL")} />
        </Form.Item>

        <Form.Item
          label={t("generalSettings.timeZone")}
          name="timeZone"
          rules={[{ required: true }]}
        >
          <AppSelectTimezone
            onChange={(value: string) => form.setFieldValue("timeZone", value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateCompanyForm;
