import { Button, Modal, Skeleton, Space, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { SUPPORT_EMAIL } from "../../../../constants/app-constants";
import blueCheckIcon from "../../../../assets/images/blue-check-icon.png";
import banner from "../../../../assets/images/upgrade-banner.png";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import { PlanApi } from "../../../../apis/plan.api";
import { CycleType, PlanType } from "../../../../constants/app.enums";
import { useEffect, useState } from "react";
import { GetPlanByTypeResponse } from "../../../../models/plan.model";
import useClientPlan from "../../../../hooks/useClientPlan";

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

function UpgradeModal(props: Props) {
    const { isOpen, setIsOpen } = props
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { t } = useTranslation();
    const [data, setData] = useState<GetPlanByTypeResponse>()
    const {getUpgradePremiumUrl, isGetPremiumUrlLoading} = useClientPlan()

    const getPremium = async () => {
        setIsLoading(true)
        try {
            const { data } = await PlanApi.getPlanByType(PlanType.Premium);
            setData(data)
        } finally {
            setIsLoading(false)
        }
    }


    const getPlanUtilities = () => {
        if (!data) {
            return [];
        }

        const unlimitedNumber = -1;

        const utilityTypes = [
            {
                key: "noOfCompanies",
                message: t("upgradeModal.upToCompanies", { number: data.noOfCompanies }),
                unlimitedMessage: t("upgradeModal.unlimitedCompanies"),
            },
            {
                key: "noOfTeamMembers",
                message: t("upgradeModal.upToExternalTeamMembers", { number: data.noOfTeamMembers }),
                unlimitedMessage: t("upgradeModal.unlimitedExternalTeamMembers"),
            },
            {
                key: "noOfPostsPerComMonthly",
                message: t("upgradeModal.upToPostsPerCompanyEveryMonth", { number: data.noOfPostsPerCom}),
                unlimitedMessage: t("upgradeModal.unlimitedPostsPerCompanyEveryMonth"),
            },
            {
                key: "noOfReportPerCom",
                message: t("upgradeModal.upToReports", { number: data.noOfReportPerCom }),
                unlimitedMessage: t("upgradeModal.unlimitedReports"),
            },
        ];

        const utilities = utilityTypes.map(({ key, message, unlimitedMessage }) => {
            const value = data[key as keyof GetPlanByTypeResponse];
            return value === unlimitedNumber ? unlimitedMessage : message;
        });

        utilities.push(data.otherFeature);

        return utilities;
    };

    const getPriceAndCycle = () => {
        if (data) {
            switch (data.cycleType) {
                case CycleType.Monthly:
                    return t("upgradeModal.planCycle", {
                        price: data.price,
                        cycleShort: 'mo',
                        cycle: 'monthly',
                    })

                case CycleType.Weekly:
                    return t("upgradeModal.planCycle", {
                        price: data.price,
                        cycleShort: 'w',
                        cycle: 'weekly',
                    })

                case CycleType.None:
                    return `$${data.price}`

                default:
                    return ''
            }
        }
    }
    
   


    useEffect(() => {
        if (isOpen && !data) {
            getPremium()
        }
    }, [isOpen])

    return (
        <>
            <Modal
                centered
                className="upgrade-modal"
                open={isOpen}
                closable={false}
                onCancel={() => setIsOpen(false)}
                footer={null}
            >
                <div className="get-premium-modal">
                    <div>
                        <img
                            className="get-premium-modal__banner"
                            src={banner}
                            alt="banner"
                            loading="eager"
                        />
                        {
                            <div
                                onClick={() => setIsOpen(false)}
                                className="get-premium-modal__closeIcon"
                            >
                                <SVGIcons.UpgradeModalCloseIcon />
                            </div>
                        }

                        <div className="get-premium-modal__heading">
                            <div className="get-premium-modal__title">
                                {t("upgradeModal.heading")}
                            </div>
                            <div className="get-premium-modal__desc">
                                {t("upgradeModal.desc")}
                            </div>
                        </div>
                    </div>
                    
                         <div className="get-premium-modal__pricing">
                            {
                                isLoading ? <Space>
                                <Skeleton.Avatar  shape='circle' />
                                <Skeleton.Input  />
                            </Space> :  <div className="get-premium-modal__plan">
                                <SVGIcons.DiamondIcon />
                                <div>
                                    <div className="get-premium-modal__premium">
                                        {data?.name}
                                    </div>
                                    <div className="get-premium-modal__price">
                                        {getPriceAndCycle()}
                                        <span>
                                            <Tooltip
                                                overlayClassName="custom-tooltip"
                                                title={t("upgradeModal.pricesAreInUSDollars")}
                                            >
                                                <span className="get-premium-modal__helpIcon">
                                                    <SVGIcons.IconHelp />
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            }
                           
                            <div>
                                <img
                                    className="get-premium-modal__checkIcon"
                                    src={blueCheckIcon}
                                    alt="blue check"
                                />
                            </div>
                    </div>
                    
                    

                    <div className="get-premium-modal__details">
                            <div className="get-premium-modal__whatYouGet">
                                {t("upgradeModal.whatYouGet")}
                            </div>
                            <div>
                            
                            </div>
                            {
                                isLoading ? <Skeleton /> :
                                    getPlanUtilities()?.map((item, index) => (
                                        <div key={index} className="get-premium-modal__detailsItem">
                                            <div>
                                                <SVGIcons.DiscountCheckIcon />
                                            </div>
                                            <div className="get-premium-modal__detailsItemText">
                                                {item}
                                            </div>
                                        </div>
                                    ))
                            }


                    </div>

                    <div className="get-premium-modal__footer">
                        <Button onClick={getUpgradePremiumUrl} loading={isGetPremiumUrlLoading} className="get-premium-modal__premiumBtn" type="primary">
                            {t("upgradeModal.upgradeToPremium")}
                        </Button>
                        <div className="get-premium-modal__questions">
                            {t("upgradeModal.haveQuestions")}{" "}
                            <span>
                                {" "}
                                <a href={`mailto:${SUPPORT_EMAIL}`} className="text-success">
                                    {t("upgradeModal.contactUs")}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default UpgradeModal;
