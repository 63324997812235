import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import SVGIcons from "../../icons/svgs";
import "./index.scss";

type NotificationType = "success" | "info" | "warning" | "error" | "delete";

const showNotification = (
  type: NotificationType,
  message: any,
  description = null
) => {
  let icon = null;
  switch (type) {
    case "success":
      icon = <SVGIcons.CheckedIcon />;
      break;
    case "error":
      icon = <SVGIcons.ErrorIcon />;
      break;
    case "delete":
      type = "info";
      icon = <SVGIcons.DeleteNotification />;
      break;
    default:
      break;
  }

  const config: ArgsProps = {
    message: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
    description: description,
    placement: "top",
  };

  if (icon) {
    config.icon = icon;
  }

  notification[type](config);
};

export default showNotification;
