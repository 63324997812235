import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import AppAvatar from "../../../components/common/app-avatar/app-avatar";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { ClientModel } from "../../../models/user";
import { authUserSelector } from "../../../states/auth";
import "./index.scss";

function SelectTeamPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [user, setUser] = useRecoilState(authUserSelector);
  const clients = user?.clients;

  const selectTeam = (client: ClientModel) => {
    user && setUser({ ...user, clientSelected: client });
    navigate(state?.redirectUrl || ROUTE_PATHS.Home);
  };
  return (
    <>
      <div className="select-team-page">
        <div className="header">{t("signUp.selectTeamTitle")}</div>
        <div className="list-client">
          {clients?.map((client) => {
            return (
              <div
                className="client-item"
                onClick={() => selectTeam(client)}
                key={client.id}
              >
                <div className="avatar">
                  <AppAvatar
                    src={client.profilePictureUrl}
                    shape="square"
                    size={40}
                  />
                </div>
                <div className="info">
                  <div className="name">{client.companyName}</div>
                  <div className="email">{client.email}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Link
        className="auth__backLink"
        to={ROUTE_PATHS.SignIn}
        dangerouslySetInnerHTML={{ __html: t("forgotPassword.sendMeBack") }}
      ></Link>
    </>
  );
}

export default SelectTeamPage;
