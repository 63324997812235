import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { BirdeyeApi } from "../../../../../apis/birdeye.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import { IntegrationStatus } from "../../../../../constants/app.enums";
import useGetClients from "../../../../../hooks/useGetClients";
import {
  connectionState,
  integrationState,
} from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import IntegrationAlert from "../alert";
import GroupBtnIntegration from "../group-btn-integration";

interface Props {
  isAddConnection?: boolean;
}

function Birdeye(props: Props) {
  const { isAddConnection } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { refreshClientList } = useGetClients();
  const [status, setStatus] = useState(IntegrationStatus.None);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [enableTestBtn, setEnableTestBtn] = useState(false);
  const [connection, setConnection] = useRecoilState(connectionState);
  const { currentConnection } = connection;

  const [integrationValues, setIntegrationValues] =
    useRecoilState(integrationState);

  useEffect(() => {
    currentConnection
      ? form.resetFields()
      : form.setFieldsValue(integrationValues?.beIntegrations[0]);

    if (integrationValues?.beIntegrations[0]?.isInvalid)
      setStatus(IntegrationStatus.ConnectionLostError);
    else setStatus(IntegrationStatus.None);
  }, [currentConnection, integrationValues]);

  async function testConnection() {
    setTestLoading(true);
    try {
      const values = form.getFieldsValue();
      await BirdeyeApi.testConnection(values);
      setStatus(IntegrationStatus.Success);
    } catch {
      setStatus(IntegrationStatus.Error);
    }
    setTestLoading(false);
  }

  async function onSyncConnection() {
    setSyncLoading(true);
    try {
      integrationValues &&
        (await BirdeyeApi.syncConnection(integrationValues.clientId));
      setStatus(IntegrationStatus.SyncSuccess);
    } catch {
      setStatus(IntegrationStatus.SyncError);
    }
    setSyncLoading(false);
  }

  async function removeConnection() {
    setRemoveLoading(true);
    try {
      const integrationId = form.getFieldValue("id");
      if (!integrationId) throw new Error("");

      await BirdeyeApi.removeConnection(integrationId);

      setIntegrationValues({
        ...integrationValues,
        beIntegrations: [],
      });

      showNotification(
        "delete",
        t("clientSites.connectionRemoved", { name: "Birdeye" })
      );

      refreshClientList();
    } catch {}
    setRemoveLoading(false);
  }

  async function submit(values: any) {
    setSaveLoading(true);
    try {
      if (!integrationValues) throw new Error("");

      if (currentConnection) {
        // add connection
        const BeResponse = await BirdeyeApi.addConnection({
          ...values,
          clientId: integrationValues.clientId,
        });
        if (!BeResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          beIntegrations: [BeResponse.data],
        });

        setConnection({ ...connection, currentConnection: "" });

        showNotification("success", "Birdeye successfully connected. It’ll take some time to sync data.");
      } else {
        //update connection
        const integrationId = form.getFieldValue("id");
        const BeResponse = await BirdeyeApi.updateConnection({
          ...values,
          clientId: integrationValues.clientId,
          id: integrationId,
        });
        if (!BeResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          beIntegrations: [BeResponse.data],
        });

        setConnection({ ...connection, currentConnection: "" });
        setIsEditing(false);
      }

      refreshClientList();
    } catch {}
    setSaveLoading(false);
  }

  function onFormChanges(changedValues: any, values: any) {
    if (integrationValues?.beIntegrations[0]) {
      const { apiKey, businessId } = integrationValues?.beIntegrations[0];
      const { apiKey: apiKeyChanged, businessId: businessIdChanged } = values;
      if (apiKey !== apiKeyChanged || businessId !== businessIdChanged)
        setIsEditing(true);
      else setIsEditing(false);
    }
    updateTestBtn();
  }

  function updateTestBtn() {
    setEnableTestBtn(
      currentConnection
        ? form.getFieldValue("businessId") && form.getFieldValue("apiKey")
        : true
    );
  }

  useEffect(() => {
    updateTestBtn();
  }, [form.getFieldsValue()]);

  useEffect(() => {
    if (
      status !== IntegrationStatus.None &&
      status !== IntegrationStatus.ConnectionLostError
    ) {
      setTimeout(function () {
        if (integrationValues?.beIntegrations[0]?.isInvalid)
          setStatus(IntegrationStatus.ConnectionLostError);
        else setStatus(IntegrationStatus.None);
      }, 5000);
    }
  }, [status]);

  return (
    <div className="integration-form__section">
      <IntegrationAlert status={status} />

      <Form
        name="birdeyeIntegration"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        className={`custom-connection-form ${
          !currentConnection ? "hiden-required-mark" : ""
        }`}
        onValuesChange={onFormChanges}
      >
        <Form.Item
          name="businessId"
          label={t("clientSites.businessID")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="apiKey"
          label={t("clientSites.APIKey")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <GroupBtnIntegration
          removeLoading={removeLoading}
          testLoading={testLoading}
          syncLoading={syncLoading}
          saveLoading={saveLoading}
          onTestConnection={testConnection}
          onSyncConnection={onSyncConnection}
          connectionName="Birdeye"
          onRemove={removeConnection}
          disableTestBtn={!enableTestBtn}
          disableSyncBtn={isEditing}
          isAddConnection={isAddConnection}
        />
      </Form>
    </div>
  );
}

export default Birdeye;
