import { Button } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import useClient from "../../../../../hooks/useClient";
import useFetchConnection from "../../../../../hooks/useFetchConnection";
import useZoho from "../../../../../hooks/useZoho";
import InsightHubIntegrationCollapse from "../../../../common/integration-collapse/insight-hub-integration";
import SaleAnalyticIntegrationCollapse from "../../../../common/integration-collapse/sale-analytic-integration";
import SVGIcons from "../../../../icons/svgs";
import AddConnection from "../../../client-sites/intergration-form/add-connection";

function ConnectionTab() {
  const { t } = useTranslation();
  const { connectZoho, connectZohoLoading } = useZoho();
  const { fetchConnection } = useFetchConnection();

  const { clientId, isOwner } = useClient();

  useEffect(() => {
    if (clientId) {
      fetchConnection();
    }
  }, [clientId]);

  return (
    <div className="connection-tab">
      <div className="connection-tab-head">
        <div className="title">
          <span>{t("common.insightsHub")}</span>
          <div className="connect-apps">
            <div className="label">
              {t("generalSettings.dashboardConnectInsightApps")}{" "}
              <a
                href={ROUTE_PATHS.Docs.SetUpDashboard}
                target="_blank"
                className="learn-more"
              >
                {t("generalSettings.learnMore")}
              </a>
            </div>
          </div>
        </div>
        {isOwner() && <AddConnection />}
      </div>

      {clientId && <InsightHubIntegrationCollapse clientId={clientId} />}

      <div className="connection-tab-head">
        <div className="title">
          <span>{t("common.salesAnalytics")}</span>
          <div className="connect-apps">
            <div className="label">
              {t("generalSettings.dashboardConnectSalesApps")}{" "}
              <a
                href={ROUTE_PATHS.Docs.ConnectZohoCrm}
                target="_blank"
                className="learn-more"
              >
                {t("generalSettings.learnMore")}
              </a>
            </div>
          </div>
        </div>
        {isOwner() && (
          <Button onClick={() => !connectZohoLoading && connectZoho()}>
            <SVGIcons.ZohoIcon /> Connect Account
          </Button>
        )}
      </div>

      {clientId && <SaleAnalyticIntegrationCollapse clientId={clientId} />}
    </div>
  );
}

export default ConnectionTab;
