import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import {
  SocialDateReportModel,
  SocialDateReportRowModel,
} from "../../../../../../models/social.model";
import { socialDashboardDateRangeState } from "../../../../../../states/social-dashboard";
import { NUMBER_UTIL } from "../../../../../../utils/number.utils";
import EngagementChart from "../../../../dashboard/charts/social-dashboard-chart/engagement";
import { renderVolatility } from "../../../../dashboard/site-traffic";
import "./index.scss";

export enum EngagementChartType {
  TotalLikes = "TotalLikes",
  TotalShares = "TotalShares",
  TotalComments = "TotalComments",
  TotalViews = "TotalViews",
}

export interface EngagementModel {
  key: EngagementChartType;
  label: string;
  color: string;
  value: string | number | undefined;
  volatility: number | undefined;
  data: SocialDateReportRowModel[];
}

interface Props {
  totalLikeReport: SocialDateReportModel;
  totalShareReport: SocialDateReportModel;
  totalCommentReport: SocialDateReportModel;
  totalViewReport: SocialDateReportModel;
  loading: boolean;
}

function Engagement(props: Props) {
  const {
    totalCommentReport,
    totalLikeReport,
    totalViewReport,
    totalShareReport,
    loading,
  } = props;
  const { t } = useTranslation();
  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const chartItems = [
    {
      key: EngagementChartType.TotalLikes,
      label: "Total Likes",
      color: "#0185de",
      value: totalLikeReport.total,
      volatility: totalLikeReport.volatility,
      data: totalLikeReport.rows,
    },
    {
      key: EngagementChartType.TotalShares,
      label: "Total Shares",
      color: "#9bc13c",
      volatility: totalShareReport.volatility,
      value: totalShareReport.total,
      data: totalShareReport.rows,
    },
    {
      key: EngagementChartType.TotalComments,
      label: "Total Comments",
      color: "#738fbd",
      value: totalCommentReport.total,
      volatility: totalCommentReport.volatility,
      data: totalCommentReport.rows,
    },
    {
      key: EngagementChartType.TotalViews,
      label: "Total Views",
      color: "#f59f00",
      volatility: totalViewReport.volatility,
      value: totalViewReport.total,
      data: totalViewReport.rows,
    },
  ];

  const [chartSelected, setChartSelected] = useState(chartItems[0]);

  useEffect(() => {
    setChartSelected(chartItems[0]);
  }, [totalCommentReport, totalLikeReport, totalViewReport, totalShareReport]);

  return (
    <div className="engagement-social">
      <Row gutter={16}>
        <Col xs={24}>
          <div className="rl-card">
            <p className="rl-card-title mr-bottom-24">
              <span>{t("dashboard.engagement")}</span>
              <span>{dateRange.totalDays}d</span>
            </p>

            <Spin spinning={loading}>
              <>
                <EngagementChart
                  chartId="engagement-chart"
                  chartData={chartSelected}
                />

                <Row gutter={16} className="engagement-social__itemWrapper">
                  {chartItems.map((item, index) => (
                    <Col
                      xs={24}
                      sm={12}
                      key={index}
                      className="engagement-social__item"
                    >
                      <div
                        className={`engagement-social__content${
                          item.key === chartSelected.key ? " active" : ""
                        }`}
                        style={{ borderColor: item.color }}
                        onClick={() => setChartSelected(item)}
                      >
                        <p className="engagement-social__content__title">
                          <span
                            className="engagement-social__content__legend"
                            style={{ backgroundColor: item.color }}
                          ></span>
                          <span>{item.label}</span>
                        </p>
                        <div className="engagement-social__content__value">
                          <span className="value">
                            {NUMBER_UTIL.convertNumericToFormattedString(
                              item.value
                            )}
                          </span>
                          <span className="volatility">
                            {renderVolatility(item.volatility)}
                          </span>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </>
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Engagement;
