import { Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import {
  CLIENT_CETEGORIES,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { ClientCategory } from "../../../../constants/app.enums";
import useFetchTimezones from "../../../../hooks/useFetchTimezones";
import { CreateSiteRequestModel } from "../../../../models/client.model";
import DateUtils from "../../../../utils/date.utils";
import AppSelectTimezone from "../../../controls/app-select-timezone";
import "./index.scss";

interface CreateSiteFormProps {
  open: boolean;
  close: () => void;
  onSubmit?: (
    model: CreateSiteRequestModel,
    onCancleForm?: () => void
  ) => Promise<void>;
}

function CreateSiteForm(props: CreateSiteFormProps) {
  const { open, close, onSubmit } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const timezones = useFetchTimezones();

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        canSendInvitationEmail: false,
        category: ClientCategory.Uncategorised,
        timeZone: DateUtils.getDefaultTimeZone(timezones.records),
      });
    }
  }, [open]);

  async function submit(values: CreateSiteRequestModel) {
    if (onSubmit) {
      setLoading(true);
      try {
        values.isDisabled = false;
        await onSubmit(values, cancel);
        // form.resetFields();
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  }

  function okClick() {
    form.submit();
  }

  function cancel() {
    form.resetFields();
    close();
  }

  return (
    <Modal
      open={open}
      title={t("clientSites.createModalTitle")}
      okText={t("clientSites.btnAddSite")}
      onOk={okClick}
      className="custom-create-modal create-site-modal"
      onCancel={cancel}
      confirmLoading={loading}
    >
      <Form
        name="createSiteForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label={t("clientSites.companyName")}
          name="companyName"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("clientSites.companyName")} />
        </Form.Item>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("users.firstName")}
              rules={[{ required: true }]}
              name="firstName"
            >
              <Input placeholder={t("users.firstName")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("users.lastName")}
              rules={[{ required: true }]}
              name="lastName"
            >
              <Input placeholder={t("users.lastName")} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("clientSites.industry")}
          name="category"
          rules={[{ required: true }]}
        >
          <Select showSearch  optionFilterProp="children">
            {CLIENT_CETEGORIES.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={t("clientSites.email")}
          name="email"
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input placeholder={t("signUp.businessEmailPlahoder")} />
        </Form.Item>

        <Form.Item
          label={t("clientSites.businessPhone")}
          name="businessPhone"
          rules={[{ required: true }]}
        >
          <PhoneInput country={"us"} enableSearch={true} />
        </Form.Item>

        <Form.Item
          label={t("clientSites.websiteURL")}
          name="website"
          rules={[{ required: true }, { type: "url" }]}
        >
          <Input placeholder={t("clientSites.websiteURL")} />
        </Form.Item>

        <Form.Item
          label={t("generalSettings.timeZone")}
          name="timeZone"
          rules={[{ required: true }]}
        >
          <AppSelectTimezone
            onChange={(value: string) => form.setFieldValue("timeZone", value)}
          />
        </Form.Item>

        <Row wrap={false}>
          <Col>
            <div className="invitation-email">
              <div className="title">
                {t("clientSites.invitationEmailTitle")}
              </div>
              <div className="description">
                {t("clientSites.invitationEmailDescription")}
              </div>
            </div>
          </Col>
          <Col>
            <Form.Item name="canSendInvitationEmail" valuePropName="checked">
              <Switch></Switch>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default CreateSiteForm;
