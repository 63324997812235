import { atom, DefaultValue, selector } from "recoil";
import { LOCAL_COOKIE_KEY } from "../constants/app-constants";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import { ROLES } from "../constants/permission.constants";
import { ClientModel, FunctionModel, UserProfileModel } from "../models/user";
import LocalUtils from "../utils/local.utils";
import { CommonUtils } from "../utils/common";

interface AuthState {
  isLogined: boolean;
  user: UserProfileModel | undefined;
  error?: string;
}

const authState = atom<AuthState>({
  key: APP_STATE_KEYS.AUTH_STATE,
  default: {
    isLogined: LocalUtils.getCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN) === "true",
    user: LocalUtils.getUserInfo(),
    error: "",
  },
});

const authUserSelector = selector<UserProfileModel | undefined>({
  key: "authUserSelector",
  get: ({ get }) => {
    const { user } = get(authState);
    return user;
  },
  set: ({ get, set }, value) => {
    const state = get(authState);
    const { user } = state;

    if (user && value) {
      const newUser = { ...user, ...value };
      LocalUtils.setUserInfo(newUser);
      set(authState, {
        ...state,
        user: newUser,
      });
    }
  },
});

const authClientSelector = selector<ClientModel | undefined>({
  key: "authClientSelector",
  get: ({ get }) => {
    const state = get(authState);
    const { user } = state;
    if (user?.clientSelected) {
      return user.clientSelected;
    }

    if (user && user.clients && user.clients.length) {
      return user.clients[0];
    }

    return undefined;
  },
  set: ({ set, get }, newValue) => {
    const state = get(authState);
    const { user } = state;

    let clientUpdated: ClientModel | undefined = undefined;
    if (newValue && !(newValue instanceof DefaultValue)) {
      clientUpdated = newValue;
    }

    if (user && clientUpdated) {
      let newClients = CommonUtils.deepCopy(user.clients) || [];
      for (let index = 0; index < newClients.length; index++) {
        const element = newClients[index];
        if (element.id === clientUpdated?.id) {
          newClients[index] = { ...element, ...clientUpdated };
          clientUpdated = newClients[index];
        }
      }

      const newUser = { ...user, clients: newClients, clientSelected: clientUpdated };
      LocalUtils.setUserInfo(newUser);
      
      set(authState, {
        ...state,
        user: newUser,
      });
    }
  },
});

const authFunctionsSelector = selector<FunctionModel[]>({
  key: "authFunctionsSelector",
  get: ({ get }) => {
    const { user } = get(authState);
    return user && user.functions ? user.functions : [];
  },
});

const authRoleSelector = selector<string>({
  key: "authRoleSelector",
  get: ({ get }) => {
    const { user } = get(authState);
    return user && user.roles && user.roles.length ? user.roles[0].name : "";
  },
});

const authIsSupperAdminSelector = selector<boolean>({
  key: "authIsSupperAdminSelector",
  get: ({ get }) => {
    const role = get(authRoleSelector);
    return role === ROLES.supperAdmin;
  },
});

const authErrorSelector = selector<string | undefined>({
  key: "authErrorSelector",
  get: ({ get }) => {
    const { error } = get(authState);
    return error;
  },
  set: ({ set, get }, newValue) => {
    const state = get(authState);

    let error = "";
    if (newValue && !(newValue instanceof DefaultValue)) {
      error = newValue;
    }

    set(authState, {
      ...state,
      error: error,
    });
  },
});

const profileUpdatedState = atom<boolean>({
  key: APP_STATE_KEYS.PROFILE_UPDATED_STATE,
  default: false
});

export {
  authState,
  authClientSelector,
  authFunctionsSelector,
  authRoleSelector,
  authIsSupperAdminSelector,
  authErrorSelector,
  authUserSelector,
  profileUpdatedState
};
