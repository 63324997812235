import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Dropdown, MenuProps, Modal, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { PlanApi } from "../../../apis/plan.api";
import { StripesApi } from "../../../apis/stripes.api";
import showNotification from "../../../components/common/notification";
import SVGIcons from "../../../components/icons/svgs";
import SuccessUpgradeModal from "../../../components/layouts/countdown/success-upgrade-modal";
import UpgradeModal from "../../../components/layouts/countdown/upgrade-modal";
import { BILLING_SUBJECT, DATE_TIME_FORMAT, SUPPORT_EMAIL } from "../../../constants/app-constants";
import { CycleType, PlanType, UserPlanStatus } from "../../../constants/app.enums";
import useClient from "../../../hooks/useClient";
import useClientPlan from "../../../hooks/useClientPlan";
import { GetClientUsageResponse, GetPlanByTypeResponse } from "../../../models/plan.model";
import { authUserSelector } from "../../../states/auth";
import DateUtils from "../../../utils/date.utils";
import "./index.scss";
import moment from 'moment';
import SpinDiv from '../../../components/common/spin-div';
type UpgradeToPremiumBtnProps = {
    isFreePlan?: boolean
}

interface BillingProps {
    usage: GetClientUsageResponse | undefined;
}

const Billing = (props: BillingProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { t } = useTranslation();
    const { isPlanExpired, isFreeTrial, isPremiumPlan, isFreePlan } = useClientPlan()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isGetManageBillingUrlLoading, setIsGetManageBillingUrlLoading] = useState<boolean>(false)
    const [data, setData] = useState<GetPlanByTypeResponse>()
    const { getUpgradePremiumUrl, isGetPremiumUrlLoading, userPlan, setCanceledPlan, planStatus } = useClientPlan();
    const { isExternalUser, clientId, isAdminViewSite, timezoneId, client } = useClient();
    const { usage } = props;
    const supportUrl = `mailto:${SUPPORT_EMAIL}?subject=${BILLING_SUBJECT}${client?.companyName}`;
    const [user, setUser] = useRecoilState(authUserSelector);
    const [isOpenCancel, setIsOpenCancel] = useState<boolean>(false)
    const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false)
    const [isOpenPaymentProcessing, setIsOpenPaymentProcessing] = useState<boolean>(false)

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownVisibleChange = (visible: boolean) => {
        setDropdownOpen(visible);
    };

    const renewTime = DateUtils.getDateWithTimezone(usage?.renewTime, timezoneId).format(DATE_TIME_FORMAT.viewFormat);
    const expiredTime = DateUtils.getDateWithTimezone(userPlan?.expiredTime, timezoneId).format(DATE_TIME_FORMAT.viewFormat);
    const renewTimeWithCanceled = DateUtils.getDateWithTimezone(moment().add(1, 'M'), timezoneId).format(DATE_TIME_FORMAT.viewFormat);

    const openModal = () => {
        setIsOpen(true);
    };

    const getManageBillingUrl = async () => {

        if (isExternalUser() || isAdminViewSite) { return; }

        setIsGetManageBillingUrlLoading(true)
        try {
            const { data } = await StripesApi.getManageBillingUrl()
            window.location.href = data.url;
        } finally {
            setIsGetManageBillingUrlLoading(false)
        }
    }

    const onCancelPlan = async () => {
        if (clientId) {
            setIsCancelLoading(true)

            try {
                const res = await PlanApi.cancelPlan(clientId);
                if (res?.data && user && client) {
                    user && setUser({ ...user, clientSelected: { ...client, userPlan: res.data } });
                }

                showNotification(
                    "delete",
                    t("usageAndBilling.cancelSuccessMsg")
                );
                setIsOpenCancel(false);
            } catch (error) {
                console.error(error);
            }

            setIsCancelLoading(false);
        }
    }

    const cancelPlan = () => {
        setDropdownOpen(false);
        setIsOpenCancel(true)
    }

    const confirmRenewPlan = () => {
        setDropdownOpen(false);
        Modal.confirm({
            className: "modal-radius-4",
            title: t("usageAndBilling.confirmRenewTitle"),
            content: <span>{t("usageAndBilling.confirmRenewDes", { time: planStatus === UserPlanStatus.Canceled ? renewTimeWithCanceled : renewTime })}</span>,
            okText: t("usageAndBilling.renewNow"),
            cancelText: t("common.cancel"),
            cancelButtonProps: { type: "text" },
            icon: "",
            centered: true,
            onOk: renewPlan
        });
    }

    const renewPlan = async () => {
        if (!clientId) { return };
        try {
            const res = await PlanApi.renewPlan(clientId, userPlan?.planType);
            if (res?.data) {

                if (res.data.isError) {
                    showNotification(
                        "error",
                        res.data.message
                    );
                } else if (res.data.isCompleted) {
                    if (user && client && res.data.userPlan) {
                        setUser({ ...user, clientSelected: { ...client, userPlan: res.data.userPlan } })
                    };
                    showNotification(
                        "success",
                        t("usageAndBilling.renewPlanSuccessMessage")
                    );
                } else if (res.data.redirectUrl) {
                    window.location.href = res.data.redirectUrl;
                } else {
                    setIsOpenPaymentProcessing(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000)
                }
            }
        } catch (error) { }
    }

    const WhatIsPlanBtn = () => {
        return <div onClick={openModal} className="what-in-plan" >{t("usageAndBilling.whatInPremiumPlan")}</div>
    }

    // const AddPaymentBtn = () => {
    //     return <div className="action-button" >
    //         <Button
    //             type="primary"
    //             className="secondary-btn btn-connect"
    //         >
    //             <span className="credit-icon" ><SVGIcons.CreditIcon /></span>
    //             {t("usageAndBilling.addPaymentMethod")}
    //         </Button>
    //     </div>
    // }


    const UpgradeToPremiumBtn = (props: UpgradeToPremiumBtnProps) => {
        return <div className="action-button" >
            <Button
                loading={isGetPremiumUrlLoading}
                type="primary"
                onClick={getUpgradePremiumUrl}
                className={props.isFreePlan ? "secondary-btn btn-connect" : undefined}
            >
                <span className="credit-icon" ><SVGIcons.ArrowBigUpLine /></span>
                {t("upgradeModal.upgradeToPremium")}
            </Button>
        </div>
    }
    const ManageBillingBtn = () => {
        return <div className="action-button" >
            <Button
                onClick={getManageBillingUrl}
                loading={isGetManageBillingUrlLoading}
                type="primary"
            >
                <span className="credit-icon" ><SVGIcons.EditIcon /></span>
                {t("usageAndBilling.manageBilling")}
            </Button>
        </div>
    }

    const getPriceAndCycle = () => {
        if (data) {

            let extraDes;

            if (userPlan && userPlan?.planType !== PlanType.Trial) {

                switch (userPlan?.status) {
                    case UserPlanStatus.Canceled:
                        extraDes = t("usageAndBilling.planWasBeCanceled", {
                            time: renewTime,
                        });
                        break;
                    case UserPlanStatus.PendingCancel:
                        extraDes = t("usageAndBilling.planWillBeCanceled", {
                            time: renewTime,
                        });
                        break;
                    case UserPlanStatus.Active:
                        if (userPlan?.planType === PlanType.Premium) {
                            extraDes = t("usageAndBilling.planRenew", {
                                time: renewTime,
                            })
                        }
                        break;
                    default:
                        break;
                }

            }


            let cycle = '';

            switch (data.cycleType) {
                case CycleType.Monthly:
                    cycle = 'month';
                    break;
                case CycleType.Weekly:
                    cycle = 'week';
                    break;

                default:
                    break;
            }

            let des = '$' + data.price;

            if (cycle) {
                des = des + ' per ' + cycle + '.';
            }

            if (extraDes) {
                if (!des.endsWith('.')) {
                    des = des + '.'
                }
                des = des + ' ' + extraDes;
            }

            return des;
        }
    }

    const contactSupport = () => {
        if (dropdownOpen) {
            setDropdownOpen(false);
        }

        if (isOpenCancel) {
            setIsOpenCancel(false);
        }
        window.open(supportUrl, "_blank");
    }

    const DropdownMenu = () => {
        let items: MenuProps['items'] = []

        items = [
            {
                key: '1',
                onClick: () => contactSupport(),
                label: t("usageAndBilling.contactSupport"),
            },
        ];

        if (!isFreeTrial && userPlan && planStatus !== UserPlanStatus.Canceled && planStatus !== UserPlanStatus.PendingCancel) {
            items.push({
                key: '2',
                onClick: () => cancelPlan(),
                label: t("usageAndBilling.cancelPlan"),
            })
        }

        if (!isFreeTrial && userPlan && (planStatus === UserPlanStatus.Canceled || planStatus === UserPlanStatus.PendingCancel)) {
            items.push({
                key: '3',
                onClick: () => confirmRenewPlan(),
                label: t("usageAndBilling.renewPlan"),
            })
        }

        return <Dropdown open={dropdownOpen} onOpenChange={handleDropdownVisibleChange} trigger={['click']} menu={{ items }} placement="bottomRight" arrow>
            <Button className="dropdown-btn" icon={<EllipsisOutlined className={dropdownOpen ? 'dropdown-icon clicked' : 'dropdown-icon'} />} />
        </Dropdown>
    }

    const renderButtons = () => {
        if (!userPlan) {
            return <>
                <WhatIsPlanBtn />
                <UpgradeToPremiumBtn isFreePlan />
            </>
        }
        if (isFreeTrial) {
            return <>
                <WhatIsPlanBtn />
                <UpgradeToPremiumBtn />
                <DropdownMenu />
            </>
        }
        if (isPremiumPlan) {
            return <>
                <ManageBillingBtn />
                <DropdownMenu />
            </>;
        }

        if (isFreePlan) {
            return (
                <>
                    <WhatIsPlanBtn />
                    <UpgradeToPremiumBtn isFreePlan />
                    <ManageBillingBtn />
                    <DropdownMenu />
                </>
            );
        }
        return null;
    };

    const getPlan = async () => {
        if (userPlan) {
            setIsLoading(true);
            try {
                const { data } = await PlanApi.getPlanByType(userPlan.planType);
                setData(data)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const getPlanNameAndIcon = () => {
        if (data) {
            switch (data.type) {
                case PlanType.Free:
                    return {
                        name: t("usageAndBilling.freePlan"),
                        avatar: <SVGIcons.FreePlanIcon />
                    }

                case PlanType.Trial:
                    return {
                        name: t("usageAndBilling.freeTrial"),
                        avatar: <SVGIcons.FreeTrialIcon />
                    }
                case PlanType.Premium:
                    return {
                        name: t("usageAndBilling.premium"),
                        avatar: <SVGIcons.PremiumPlanIcon />
                    }

                default:
                    return {
                        name: '',
                        avatar: ''
                    }
            }
        }
    }

    useEffect(() => {
        getPlan()
    }, [userPlan])

    return (
        <div className="billing" >
            <Card >
                <div className="billing__heading">
                    {t("usageAndBilling.billing")}
                </div>
                <Divider className="billing__divider" />
                <div className="billing__wrapper" >
                    {isLoading ? (
                        <Skeleton.Input size="small" block />
                    ) : (
                        <>
                            {
                                userPlan?.planType === PlanType.Trial &&
                                (
                                    isPlanExpired ? (
                                        <div className="info expired">
                                            <SVGIcons.ClockHour4Filled />
                                            {t("usageAndBilling.freeTrialEnded")}
                                        </div>
                                    ) :
                                        (
                                            <div className="info">
                                                <SVGIcons.ClockHour4Filled />
                                                {t("usageAndBilling.freeTrialEnd", {
                                                    time: expiredTime,
                                                })}
                                            </div>
                                        )
                                )
                            }

                            {
                                planStatus === UserPlanStatus.Canceled &&
                                <div className="info canceled">
                                    <SVGIcons.XCloseIcon />
                                    {t("usageAndBilling.canceledPlan")}
                                </div>
                            }

                            {userPlan?.planType === PlanType.Premium &&
                                userPlan?.isPaymentError && (
                                    <div className="info renewal-failed">
                                        <SVGIcons.RedWarningIcon />
                                        {t("usageAndBilling.renewalFailed")}
                                    </div>
                                )}
                        </>

                    )}

                    {
                        isLoading ? <div className="billing__skeleton" >
                            <Skeleton.Avatar size='large' shape='circle' />
                            <div className="billing__skeletonInput">
                                <Skeleton.Input size='large' block />
                            </div>
                        </div> :
                            (
                                <div className={userPlan ? "plan" : "plan none"} >
                                    {
                                        userPlan ? <div className="left">
                                            <div>
                                                {getPlanNameAndIcon()?.avatar}
                                            </div>
                                            <div>
                                                <div className="plan-name" >
                                                    {getPlanNameAndIcon()?.name}
                                                </div>
                                                <div className="plan-amount">
                                                    {getPriceAndCycle()}
                                                </div>
                                            </div>
                                        </div> :
                                            <ManageBillingBtn />
                                    }

                                    <div className="right" >
                                        {renderButtons()}
                                    </div>
                                </div>
                            )
                    }

                </div>
            </Card>

            <UpgradeModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <SuccessUpgradeModal />
            <Modal
                width={480}
                open={isOpenCancel}
                onCancel={() => setIsOpenCancel(false)}
                onOk={() => window.open(supportUrl)}
                closable={true}
                maskClosable
                title={t("usageAndBilling.cancelYourPlan")}
                centered={true}
                className="custom-create-modal"
                style={{ maxWidth: "90%" }}
                footer={
                    <div className="remove-connection-footer">
                        <Button loading={isCancelLoading} onClick={() => onCancelPlan()}>
                            {t("usageAndBilling.noCancel")}
                        </Button>
                        <Button type="primary" onClick={() => contactSupport()}>
                            {t("usageAndBilling.yesCancel")}
                        </Button>
                    </div>
                }
            >
                <span dangerouslySetInnerHTML={{
                    __html: t("usageAndBilling.cancelPlanPopupDes", {
                        time: renewTime
                    })
                }} />
            </Modal>

            <Modal
                width={470}
                open={isOpenPaymentProcessing}
                closable={false}
                maskClosable={false}
                title={null}
                centered={true}
                className="modal-radius-4"
                style={{ textAlign: "center" }}
                footer={null}
            >
                <SpinDiv loading={true} style={{ height: 60 }}>
                    <></>
                </SpinDiv>
                <h3 style={{ marginTop: 25 }}>Payment Processing</h3>
                <p>Please wait a moment while we process your payment. It might take a few seconds.</p>
            </Modal>
        </div>
    );
};

export default Billing;
