// Rocket Level
// const awsConfig = {
//     "aws_project_region": "us-east-2",
//     "aws_cognito_region": "us-east-2",
//     "aws_user_pools_id": "us-east-2_MAuhBiGiv",
//     "aws_user_pools_web_client_id": "5ik0glvb6mbg9evskeqc71rhko",
//     "oauth": {
//         "domain": "rocketlevel-development.auth.us-east-2.amazoncognito.com",
//         "scope": [
//             "email",
//             "openid"
//         ],
//         "redirectSignIn": "http://localhost:3000",
//         "redirectSignOut": "http://localhost:3000/sign-in",
//         "responseType": "code"
//     }
// };

// Rocket Level v2
import {AppConfig} from "./configs/app.config"

const awsConfig = AppConfig.awsConfig;

export default awsConfig;
