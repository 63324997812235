import { Button, Checkbox, Dropdown, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  ApprovalStatusEnum,
  BulkApprovalsStatus,
  ClientCategory,
  PlanType,
  PostStatusType,
  PostType,
  SocialType,
} from "../../../constants/app.enums";
import { FilterModel } from "../../../models/common.model";
import SVGIcons from "../../icons/svgs";
import "./index.scss";

const { Option } = Select;

interface FilterListData {
  [key: string]: {
    name: string;
    value:
    | string
    | ClientCategory
    | SocialType
    | PostStatusType
    | ApprovalStatusEnum
    | PostType
    | BulkApprovalsStatus
    | PlanType;
    color?: string;
  }[];
}

interface FilterListProps {
  data: FilterListData;
  onChange?: (values: FilterModel<any>) => void;
  disabled?: boolean;
}

function FilterList(props: FilterListProps) {
  const { data, onChange, disabled } = props;
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();

  const [formValue, setFormValue] = useState<any>({});
  const [listFilterText, setListFilterText] = useState<string>("");
  const [openDropdown, setOpenDropdown] = useState(false);

  const onChangeForm = (value: any) => {
    setFormValue({ ...formValue, ...value });
  };

  const clearAll = () => {
    form.resetFields();
    setFormValue({});
  };

  const applyFilter = () => {
    if (onChange) onChange(formValue);
    setListFilterText(getListFilterText(formValue));
    setOpenDropdown(false);
  };
  const renderFilter = () => {
    return (
      <div className="filter-list">
        <Form
          name="filter-list"
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          form={form}
          onValuesChange={onChangeForm}
        >
          {Object.keys(data).map((key) => {
            return (
              <Form.Item label={t(`filter.${key}`)} name={key} key={key}>
                <Select
                  placeholder="Select an option"
                  mode="multiple"
                  menuItemSelectedIcon={null}
                  tagRender={(props: any) => {
                    const { name, color } =
                      data[key].find((e) => e.value === props.value) || {};

                    const planRender = `<span
                      class='client-sites-page__plan ${color}'
                      >${name}</span>`

                    if (key === "tagIds") {
                      return (
                        <span
                          className="tags-text"
                          style={{
                            backgroundColor: `${color}19`,
                            color: `${color}`,
                          }}
                          key={name}
                        >
                          {name}
                        </span>
                      );
                    }

                    if (key === "plan") {
                      return (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: planRender,
                          }}
                        ></span>
                      );
                    }
                    return (
                      <div key={props.value}>
                        <div key={props.value}>{`${name}${props.value !==
                          formValue[key][formValue[key].length - 1]
                          ? ", "
                          : ""
                          }`}</div>
                      </div>
                    );
                  }}
                  popupClassName="filter-list-select"
                  showArrow={true}
                  suffixIcon={<SVGIcons.ChevronDown />}
                >
                  {data[key].map((item) => {
                    const planRender = `<span
                        class='client-sites-page__plan ${item.color}'
                      >${item.name}</span>`

                    if (key === "tagIds") {
                      const getColor = item.color;
                      return (
                        <Option value={item.value} key={item.value}>
                          <Checkbox
                            checked={
                              formValue[key] &&
                              formValue[key].includes(item.value)
                            }
                          />{" "}
                          <span
                            className="tags-text"
                            style={{
                              backgroundColor: `${getColor}19`,
                              color: `${getColor}`,
                            }}
                          >
                            {item.name}
                          </span>
                        </Option>
                      );
                    }
                    return (
                      <Option value={item.value} key={item.value}>
                        <div className="filter-list-select-option">
                          <div>
                            <Checkbox
                              checked={
                                formValue[key] &&
                                formValue[key].includes(item.value)
                              }
                            />
                          </div>
                          <div
                            className="filter-list-select-option-name"
                            dangerouslySetInnerHTML={{
                              __html: key === "plan" ? planRender : item.name,
                            }}
                          ></div>

                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          })}
        </Form>

        <div className="group-btn">
          <Button type="text" onClick={clearAll}>
            {t("filter.clearAll")}
          </Button>
          <Button type="primary" onClick={applyFilter}>
            {t("filter.apply")}
          </Button>
        </div>
      </div>
    );
  };

  const getListFilterText = (listFilter: any) =>
    (
      Object.keys(listFilter).reduce((result: any, key: any) => {
        const newValue = listFilter[key].map(
          (v: any) => data[key].find((d) => v === d.value)?.name
        );
        return [...result, ...newValue];
      }, []) as string[]
    ).join(",");

  const onOpenChange = (open: boolean) => {
    setOpenDropdown(open);
  };

  useEffect(() => {
    if (searchParams.has("postType")) {
      const postTypeParams = Number(searchParams.get("postType"));
      setListFilterText(
        getListFilterText({ ...formValue, postTypes: [postTypeParams] })
      );
      setFormValue({ ...formValue, postTypes: [postTypeParams] });
      form.setFieldValue("postTypes", [postTypeParams]);
    }
  }, [searchParams]);

  return (
    <Dropdown
      className="dropdown-filter"
      overlayClassName="dropdown-filter-overlay"
      overlay={renderFilter()}
      trigger={["click"]}
      placement="bottomRight"
      open={openDropdown}
      onOpenChange={onOpenChange}
      disabled={disabled}
    >
      <Button>
        {t("common.filterPlahoder")}
        {listFilterText && `: ${listFilterText}`}
        <SVGIcons.ChevronDown />
      </Button>
    </Dropdown>
  );
}

export default FilterList;
