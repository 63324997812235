import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AccountVerificationError from "../../components/views/account-verification/error";
import AccountVerificationSuccess from "../../components/views/account-verification/success";
import { CommonUtils } from "../../utils/common";
import { AuthApi } from "../../apis/auth.api";

type AccountVerificationStatus = "None" | "Success" | "Error";

function AccountVerificationPage() {
  const [searchParams] = useSearchParams();
  const email = CommonUtils.queryParamReplaceSpace(searchParams.get("email") || "");

  const code = searchParams.get("confirmation_code") || "";
  const [status, setStatus] = useState<AccountVerificationStatus>("None");

  useEffect(() => {
    if (email && code) {
      AuthApi.verifyAccount(email, code)
        .then(() => {
          setStatus("Success");
        })
        .catch(() => {
          setStatus("Error");
        });
    } else {
      setStatus("Error");
    }
  }, []);

  return (
    <>
      {status === "None" ? (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        ></Spin>
      ) : status === "Success" ? (
        <AccountVerificationSuccess />
      ) : (
        <AccountVerificationError />
      )}
    </>
  );
}

export default AccountVerificationPage;
