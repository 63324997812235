import { Button, Checkbox, Dropdown } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import {
  FunctionEnum,
  FUNCTIONS,
} from "../../../../constants/permission.constants";
import { SelectFunctionModel } from "../../../../models/common.model";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";

interface Props {
  value?: FunctionEnum[];
  disabled?: boolean;
  onChange?: any;
}

function SelectPermissons(props: Props) {
  const { value, disabled, onChange } = props;
  const [selected, setSelected] = useState<SelectFunctionModel[]>([]);
  const [displayText, setDisplayText] = useState("");
  useEffect(() => {
    if (value && value.length) {
      const newItems = FUNCTIONS.filter(
        (x) =>
          value.includes(x.code) ||
          (value.includes(FunctionEnum.Everything) &&
            x.code !== FunctionEnum.Nothing)
      );
      setSelected(newItems);
    }
  }, [value]);

  useEffect(() => {
    if (selected && selected.length) {
      let newText = "";

      const everyThing = selected.find(
        (x) => x.code === FunctionEnum.Everything
      );
      if (everyThing) {
        newText = everyThing.name;
      } else {
        selected.forEach((item) => {
          newText = newText ? newText + `, ${item.name}` : item.name;
        });
      }

      setDisplayText(newText);
    } else {
      setDisplayText("");
    }
  }, [selected]);

  function filterChange(e: CheckboxChangeEvent, item: SelectFunctionModel) {
    let newFilter = [...selected];
    if (e.target.checked) {
      if (item.code === FunctionEnum.Everything) {
        newFilter = FUNCTIONS.filter((x) => x.code !== FunctionEnum.Nothing);
      } else if (item.code === FunctionEnum.Nothing) {
        newFilter = FUNCTIONS.filter((x) => x.code === FunctionEnum.Nothing);
      } else {
        newFilter = newFilter.filter((x) => x.code !== FunctionEnum.Nothing);
        newFilter.push(item);
        if (newFilter.length === FUNCTIONS.length - 2) {
          const everyThing = FUNCTIONS.find(
            (x) => x.code === FunctionEnum.Everything
          );

          if (everyThing) {
            newFilter.push(everyThing);
          }
        }
      }
    } else {
      newFilter = selected.filter(
        (x) => x.code !== item.code && x.code !== FunctionEnum.Everything
      );

      if (item.code === FunctionEnum.Everything) {
        newFilter = [];
      }

      if (item.code === FunctionEnum.Nothing) {
        newFilter = FUNCTIONS.filter((x) => x.code !== FunctionEnum.Nothing);
      }

      if (newFilter.length === 0) {
        const noThing = FUNCTIONS.find((x) => x.code === FunctionEnum.Nothing);

        if (noThing) {
          newFilter.push(noThing);
        }
      }
    }
    setSelected(newFilter);
    onChange(newFilter.map((e) => e.code));
  }

  function renderCheckboxItem(item: SelectFunctionModel) {
    return (
      <div className="filter-item permission-item" key={item.code}>
        <Checkbox
          onChange={(e) => filterChange(e, item)}
          checked={selected.some((s) => s.code === item.code)}
        >
          {item.name}
        </Checkbox>
      </div>
    );
  }

  const renderFilter = () => {
    return (
      <div className="filter-wrapper">
        <div className="filter-section">
          {FUNCTIONS.map((x) => renderCheckboxItem(x))}
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      className="select-permissons"
      overlay={renderFilter()}
      trigger={["click"]}
      disabled={disabled}
    >
      <Button className="select-permission-btn">
        {displayText}
        <SVGIcons.ChevronDown />
      </Button>
    </Dropdown>
  );
}

export default SelectPermissons;
