import moment from "moment";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {
  DATE_TIME_FORMAT,
  LIMIT_CHARACTER_FB,
  LIMIT_CHARACTER_GOOGLE,
  LIMIT_CHARACTER_INS,
  LIMIT_CHARACTER_TIKTOK,
  LIMIT_CHARACTER_YOUTUBE,
} from "../../../../../constants/app-constants";
import {
  ApprovalStatusEnum,
  ContentErrorType,
  GmbActionType,
  GmbPostType,
  PlanUsageType,
  PostStatusType,
  SocialExtendReelType,
  SocialReelType,
  SocialType,
  SubmitType,
} from "../../../../../constants/app.enums";
import useClient from "../../../../../hooks/useClient";
import {
  contentValueState,
  CreateContentState,
  currentContentSelector,
  listPlatformContentSelector,
  SocialContentState,
} from "../../../../../states/social";
import showNotification from "../../../../common/notification";
import useService from "../../../../../hooks/useService";
import MediaUtils from "../../../../../utils/media.utils";
import { CommonUtils } from "../../../../../utils/common";
import momentTimezone from "moment-timezone";
import DateUtils from "../../../../../utils/date.utils";
import SocialUtils from "../../../../../utils/social";
import useClientPlan from "../../../../../hooks/useClientPlan";
import { ContentMediaModel } from "../../../../../models/content.model";

const useUpdateContent = () => {
  const { t } = useTranslation();
  const { clientId, client, timezoneId } = useClient();
  const { SocialService } = useService();
  const [listPlatformContent, setListPlatformContent] = useRecoilState(
    listPlatformContentSelector
  );
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const currentContent = useRecoilValue(currentContentSelector);
  const resetContentValue = useResetRecoilState(contentValueState);
  const { checkPremiumPlan } = useClientPlan();

  const { status, scheduleOn, isSyncContent } = contentValue;

  const getPlatformContent = (platform: SocialExtendReelType) => {
    return listPlatformContent.find((c) => c.platformExtendReel === platform);
  };

  const facebookContent = getPlatformContent(SocialType.Facebook);
  const facebookReelContent = getPlatformContent(SocialReelType.FacebookReel);
  const instagramContent = getPlatformContent(SocialType.Instagram);
  const instagramReelContent = getPlatformContent(SocialReelType.InstagramReel);
  const tiktokContent = getPlatformContent(SocialType.Tiktok);
  const youtubeContent = getPlatformContent(SocialType.Youtube);
  const youtubeShortContent = getPlatformContent(SocialReelType.YoutubeShort);
  const googleContent = getPlatformContent(SocialType.Google);

  const updateContents = async (
    contentType: SocialExtendReelType | "all",
    updateType: keyof SocialContentState,
    value: any
  ) => {
    const listPlatformContentClone = [...listPlatformContent];
    if (contentType === "all") {
      listPlatformContent.forEach((content) => {
        !content?.disabled &&
          handleUpdateContent(
            content.platformExtendReel,
            listPlatformContentClone,
            updateType,
            value
          );
      });
    } else
      handleUpdateContent(
        contentType,
        listPlatformContentClone,
        updateType,
        value
      );

    setListPlatformContent(listPlatformContentClone);
  };

  const handleUpdateContent = (
    platform: SocialExtendReelType,
    listPlatformContentClone: SocialContentState[],
    updateType: keyof SocialContentState,
    value: any
  ) => {
    const contentIdx = listPlatformContentClone.findIndex(
      (c) => c.platformExtendReel === platform
    );
    if (contentIdx === -1) return;
    /// update content
    listPlatformContentClone[contentIdx] = {
      ...listPlatformContentClone[contentIdx],
      [updateType]: value,
    };
    // check error
    if (updateType !== "errors") {
      listPlatformContentClone[contentIdx] = {
        ...listPlatformContentClone[contentIdx],
        errors: checkValidateContent(listPlatformContentClone[contentIdx]),
      };
    }
  };

  const setContent = (key: any) => {
    setContentValue({
      ...contentValue,
      status: Number(key),
      scheduleOn:
        Number(key) === SubmitType.Schedule
          ? DateUtils.convertTimeToTimezone(timezoneId).utc().format()
          : scheduleOn,
    });
  };

  const onChangeMenu = (key: any) => {
    const menuKey = Number(key);
    if (menuKey === SubmitType.Publish || menuKey === SubmitType.Schedule) {
      checkPremiumPlan(() => {
        setContent(menuKey);
      }, PlanUsageType.PostPerCom);
    } else {
      setContent(menuKey);
    }
  };

  const onChangeDate = (values: string) => {
    setContentValue({ ...contentValue, scheduleOn: values });
  };

  const onChangeSync = async (value: boolean) => {
    if (!currentContent) return;
    // when off sync
    if (!value)
      setContentValue({
        ...contentValue,
        isSyncContent: value,
      });
    // when on sync
    else {
      // validate media
      let isValidateMedia = await CommonUtils.everyAsync<
        SocialContentState | undefined
      >(listPlatformContent, async (content) => {
        if (content?.disabled) return true;
        return await isValidMedias(
          currentContent?.medias || [],
          content?.platformExtendReel
        );
      });
      if (!isValidateMedia) return;
      // apply currentContent for all content
      const { post, medias, title } = currentContent;
      const newPlatformContents = listPlatformContent.map((c) => {
        return { ...c, post, medias, title };
      });
      setContentValue({
        ...contentValue,
        platformContents: newPlatformContents,
        isSyncContent: value,
      });
    }
  };

  const onChangeStatusAvatar = async (content: SocialContentState) => {
    const contentUpdate = { ...content };
    const { platform, disabled } = contentUpdate;

    let newListContent = [...listPlatformContent].map((content) => {
      return content && content.platform === platform
        ? { ...content, disabled: !content.disabled }
        : content;
    });
    if (newListContent.every((content) => content?.disabled)) {
      showNotification(
        "warning",
        "You need to choose at least 1 social network!"
      );
      return;
    }
    if (isSyncContent && disabled) {
      if (!(await isValidMedias(currentContent?.medias || [], platform)))
        return;
      contentUpdate.post = currentContent?.post;
      contentUpdate.medias = currentContent?.medias;
      contentUpdate.platform = platform;
      newListContent = [...newListContent].map((content) => {
        return content && content.platform === platform
          ? {
              ...content,
              post: currentContent?.post,
              medias: currentContent?.medias,
            }
          : content;
      });
    }
    setContentValue({
      ...contentValue,
      platformContents: newListContent,
      currentSocial:
        platform === contentValue.currentSocial
          ? newListContent.find((content) => !content?.disabled)?.platform ||
            SocialType.Facebook
          : contentValue.currentSocial,
    });
  };

  const isValidContent = async () => {
    if (status === SubmitType.Schedule) {
      if (DateUtils.isBeforeUTC(momentTimezone.utc(scheduleOn))) {
        showNotification("error", "Schedule time cannot be in the past!");
        return false;
      }
    }
    if (status === SubmitType.Schedule || status === SubmitType.Publish) {
      const listDraftContentEnable = listPlatformContent.filter(
        (c) => !c.profile && !c.disabled
      );
      if (listDraftContentEnable.length) {
        const listPlatformString = listDraftContentEnable.map((c) =>
          SocialUtils.getSocialName(c.platform)
        );
        showNotification(
          "error",
          `Connect your ${listPlatformString.join(
            ", "
          )} profiles or disable them in order to schedule and publish content.`
        );
        return false;
      }
    }
    // validate gmb update url
    if (currentContent?.platform === SocialType.Google) {
      if (
        currentContent.gmbActionType &&
        ![GmbActionType.None, GmbActionType.CallNow].includes(
          currentContent.gmbActionType
        )
      ) {
        if (!currentContent.gmbActionUrl) {
          onValuesPlatformChange({
            gmbActionUrlError: "Link For Your Button field is required.",
          });
          return false;
        }
        if (!CommonUtils.isValidUrl(currentContent.gmbActionUrl)) {
          onValuesPlatformChange({
            gmbActionUrlError: "Invalid URL format.",
          });
          return false;
        }
      }
    }
    // validate content
    const errors = checkValidate(contentValue);
    if (errors.length > 0) return false;
    return true;
  };

  const createContent = async () => {
    setContentValue({ ...contentValue, loadingSubmit: true });
    let isSussess = false;
    try {
      const res = await SocialService.createContent(clientId);
      if (!res?.data) return false;
      const { status } = res.data;
      const scheduledDate = client?.timeZoneInfo?.id
        ? momentTimezone
            .utc(contentValue.scheduleOn)
            .tz(client?.timeZoneInfo?.id)
        : moment(contentValue.scheduleOn);

      switch (contentValue.status) {
        case SubmitType.Save:
          showNotification(
            "success",
            t("social.content.createDraftContentSuccess")
          );
          break;
        case SubmitType.Schedule:
          showNotification(
            "success",
            t("social.content.scheduleNowSuccess", {
              time: scheduledDate.format(DATE_TIME_FORMAT.dateTimeViewFormatPM),
            })
          );
          break;
        case SubmitType.Publish:
          if (status === PostStatusType.Error)
            showNotification("error", t("social.content.failToPublish"));
          else
            showNotification("success", t("social.content.publicNowSuccess"));
          break;
        default:
          break;
      }
      resetContentValue();
      isSussess = true;
    } catch (error) {
      isSussess = false;
    }
    setContentValue({ ...contentValue, loadingSubmit: false });
    return isSussess;
  };

  const editContent = async (contentId: number) => {
    setContentValue({ ...contentValue, loadingSubmit: true });
    let isSussess = false;
    try {
      const res = await SocialService.createContent(clientId, true, contentId);
      if (!res?.data) return false;
      const { status, date } = res.data;

      const scheduledDate = client?.timeZoneInfo?.id
        ? momentTimezone.utc(date).tz(client?.timeZoneInfo?.id)
        : moment(date);

      switch (status) {
        case PostStatusType.Drafted:
          showNotification("success", t("social.content.saveContentSuccess"));
          break;
        case PostStatusType.Scheduled:
          showNotification(
            "success",
            t("social.content.scheduleNowSuccess", {
              time: scheduledDate.format(DATE_TIME_FORMAT.dateTimeViewFormatPM),
            })
          );
          break;
        case PostStatusType.Posted:
          if (contentValue.status === SubmitType.Save)
            showNotification("success", t("social.content.saveContentSuccess"));
          else
            showNotification("success", t("social.content.publicNowSuccess"));
          break;
        case PostStatusType.Error:
          if (contentValue.status === SubmitType.Save)
            showNotification("success", t("social.content.saveContentSuccess"));
          else showNotification("error", t("social.content.failToPublish"));
          break;
        default:
          break;
      }
      setContentValue({ ...contentValue, loadingSubmit: false });
      resetContentValue();
      isSussess = true;
    } catch (error) {
      setContentValue({ ...contentValue, loadingSubmit: false });
      isSussess = false;
    }

    return isSussess;
  };

  const renderModeSubmit = (isPublished?: boolean) => {
    if (isPublished) return "Save";
    switch (contentValue.status) {
      case SubmitType.Save:
        return "Save";
      case SubmitType.Schedule:
        return "Schedule";
      case SubmitType.Publish:
        return "Publish Now";
      case PostStatusType.Error:
        if (contentValue.approvalStatus === ApprovalStatusEnum.Declined)
          return "Save";
        return "Publish Now";
      default:
        break;
    }
  };

  const checkValidate = (
    newContentValue: CreateContentState
  ): ContentErrorType[] => {
    let { platformContents } = newContentValue;
    let newPlatformContents = [...platformContents];
    let errors = platformContents
      .filter((content) => !content?.disabled)
      .reduce((result: ContentErrorType[], content) => {
        const errorsContent = checkValidateContent(content);
        const contentIdx = newPlatformContents.findIndex(
          (c) => c.platformExtendReel === content.platformExtendReel
        );
        if (contentIdx !== -1)
          newPlatformContents[contentIdx] = {
            ...newPlatformContents[contentIdx],
            errors: errorsContent,
          }; // add error to platformContent
        return [...result, ...errorsContent];
      }, []);
    if (errors.length > 0) setListPlatformContent(newPlatformContents);
    return errors;
  };

  // get errors of content
  const checkValidateContent = (
    content?: SocialContentState
  ): ContentErrorType[] => {
    if (!content) return [];
    let errors: ContentErrorType[] = [];
    switch (content.platformExtendReel) {
      case SocialType.Facebook:
        if (!content.post) errors.push(ContentErrorType.RequiredPostFB);
        else if (content.post.length > LIMIT_CHARACTER_FB)
          errors.push(ContentErrorType.LimitPostFB);
        break;

      case SocialReelType.FacebookReel:
        if (!content.post) break;
        if (content.post.length > LIMIT_CHARACTER_FB)
          errors.push(ContentErrorType.LimitReel);

        if (!isSyncContent && !content.medias?.length)
          errors.push(ContentErrorType.RequiredMediaFbReel);
        break;

      case SocialType.Instagram:
        if (!content.post) errors.push(ContentErrorType.RequiredPostInsta);
        else if (content.post.length > LIMIT_CHARACTER_INS)
          errors.push(ContentErrorType.LimitPostIns);

        if (!content.medias?.length)
          errors.push(ContentErrorType.RequiredMediaIns);
        break;

      case SocialReelType.InstagramReel:
        if (!content.post) break;
        if (content.post.length > LIMIT_CHARACTER_INS)
          errors.push(ContentErrorType.LimitReel);

        if (!isSyncContent && !content.medias?.length)
          errors.push(ContentErrorType.RequiredMediaInsReel);
        break;

      case SocialType.Tiktok:
        if (!content.post) errors.push(ContentErrorType.RequiredPostTiktok);
        else if (content.post?.length > LIMIT_CHARACTER_TIKTOK)
          errors.push(ContentErrorType.LimitPostTiktok);

        if (!content.medias?.length)
          errors.push(ContentErrorType.RequiredMediaTiktok);
        break;

      case SocialType.Youtube:
        if (!content.post) errors.push(ContentErrorType.RequiredPostYoutube);
        else if (content.post?.length > LIMIT_CHARACTER_YOUTUBE)
          errors.push(ContentErrorType.LimitPostYoutube);

        if (!content.medias?.length)
          errors.push(ContentErrorType.RequiredMediaYoutube);
        break;

      case SocialReelType.YoutubeShort:
        if (content.post && content.post?.length > LIMIT_CHARACTER_YOUTUBE)
          errors.push(ContentErrorType.LimitReel);

        if (!content.medias?.length)
          errors.push(ContentErrorType.RequiredMediaYoutubeShort);
        break;

      case SocialType.Google:
        if (content.gmbPostType === undefined) break;
        if (
          !content.post &&
          [GmbPostType.STANDARD].includes(content.gmbPostType)
        )
          errors.push(ContentErrorType.RequiredPost);
        else if (content.post && content.post?.length > LIMIT_CHARACTER_GOOGLE)
          errors.push(ContentErrorType.LimitPostGBP);

        if (
          [GmbPostType.PHOTO_VIDEO].includes(content.gmbPostType) &&
          !content.medias?.length
        )
          errors.push(ContentErrorType.RequiredMediaGBPPhoto);

        if (
          !content.gmbTitle &&
          [GmbPostType.EVENT, GmbPostType.OFFER].includes(content.gmbPostType)
        )
          errors.push(ContentErrorType.RequiredPost);
        break;
      default:
        break;
    }
    return errors;
  };

  const isValidMedias = async (
    mediaUrls: ContentMediaModel[],
    platform?: SocialExtendReelType
  ): Promise<boolean> => {
    let { isSyncContent } = contentValue;
    const currentPlatform =
      platform !== undefined ? platform : currentContent?.platformExtendReel;
    // check instagram < 10 photo
    if (
      (isSyncContent && !instagramContent?.disabled) ||
      currentPlatform === SocialType.Instagram
    ) {
      if (mediaUrls.length > 10) {
        showNotification("error", t("social.content.instagramLimitPhoto"));
        return false;
      }
    }
    // check tiktok
    if (
      (isSyncContent && !tiktokContent?.disabled) ||
      currentPlatform === SocialType.Tiktok
    ) {
      // not allow mutil video
      if (mediaUrls.filter((x) => MediaUtils.isVideo(x.url)).length > 1) {
        showNotification("error", t("social.content.tiktokNotAllowMultiVideo"));
        return false;
      }
      // required video
      if (mediaUrls.some((x) => !MediaUtils.isVideo(x.url))) {
        showNotification("error", "Images are not supported for Tiktok");
        return false;
      }
    }
    // check not allow mixed media fb, insta
    if (
      (isSyncContent &&
        (!facebookContent?.disabled || !instagramContent?.disabled)) ||
      (currentPlatform &&
        [SocialType.Facebook, SocialType.Instagram].includes(
          currentPlatform as SocialType
        ))
    ) {
      if (
        mediaUrls.some((x) => MediaUtils.isVideo(x.url)) &&
        mediaUrls.some((x) => !MediaUtils.isVideo(x.url))
      ) {
        showNotification("error", t("social.content.notAllowMixMedia"));
        return false;
      }
    }
    // not support multiple video fb reel when sync off
    if (!isSyncContent && currentPlatform === SocialReelType.FacebookReel) {
      if (mediaUrls.filter((x) => MediaUtils.isVideo(x.url)).length > 1) {
        showNotification("error", t("social.content.fbReelLimitVideo"));
        return false;
      }
    }
    // not support multiple video insta reel when sync off
    if (!isSyncContent && currentPlatform === SocialReelType.InstagramReel) {
      if (mediaUrls.filter((x) => MediaUtils.isVideo(x.url)).length > 1) {
        showNotification("error", t("social.content.instaReelLimitVideo"));
        return false;
      }
    }

    // check youtube
    if (
      (isSyncContent && !youtubeContent?.disabled) ||
      (currentPlatform && currentPlatform % 10 === SocialType.Youtube)
    ) {
      if (
        mediaUrls.some((x) => MediaUtils.isVideo(x.url)) &&
        mediaUrls.some((x) => !MediaUtils.isVideo(x.url))
      ) {
        showNotification(
          "error",
          "Mixed media files are not supported. Please either use a single image/video or multiple images."
        );
        return false;
      }
      // not allow mutil video
      if (mediaUrls.filter((x) => MediaUtils.isVideo(x.url)).length > 1) {
        showNotification(
          "error",
          "Multiple videos are not supported for Youtube"
        );
        return false;
      }
      // required video
      if (mediaUrls.some((x) => !MediaUtils.isVideo(x.url))) {
        showNotification("error", "Images are not supported for Youtube");
        return false;
      }
    }

    // check GBP
    if (
      (isSyncContent && !googleContent?.disabled) ||
      currentPlatform === SocialType.Google
    ) {
      if (googleContent?.gmbPostType === undefined) return true;
      // not allow mutil image
      if (
        [GmbPostType.STANDARD, GmbPostType.PHOTO_VIDEO].includes(
          googleContent?.gmbPostType
        ) &&
        mediaUrls.filter((x) => !MediaUtils.isVideo(x.url)).length > 1
      ) {
        showNotification(
          "error",
          t("Multiple images are not supported for Google Business Profile")
        );
        return false;
      }
      // not allow mutil video
      if (
        [GmbPostType.PHOTO_VIDEO].includes(googleContent?.gmbPostType) &&
        mediaUrls.filter((x) => MediaUtils.isVideo(x.url)).length > 1
      ) {
        showNotification(
          "error",
          t("Multiple videos are not supported for Google Business Profile")
        );
        return false;
      }
      // if (
      //   [GmbPostType.STANDARD].includes(googleContent?.gmbPostType) &&
      //   mediaUrls.some((x) => MediaUtils.isVideo(x.url)) &&
      //   mediaUrls.some((x) => !MediaUtils.isVideo(x.url))
      // ) {
      //   showNotification(
      //     "error",
      //     "Mixed media files are not supported. Please either use a single image/video or multiple images."
      //   );
      //   return false;
      // }
      if (
        [
          GmbPostType.STANDARD,
          GmbPostType.PHOTO_VIDEO,
          GmbPostType.EVENT,
          GmbPostType.OFFER,
        ].includes(googleContent?.gmbPostType) &&
        mediaUrls.some((x) => MediaUtils.isVideo(x.url)) &&
        mediaUrls.some((x) => !MediaUtils.isVideo(x.url))
      ) {
        showNotification(
          "error",
          "Mixed media files are not supported. Please either use a single image or video."
        );
        return false;
      }
      // required image
      if (mediaUrls.some((x) => MediaUtils.isVideo(x.url))) {
        showNotification(
          "error",
          "Videos are not supported for Google Business Profile"
        );
        return false;
      }
      if (mediaUrls.some((x) => x.height < 250 || x.width < 250)) {
        showNotification(
          "error",
          "Minimum dimension of image for Google Business Profile is 250px x 250px"
        );
        return false;
      }
      // file size < 5MB
      if (
        mediaUrls.some(
          (x) => !MediaUtils.isVideo(x.url) && x.fileSize > 5000000
        )
      ) {
        showNotification("error", "Images should be smaller than 5 MB");
        return false;
      }
      // file size video < 75MB
      if (
        [GmbPostType.PHOTO_VIDEO].includes(googleContent?.gmbPostType) &&
        mediaUrls.some((x) => x.fileSize > 75000000)
      ) {
        showNotification(
          "error",
          "Maximum video size for Google Business Profile is 75 MB"
        );
        return false;
      }
    }

    return await CommonUtils.everyAsync<ContentMediaModel>(
      mediaUrls,
      async (x) => {
        if (facebookContent?.disabled === false) {
          if (isSyncContent || currentPlatform === SocialType.Facebook) {
            if (!(await MediaUtils.isValidMedia(x.url, SocialType.Facebook)))
              return false;
          }
        }
        if (facebookReelContent?.disabled === false) {
          if (
            !isSyncContent &&
            currentPlatform === SocialReelType.FacebookReel
          ) {
            if (
              !(await MediaUtils.isValidMedia(
                x.url,
                SocialReelType.FacebookReel
              ))
            )
              return false;
          }
        }
        if (instagramContent?.disabled === false) {
          if (isSyncContent || currentPlatform === SocialType.Instagram) {
            if (!(await MediaUtils.isValidMedia(x.url, SocialType.Instagram)))
              return false;
          }
        }
        if (instagramReelContent?.disabled === false) {
          if (
            !isSyncContent &&
            currentPlatform === SocialReelType.InstagramReel
          ) {
            if (
              !(await MediaUtils.isValidMedia(
                x.url,
                SocialReelType.InstagramReel
              ))
            )
              return false;
          }
        }
        if (tiktokContent?.disabled === false) {
          if (isSyncContent || currentPlatform === SocialType.Tiktok) {
            if (!(await MediaUtils.isValidMedia(x.url, SocialType.Tiktok)))
              return false;
          }
        }
        if (youtubeContent?.disabled === false) {
          if (isSyncContent || currentPlatform === SocialType.Youtube) {
            if (!(await MediaUtils.isValidMedia(x.url, SocialType.Youtube)))
              return false;
          }
        }
        if (youtubeShortContent?.disabled === false) {
          if (
            !isSyncContent &&
            currentPlatform === SocialReelType.YoutubeShort
          ) {
            if (
              !(await MediaUtils.isValidMedia(
                x.url,
                SocialReelType.YoutubeShort
              ))
            )
              return false;
          }
        }
        if (googleContent?.disabled === false) {
          if (isSyncContent || currentPlatform === SocialType.Google) {
            if (!(await MediaUtils.isValidMedia(x.url, SocialType.Google)))
              return false;
          }
        }
        return true;
      }
    );
  };

  const onValuesPlatformChange = (values: Partial<SocialContentState>) => {
    const listPlatformContentClone = [...listPlatformContent];

    const contentIdx = listPlatformContentClone.findIndex(
      (c) => c.platformExtendReel === currentContent?.platformExtendReel
    );
    if (contentIdx === -1) return;
    /// update content
    listPlatformContentClone[contentIdx] = {
      ...listPlatformContentClone[contentIdx],
      ...values,
    };

    setListPlatformContent(listPlatformContentClone);
  };
  return {
    updateContents,
    onChangeMenu,
    onChangeDate,
    onChangeStatusAvatar,
    createContent,
    renderModeSubmit,
    onChangeSync,
    editContent,
    checkValidate,
    checkValidateContent,
    isValidContent,
    isValidMedias,
    getPlatformContent,
    onValuesPlatformChange,
  };
};

export default useUpdateContent;
