import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import "./App.css";
import awsConfig from "./aws-config";
import AdminLayout from "./components/layouts/admin-layout";
import AuthLayout from "./components/layouts/auth-layout";
import MainLayout from "./components/layouts/main-layout";
import ProtectedRoute from "./components/router-protected";
import { AdminPrefixRouter, ROUTE_PATHS } from "./constants/router.constants";
import useAdminSite from "./hooks/useAdminSite";
import useClient from "./hooks/useClient";
import NotFoundPage from "./pages/error/404";
import MaintenancePage from "./pages/error/maintenance";
import ReviewContentPage from "./pages/review-content";
import AddProfilesPage from "./pages/social/add-profiles";
import { anonymousRouters, authorizedRouters } from "./routing";
import AppNotificationWrapper from "./components/controls/notification-wrapper";
import ApprovalRequestPage from "./pages/social/approvals/approval-request";
import ReportRequestPage from "./pages/reports/report-request";

Amplify.configure(awsConfig);
Amplify.Logger.LOG_LEVEL = "DEBUG";

function AuthorizeLayout() {
  const isAdmin = useAdminSite();
  const { pathname } = useLocation();
  const { isAdminViewSite } = useClient();

  if (pathname.startsWith(ROUTE_PATHS.SelectCompany)) return <AuthLayout />;
  return isAdmin && !isAdminViewSite ? <AdminLayout /> : <MainLayout />;
}

function App() {
  const pathname = window.location.pathname;
  const isAdmin = pathname.startsWith(AdminPrefixRouter);

  useEffect(() => {
    document.title = isAdmin ? "RocketLevel Admin" : "RocketLevel";
  }, [pathname]);

  return process.env.REACT_APP_MAINTENANCE_MODE === "true" ? (
    <MaintenancePage />
  ) : (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route>
            <Route element={<AuthLayout />}>
              {anonymousRouters
                .filter((p) => p.component)
                .map(({ href, component: Component }) => (
                  <Route
                    key={href}
                    path={href}
                    element={
                      <React.Suspense>
                        <Component />
                      </React.Suspense>
                    }
                  />
                ))}
            </Route>


            <Route element={<AppNotificationWrapper>
              <AuthorizeLayout />
            </AppNotificationWrapper>}>
              {authorizedRouters
                .filter((p) => p.component)
                .map(({ href, component: Component, permissions, service }) => (
                  <Route
                    key={href}
                    path={href}
                    element={
                      <React.Suspense>
                        <ProtectedRoute service={service} allowFunctions={permissions}>
                          <Component />
                        </ProtectedRoute>
                      </React.Suspense>
                    }
                  />
                ))}
            </Route>

            <Route
              path={ROUTE_PATHS.NotFound}
              element={<NotFoundPage />}
            ></Route>
            <Route
              path={ROUTE_PATHS.AddProfiles}
              element={<AddProfilesPage />}
            ></Route>
            <Route
              path={ROUTE_PATHS.Social.ReviewContent}
              element={<ReviewContentPage />}
            ></Route>
            <Route
              path={ROUTE_PATHS.Social.ApprovalRequest}
              element={<ApprovalRequestPage />}
            ></Route>
              <Route
              path={`${ROUTE_PATHS.Reports}/:id`}
              element={<ReportRequestPage />}
            ></Route>
          </Route >
        )
      )
      }
    />
  );
}

export default App;
