import { Dropdown, Modal, Tabs } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  GmbActionType,
  GmbPostType,
  PlatformStatusType,
  SocialType,
} from "../../../../../../../constants/app.enums";
import {
  contentValueState,
  currentContentSelector,
} from "../../../../../../../states/social";
import SVGIcons from "../../../../../../icons/svgs";
import useUpdateContent from "../../../hook/useUpdateContent";
import ContentCaption, { ContentCaptionProps } from "../content-caption";
import EventTab from "./event-tab";
import "./index.scss";
import OfferTab from "./offer-tab";
import PhotoVideoTab from "./photo-video-tab";
import GbpUpdateTab from "./update-tab";

enum GBPContentTabKey {
  Update = "UPDATE",
  Photo = "PHOTO",
  Event = "EVENT",
  Offer = "OFFER",
}

const ContentCaptionGBP = (props: ContentCaptionProps) => {
  const { isReviewing } = props;
  const { t } = useTranslation();
  const { onValuesPlatformChange } = useUpdateContent();
  const currentContent = useRecoilValue(currentContentSelector);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);

  const { post, medias, gmbPostType, gmbCategory, gmbTitle } =
    props.contentValue?.currentContent || currentContent || {};

  const isPublished = currentContent?.status === PlatformStatusType.Success;

  function onChangeTab(value: string) {
    if (post || (medias && medias?.length > 0) || gmbCategory || gmbTitle) {
      Modal.confirm({
        title: t(`social.content.changePostTypeTitle`),
        content: <span>{t(`social.content.changePostTypeDescription`)}</span>,
        okText: "Yes, continue",
        cancelText: t("common.cancel"),
        cancelButtonProps: { type: "text" },
        icon: "",
        className: "confirm-modal confirm-delete-modal",
        centered: true,
        onOk: async () => {
          onValuesPlatformChange({
            post: "",
            medias: [],
            gmbActionType: GmbActionType.None,
            gmbActionUrl: "",
            gmbActionUrlError: "",
            gmbTitle: "",
            gmbStartDate: undefined,
            gmbEndDate: undefined,
            gmbCategory: undefined,
            gmbPostType: parseInt(value),
            gmbCouponCode: "",
            gmbRedeemOnlineUrl: "",
            gmbTermsConditions: "",
          });
        },
      });
    } else {
      onValuesPlatformChange({
        gmbPostType: parseInt(value),
      });
    }
  }

  const onClickMenuEventPost = (menuItem: any) => {
    const { key } = menuItem;
    if (key === "edit") {
      setContentValue({ ...contentValue, eventPostModalStatus: "edit" });
    }
    if (key === "clear") {
      onValuesPlatformChange({
        gmbActionType: GmbActionType.None,
        gmbActionUrl: undefined,
        gmbTitle: undefined,
        gmbStartDate: undefined,
        gmbEndDate: undefined,
        post: undefined,
      });
    }
  };

  const menuActionEventPost = {
    items: [
      { key: "edit", label: "Edit Details" },
      {
        key: "clear",
        label: "Clear All",
        danger: true,
      },
    ],
    onClick: onClickMenuEventPost,
  };
  useEffect(() => {
    if (
      currentContent?.platform === SocialType.Google &&
      !currentContent?.gmbActionType
    )
      onValuesPlatformChange({
        gmbActionType: GmbActionType.None,
        gmbPostType: GmbPostType.STANDARD,
      });
  }, [currentContent]);
  return (
    <Tabs
      activeKey={gmbPostType?.toString() || GmbPostType.STANDARD.toString()}
      type="card"
      className={`ant-tab-content-caption ${
        isPublished || isReviewing ? "no-margin" : ""
      }`}
      onChange={onChangeTab}
      tabBarExtraContent={
        gmbTitle &&
        !isPublished &&
        !isReviewing && (
          <Dropdown
            menu={menuActionEventPost}
            trigger={["click"]}
            placement="bottomRight"
          >
            <div className="event-post-action">
              <SVGIcons.DotsIcon />
            </div>
          </Dropdown>
        )
      }
      items={[
        {
          label: (
            <>
              <SVGIcons.NotesIcon /> {GBPContentTabKey.Update}
            </>
          ),
          key: GmbPostType.STANDARD.toString(),
          children: (
            <>
              <ContentCaption {...props} />
              <GbpUpdateTab
                isReviewing={isReviewing}
                currentContent={props.contentValue?.currentContent}
              />
            </>
          ),
        },
        {
          label: (
            <>
              <SVGIcons.PhotoVideoIcon /> {GBPContentTabKey.Photo}
            </>
          ),
          key: GmbPostType.PHOTO_VIDEO.toString(),
          children: (
            <>
              <PhotoVideoTab
                isReviewing={isReviewing}
                currentContent={props.contentValue?.currentContent}
              />
            </>
          ),
        },
        {
          label: (
            <>
              <SVGIcons.CalendarPlusIcon /> {GBPContentTabKey.Event}
            </>
          ),
          key: GmbPostType.EVENT.toString(),
          children: (
            <>
              <EventTab
                isReviewing={isReviewing}
                currentContent={props.contentValue?.currentContent}
              />
            </>
          ),
        },
        {
          label: (
            <>
              <SVGIcons.CirclePercentageIcon /> {GBPContentTabKey.Offer}
            </>
          ),
          key: GmbPostType.OFFER.toString(),
          children: (
            <>
              <OfferTab
                isReviewing={isReviewing}
                currentContent={props.contentValue?.currentContent}
              />
            </>
          ),
        },
      ].filter((item) => {
        if ((isReviewing || isPublished) && gmbPostType?.toString())
          return item.key === gmbPostType?.toString();
        return true;
      })}
    ></Tabs>
  );
};

export default ContentCaptionGBP;
