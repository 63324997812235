import { Button, Drawer, Form, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthApi } from "../../../apis/auth.api";
import { authState, authUserSelector } from "../../../states/auth";
import showNotification from "../../common/notification";
import "./index.scss";
import ServiceTab from "./services";
import UserProfile from "./user-profile";

enum TabKeys {
  OverView = "OverView",
  Services = "Services",
}
interface EditUserFormProps {
  open: boolean;
  close: () => void;
}

function AdminMyProfile(props: EditUserFormProps) {
  const { open, close } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState(TabKeys.OverView);
  const { user } = useRecoilValue(authState);
  const [userState, setUserState] = useRecoilState(authUserSelector);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function okClick() {
    switch (activeTab) {
      case TabKeys.OverView:
        submit();
        break;
      default:
        break;
    }
  }

  async function submit() {
    setSubmitLoading(true);
    try {
      const { firstName, lastName, profilePicture } = form.getFieldsValue(true);
      if (user) {
        const { data: userResponse } = await AuthApi.updateMyUserProfile({
          firstName,
          lastName,
          userId: user?.userId,
          profilePicture,
        });

        showNotification("success", t("common.saveChangesSuccessfully"));
        userState &&
          setUserState({
            ...userState,
            firstName,
            lastName,
            profilePictureUrl: userResponse.profilePictureUrl,
          });
        close();
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitLoading(false);
  }
  function cancel() {
    close();
  }

  useEffect(() => {
    if (open) {
      form.resetFields();
      if (user) {
        form.setFieldsValue({
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          userGroup:
            user.userGroups && user.userGroups.length
              ? user.userGroups[0].name
              : "",
        });
      }
    }
  }, [open]);
  return (
    <Drawer
      className="app-edit-drawer"
      title={t("users.userProfile")}
      open={open}
      width={540}
      onClose={() => cancel()}
      maskClosable={false}
      footer={
        <div className="drawer-footer-wrapper">
          <Button type="text" onClick={() => cancel()}>
            {t("clientSites.btnClose")}
          </Button>
          <Button
            type="primary"
            onClick={okClick}
            loading={submitLoading}
            disabled={activeTab !== TabKeys.OverView}
          >
            {t("clientSites.btnSave")}
          </Button>
        </div>
      }
    >
      <Tabs
        type="card"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as TabKeys)}
        className="edit-client-site-tab"
      >
        <Tabs.TabPane tab={t("clientSites.tabOverview")} key={TabKeys.OverView}>
          <UserProfile form={form} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("clientSites.services")} key={TabKeys.Services}>
          <ServiceTab open={open} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
}

export default AdminMyProfile;
