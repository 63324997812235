import { FunctionEnum } from "./constants/permission.constants";
import {
  AdminViewSitePrefixRouter,
  ROUTE_PATHS,
} from "./constants/router.constants";
import AccountVerificationPage from "./pages/account-verification";
import AdminChangePasswordPage from "./pages/admin/change-password";
import ClientSitesPage from "./pages/admin/client-sites";
import AdminHome from "./pages/admin/home";
import RolePermissionPage from "./pages/admin/role-permissons";
import UserGroupsPage from "./pages/admin/user-groups";
import UserListPage from "./pages/admin/user-list";
import ContactsPage from "./pages/contacts";
import EmailVerificationPage from "./pages/email-verification";
import ForgotPasswordPage from "./pages/forgot-password";
import GeneralSettingsPage from "./pages/general-settings";
import HomePage from "./pages/home";
import ListingsPage from "./pages/listings";
import ResetPasswordPage from "./pages/reset-password";
import ReviewsPage from "./pages/reviews";
import SignInPage from "./pages/sign-in";
import SignUpPage from "./pages/sign-up";
import SocialCalendar from "./pages/social/calendar";
import SocialContent from "./pages/social/content";
import SocialDashboard from "./pages/social/dashboard";
import UpdatePasswordPage from "./pages/update-password";
import UpdatePasswordSuccessPage from "./pages/update-password/success";
import SocialConnectProfilePage from "./pages/social/connect-profiles";
import TeamInvitationPage from "./pages/team/invite-team";
import SelectTeamPage from "./pages/team/select-team";
import CreateMemberSuccessPage from "./pages/team/invite-team/success";
import ConnectedProfileSuccessPage from "./pages/social/connect-profiles/success";
import SocialApprovals from "./pages/social/approvals";
import ShortLinkPage from "./pages/shortlink";
import SelectCompanyPage from "./pages/select-company";
import Reports from "./pages/reports";
import { ServiceType } from "./constants/app.enums";
import UsageAndBilling from "./pages/usage-and-billing";
import StripePage from "./pages/stripe";
import MediaLibrary from "./pages/media-library";
import SalesAnalytics from "./pages/sales-analytics";
import ZohoReturnPage from "./pages/zoho-return";

interface RouterModel {
  href: string;
  component: () => JSX.Element;
  permissions?: string[];
  service?: ServiceType
}

export const clientAnonymousRouters: RouterModel[] = [
  {
    href: ROUTE_PATHS.SignIn,
    component: SignInPage,
  },
  {
    href: ROUTE_PATHS.ForgotPassword,
    component: ForgotPasswordPage,
  },
  {
    href: ROUTE_PATHS.ResetPassword,
    component: ResetPasswordPage,
  },
  {
    href: ROUTE_PATHS.UpdatePassword,
    component: UpdatePasswordPage,
  },
  {
    href: ROUTE_PATHS.UpdatePasswordSuccess,
    component: UpdatePasswordSuccessPage,
  },
  {
    href: ROUTE_PATHS.CreateMemberSuccess,
    component: CreateMemberSuccessPage,
  },
  {
    href: ROUTE_PATHS.SignUp,
    component: SignUpPage,
  },
  {
    href: ROUTE_PATHS.TeamInvitation,
    component: TeamInvitationPage,
  },
  {
    href: ROUTE_PATHS.AccountVerification,
    component: AccountVerificationPage,
  },
  {
    href: ROUTE_PATHS.EmailVerification,
    component: EmailVerificationPage,
  },
  {
    href: ROUTE_PATHS.Social.ConnectProfiles,
    component: SocialConnectProfilePage,
  },
  {
    href: ROUTE_PATHS.Social.ConnectedProfilesSuccess,
    component: ConnectedProfileSuccessPage,
  },
  {
    href: ROUTE_PATHS.ShortLink,
    component: ShortLinkPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Stripe,
    component: StripePage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.ZohoReturn,
    component: ZohoReturnPage,
    permissions: [],
  },
];

export const clientRouters: RouterModel[] = [
  {
    href: ROUTE_PATHS.Home,
    component: HomePage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.SalesAnalytics,
    component: SalesAnalytics,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.SelectTeam,
    component: SelectTeamPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.SelectCompany,
    component: SelectCompanyPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.GeneralSettings,
    component: GeneralSettingsPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Contacts,
    component: ContactsPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Listings,
    component: ListingsPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Reviews,
    component: ReviewsPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.Social.Dashboard,
    component: SocialDashboard,
    permissions: [],
    service: ServiceType.Social
  },
  {
    href: ROUTE_PATHS.Social.Calendar,
    component: SocialCalendar,
    permissions: [],
    service: ServiceType.Social
  },
  {
    href: ROUTE_PATHS.Social.Content,
    component: SocialContent,
    permissions: [],
    service: ServiceType.Social
  },
  {
    href: ROUTE_PATHS.Social.Approvals,
    component: SocialApprovals,
    permissions: [],
    service: ServiceType.Social
  },
  {
    href: ROUTE_PATHS.Reports,
    component: Reports,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.UsageAndBilling,
    component: UsageAndBilling,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.MediaLibrary,
    component: MediaLibrary,
    permissions: [],
  }
];

export const adminAnonymousRouters: RouterModel[] = [
  {
    href: ROUTE_PATHS.AdminSignIn,
    component: SignInPage,
  },
  {
    href: ROUTE_PATHS.AdminForgotPassword,
    component: ForgotPasswordPage,
  },
  {
    href: ROUTE_PATHS.AdminResetPassword,
    component: ResetPasswordPage,
  },
  {
    href: ROUTE_PATHS.AdminChangePassword,
    component: AdminChangePasswordPage,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.AdminUpdatePassword,
    component: UpdatePasswordPage,
  },
  {
    href: ROUTE_PATHS.AdminUpdatePasswordSuccess,
    component: UpdatePasswordSuccessPage,
  },
  {
    href: ROUTE_PATHS.AdminZohoReturn,
    component: ZohoReturnPage,
    permissions: [],
  },
];

export const adminRouters: RouterModel[] = [
  {
    href: ROUTE_PATHS.AdminHome,
    component: AdminHome,
    permissions: [],
  },
  {
    href: ROUTE_PATHS.ClientSite,
    component: ClientSitesPage,
    permissions: [FunctionEnum.ClientSites],
  },
  {
    href: ROUTE_PATHS.UserList,
    component: UserListPage,
    permissions: [FunctionEnum.Users],
  },
  {
    href: ROUTE_PATHS.UserGroups,
    component: UserGroupsPage,
    permissions: [FunctionEnum.UserGroups],
  },
  {
    href: ROUTE_PATHS.RolePermission,
    component: RolePermissionPage,
    permissions: [FunctionEnum.Role],
  },
];

const adminViewClientSiteRouters: RouterModel[] = clientRouters.map((x) => {
  return {
    component: x.component,
    href:
      x.href === ROUTE_PATHS.Home
        ? `${AdminViewSitePrefixRouter}/:id`
        : `${AdminViewSitePrefixRouter}${x.href}/:id`,
    permissions: [FunctionEnum.ClientSites],
  };
});

export const anonymousRouters = [
  ...clientAnonymousRouters,
  ...adminAnonymousRouters,
];

export const authorizedRouters = [
  ...clientRouters,
  ...adminRouters,
  ...adminViewClientSiteRouters,
];

export const appRoutes = [...anonymousRouters, ...authorizedRouters];
