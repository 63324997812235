import { AxiosResponse } from "axios";
import {
  MediaGetFilesModel,
  MediaSaveFileModel,
} from "../models/media-library.model";
import {
  deleteAsync,
  getAsync,
  postAsync,
  postFormDataAsync,
  putFormDataAsync,
} from "./http-client";
import { MediaType } from "../components/views/media-library/components/media-library-header";
import { MediaModel, UploadMediaGGDriveModel, UploadMediaModel } from "../models/content.model";
import qs from "qs";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/social/api/v1`;

const uploadBaseUrl = `${process.env.REACT_APP_API_ENPOINT}/gravityform/api/v1`;

/* unsplash */
function getLibraryMedias(
  model: MediaGetFilesModel,
  platform: string,
  mediaType: MediaType
): Promise<AxiosResponse<MediaModel[]>> {
  const url = `${baseUrl}/library/${platform}/${mediaType}`;
  return getAsync(url, model);
}

function saveLibraryMedias(
  model: MediaSaveFileModel,
  platform: string,
  mediaType: MediaType
): Promise<AxiosResponse> {
  const url = `${baseUrl}/library/${platform}/${mediaType}`;
  return postAsync(url, model);
}

function uploadMedia(
  model: UploadMediaModel,
  signal?: AbortSignal,
  onProgress?: (progress: number) => void
): Promise<AxiosResponse<MediaModel, any>> {
  const url = `${uploadBaseUrl}/media/handle-upload/${model.clientId}`;
  return postFormDataAsync(url, model, false, false, false, { signal,
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (onProgress) {
        onProgress(percentCompleted);
      }
    },
   });
}

function deleteMedia(request: { ids: number[] }) {
  const params = qs.stringify(request, { arrayFormat: "repeat" });
  const url = `${baseUrl}/media/delete-medias?${params}`;
  return deleteAsync(url);
}

function checkMediaInContent(request: {
  ids: number[];
}): Promise<AxiosResponse<{ id: number; inContentIds: number[] }[], any>> {
  const params = qs.stringify(request, { arrayFormat: "repeat" });
  const url = `${baseUrl}/media/check-medias-in-content?${params}`;
  return getAsync(url);
}

function editMedia(
  id: number,
  model: { file?: any }
): Promise<AxiosResponse<MediaModel, any>> {
  const url = `${uploadBaseUrl}/media/edit/${id}`;
  return putFormDataAsync(url, model, false, false, false);
}

function uploadGGDriveMedia(
  model: UploadMediaGGDriveModel
): Promise<AxiosResponse<MediaModel, any>> {
  const url = `${uploadBaseUrl}/media/gg-driver-upload`;
  return postAsync(url, model);
}

export const MediaLibraryApi = {
  getLibraryMedias,
  saveLibraryMedias,
  uploadMedia,
  deleteMedia,
  checkMediaInContent,
  editMedia,
  uploadGGDriveMedia
};
