export const DEBOUNCE_SEARCH_TIME = 500;
let searchDebounceHandler: NodeJS.Timeout;

function searchDebounce(
  value: string,
  callBack: (value?: string) => void | Promise<void>,
  delay: number = DEBOUNCE_SEARCH_TIME
) {
  if (searchDebounceHandler) {
    clearTimeout(searchDebounceHandler);
  }

  searchDebounceHandler = setTimeout(async () => {
    await callBack(value);
  }, delay);
}

export default searchDebounce;
