import { SelectFunctionModel } from "../models/common.model";
import i18n from "../utils/i18n";

export const ROLES = {
  supperAdmin: "Super Admin",
};

export enum PermissionEnum {
  None = 0,
  View = 1,
  Create = 2,
  Edit = 3,
  Delete = 4,
  All = 5,
}

export enum FunctionEnum {
  Everything = "EV",
  Role = "RL",
  ClientSites = "CS",
  UserGroups = "UG",
  Users = "US",
  Nothing = "Nothing",
}

export const DefaultUserGroups = {
  Uncategorised: "Uncategorised",
  Client: "Client",
  SuperAdmin: "Super Admin",
  Editor: "Editor",
};

export const FUNCTIONS: SelectFunctionModel[] = [
  {
    code: FunctionEnum.Everything,
    name: i18n.t("function.everything"),
  },
  {
    code: FunctionEnum.ClientSites,
    name: i18n.t("function.clientSites"),
  },
  {
    code: FunctionEnum.UserGroups,
    name: i18n.t("function.userGroups"),
  },
  {
    code: FunctionEnum.Users,
    name: i18n.t("function.users"),
  },
  {
    code: FunctionEnum.Nothing,
    name: i18n.t("function.nothing"),
  },
];
