import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { StripesApi } from "../../../../apis/stripes.api";
import SuccessUpgradeAnimation from "../../../../assets/animations/success-upgrade.gif";
import useClient from "../../../../hooks/useClient";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import useClientPlan from "../../../../hooks/useClientPlan";
import { PlanType } from "../../../../constants/app.enums";

function SuccessUpgradeModal() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const [searchParams, setSearchParams] = useSearchParams();
    const status = searchParams.get("upgrade-status");
    const { isExternalUser, isAdminViewSite } = useClient();
    const { userPlan } = useClientPlan();

    useEffect(() => {
        if (searchParams && status === "success" && userPlan?.planType === PlanType.Premium) {
            setIsOpen(true)
            searchParams.delete("upgrade-status")
            if (searchParams.get("reloaded")) {
                searchParams.delete("reloaded")
            }
            setSearchParams(searchParams);
        }

    }, [status, searchParams])

    const redirectToStripePotal = async () => {

        if (isExternalUser() || isAdminViewSite) { return; }

        try {
            const { data } = await StripesApi.getManageBillingUrl()
            window.location.href = data.url;
        } finally {
        }
    }

    return (
        <>
            <Modal
                centered
                className="upgrade-modal"
                open={isOpen}
                closable={false}
                onCancel={() => setIsOpen(false)}
                footer={null}
            >
                <div className="success-upgrade-modal">
                    <div className="success-upgrade-modal__header" >
                        <div
                            onClick={() => setIsOpen(false)}
                            className="success-upgrade-modal__closeIcon"
                        >
                            <SVGIcons.UpgradeModalCloseIcon />
                        </div>
                    </div>

                    <div className="success-upgrade-modal__content" >
                        <img alt="" src={SuccessUpgradeAnimation} />
                    </div>

                    <div className="success-upgrade-modal__footer" >
                        <div className="title" >
                            {t("upgradeSuccessModal.title")}
                        </div>

                        <div className="desc" >
                            {t("upgradeSuccessModal.desc")}
                        </div>
                        <div className="subscription" onClick={redirectToStripePotal}>
                            {t("upgradeSuccessModal.manageYourSubscription")}
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    );
}

export default SuccessUpgradeModal;
