enum CharactersPositionType {
    front = "front",
    behind = "behind"
}

export const AmountFormat = {
    thousandSeperator: ",",
    decimalSeperator: ".",
    allowedDecimals: 0,
    currencyCharacters: "$",
    charactersPosition: CharactersPositionType.front
}

const padding = "000000";

const convertNumericToFormattedAmount = (value?: number, allowedDecimals: number = AmountFormat.allowedDecimals): string => {


    const { currencyCharacters } = AmountFormat;
    if (value && value.toString().includes(currencyCharacters)) {
        return convertNumericToFormattedString(value, allowedDecimals);  
    }

    return currencyCharacters + convertNumericToFormattedString(value, allowedDecimals);
}

const convertNumericToFormattedString = (value?: number, allowedDecimals: number = AmountFormat.allowedDecimals): string => {

    if (!value) {
        value = 0;
    }

    if (isNaN(value)) {
        return value.toString();
    }

    const numericAmount = Number(value).toFixed(allowedDecimals).toString();

    const { thousandSeperator, decimalSeperator } = AmountFormat;


    let [integer, fraction = ""] = numericAmount.split(".");

    fraction = allowedDecimals > 0
        ? decimalSeperator + (fraction + padding).substring(0, allowedDecimals)
        : "";

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeperator);
    //if (integer == "") integer = "0";
    return integer + fraction;
}

const formatInput = (value: string | number | undefined) => {
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const converNumberToPercentage = (value: number | undefined, allowedDecimals: number = AmountFormat.allowedDecimals) => {
    if (!value) {
        value = 0;
    }

    if (isNaN(value)) {
        return value.toString();
    }

    return convertNumericToFormattedString(value, allowedDecimals) + "%";
}


export const NUMBER_UTIL = {
    convertNumericToFormattedAmount,
    formatInput,
    convertNumericToFormattedString,
    converNumberToPercentage
}