import { Col, Form, Input, Modal, Row } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ReportApi } from "../../../../../apis/report.api";
import {
    VALIDATION_MESSAGE_CONFIG
} from "../../../../../constants/app-constants";
import { DateRangeValues } from "../../../../../constants/app.enums";
import useClient from "../../../../../hooks/useClient";
import { GenerateReportRequestModel, GetReportsRequest } from "../../../../../models/report.model";
import { isMobileSelector } from "../../../../../states/common";
import showNotification from "../../../../common/notification";
import TrumbowygEditor from "../../../../common/trumbowyg-editor";
import AppDateRangePicker from "../../../../controls/app-date-range-picker";
import AppMobileDateRangePicker from "../../../../controls/app-mobile-date-range-picker";
import "./index.scss";
import AppDateRangePicker2 from "../../../../controls/app-date-range-picker-2";

interface GenerateReportFormProps {
    open: boolean;
    close: () => void;
    modalTitle: string
    reportType: number
    initListReport: (request: GetReportsRequest) => Promise<void>;
    currentReport?: GenerateReportRequestModel;
    isEdit?: boolean
    pageIndex: number
}

function GenerateReportForm(props: GenerateReportFormProps) {
    const { open, close, modalTitle, reportType, initListReport, currentReport, isEdit, pageIndex } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [generating, setGenerating] = useState(false);
    const [uploading, setUploading] = useState(false);
    const isMobile = useRecoilValue(isMobileSelector);
    const [defaultDateRange, setDefaultDateRange] = useState<Moment[]>([])

    const [dateRange, setDateRange] = useState({
        startDate: "",
        endDate: "",
    })
    const { clientId } = useClient();

    const [editorValue, setEditorValue] = useState<string>("")
    const onClose = () => {
        form.resetFields();
        close();
        setEditorValue("")
    };


    const createReport = async (values: GenerateReportRequestModel) => {
        close();

        setGenerating(true);
        try {
            const requestModel = {
                clientId,
                title: values.title,
                additionalDetails: editorValue,
                reportType,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate
            };
            await ReportApi.generateReport(requestModel)
            showNotification(
                "success",
                t("generalSettings.generateSuccess", { title: requestModel.title })
            );
            form.resetFields();
            setEditorValue("")
            
            initListReport({
                pageIndex: 1,
                reportType
            })
        } finally {
            setGenerating(false);
        }

    };
    const updateReport = async (values: GenerateReportRequestModel) => {
        setUploading(true);
        try {
            if (currentReport?.id) {
                const requestModel = {
                    id: currentReport.id,
                    title: values.title,
                    additionalDetails: editorValue
                };
                await ReportApi.updateReport(requestModel)
                showNotification(
                    "success",
                    t("generalSettings.updateSuccess", { title: requestModel.title })
                );
                onClose();
                initListReport({
                    pageIndex,
                    reportType
                })
            }

        } finally {
            setUploading(false);
        }

    };
    useEffect(() => {
        if (isEdit && currentReport && open) {
            form.setFieldsValue({
                title: currentReport.title
            });

            if(currentReport.additionalDetails) {
                setEditorValue(currentReport.additionalDetails);
            }
            setDefaultDateRange([moment(currentReport.startDate), moment(currentReport.endDate)])
            
        }
    }, [currentReport, open]);
    

    async function submit(values: GenerateReportRequestModel) {
        if (isEdit) {
            await updateReport(values)
        }
        else {
            await createReport(values)
        }
    }

    function okClick() {
        form.submit();
    }

    function cancel() {
        form.resetFields();
        close();
    }

    function dateChange(values: any) {
        const startDate = values.startDate.format("yyyy-MM-DDT00:00:00");
        const endDate = values.endDate.format("yyyy-MM-DDT23:59:00");
        setDateRange({
            startDate,
            endDate
        })
    }

    return (
        <>
            <Modal
                destroyOnClose={true}
                open={open}
                title={modalTitle}
                okText={isEdit ? t("rolePermission.update") : t("generalSettings.generate")}
                onOk={okClick}
                className="custom-create-modal generate-report-modal"
                onCancel={cancel}
                confirmLoading={uploading || generating}
            >
 
                <Form
                    name="generateReportForm"
                    layout="vertical"
                    validateMessages={VALIDATION_MESSAGE_CONFIG}
                    onFinish={submit}
                    form={form}
                    autoComplete="off"
                >
                    <div className="headingTitle top" >{t("common.generalSettings")}</div>
                    <Form.Item
                        label={t("generalSettings.reportTitle")}
                        name="title"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder={t("generalSettings.reportTitle")} />
                    </Form.Item>

                    <Form.Item
                        label={t("generalSettings.dateRange")}
                        rules={[{ required: true }]}
                        name="dateRange"
                        style={{
                            width: isMobile ? "100%" : "42%",
                        }}
                    >
                        {isMobile ? (
                            <AppMobileDateRangePicker
                                disabled={isEdit}
                                defaultDateRange={isEdit ? defaultDateRange : null}
                                defaulValue={DateRangeValues.thisWeek}
                                onChange={dateChange}
                            />
                        ) : (
                            <AppDateRangePicker2
                                    hasBorder
                                    disabled={isEdit}
                                    defaultDateRange={isEdit ? defaultDateRange : null}
                                    defaulValue={DateRangeValues.thisWeek}
                                    onChange={dateChange}
                                    />
                        )}
                    </Form.Item>

                    <div className="headingTitle" >{t("generalSettings.additionalDetails")}</div>
                    <div className="headingTitleDesc" >{t("generalSettings.additionalDetailsDesc")}</div>

                    <TrumbowygEditor  placeholder={t("generalSettings.reportSummary")}
                        setValue={setEditorValue}
                        initValue={currentReport?.additionalDetails} />


                </Form>
            </Modal>
            <Modal
                title={ isEdit ? t("generalSettings.updatingReport") : t("generalSettings.generatingReport")}
                open={generating}
                className="custom-create-modal generate-report-loading"
                footer={null}
                onCancel={() => {
                    setGenerating(false);
                }}
                centered
            >
                <div className="uploading">
                    <div className="format">{t("social.content.uploadingText")}</div>
                    <div className="scroll-upload">
                        <div className="scroll-bar"></div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default GenerateReportForm;
