import { CommonModel } from "./common.model";

export interface BeReportModel {
  reivewSummary: {
    totalReview: number;
    avgRating: number;
    ratings: { rating: number; review: number }[];
  };
  topNetworks: TopNewworkModel[];
  reviews: { date: string; review: number }[];
}

export interface TopNewworkModel {
  name: string;
  review: number;
  negative: number;
  positive: number;
}

export const beReportDefaulValue: BeReportModel = {
  reviews: [],
  reivewSummary: {
    avgRating: 0,
    ratings: [
      { rating: 5, review: 0 },
      { rating: 4, review: 0 },
      { rating: 3, review: 0 },
      { rating: 2, review: 0 },
      { rating: 1, review: 0 },
    ],
    totalReview: 0,
  },
  topNetworks: [],
};

export interface BEIntegrationModel extends CommonModel {
  apiKey: string;
  businessId: string;
  clientId: number;
  id: number;
  isEnable: boolean;
  isInvalid: boolean;
}

export interface BeAddConnectionRequest {
  clientId: number;
  businessId: string;
  apiKey: string;
}

export interface BeUpdateConnectionRequest {
  clientId: number;
  businessId: string;
  apiKey: string;
  id: number;
}