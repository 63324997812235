import { ApexOptions } from "apexcharts";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import { generalChartSelector } from "../../../../../../states/dashboard";
import { NUMBER_UTIL } from "../../../../../../utils/number.utils";
import { DATE_TIME_FORMAT } from "../../../../../../constants/app-constants";
import { EngagementModel } from "../../../../social/dashboard/overview/engagement";
import SocialUtils from "../../../../../../utils/social";
import { socialDashboardDateRangeState } from "../../../../../../states/social-dashboard";

interface Props {
  chartId: string;
  chartData: EngagementModel;
}

function EngagementChart(props: Props) {
  const { chartId, chartData } = props;
  const generalChartData = useRecoilValue(generalChartSelector);
  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([]);

  useEffect(() => {
    getSeriesData();
  }, [chartData, generalChartData]);

  const valueFormater = useCallback(
    (value: number) => {
      return NUMBER_UTIL.convertNumericToFormattedString(value);
    },
    [chartData.key]
  );

  const chartDataSet = useMemo(() => {
    return SocialUtils.createChartDataset(dateRange, chartData.data);
  }, [chartData]);

  function getSeriesData() {
    setSeries([
      {
        name: chartData.label,
        data: chartDataSet.map((d) => d.value) || [],
      },
    ]);
  }

  const options: ApexOptions = {
    chart: {
      id: chartId,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartDataSet.map((d) => d.label),
      type: "datetime",
      labels: {
        format: DATE_TIME_FORMAT.chartDateFormat,
        datetimeUTC: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return valueFormater(value);
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        format: DATE_TIME_FORMAT.chartDateFormat,
      },
      y: {
        formatter: (val) => {
          return valueFormater(val);
        },
      },
      marker: {
        show: true,
        fillColors: [chartData.color],
      },
    },
    markers: {
      colors: [chartData.color],
    },
    stroke: {
      width: 2,
      curve: "smooth",
      colors: [chartData.color],
    },
    fill: {
      colors: [chartData.color],
    },
    grid: {
      strokeDashArray: 4,
      yaxis: {},
    },
  };

  return (
    <div style={{ height: 258 }} className="site-traffic-chart">
      <Chart
        options={options}
        series={series}
        type="area"
        width={"100%"}
        height={258}
      />
    </div>
  );
}

export default memo(EngagementChart);
