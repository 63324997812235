import { Button, Divider, Form, Input, Select, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { GaApi } from "../../../../../apis/ga.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import { IntegrationStatus } from "../../../../../constants/app.enums";
import useAdminSite from "../../../../../hooks/useAdminSite";
import useFetchConnection from "../../../../../hooks/useFetchConnection";
import useGetClients from "../../../../../hooks/useGetClients";
import useGoogle from "../../../../../hooks/useGoogle";
import { GaConnectionModel, GaLocationConnectionModel } from "../../../../../models/ga.model";
import {
  connectionState,
  integrationState,
} from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import IntegrationAlert from "../alert";
import GroupBtnIntegration from "../group-btn-integration";
import "./index.scss";

interface Props {
  isAddConnection?: boolean;
  isOpenAddConnection?: boolean;
}

function GoogleMyBusiness(props: Props) {
  const { isAddConnection, isOpenAddConnection } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { fetchConnection } = useFetchConnection();
  const { refreshClientList } = useGetClients();
  const [status, setStatus] = useState(IntegrationStatus.None);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [enableTestBtn, setEnableTestBtn] = useState(false);
  const [accountSelected, setAccountSelected] = useState<number | undefined>(
    undefined
  );
  const [locations, setLocations] = useState<GaLocationConnectionModel[]>([]);
  const [disabledLocations, setDisabledLocations] = useState<GaLocationConnectionModel[]>([]);
  const [disabledGaConnection, setDisabledGaConnection] = useState<GaConnectionModel[]>([])
  const [locationsLoading, setLocationsLoading] = useState(false);

  const [searchLocation, setSearchLocation] = useState("");
  const [connection, setConnection] = useRecoilState(connectionState);
  const { currentConnection, listGAConnection } = connection;
  const { loginGoogle, reAuthenticateGoogle } = useGoogle({
    idConnection: accountSelected,
  });

  const isAdmin = useAdminSite();

  const [integrationValues, setIntegrationValues] =
    useRecoilState(integrationState);

  async function testConnection() {
    setTestLoading(true);
    try {
      const values = form.getFieldsValue();
      await GaApi.testGMBIntegration({
        locationId: values.locationId,
      });
      setStatus(IntegrationStatus.Success);
    } catch {
      setStatus(IntegrationStatus.Error);
    }
    setTestLoading(false);
  }

  const getLocations = async(id: number | undefined) => {
    setLocationsLoading(true)
    try {
      if(id) {
        const { data } = await GaApi.getLocations(id);
        setLocations(data)
      }
    } finally {
      setLocationsLoading(false)
    }
  }

  async function removeConnection() {
    setRemoveLoading(true);
    try {
      const integrationId = form.getFieldValue("id");
      if (!integrationId) throw new Error("");

      await GaApi.removeGMBIntegration(integrationId);

      setIntegrationValues({
        ...integrationValues,
        gmbIntegrations: [],
      });

      showNotification(
        "delete",
        t("clientSites.connectionRemoved", { name: "Google My Business" })
      );

      refreshClientList();
    } catch {}
    setRemoveLoading(false);
  }

  async function submit(values: any) {
    setSaveLoading(true);
    try {
      if (!integrationValues) throw new Error("");

      if (currentConnection) {
        // add connection
        const values = form.getFieldsValue();
        const GmbResponse = await GaApi.addGMBIntegration({
          locationId: values.locationId,
          clientId: integrationValues.clientId,
        });
        if (!GmbResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          gmbIntegrations: [GmbResponse.data],
        });
        setConnection({ ...connection, currentConnection: "" });

        showNotification("success", "Google My Business successfully connected. It’ll take some time to sync data.");
      } else {
        //update connection
        const integrationId = form.getFieldValue("id");
        const values = form.getFieldsValue();
        const GmbResponse = await GaApi.updateGMBIntegration({
          id: integrationId,
          locationId: values.locationId,
        });
        if (!GmbResponse.data) throw new Error("");

        setIntegrationValues({
          ...integrationValues,
          gmbIntegrations: [GmbResponse.data],
        });

        setConnection({ ...connection, currentConnection: "" });
      }

      refreshClientList();
    } catch {}
    setSaveLoading(false);
  }

  function onChangeAccount(value: number) {
    setAccountSelected(value);
    setEnableTestBtn(false);
    form.resetFields(["locationId"]);
    getLocations(value)
  }

  function onChangeLocation(value: number) {
    setEnableTestBtn(!!value);
  }

  function onSearchLocation(e: any) {
    setSearchLocation(e.target.value);
  }

  function onBlurSearch() {
    setSearchLocation("");
  }

  async function onReAuthenticate() {
    reAuthenticateGoogle();
  }

  function addConnection() {
    loginGoogle();
  }

  function initGMBIntegration() {
    if (currentConnection && isOpenAddConnection) form.resetFields();
    else {
      if (!integrationValues?.gmbIntegrations[0]) return;
      form.setFieldsValue(integrationValues?.gmbIntegrations[0]);
      const { gmbLocation } = integrationValues?.gmbIntegrations[0];
      if (!gmbLocation) return;
      setAccountSelected(gmbLocation.googleConnection.id);
      form.setFieldValue("account", gmbLocation.googleConnection.id);
      if (gmbLocation.id) {
        form.setFieldValue("locationId", gmbLocation.id);
        setEnableTestBtn(true);
      }
      if(!gmbLocation.googleConnection.clientId && !isAdmin) {
        setIsDisabled(true)
  
        if (gmbLocation) {
          setDisabledLocations([{
            name: gmbLocation.name,
            address: gmbLocation.address,
            id: gmbLocation.id
          }])
          if (gmbLocation.googleConnection) {
            setDisabledGaConnection(
              [
                {
                  familyName: gmbLocation.googleConnection.familyName,
                  id: gmbLocation.googleConnection.id,
                  givenName: gmbLocation.googleConnection.givenName
                }
              ]
            )
          }
        }
      }
      else {
        getLocations(gmbLocation.googleConnection.id)
      }
    }
    if (
      integrationValues?.gmbIntegrations[0]?.gmbLocation?.googleConnection
        ?.isInvalid
    )
      setStatus(IntegrationStatus.ConnectionGALostError);
    else if (integrationValues?.gmbIntegrations[0]?.gmbLocation?.isInvalid)
      setStatus(IntegrationStatus.ConnectionLostError);
    else setStatus(IntegrationStatus.None);
  }

  function displayLocation(location: GaLocationConnectionModel) {
    const { address, name } = location;
    const indexSearch = name.indexOf(searchLocation);
    return (
      <div className="display-property">
        <div className="property-name">
          {name.substring(0, indexSearch)}
          <span className="highlight">{searchLocation}</span>
          {name.substring(indexSearch + searchLocation.length)}
        </div>
        <div className="property-id">{address}</div>
      </div>
    );
  }

  async function syncLocations() {
    if (!accountSelected) return;
    setSyncLoading(true);
    try {
      await GaApi.syncLocations(accountSelected);
      await fetchConnection();
    } catch (error) {}
    setSyncLoading(false);
  }

  useEffect(() => {
    initGMBIntegration();
  }, [currentConnection, integrationValues]);

  useEffect(() => {
    if (
      status !== IntegrationStatus.None &&
      status !== IntegrationStatus.ConnectionGALostError
    ) {
      setTimeout(function () {
        if (
          integrationValues?.gmbIntegrations[0]?.gmbLocation?.googleConnection
            ?.isInvalid
        )
          setStatus(IntegrationStatus.ConnectionGALostError);
        else if (integrationValues?.gmbIntegrations[0]?.gmbLocation?.isInvalid)
          setStatus(IntegrationStatus.ConnectionLostError);
        else setStatus(IntegrationStatus.None);
      }, 5000);
    }
  }, [status]);


  return (
    <div className="integration-form__section">
      <IntegrationAlert status={status} onReAuthenticate={onReAuthenticate} />

      <Form
        name="googleAnalyticsIntegration"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={submit}
        form={form}
        className={`custom-connection-form ${
          !currentConnection ? "hiden-required-mark" : ""
        }`}
      >
        <Form.Item
          name="account"
          label={t("clientSites.account")}
          rules={[{ required: true }]}
        >
          <Select
            disabled={isDisabled}
            onChange={onChangeAccount}
            value={accountSelected}
            popupClassName="select-account-ga"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Button
                  type="text"
                  className="add-connection"
                  icon={<SVGIcons.LogoGoogleIcon />}
                  onClick={addConnection}
                >
                  {t("clientSites.signInWithGoogle")}
                </Button>
              </>
            )}
          >
            {(isDisabled ? disabledGaConnection : listGAConnection).map((connection) => {
              return (
                <Select.Option value={connection.id} key={connection.id}>
                  {`${connection.givenName} ${connection.familyName}`}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="locationId"
          label={t("clientSites.location")}
          rules={[{ required: true }]}
        >
          <Select
            disabled={isDisabled}
            loading={locationsLoading}
            onChange={onChangeLocation}
            popupClassName="select-property-ga"
            dropdownRender={(menu) => (
              <>
                <div className="search-property">
                  <Input
                    value={searchLocation}
                    suffix={<SVGIcons.SearchIcon />}
                    onChange={onSearchLocation}
                    onBlur={onBlurSearch}
                  />
                  <Tooltip
                    title="Click to get the latest locations"
                    placement="topRight"
                  >
                    <Button
                      type="default"
                      className="refresh-btn"
                      onClick={syncLocations}
                    >
                      <SVGIcons.RefreshIcon />
                    </Button>
                  </Tooltip>
                </div>
                <Spin spinning={syncLoading}>{menu}</Spin>
              </>
            )}
          >
            {(isDisabled ? disabledLocations : locations).filter((p) => p.name.includes(searchLocation))
              .map((location) => {
                return (
                  <Select.Option value={location.id} key={location.id}>
                    {displayLocation(location)}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <GroupBtnIntegration
          removeLoading={removeLoading}
          testLoading={testLoading}
          saveLoading={saveLoading}
          onTestConnection={testConnection}
          connectionName="Google My Business"
          onRemove={removeConnection}
          disableTestBtn={!enableTestBtn}
          disableSaveBtn={isDisabled}
          isAddConnection={isAddConnection}
        />
      </Form>
    </div>
  );
}

export default GoogleMyBusiness;
