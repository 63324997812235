import {
  Button,
  Calendar,
  Col,
  Popover,
  Row
} from "antd";
import { CalendarMode } from "antd/lib/calendar/generateCalendar";
import { RangePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT, DateRangeList } from "../../../constants/app-constants";
import { DateRangeValues } from "../../../constants/app.enums";
import SVGIcons from "../../icons/svgs";
import { AppDateRangePickerProps } from "../app-date-range-picker";
import "./index.scss";

function AppDateRangePicker2(props: AppDateRangePickerProps) {
  const {
    defaulValue,
    onChange: onChangeProps,
    disabled,
    hasBorder,
    defaultDateRange,
    isHomeDateRange,
  } = props;
  const { t } = useTranslation();
  const [rangeSelected, setRangeSelected] = useState(defaulValue);

  const [dateOriginValue, setDateOriginValue] = useState<Moment[]>([
    moment(),
    moment(),
  ]);

  const [dateValue, setDateValue] = useState<Moment[]>([moment(), moment()]);

  const [showEndDate, setShowEndDate] = useState(false);
  const [calendarValue, setCalendarValue] = useState<Moment>(moment());
  const [popoverVisible, setPopoverVisible] = useState(false);

  useEffect(() => {
    setCalendarValue(showEndDate ? dateValue[1] : dateValue[0]);
  }, [dateValue]);

  useEffect(() => {
    setCalendarValue(showEndDate ? dateValue[1] : dateValue[0]);
  }, [showEndDate]);

  useEffect(() => {
    if (defaulValue) {
      const rangeDate = getDateRange(defaulValue);
      onChange(rangeDate[0], rangeDate[1], defaulValue);
      selectRange(defaulValue);
    }
  }, [defaulValue]);

  function getDateRange(value: DateRangeValues | string) {
    let endDate = moment().add(-1, "days");
    let startDate = moment().add(-1, "days");

    switch (value) {
      case DateRangeValues.lastMonth:
        startDate = moment().subtract(1, "months").startOf('month')
        endDate = moment().subtract(1, "months").endOf('month')

        break;
      case DateRangeValues.lastYear:
        startDate = moment().subtract(1, "years").startOf('year')
        endDate = moment().subtract(1, "years").endOf('year')

        break;
      case DateRangeValues.last7days:
        startDate = moment().subtract(1, "weeks").startOf('isoWeek')
        endDate = moment().subtract(1, "weeks").endOf('isoWeek')
        break;

      case DateRangeValues.last3Months:
        startDate = moment().add(-3, "months").startOf('month')
        endDate = moment().add(-1, "months").endOf('month')
        break;
      case DateRangeValues.thisWeek:
        startDate = moment().startOf('isoWeek')
        endDate = moment().endOf('isoWeek')
        break;

      case DateRangeValues.thisMonth:
        startDate = moment().startOf('month')
        endDate = moment().endOf('month')
        break;

      case DateRangeValues.thisYear:
        startDate = moment().startOf('year')
        endDate = moment().endOf('year')
        break;
      default:
        break;
    }

    return [startDate, endDate];
  }

  function selectRange(value: DateRangeValues | string) {
    setRangeSelected(value);
    const dateRange = getDateRange(value);
    setDateValue(dateRange);
  }

  function apply() {
    onChange(dateValue[0], dateValue[1], rangeSelected);
    setPopoverVisible(false);
  }

  function onChange(startDate: Moment, endDate: Moment, value: string) {
    onChangeProps({
      endDate: endDate,
      startDate: startDate,
      value: value,
    });
    setDateOriginValue([startDate, endDate]);
  }

  useEffect(() => {
    if (defaultDateRange) setDateValue(defaultDateRange);
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = useCallback(
    (current: Moment) => {
      const outOfRange =
        current <= moment().add(-1, "years").add(-1, "d") || current > moment();

      if (showEndDate) {
        return outOfRange || current < dateValue[0];
      }

      return outOfRange || current > dateValue[1];
    },
    [showEndDate]
  );

  const header = (config: {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
  }) => {
    const { value, type, onChange, onTypeChange } = config;
    const nextMonthDate = value.clone().add(1, "M");
    const preMonthDate = value.clone().add(-1, "M");

    return (
      <div className="app-date-range-picker-header">
        <span className="btn-pre" onClick={() => onChange(preMonthDate)}>
          <SVGIcons.ChevronLeft />
        </span>
        <span>{moment(value).format("MMM, YYYY")}</span>
        <span className="btn-next" onClick={() => onChange(nextMonthDate)}>
          <SVGIcons.ChevronRight />
        </span>
      </div>
    );
  };

  function selectDate(date: Moment) {
    if (showEndDate) {
      setDateValue([dateValue[0], date]);
    } else {
      setDateValue([date, dateValue[1]]);
    }

    setRangeSelected(DateRangeValues.customRange);
  }

  const dateRender = (date: Moment) => {
    const isSelected = showEndDate
      ? date.format("DDMMYYY") === dateValue[1].format("DDMMYYY")
      : date.format("DDMMYYY") === dateValue[0].format("DDMMYYY");
    const inrange = dateValue[0] <= date && date <= dateValue[1];
    return (
      <div
        className={`custom-date-cell${isSelected ? " custom-date-cell__selected" : ""
          }${inrange ? " custom-date-cell__inrange" : ""}`}
        onClick={() => selectDate(date)}
      >
        <div className="custom-date-cell-value">{date.format("D")}</div>
      </div>
    );
  };

  return (
    <>
      <Popover
        showArrow={false}
        open={disabled ? false : popoverVisible}
        placement="bottomLeft"
        overlayClassName="custom-popover"
        content={
          <div

            className="app-date-range-picker__popover"
          >
            <div className="time-select" >
              {DateRangeList
                .filter((x) => x.key !== DateRangeValues.customRange)
                .map((item, index) => (
                  <Button
                    key={index}
                    type="text"
                    className={`${item.key === rangeSelected ? "active" : ""}`}
                    onClick={() => selectRange(item.key)}
                    block
                    style={{ justifyContent: "flex-start" }}
                  >
                    {item.text}
                  </Button>
                ))}
            </div>
            <div className="app-date-range-picker">
              <Row>
                <Col span={24} className="app-date-range-picker-value">
                  <span
                    className={`${showEndDate ? "" : "active"}`}
                    onClick={() => setShowEndDate(false)}
                  >
                    {dateValue[0].format(DATE_TIME_FORMAT.dateView)}
                  </span>
                  <div className="separator"> - </div>
                  <span
                    className={`${showEndDate ? "active" : ""}`}
                    onClick={() => setShowEndDate(true)}
                  >
                    {dateValue[1].format(DATE_TIME_FORMAT.dateView)}
                  </span>
                </Col>
              </Row>
              <div className="app-date-range-picker__footer">
                <Calendar
                  headerRender={header}
                  fullscreen={false}
                  disabledDate={disabledDate}
                  dateFullCellRender={dateRender}
                  value={calendarValue}
                  onChange={(d) => setCalendarValue(d)}
                />
                <div
                  className="app-date-range-picker__apply"
                >
                  <Button type="text" onClick={apply}>
                    {t("common.apply")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
        trigger="click"
        onOpenChange={(visible) => setPopoverVisible(visible)}
      >
        <div
          className={`date-range-picker-box ${hasBorder ? "app-range-picker-has-border" : ""
            } ${disabled ? "app-range-picker-disabled" : ""}
              ${(isHomeDateRange && disabled) ? "app-range-picker-home-date-range" : ""}
            `}
          onClick={() => setPopoverVisible(true)}>
          <SVGIcons.CalendarIcon />
          <div>
            <span>{dateOriginValue[0].format(DATE_TIME_FORMAT.dateView)}</span>
            <span className="separator"> - </span>
            <span>{dateOriginValue[1].format(DATE_TIME_FORMAT.dateView)}</span>
          </div>
        </div>
      </Popover>
    </>
  );
}

export default AppDateRangePicker2;
