import { Modal, Select, Tag } from "antd";
import { useState } from "react";
import { UserServiceModel } from "../../../../../models/service.model";
import SVGIcons from "../../../../icons/svgs";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import "./index.scss";

interface AddUserServiceModalProps {
  open: boolean;
  close: () => void;
  addUserService: (userIds: number[]) => Promise<void>;
  listAddableUser: UserServiceModel[];
}

const AddUserServiceModal = (props: AddUserServiceModalProps) => {
  const { open, close, addUserService, listAddableUser } = props;

  const [userSelected, setUserSelected] = useState<number[]>([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const onOK = async () => {
    setLoadingModal(true);
    try {
      await addUserService(userSelected);
      setUserSelected([]);
    } catch (error) {}
    setLoadingModal(false);
  };

  const onChange = (value: any) => {
    setUserSelected(value);
    setSearchValue("");
  };

  const onSearch = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };

  const onBlurSearch = () => {
    setSearchValue("");
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const userInfo = listAddableUser.find((user) => user.id === value);
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, marginBottom: 3 }}
      >
        {userInfo && userInfo.fullName}
      </Tag>
    );
  };

  function displayUser(user: UserServiceModel) {
    const { fullName, email } = user;
    const indexSearch = fullName
      .toLocaleLowerCase()
      .indexOf(searchValue.toLocaleLowerCase());
    return (
      <div className="display-user-social-select">
        <div className="user-name">
          {fullName.substring(0, indexSearch)}
          <span className="highlight">
            {fullName.substring(indexSearch, indexSearch + searchValue.length)}
          </span>
          {fullName.substring(indexSearch + searchValue.length)}
        </div>
        <div className="email">{email}</div>
      </div>
    );
  }
  return (
    <Modal
      title={"Social - Add Users"}
      open={open}
      onCancel={close}
      onOk={onOK}
      closable
      maskClosable
      okText="Add Users"
      okButtonProps={{ disabled: !userSelected.length }}
      className="custom-create-modal add-user-service-modal"
      confirmLoading={loadingModal}
    >
      <div className="add-user-service-form">
        <div className="label">
          Username or Email <span>*</span>
        </div>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Search..."
          suffixIcon={<SVGIcons.SearchIcon />}
          showArrow
          popupClassName="addable-user-select"
          tagRender={tagRender}
          onSearch={onSearch}
          onChange={onChange}
          onBlur={onBlurSearch}
          filterOption={false}
          value={userSelected}
        >
          {listAddableUser
            .filter((user) =>
              user.fullName
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase())
            )
            .map((user) => {
              return (
                <Select.Option key={user.id} value={user.id} user={user}>
                  {displayUser(user)}
                </Select.Option>
              );
            })}
        </Select>
      </div>
    </Modal>
  );
};

export default AddUserServiceModal;
