import { AxiosResponse } from "axios";
import { GetManageBillingUrl } from "../models/stripes.model";
import { getAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth/api/v1`;

function getManageBillingUrl(): Promise<AxiosResponse<GetManageBillingUrl, any>> {
    const url = `${baseUrl}/stripes/access-portal
`;
    return getAsync(url);
}

export const StripesApi = {
    getManageBillingUrl,
}