import { Button, Col, Input, Modal, Row, Slider, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import showNotification from "../../../../../../../common/notification";
import SVGIcons from "../../../../../../../icons/svgs";
import EmptyResultAI from "../../../../../../../../assets/images/empty-result-AI.png";
import "./index.scss";
import IdeasCollapse from "./ideas-collapse";
import useClient from "../../../../../../../../hooks/useClient";
import { SocialApi } from "../../../../../../../../apis/social.api";
import { AITone } from "../../../../../../../../constants/app.enums";
import {
  IdeaModel,
  ResultGenerateAIModel,
  SuggestIdeasAIModel,
} from "../../../../../../../../models/ai.model";
import AILoadingGif from "../../../../../../../../assets/animations/AI-loading-animation.gif";
import { PlanUsageModel } from "../../../../../../../../models/plan.model";

interface WriteWithAIModalProps {
  open: boolean;
  close: () => void;
  addContent: (content: string, isNew?: boolean) => void;
}

const listTones = [
  { key: AITone.Polite, label: "Polite", icon: <SVGIcons.MoodSmileIcon /> },
  { key: AITone.Funny, label: "Funny", icon: <SVGIcons.MoodHappyIcon /> },
  {
    key: AITone.Enthusiastic,
    label: "Enthusiastic",
    icon: <SVGIcons.MoodCrazyHappyIcon />,
  },
  {
    key: AITone.Friendly,
    label: "Friendly",
    icon: <SVGIcons.MoodSmileBeamIcon />,
  },
  {
    key: AITone.Informational,
    label: "Informational",
    icon: <SVGIcons.MoodNerdIcon />,
  },
  { key: AITone.Witty, label: "Witty", icon: <SVGIcons.MoodTongueWink /> },
];

const defaultIdea = {
  prompt: "",
  tone: AITone.Polite,
  approximate: 70,
  isIncludeHashTags: true,
  isIncludeEmoji: true,
};

const WriteWithAIModal = (props: WriteWithAIModalProps) => {
  const { open, close, addContent } = props;
  const { t } = useTranslation();
  const { client } = useClient();

  const [listSaved, setListSaved] = useState<IdeaModel[]>([]);
  const [ideaSelected, setIdeaSelected] = useState<IdeaModel>(defaultIdea);
  const [listResult, setListResult] = useState<ResultGenerateAIModel[]>([]);
  const [resultSelected, setResultSelected] = useState<ResultGenerateAIModel>();
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isSavedIdea, setIsSavedIdea] = useState(false);
  const [aiUsage, setAiUsage] = useState<PlanUsageModel>();
  const [statusPrompt, setStatusPrompt] = useState<
    "" | "error" | "warning" | undefined
  >("");

  const onChangeDescribe = (e: any) => {
    setIdeaSelected({ ...ideaSelected, prompt: e.target.value });
    setIsSavedIdea(false);
    setStatusPrompt("");
  };

  const onClickTone = (tone: AITone) => {
    setIdeaSelected({ ...ideaSelected, tone: tone });
    setIsSavedIdea(false);
  };

  const onChangeApproximate = (value: number) => {
    setIdeaSelected({ ...ideaSelected, approximate: value });
    setIsSavedIdea(false);
  };

  const onChangeHashtag = (value: boolean) => {
    setIdeaSelected({ ...ideaSelected, isIncludeHashTags: value });
    setIsSavedIdea(false);
  };

  const onChangeEmoji = (value: boolean) => {
    setIdeaSelected({ ...ideaSelected, isIncludeEmoji: value });
    setIsSavedIdea(false);
  };

  const onSelectIdea = (idea: IdeaModel) => {
    setIdeaSelected(idea);
    setIsSavedIdea(false);
  };

  const onSelectSuggestIdea = (idea: SuggestIdeasAIModel) => {
    setIdeaSelected({ ...ideaSelected, prompt: idea.prompt, id: idea.id });
    setIsSavedIdea(false);
  };

  const onClickGenerate = async () => {
    if (!client?.id) return;
    setLoadingGenerate(true);
    try {
      const { data } = await SocialApi.AI.generateAIContent({
        clientId: client.id,
        ...ideaSelected,
      });
      if (data.usage) {
        setAiUsage(data.usage);
      }
      const results = data.results ? data.results.filter((x) => x.result) : [];
      setListResult(results);
    } catch (error) { }
    setLoadingGenerate(false);
  };

  const onClickResult = (result: ResultGenerateAIModel) => {
    setResultSelected(result);
  };

  const onClose = () => {
    setIdeaSelected(defaultIdea);
    close();
  };

  const onAddContent = () => {
    addContent(resultSelected?.result || "", true);
    showNotification("success", "Generated content added successfully");
    setIdeaSelected(defaultIdea);
    setResultSelected(undefined);
    setListResult([]);
    close();
  };

  const deleteIdea = (ideaId: number) => {
    Modal.confirm({
      title: t(`social.aiFeature.deleteIdeaTitle`),
      content: <span>{t(`social.aiFeature.deleteIdeaDescription`)}</span>,
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: async () => {
        await SocialApi.AI.deleteIdea(ideaId);
        initSavedIdeas();
        showNotification("delete", "Saved idea deleted successfully");
        setIdeaSelected(defaultIdea);
        setIsSavedIdea(false);
      },
    });
  };

  const initSavedIdeas = async () => {
    if (!client?.id) return;
    const { data } = await SocialApi.AI.getSavedIdeas(client?.id);
    setListSaved(data.aiClientIdeaModels);
    setAiUsage(data.usage);
  };

  const saveYourIdea = async () => {
    if (!ideaSelected?.prompt) {
      setStatusPrompt("error");
      return;
    }
    if (!client?.id) return;
    setLoadingSave(true);
    try {
      const res = await SocialApi.AI.createIdeas({
        ...ideaSelected,
        clientId: client.id,
      });
      setIsSavedIdea(true);
      await initSavedIdeas();

      if (res && res.data) {
        setIdeaSelected(res.data);
      }

    } catch (error) { }
    setLoadingSave(false);
  };
  useEffect(() => {
    setIdeaSelected(ideaSelected);
    setStatusPrompt("");
  }, [ideaSelected]);

  useEffect(() => {
    initSavedIdeas();
  }, [client]);

  return (
    <Modal
      open={open}
      title={t("social.aiFeature.writeWithAI")}
      className="custom-create-modal write-with-ai-modal"
      onCancel={onClose}
      maskClosable={false}
      footer={null}
    >
      <Row className="container">
        <Col span={6}>
          <IdeasCollapse
            listSavedIdeas={listSaved}
            ideaSelected={ideaSelected}
            onSelectIdea={onSelectIdea}
            onSelectSuggestIdea={onSelectSuggestIdea}
            deleteIdea={deleteIdea}
          />
        </Col>
        <Col span={6} className="your-idea">
          <div className="describe">
            <span>Describe Your Content</span>
            <span className="limit">{ideaSelected.prompt.length}/900</span>
          </div>
          <Input.TextArea
            placeholder="e.g. A post for an ice-cream shop…"
            value={ideaSelected?.prompt}
            onChange={onChangeDescribe}
            maxLength={900}
            status={statusPrompt}
          ></Input.TextArea>

          <div className="tone-label">Select The Tone</div>
          <div className="tones">
            {listTones.map((tone) => {
              return (
                <Button
                  onClick={() => onClickTone(tone.key)}
                  className={ideaSelected.tone === tone.key ? "selected" : ""}
                  key={tone.label}
                >
                  <span>{tone.icon}</span>
                  <span>{tone.label}</span>
                </Button>
              );
            })}
          </div>

          <div className="approximate">
            Approximate Words: {ideaSelected.approximate}
          </div>
          <Slider
            value={ideaSelected.approximate}
            onChange={onChangeApproximate}
            min={10}
          />

          <div className="include">
            <div>
              <Switch
                checked={ideaSelected.isIncludeHashTags}
                onChange={onChangeHashtag}
              />{" "}
              Include hashtags
            </div>
            <div>
              <Switch
                checked={ideaSelected.isIncludeEmoji}
                onChange={onChangeEmoji}
              />{" "}
              Include emoji
            </div>
          </div>

          <Button
            type="text"
            className={`save ${isSavedIdea ? "saved" : ""}`}
            onClick={() => !isSavedIdea && saveYourIdea()}
            loading={loadingSave}
            disabled={listSaved.length >= 10}
          >
            {isSavedIdea ? (
              <SVGIcons.CheckedNotRoundedIcon />
            ) : (
              <SVGIcons.BookmarkIcon />
            )}
            {isSavedIdea ? "Saved" : "Save Your Idea"}
          </Button>

          <div className="generate">
            <Button
              type="primary"
              className="ant-btn-primary secondary-btn"
              onClick={onClickGenerate}
              loading={loadingGenerate}
            >
              {loadingGenerate ? "Generating..." : "Generate"}
            </Button>
          </div>
        </Col>
        <Col span={12} className="result">
          <div>Results</div>
          <small>Select one of the results to add to content</small>
          {loadingGenerate ? (
            <div className="empty-result">
              <img src={AILoadingGif} alt="" />
              <div className="title">Generating content…</div>
              <div className="description">Please wait for a few minutes.</div>
            </div>
          ) : listResult.length === 0 ? (
            <div className="empty-result">
              <img src={EmptyResultAI} alt="" />
              <div className="title">Get Started</div>
              <div className="description">
                1. Write a short description <SVGIcons.ArrowNarrowRight /> 2.
                Select a tone <SVGIcons.ArrowNarrowRight /> 3. Click Generate
                and Bob Your Uncle!
              </div>
            </div>
          ) : (
            <div className="list-result">
              {listResult.map((r, idx) => {
                return (
                  <div
                    className={`result-item ${r.id === resultSelected?.id ? "selected" : ""
                      }`}
                    key={idx}
                    onClick={() => onClickResult(r)}
                  >
                    {r.result}
                  </div>
                );
              })}
            </div>
          )}

          <div className="result-footer">
            <div className="credit">
              <SVGIcons.InfoCircleIcon />
              AI Credits: {aiUsage?.totalUsed || 0}/{aiUsage?.maximum ?? 0}
            </div>
            <Button
              type="primary"
              disabled={!resultSelected}
              onClick={onAddContent}
            >
              Add To Content
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default WriteWithAIModal;
