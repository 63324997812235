import { Spin } from "antd";
import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import {
  CHART_COLORS,
  EMPTY_DATA_COLOR,
} from "../../../../../../constants/app-constants";
import { ChartDataModel } from "../../../../../../models/chart.model";
import { PostTypeModel } from "../../../../../../models/social.model";
import { NUMBER_UTIL } from "../../../../../../utils/number.utils";
import SocialUtils from "../../../../../../utils/social";
import "./index.scss";

interface Props {
  chartId: string;
  listPostType: PostTypeModel[];
  loading: boolean;
  isEmpty: boolean;
}

function PostTypeDistributionChart(props: Props) {
  const { t } = useTranslation();
  const { chartId, listPostType, loading, isEmpty } = props;
  const emptyData: ChartDataModel[] = [
    {
      label: "Post type name",
      value: 0,
    },
    {
      label: "Post type name",
      value: 0,
    },
    {
      label: "Post type name",
      value: 0,
    },
    {
      label: "Post type name",
      value: 0,
    },
    {
      label: "Post type name",
      value: 0,
    },
  ];

  const series = listPostType.map((x) => x.postsCount);
  const colors = CHART_COLORS.filter((x, i) => i < series.length);

  const options: ApexOptions = listPostType.length
    ? {
        chart: {
          id: chartId,
          toolbar: {
            show: false,
          },
        },
        labels: listPostType.map((x) =>
          SocialUtils.createKeyPostType(x.postType)
        ),
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: listPostType.map((x) =>
            SocialUtils.createKeyPostType(x.postType)
          ),
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          max: 100,
        },
        fill: {
          colors: colors,
        },
        tooltip: {
          fillSeriesColor: false,
          custom: function ({ series, seriesIndex }) {
            return (
              '<div class="custom-tooltip-wrapper">' +
              `<span class="tooltip-color" style="background-color: ${colors[seriesIndex]}"></span>` +
              `<span class="tooltip-name">${SocialUtils.createKeyPostType(
                listPostType[seriesIndex].postType
              )}:</span>` +
              "<span>" +
              series[seriesIndex] +
              "</span>" +
              "</div>"
            );
          },
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          },
        },
      }
    : {};

  const totalPost = listPostType.reduce((sum, a) => sum + a.postsCount, 0);

  return (
    <Spin spinning={loading}>
      <div className="post-type-distribution">
        <div className="post-type-distribution__chartWrapper">
          {listPostType.length ? (
            <>
              <Chart
                options={options}
                series={series}
                type="donut"
                width={180}
                height={180}
              />
              <div className="post-type-distribution__total">
                <span>{t("dashboard.totalPosts")}</span>
                <span>
                  {NUMBER_UTIL.convertNumericToFormattedString(totalPost)}
                </span>
              </div>
            </>
          ) : (
            <div
              className="post-type-distribution__emptyChart"
              style={{
                background: EMPTY_DATA_COLOR,
                width: 180,
                height: 180,
              }}
            >
              <div className="post-type-distribution__empty__total">
                <span>{t("dashboard.totalPosts")}</span>
                <span>{totalPost}</span>
              </div>
            </div>
          )}
        </div>
        <div className="post-type-distribution__legend">
          {listPostType.length && !isEmpty
            ? listPostType
                .sort((a, b) => b.postsCount - a.postsCount)
                .map((item, index) => (
                  <div
                    key={index}
                    className="post-type-distribution__legend__item"
                  >
                    <span
                      style={{ backgroundColor: CHART_COLORS[index] }}
                    ></span>
                    <span className="post-type-distribution__legend__item__name">
                      {item.postType
                        ? SocialUtils.createKeyPostType(item.postType)
                        : "Post type name"}
                    </span>
                    <span className="post-count">
                      {NUMBER_UTIL.convertNumericToFormattedString(
                        item.postsCount
                      )}
                    </span>
                  </div>
                ))
            : emptyData.map((item, index) => (
                <div
                  key={index}
                  className="post-type-distribution__legend__item"
                >
                  <span style={{ backgroundColor: CHART_COLORS[index] }}></span>
                  <span className="post-type-distribution__legend__item__name">
                    {item.label}
                  </span>
                  <span>{item.value}</span>
                </div>
              ))}
        </div>
      </div>
    </Spin>
  );
}

export default memo(PostTypeDistributionChart);
