import { useGoogleLogin } from "@react-oauth/google";
import { useRecoilState } from "recoil";
import { GaApi } from "../apis/ga.api";
import { clientSitesState } from "../states/clientSites";
import useFetchConnection from "./useFetchConnection";
import useGetClients from "./useGetClients";
import useAdminSite from "./useAdminSite";
import useClient from "./useClient";

interface UseGoogleProps {
  idConnection?: number;
}

const useGoogle = (props: UseGoogleProps) => {
  const { idConnection } = props;
  const { getClients } = useGetClients();
  const { fetchConnection } = useFetchConnection();
  const [state, setState] = useRecoilState(clientSitesState);

  const isAdmin = useAdminSite();
  const { clientId } = useClient();

  const scope = [
    "https://www.googleapis.com/auth/analytics.readonly",
    "https://www.googleapis.com/auth/business.manage",
  ];

  const loginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      await GaApi.addConnection({ code, redirectUrl: window.location.origin, clientId: isAdmin ? null : clientId });
      await fetchConnection();
    },
    scope: scope.join(" "),
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
  });

  const reAuthenticateGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      if (!idConnection) return;
      const { code } = codeResponse;
      await GaApi.reAuthenConnection({
        code,
        redirectUrl: window.location.origin,
        id: idConnection,
      });

      await fetchConnection();
      await getClients();
    },
    scope: scope.join(" "),
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
  });

  return {
    loginGoogle,
    reAuthenticateGoogle,
  };
};

export default useGoogle;
