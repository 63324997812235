import { Button, Divider, Form, Modal, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ZohoApi } from "../../../../../apis/zoho.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../../constants/app-constants";
import {
  IntegrationStatus,
  ZohoSyncStatus,
} from "../../../../../constants/app.enums";
import useZoho from "../../../../../hooks/useZoho";
import { ZohoAdminClientInfo } from "../../../../../models/zoho.model";
import SVGIcons from "../../../../icons/svgs";
import IntegrationAlert from "../alert";
import { useRecoilValue } from "recoil";
import { clientSitesState } from "../../../../../states/clientSites";
import showNotification from "../../../../common/notification";

const AddZohoConnection = () => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [status, setStatus] = useState<IntegrationStatus | ZohoSyncStatus>(
    IntegrationStatus.None
  );
  const [zohoAdminClients, setZohoAdminClients] = useState<ZohoAdminClientInfo[]>([]);
  const [selectedId, setSelectedId] = useState<number>();

  const { connectZoho, connectZohoLoading, checkConnectedZoho, getZohoClient, zohoClients } = useZoho();
  const { clientSelected } = useRecoilValue(clientSitesState);

  const onAddConnection = () => {
    if (zohoClients.length === 3) {
      showNotification("error", "Only 3 Zoho accounts are allowed. Please remove one account then try again.")
      return;
    }
    setOpen(true);
  };

  const submit = () => {
    form.validateFields().then(async (values) => {
      try {
        if (!clientSelected?.id || !selectedId) return;
        setSaveLoading(true);
        await ZohoApi.addMapZohoClient(clientSelected.id, selectedId);
        setOpen(false);
        getZohoClient(clientSelected.id);
      } catch (error) { }
      setSaveLoading(false);
    });
  };

  const onChangeAccount = (value: number) => {
    setSelectedId(value);
  };

  const onTestConnection = () => {
    form.validateFields().then(async () => {
      try {
        if (!selectedId) return;
        setTestLoading(true);
        await checkConnectedZoho(selectedId);
        setStatus(IntegrationStatus.Success);
      } catch (error) {
        setStatus(IntegrationStatus.Error);
      }
      setTestLoading(false);

    });
  };

  async function getAdminZohoAccount() {
    if (!clientSelected?.id) return;

    try {
      setLoading(true);
      let { data } = await ZohoApi.getAllAdminZohoClient(clientSelected.id);
      if (data && data.length) {
        data = data.filter(x => !x.isConnected);
        setZohoAdminClients(data);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      form.resetFields();
      getAdminZohoAccount();
    }
  }, [open]);
  return (
    <div className="add-zoho-connection">
      <Button onClick={onAddConnection}>
        <SVGIcons.PlusIcon />
        <span className="mr-right-4">{t("clientSites.addConnection")}</span>
      </Button>
      <Modal
        title="Add Connection - Zoho CRM"
        centered
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        className="custom-create-modal"
      >
        <Form
          name="addZohoConnection"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          onFinish={submit}
          form={form}
        >
          <div className="connection-alert">
            <IntegrationAlert status={status} />
          </div>
          <Form.Item
            label={t("salesAnalytics.account")}
            name="zohoClientId"
            required
          >
            <Select
              onChange={onChangeAccount}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <div
                    onClick={() => !connectZohoLoading && connectZoho()}
                    className="connect-account"
                  >
                    {connectZohoLoading ? (
                      <Spin size="small" />
                    ) : (
                      <SVGIcons.ZohoIcon />
                    )}
                    {t("salesAnalytics.connectAccount")}
                  </div>
                </>
              )}
              loading={loading}
            >
              {zohoAdminClients.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.zohoClientId}>
                    <div className="account-dropdown">{item.email}</div>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <div className={`group-btn-integration`}>
            <Button
              className="test-btn secondary-btn"
              type="primary"
              loading={testLoading}
              onClick={onTestConnection}
              disabled={!selectedId}
            >
              {t("clientSites.testConnection")}
            </Button>
            <Button
              htmlType="submit"
              className="save-btn primary-btn"
              type="primary"
              loading={saveLoading}
              disabled={!selectedId}
            >
              {t("clientSites.addConnection")}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddZohoConnection;
