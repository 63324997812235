import { useState } from "react";
import {
  PlatformStatusType,
  SocialType,
} from "../../../../../../constants/app.enums";
import SVGIcons from "../../../../../icons/svgs";
import { AvatarSocialStyled } from "./index.style";
import AvatarDefault from "../../../../../../assets/images/avatar-default.svg";
import "./index.style.ts";
import { Tooltip } from "antd";
import { useRecoilValue } from "recoil";
import { isMobileSelector } from "../../../../../../states/common";

interface AvatarSocialProps {
  avatar: string;
  socialType?: SocialType;
  onChangeStatus?: () => void;
  size?: number;
  sizeIcon?: number;
  disabled?: boolean;
  tooltip?: string;
  status?: PlatformStatusType;
  isReviewing?: boolean;
}

const AvatarSocial = (props: AvatarSocialProps) => {
  const {
    avatar,
    socialType,
    onChangeStatus,
    size,
    disabled,
    tooltip,
    sizeIcon,
    status,
    isReviewing,
  } = props;
  const isMobile = useRecoilValue(isMobileSelector);

  const [hovered, setHovered] = useState(false);

  const typeSocialIcon = () => {
    if (!isReviewing && !status && hovered && !disabled) {
      return <div className="remove-icon">x</div>;
    }
    if (!isReviewing && status === PlatformStatusType.Error) {
      return <div className="remove-icon"></div>;
    }
    switch (socialType) {
      case SocialType.Facebook:
        return <SVGIcons.FacebookImage />;
      case SocialType.Instagram:
        return <SVGIcons.InstagramImage />;
      case SocialType.Tiktok:
        return <SVGIcons.TiktokImage />;
      case SocialType.Youtube:
        return <SVGIcons.YoutubeImage />;
      case SocialType.Google:
        return <SVGIcons.GoogleImage />;
      default:
        return <></>;
    }
  };

  const onHoverIcon = () => {
    setHovered(true);
  };

  const onLeaveIcon = () => {
    setHovered(false);
  };

  const onClickAvatar = () => {
    if (!isReviewing && status) return;
    onChangeStatus && onChangeStatus();
  };

  return (
    <Tooltip title={tooltip}>
      {onChangeStatus ? (
        <AvatarSocialStyled
          disabled={
            (isReviewing && disabled) ||
            (!isReviewing && status === PlatformStatusType.Success) ||
            disabled
          }
          size={size}
          sizeIcon={sizeIcon}
        >
          <div
            className="avatar-social-wrap"
            onClick={onClickAvatar}
            onMouseEnter={onHoverIcon}
            onMouseLeave={onLeaveIcon}
          >
           {isMobile ? <img style={{width: 40, height: 40}} src={avatar || AvatarDefault} alt="" /> : <img src={avatar || AvatarDefault} alt="" /> } 
            <div className="social-icon">{typeSocialIcon()}</div>
          </div>
        </AvatarSocialStyled>
      ) : (
        <AvatarSocialStyled
          disabled={status === PlatformStatusType.Success || disabled}
          size={size}
          sizeIcon={sizeIcon}
        >
          <div className="avatar-social-wrap">
            <img src={avatar || AvatarDefault} alt="" />
            <div className="social-icon">{typeSocialIcon()}</div>
          </div>
        </AvatarSocialStyled>
      )}
    </Tooltip>
  );
};

export default AvatarSocial;
