import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import "react-trumbowyg/dist/trumbowyg.min.css";
import Trumbowyg from "react-trumbowyg";
interface Props {
  initValue?: string;
  setValue: Dispatch<SetStateAction<string>>;
  placeholder?: string;
}

const TrumbowygEditor = ({ setValue, initValue, placeholder = "" }: Props) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRender(true);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleChange = (newContent: any) => {
    setValue(newContent.target.innerHTML);
  };

  return (
    <div>
      {shouldRender && (
        <Trumbowyg
          buttons={
            [
              // ['viewHTML'],
              ['formatting'],
              ['strong', 'em', 'del'],
              ['foreColor'],
              ['backColor'],
              ['link'],
              ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
              ['unorderedList', 'orderedList'],
              ['horizontalRule'],
              ['removeformat'],
              // ['fullscreen'],
            ]
          }
          placeholder={placeholder}
          id="react-trumbowyg"
          onChange={handleChange}
          data={initValue}
        />
      )}
    </div>
  );
};

export default TrumbowygEditor;
